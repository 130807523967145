import { useMutation, useQueryClient } from '@tanstack/react-query';

import { billingEntitiesService, ForkBillingEntityPayload } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from '../../organizations/organizationsCacheKey';

export const useAdminForkBillingEntity = (billingEntityId: number, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: ForkBillingEntityPayload) =>
            billingEntitiesService.forkBillingEntity(billingEntityId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Billing entity forked',
            });
            onSuccess();
        },
    });

    const forkBillingEntity = (payload: ForkBillingEntityPayload) => {
        mutation.mutate(payload);
    };

    return {
        forkBillingEntity,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
