import { filter, includes } from 'lodash';
import React, { FC } from 'react';

import { HofyWarehouseDetailsDto } from '@hofy/api-admin';
import { SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../../../components/design/blockFilters/ListMultiBlockFilter';

interface WarehouseBlockFilterProps {
    warehouses: HofyWarehouseDetailsDto[];
    selected: number[];
    setSelected(ids: number[]): void;
}

export const WarehouseBlockFilter: FC<WarehouseBlockFilterProps> = ({
    warehouses,
    selected,
    setSelected,
}) => {
    return (
        <ListMultiBlockFilter
            title='Warehouse'
            icon={SvgIcon.Truck}
            selected={filter(warehouses, wh => includes(selected, wh.idDeprecated))}
            onChange={whs => setSelected(whs.map(wh => wh.idDeprecated))}
            items={warehouses}
            renderItem={wh => wh.name}
        />
    );
};
