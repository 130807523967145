import React, { FC } from 'react';

import { itemsService } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import {
    AlertTooltipIcon,
    FormFieldApi,
    LabeledDebouncedInput,
    Spinner,
    SvgIcon,
    TooltipIcon,
    useAsyncInputFormData,
} from '@hofy/ui';

interface ItemCodeInputProps {
    api: FormFieldApi<string | null>;
    itemId: number;
    isRequired?: boolean;
    width?: number;
}

export const ItemCodeInput: FC<ItemCodeInputProps> = ({ api, itemId, isRequired, width }) => {
    const { inputValue, setInputValue, isLoading, error } = useAsyncInputFormData({
        api,
        dataProvider: inputValue =>
            itemsService
                .getItemByCode(inputValue)
                .then(item => (item?.id === itemId ? item : Promise.reject(itemExistsError))),
    });

    return (
        <LabeledDebouncedInput
            ref={api.ref}
            label='Item code'
            value={inputValue}
            onChange={setInputValue}
            rightSlot={isLoading ? <Spinner size={24} /> : getInputIcon(error)}
            errorMessage={api.errorMessage}
            onBlur={() => api.setTouched(true)}
            isRequired={isRequired}
            width={width}
        />
    );
};

const getInputIcon = (error: Error | null) => {
    return error === itemExistsError ? (
        <AlertTooltipIcon body='Code belongs to another item.' />
    ) : !error ? (
        <TooltipIcon body='Item found' icon={SvgIcon.Check} iconColor={Color.ContentPositive} />
    ) : null;
};

const itemExistsError = new Error('Item code already exists');
