import React, { FC } from 'react';

import { ShipmentCollectionRequestDto, ShipmentDetailsDto } from '@hofy/api-admin';
import { Box, FormSection } from '@hofy/ui';

import { ActivityLogSection } from './activityLogSection/ActivityLogSection';
import { AssignmentDetailsSection } from './assignmentDetailsSection/AssignmentDetailsSection';
import { PackagingDetailsSection } from './packagingDetailsSection/PackagingDetailsSection';
import { RequiredActionsSection } from './requiredActionsSection/RequiredActionsSection';
import { SurveyResultsSection } from './surveyResultsSection/SurveyResultsSection';
import { WarningsSection } from './warningsSection/WarningsSection';

interface ShipmentSlideoutCollectionSurveyProps {
    shipment: ShipmentDetailsDto;
    collectionRequest: ShipmentCollectionRequestDto | null | undefined;
    onAssignmentClick(itemId: number): void;
}

export const ShipmentSlideoutCollectionSurvey: FC<ShipmentSlideoutCollectionSurveyProps> = ({
    shipment,
    collectionRequest,
    onAssignmentClick,
}) => {
    if (!collectionRequest) {
        return null;
    }

    return (
        <Box column gap={24} paddingVertical={24} fullHeight>
            <FormSection label='Warnings'>
                <WarningsSection collectionRequest={collectionRequest} />
            </FormSection>
            <RequiredActionsSection
                shipment={shipment}
                collectionRequest={collectionRequest}
                onAssignmentClick={onAssignmentClick}
            />
            <FormSection label='Survey results'>
                <SurveyResultsSection shipment={shipment} collectionRequest={collectionRequest} />
            </FormSection>
            {collectionRequest?.formSubmittedByUser && (
                <FormSection label='Items to be collected'>
                    <AssignmentDetailsSection
                        itemsToCollect={collectionRequest.itemsToCollect}
                        onAssignmentClick={onAssignmentClick}
                    />
                </FormSection>
            )}
            {collectionRequest.packagingDetails && (
                <FormSection label='Collection packaging details'>
                    <PackagingDetailsSection packagingDetails={collectionRequest.packagingDetails} />
                </FormSection>
            )}
            <FormSection label='Activity log'>
                <ActivityLogSection shipment={shipment} collectionRequest={collectionRequest} />
            </FormSection>
        </Box>
    );
};
