import { useQuery } from '@tanstack/react-query';

import { assignmentsCacheKey } from '../../assignment/store/cacheKey';
import { shipmentService } from '../service/shipmentService';

export const useShipmentQuery = (id?: number) => {
    const { data, isPending } = useQuery({
        queryKey: [assignmentsCacheKey, 'shipment', id],
        queryFn: () => shipmentService.getShipment(id!),
        enabled: !!id,
    });

    return {
        data,
        isPending,
    };
};
