import React, { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Redirect } from 'react-router-dom';

import { decodeToken } from '@hofy/api-auth';
import { APP_CONFIG } from '@hofy/config';
import { useStringQueryParam } from '@hofy/router';
import { Button, ErrorPage, SvgIllustration } from '@hofy/ui';

import { useAuthI18n } from '../../../i18n/useAuthI18n';
import { useOrganizationSignupFeatureQuery } from '../../../store/auth/useOrganizationSignupFeatureQuery';
import { AuthNavLink } from '../../AuthNavLink';
import { CompleteOrganizationSignupPage } from './completeOrganizationSignupPage/CompleteOrganizationSignupPage';
import { VerifyEmailPage } from './verifyEmailPage/VerifyEmailPage';

export const OrganizationSignup: FC = () => {
    const { tr } = useAuthI18n();
    const { data: organizationSignUpEnabled, isLoading } = useOrganizationSignupFeatureQuery();
    const [token] = useStringQueryParam('token');
    const [error] = useStringQueryParam('error');
    const isEmailVerified = token ? !!decodeToken(token).email : false;

    if (error === 'user_exists') {
        return (
            <ErrorPage
                title={tr('org-sign-up-page.existing-user.title')}
                subtitle={tr('org-sign-up-page.existing-user.subtitle')}
                illustration={SvgIllustration.Error}
            >
                <Button
                    to={AuthNavLink.SignIn}
                    marginTop={40}
                    label={tr('org-sign-up-page.existing-user.button')}
                />
            </ErrorPage>
        );
    }

    if ((!organizationSignUpEnabled && !isLoading) || APP_CONFIG.isAdminApp) {
        return <Redirect to={AuthNavLink.SignIn} />;
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={APP_CONFIG.recaptchaSiteKey || ''}>
            {isEmailVerified ? <CompleteOrganizationSignupPage /> : <VerifyEmailPage />}
        </GoogleReCaptchaProvider>
    );
};
