import React, { FC, ReactNode } from 'react';

import { ImageDto, ProductCategory, SpecificationDto, useTrProductCategory } from '@hofy/api-shared';
import { Markdown } from '@hofy/editor';
import { Color } from '@hofy/theme';
import { Box, Heading3, ImageCarousel, Paragraph3, RWDImageCarousel, useRWD, useRWDConfig } from '@hofy/ui';

import { useProductI18n } from '../store/useProductI18n';
import { useProductImages } from '../store/useProductImages';
import { FallbackCategoryImage } from './FallbackCategoryImage';
import { ProductSpecifications } from './ProductSpecifications';

interface BaseProductDetailsProps {
    product: {
        images: ImageDto[];
        name: string;
        brand: string;
        category: ProductCategory;
        description: string;
        specifications: SpecificationDto[];
    };
    variant?: {};
    zoomable?: boolean;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
}

export const BaseProductDetails: FC<BaseProductDetailsProps> = ({
    product,
    variant,
    zoomable,
    leftContent,
    rightContent,
}) => {
    const { isMobile } = useRWD();
    const { trStyled } = useProductI18n();
    const { images, image, setImage } = useProductImages(product.images, variant);
    const trProductCategory = useTrProductCategory();

    return (
        <Box paddingVertical='mainMarginVertical' data-test-key='product-details'>
            <Box direction={isMobile ? 'column' : 'row'} alignItems='stretch' gap={30}>
                <Box flex='auto'>
                    <Box marginBottom={20}>
                        <Heading3 data-test-key='name'>{product.name}</Heading3>
                        <Paragraph3 color={Color.ContentTertiary} marginTop={6}>
                            {trStyled(
                                'product.by',
                                {
                                    color: Color.ContentSecondary,
                                },
                                {
                                    value: product.brand,
                                },
                            )}
                        </Paragraph3>
                        <Paragraph3 color={Color.ContentTertiary} marginTop={6} data-test-key='category'>
                            {trProductCategory(product.category)}
                        </Paragraph3>
                    </Box>
                    <ImageCarouselWrapper
                        images={images}
                        selected={image || images[0]}
                        onSelect={setImage}
                        zoomable={zoomable}
                        category={product.category}
                    />
                    {leftContent && <Box>{leftContent}</Box>}
                    <Markdown marginTop={10}>{product.description}</Markdown>
                    <ProductSpecifications specifications={product.specifications} />
                </Box>
                {rightContent}
            </Box>
        </Box>
    );
};

interface ImageCarouselWrapperProps {
    images: ImageDto[];
    selected: ImageDto;
    onSelect(img: ImageDto): void;
    zoomable?: boolean;
    category: ProductCategory;
}

const ImageCarouselWrapper: FC<ImageCarouselWrapperProps> = ({
    images,
    selected,
    onSelect,
    zoomable,
    category,
}) => {
    const { isMobile, isLaptop } = useRWDConfig();

    return isMobile ? (
        <Box marginBottom={20} fullWidth centered>
            <Box maxWidth={480} fullWidth>
                <RWDImageCarousel
                    images={images}
                    selected={selected}
                    onSelect={onSelect}
                    fallback={<FallbackCategoryImage category={category} />}
                    zoomable={zoomable}
                    vertical
                />
            </Box>
        </Box>
    ) : (
        <ImageCarousel
            images={images}
            selected={selected}
            onSelect={onSelect}
            fallback={<FallbackCategoryImage category={category} />}
            zoomable={zoomable}
            marginRight={30}
            size={isLaptop ? 300 : 370}
        />
    );
};
