import React, { FC } from 'react';

import { Form, FormInput, SectionTitle2, SubmitButton, UseForm } from '@hofy/ui';

import { AnalyticsSelfSignUpEvent, useAuthAnalyticsPageOnce } from '../../../../hooks/useAnalytics';
import { useAuthI18n } from '../../../../i18n/useAuthI18n';
import { PersonalDetailsFormData } from '../../../../store/auth/useOrganizationSignUp';

interface PersonalDetailsStepProps {
    form: UseForm<PersonalDetailsFormData>;
    onSubmit(): void;
}

export const PersonalDetailsStep: FC<PersonalDetailsStepProps> = ({ form, onSubmit }) => {
    const { tr } = useAuthI18n();

    useAuthAnalyticsPageOnce(AnalyticsSelfSignUpEvent.PersonalDetailsStepViewed);

    return (
        <Form onSubmit={onSubmit} submitOnEnter column gap={24}>
            <SectionTitle2>{tr('org-sign-up-page.personal-details-form.title')}</SectionTitle2>
            <FormInput
                autoFocus
                label={tr('org-sign-up-page.personal-details-form.first-name.label')}
                placeholder={tr('org-sign-up-page.personal-details-form.first-name.placeholder')}
                api={form.fields.firstName}
            />
            <FormInput
                label={tr('org-sign-up-page.personal-details-form.last-name.label')}
                placeholder={tr('org-sign-up-page.personal-details-form.last-name.placeholder')}
                api={form.fields.lastName}
            />
            <SubmitButton label={tr('org-sign-up-page.personal-details-form.continue')} />
        </Form>
    );
};
