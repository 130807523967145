import React, { FC } from 'react';

import { CollectionRequestStatus, CollectionRequestStatusAware, WithId } from '@hofy/api-shared';

import { DeferCollectionSurveyButton } from './DeferCollectionSurveyButton';
import { EscalateCollectionSurveyButton } from './EscalateCollectionSurveyButton';

interface CollectionSurveyStatusButtonsProps {
    collectionRequest: WithId<CollectionRequestStatusAware>;
    shipmentId: number;
}

export const CollectionSurveyStatusButtons: FC<CollectionSurveyStatusButtonsProps> = ({
    collectionRequest: { id, status },
    shipmentId,
}) => {
    return (
        <>
            {![CollectionRequestStatus.Deferred, CollectionRequestStatus.Cancelled].includes(status) && (
                <EscalateCollectionSurveyButton
                    collectionRequestId={id}
                    escalated={status === CollectionRequestStatus.Escalated}
                    shipmentId={shipmentId}
                />
            )}
            {[CollectionRequestStatus.Deferred, CollectionRequestStatus.Escalated].includes(status) && (
                <DeferCollectionSurveyButton
                    collectionRequestId={id}
                    deferred={status === CollectionRequestStatus.Deferred}
                    shipmentId={shipmentId}
                />
            )}
        </>
    );
};
