import React, { FC, useEffect, useState } from 'react';

import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader, WizardFooter } from '@hofy/common';

import { useCreatePurchaseOrder } from '../../../../store/purchaseOrders/useCreatePurchaseOrder';
import { CreateUpdatePurchaseOrderForm } from './CreateUpdatePurchaseOrderForm';
import { NavigationStep } from './hooks/useAllowedEditStep';
import { useNavigationSteps } from './hooks/useNavigationSteps';

interface NewPurchaseOrderSlideoutProps {
    onClose(): void;
}

export const NewPurchaseOrderSlideout: FC<NewPurchaseOrderSlideoutProps> = ({ onClose }) => {
    const purchaseOrderForm = useCreatePurchaseOrder(onClose);

    const [step, setStep] = useState(NavigationStep.Basic);
    const navigateToStep = useNavigationSteps(purchaseOrderForm.form, setStep);

    useEffect(() => {
        if (step === NavigationStep.Items) {
            purchaseOrderForm.setValidateItems(true);
        } else {
            purchaseOrderForm.setValidateItems(false);
        }
    }, [step]);

    const titleType = purchaseOrderForm.form.values.isDropship ? 'dropship' : 'warehouse';

    return (
        <Slideout width={1300} onClose={onClose} slideoutId='purchase-order'>
            <SlideoutHeader title={`New ${titleType} purchase order`} />
            <SlideoutContent>
                <CreateUpdatePurchaseOrderForm form={purchaseOrderForm} step={step} />
            </SlideoutContent>
            <SlideoutFooter>
                <WizardFooter
                    step={step}
                    finalStep={3}
                    onCancel={onClose}
                    nextLabel='Next'
                    cancelLabel='Cancel'
                    prevLabel='Previous'
                    onSubmit={purchaseOrderForm.form.submit}
                    isSubmitLoading={purchaseOrderForm.isLoadingMutation}
                    onChangeStep={navigateToStep}
                    submitLabel='Save'
                />
            </SlideoutFooter>
        </Slideout>
    );
};
