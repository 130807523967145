import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, Dot, Paragraph4 } from '@hofy/ui';

interface PasswordHintProps {
    isError?: boolean;
    isOk?: boolean;
    label: string;
}

export const PasswordHint: FC<PasswordHintProps> = ({ isError, isOk, label }) => {
    const color = getColor(isError, isOk);

    return (
        <Box row gap={8}>
            <Dot size={6} color={color} />
            <Paragraph4 color={color}>{label}</Paragraph4>
        </Box>
    );
};

const getColor = (isError?: boolean, isOk?: boolean) => {
    if (isError) {
        return Color.ContentNegative;
    }
    if (isOk) {
        return Color.ContentPositive;
    }
    return Color.ContentSecondary;
};
