import { omit, xor } from 'lodash';
import { useMemo } from 'react';

import {
    allShipmentTrackingProviders,
    ShipmentStatus,
    ShipmentTrackingProvider,
    ShipmentType,
    unassignedUser,
} from '@hofy/api-shared';
import { AnyRegion, Country, UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useArrayQueryParam, useBooleanQueryParam, useStringQueryParam } from '@hofy/router';

export const useAdminShipmentFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [shipmentStatus, setShipmentStatus] = useArrayQueryParam<ShipmentStatus>('shipmentStatus');
    const [shipmentTypes, setShipmentTypes] = useArrayQueryParam<ShipmentType>('shipmentType');
    const [assignedUser, setAssignedUser] = useArrayQueryParam<UUID>('assignedUser');
    const [shouldUpdate, setShouldUpdate] = useBooleanQueryParam('shouldUpdate');
    const [shipmentTrackingProviders, setShipmentTrackingProviders] =
        useArrayQueryParam<ShipmentTrackingProvider>('trackingProvider');
    const [withTrackingException, setWithTrackingException] = useBooleanQueryParam('withTracking');
    const [withoutTracking, setWithoutTracking] = useBooleanQueryParam('withoutTracking');
    const [toUserId, setToUserId] = useStringQueryParam<UUID>('toUser');
    const [region, setRegion] = useArrayQueryParam<AnyRegion>('region');
    const [country, setCountry] = useArrayQueryParam<Country>('country');
    const [warehouse, setWarehouse] = useArrayQueryParam<number>('warehouse');
    const [fromUserId, setFromUserId] = useStringQueryParam<UUID>('fromUser');

    const toggleUnassigned = () => {
        setAssignedUser(xor(assignedUser, [unassignedUser]));
    };

    const toggleShouldUpdate = () => {
        setShouldUpdate(shouldUpdate ? null : true);
    };

    const toggleShipmentTrackingProviders = () => {
        setShipmentTrackingProviders(
            shipmentTrackingProviders.length > 0 ? [] : allShipmentTrackingProviders,
        );
    };

    const toggleWithTrackingException = () => {
        setWithTrackingException(withTrackingException ? null : true);
    };

    const toggleWithoutTracking = () => {
        setWithoutTracking(withoutTracking ? null : true);
    };

    const filters = useMemo(() => {
        return {
            search,
            shipmentStatus,
            shipmentTypes,
            assignedUser,
            toUserId,
            fromUserId,
            region,
            country,
            warehouse,
            shouldUpdate,
            shipmentTrackingProviders,
            withTrackingException,
            withoutTracking,
        };
    }, [
        search,
        shipmentStatus,
        shipmentTypes,
        assignedUser,
        toUserId,
        fromUserId,
        region,
        country,
        warehouse,
        shouldUpdate,
        toggleShipmentTrackingProviders,
        toggleWithTrackingException,
        toggleWithoutTracking,
    ]);

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        setSearch,
        setShipmentStatus,
        toUserId,
        fromUserId,
        setToUserId,
        setFromUserId,
        shipmentStatus,
        shipmentTypes,
        setShipmentTypes,
        assignedUser,
        setAssignedUser,
        toggleUnassigned,
        region,
        setRegion,
        country,
        setCountry,
        warehouse,
        setWarehouse,
        filterCount,
        shouldUpdate,
        toggleShouldUpdate,
        shipmentTrackingProviders,
        toggleShipmentTrackingProviders,
        withTrackingException,
        toggleWithTrackingException,
        withoutTracking,
        toggleWithoutTracking,
    };
};
