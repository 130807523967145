import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { Slideout } from '@hofy/common';
import { Heading3 } from '@hofy/ui';

import { useCreateAddon } from '../../../../store/addons/useCreateAddon';
import { AddonSlideoutContent } from './AddonSlideoutContent';

interface AddonCreateSlideoutProps {
    onClose(): void;
}

export const AddonCreateSlideout: FC<AddonCreateSlideoutProps> = ({ onClose }) => {
    const { form, isLoading, isError } = useCreateAddon(onClose);
    const { hasPermission } = useSession();

    return (
        <Slideout width={1150} onClose={onClose}>
            <AddonSlideoutContent
                title={<Heading3>Create addon</Heading3>}
                form={form}
                isLoading={isLoading}
                isError={isError}
                isDisabled={!hasPermission(Permission.AdminAddonsCreate)}
                onClose={onClose}
            />
        </Slideout>
    );
};
