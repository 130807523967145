import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { BetaFeatureDto } from '@hofy/api-admin';
import { useGoBack } from '@hofy/hooks';
import { IntRoute } from '@hofy/router';
import { Box } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { BetaFeaturesTab } from './BetaFeaturesTab';
import { UpdateBetaFeaturesSlideout } from './UpdateBetaFeaturesSlideout';

interface BetaFeaturesRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const BetaFeaturesRouter: FC<BetaFeaturesRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const handleEditFeatureKey = (key: BetaFeatureDto) => {
        history.push(`${AdminNavLink.BetaFeatures}/${key.id}/edit`, key);
    };

    return (
        <Box column flex='auto'>
            <SettingsHeader tabs={tabs} tab={tab} onChangeTab={onChangeTab} />
            <BetaFeaturesTab onEdit={handleEditFeatureKey} />
            <IdRoute path={`${AdminNavLink.BetaFeatures}/:featureId(\\d+)/edit`}>
                {({ featureId }) => <UpdateBetaFeaturesSlideout featureId={featureId} onClose={goBack} />}
            </IdRoute>
        </Box>
    );
};

const IdRoute = IntRoute('featureId', Route);
