import { includes, xor } from 'lodash';
import React, { FC, useState } from 'react';

import { SupplierRefDto } from '@hofy/api-shared';
import { useSearch } from '@hofy/hooks';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../../../../components/design/blockFilters/SearchableBlockFilter';

interface SupplierBlockFilterProps {
    suppliers?: SupplierRefDto[];
    selected: number[];
    setSelected(ids: number[]): void;
}

export const SupplierBlockFilter: FC<SupplierBlockFilterProps> = ({ suppliers, selected, setSelected }) => {
    const [supplierSearchQuery, setSupplierSearchQuery] = useState('');

    const results = useSearch(suppliers || [], supplier => [supplier.name], supplierSearchQuery);

    return (
        <SearchableBlockFilter<SupplierRefDto>
            title='Supplier'
            icon={SvgIcon.Globe}
            onFilterClick={supplier => setSelected(xor(selected, [supplier.id]))}
            isSelected={supplier => includes(selected, supplier.id)}
            items={results}
            renderItem={supplier => supplier.name}
            searchPlaceholder='Search'
            search={supplierSearchQuery}
            onSearch={setSupplierSearchQuery}
        />
    );
};
