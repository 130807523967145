import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Spinner } from '@hofy/ui';

import { useSyncPaymentsToNetsuite } from '../../../store/payments/useSyncPaymentsToAccounting';

export const PaymentsPageMenu: FC = () => {
    const { hasPermission } = useSession();

    const { syncToNetsuite, syncToNetsuiteIsLoading } = useSyncPaymentsToNetsuite();

    if (syncToNetsuiteIsLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: syncToNetsuite,
                    label: 'Sync all to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                },
            ]}
        />
    );
};
