import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { AddonDto } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { pathUuid } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { UUIDRoute } from '@hofy/router';
import { Box, Button, SvgIcon } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { AddonsTable } from './AddonsTable';
import { AddonCreateSlideout } from './slideout/AddonCreateSlideout';
import { AddonUpdateSlideout } from './slideout/AddonUpdateSlideout';

interface AddonsRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const AddonsRouter: FC<AddonsRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const handleAddonUpdate = (addon: AddonDto) => {
        history.push(`${AdminNavLink.Settings}/${AdminSettingsTab.Addons}/${addon.id}/edit`);
    };

    const { hasPermission } = useSession();

    return (
        <Box column flex='auto'>
            <SettingsHeader
                tabs={tabs}
                tab={tab}
                onChangeTab={onChangeTab}
                titleContent={
                    <Button
                        leftIcon={SvgIcon.Add}
                        label='Add Addon'
                        disabled={!hasPermission(Permission.AdminAddonsCreate)}
                        to={`${AdminNavLink.Settings}/${AdminSettingsTab.Addons}/create`}
                    />
                }
            />
            <AddonsTable onUpdate={handleAddonUpdate} />
            <Route path={`${AdminNavLink.Settings}/${AdminSettingsTab.Addons}/create`} exact>
                <AddonCreateSlideout onClose={goBack} />
            </Route>
            <AddonUpdateSlideoutRoute
                path={`${AdminNavLink.Settings}/${AdminSettingsTab.Addons}/:addonId(${pathUuid})/edit`}
            >
                {({ addonId }) => <AddonUpdateSlideout addonId={addonId} onClose={goBack} />}
            </AddonUpdateSlideoutRoute>
        </Box>
    );
};

const AddonUpdateSlideoutRoute = UUIDRoute('addonId', Route);
