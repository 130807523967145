import { BYODOrderStatus } from '@hofy/api-shared';

export interface BYODOrdersFilter {
    search: string;
    warehouses: number[];
    statuses: BYODOrderStatus[];
}

export const emptyBYODOrdersFilter: BYODOrdersFilter = {
    search: '',
    warehouses: [],
    statuses: [],
};
