import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { UUID } from '@hofy/global';

import { useGenerateInvoices } from '../../../store/invoices/useGenerateInvoices';
import { GenerateInvoicesModal } from '../../invoicingPage/invoices/components/GenerateInvoicesModal';

interface GenerateOrganizationInvoicesModalProps {
    onClose(): void;
    organizationId: UUID;
}

export const GenerateOrganizationInvoicesModal: FC<GenerateOrganizationInvoicesModalProps> = ({
    organizationId,
    onClose,
}) => {
    const { generateInvoices, isLoading } = useGenerateInvoices(onClose);
    const handleSubmit = (invoiceDate: DateTime) => {
        generateInvoices({
            month: invoiceDate.toISODate()!,
            organizationId,
        });
    };

    return <GenerateInvoicesModal onClose={onClose} isLoading={isLoading} onSubmit={handleSubmit} />;
};
