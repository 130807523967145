import { ColorDye, getEnumValues } from '@hofy/global';

export enum PurchaseOrderStatus {
    PendingApproval = 'pending_approval',
    Declined = 'declined',
    Cancelled = 'cancelled',
    Approved = 'approved',
    PendingReceipt = 'pending_receipt',
    PartiallyReceived = 'partially_received',
    PendingBilling = 'pending_billing',
    PartiallyBilled = 'partially_billed',
    PartiallyBilledAndReceived = 'partially_billed_and_received',
    Billed = 'billed',
    Closed = 'closed',
}

export const allPurchaseOrderStatuses = getEnumValues<PurchaseOrderStatus>(PurchaseOrderStatus);

export const purchaseOrderStatusColors: Record<PurchaseOrderStatus, ColorDye> = {
    [PurchaseOrderStatus.PendingApproval]: 'orange',
    [PurchaseOrderStatus.Declined]: 'red',
    [PurchaseOrderStatus.Cancelled]: 'red',
    [PurchaseOrderStatus.Approved]: 'green',
    [PurchaseOrderStatus.PendingReceipt]: 'teal',
    [PurchaseOrderStatus.PartiallyReceived]: 'purple',
    [PurchaseOrderStatus.PendingBilling]: 'teal',
    [PurchaseOrderStatus.PartiallyBilled]: 'purple',
    [PurchaseOrderStatus.PartiallyBilledAndReceived]: 'purple',
    [PurchaseOrderStatus.Billed]: 'green',
    [PurchaseOrderStatus.Closed]: 'green',
};
