import React, { FC } from 'react';

import { Country, UUID } from '@hofy/global';
import { ConfirmModal } from '@hofy/ui';

import { useEnableStoreAndReuseCollection } from '../../../store/assignments/useEnableStoreAndReuseCollection';
import { StoreAndReuseSelection } from './StoreAndReuseSelection';

interface EnableStoreAndReuseCollectionProps {
    organizationId: UUID;
    isActiveRental: boolean;
    assignmentId: number;
    contractId: number | null;
    onSuccess(): void;
    country: Country;
    isStoreAndReuseCollectionToHubEnabled: boolean;
    isStoreAndReuseCollectionToLocalPartnerEnabled: boolean;
}

export const EnableStoreAndReuseCollectionModal: FC<EnableStoreAndReuseCollectionProps> = ({
    organizationId,
    isActiveRental,
    assignmentId,
    contractId,
    onSuccess,
    country,
    isStoreAndReuseCollectionToHubEnabled,
    isStoreAndReuseCollectionToLocalPartnerEnabled,
}) => {
    const { form, isLoading } = useEnableStoreAndReuseCollection({
        organizationId,
        isActiveRental,
        assignmentId,
        contractId,
        country,
        isStoreAndReuseCollectionToHubEnabled,
        isStoreAndReuseCollectionToLocalPartnerEnabled,
        onSuccess,
    });

    return (
        <ConfirmModal
            width={550}
            keyPrefix='enable-store-and-reuse-collection-modal'
            onClose={onSuccess}
            onConfirm={form.submit}
            isLoading={isLoading}
            closeOnConfirm={false}
        >
            <StoreAndReuseSelection
                isStoreAndReuseCollection
                isActiveRental={isActiveRental}
                collectToHub={isStoreAndReuseCollectionToHubEnabled}
                collectToLocalPartner={isStoreAndReuseCollectionToLocalPartnerEnabled}
                form={form}
            />
        </ConfirmModal>
    );
};
