import { useQuery } from '@tanstack/react-query';

import { sessionService } from '@hofy/api-auth';

export const useOrganizationSignupFeatureQuery = () => {
    const { data, isLoading } = useQuery({
        queryKey: ['organization-signup-feature-flag'],
        queryFn: sessionService.getOrganizationSignUpFeature,
    });

    return {
        data: data?.enabled || null,
        isLoading,
    };
};
