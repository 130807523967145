import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    RemoveAssignmentsFromShipmentPayload,
    shipmentsCacheKey,
    shipmentService,
} from '@hofy/api-admin';
import { WithId } from '@hofy/api-shared';
import { useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from './useShipmentCollectionRequestQuery';

export const useRemoveAssignmentsFromShipment = (shipmentId: number | null) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation<void, unknown, WithId<RemoveAssignmentsFromShipmentPayload>>({
        mutationFn: ({ id, ...payload }) => shipmentService.removeAssignmentsFromShipment(id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [shipmentsCacheKey, shipmentId] });
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Assignments removed from shipment',
            });
        },
    });

    const removeAssignmentFromShipment = (assignmentIDs: number[]) => {
        if (!shipmentId) {
            return;
        }
        mutation.mutate({ id: shipmentId, assignmentIDs });
    };

    return {
        removeAssignmentFromShipment,
    };
};
