import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { EnumRoute, IntRoute } from '@hofy/router';

import { RepairSlideoutTab } from '../../../../store/repairs/repairSlideoutTab';
import { RepairSlideout } from './RepairSlideout';

interface RepairSlideoutRouterProps {
    base: string;
    onClose(): void;
}

export const RepairSlideoutRouter: FC<RepairSlideoutRouterProps> = ({ base, onClose }) => {
    const history = useHistory();

    return (
        <RepairIdRoute path={`${base}/:repairId(\\d+)`}>
            {({ repairId }) => (
                <Switch>
                    <RepairDetailsTabRoute path={`${base}/:repairId(\\d+)/:activeTab`}>
                        {({ activeTab }) => (
                            <RepairSlideout
                                repairId={repairId}
                                activeTab={activeTab}
                                onTabChange={newTab =>
                                    history.replace(`${base}/${repairId}/${newTab}${history.location.search}`)
                                }
                                onClose={onClose}
                            />
                        )}
                    </RepairDetailsTabRoute>
                    <Redirect
                        to={`${base}/${repairId}/${RepairSlideoutTab.Progress}${history.location.search}`}
                        from={`${base}/${repairId}`}
                    />
                </Switch>
            )}
        </RepairIdRoute>
    );
};

const RepairIdRoute = IntRoute('repairId', Route);
const RepairDetailsTabRoute = EnumRoute<RepairSlideoutTab>('activeTab', RepairSlideoutTab, Route);
