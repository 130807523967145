import { keyBy } from 'lodash';
import React, { forwardRef, useMemo } from 'react';

import { useUserRefsQuery } from '@hofy/api-admin';
import { formatUserName, UserWithCountryRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
    Paragraph3,
} from '@hofy/ui';
import { UserSelectorItem } from '@hofy/ui-domain';

interface BaseProps {
    organizationId?: UUID;
    userPredicate?(user: UserWithCountryRefDto): boolean;
    placeholder?: string;
}

type LabeledOrganizationNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledOrganizationNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledUserProps = LabeledOrganizationNormalProps | LabeledOrganizationNullableProps;

const optionsLengthToRender = 30;

export const LabeledUserSelect = forwardRef<HTMLDivElement, LabeledUserProps>(
    ({ organizationId, userPredicate, ...rest }, ref) => {
        const { data, isLoading: isLoadingUsers } = useUserRefsQuery(organizationId);
        const users = useMemo(
            () => (userPredicate ? data.filter(userPredicate) : data),
            [data, userPredicate],
        );
        const usersMap = useMemo(() => keyBy(users, v => v.id), [users]);

        const dropdownBottomSlot = (
            <Paragraph3 padding={5} paddingLeft={16}>
                Search to see others
            </Paragraph3>
        );

        return (
            <LabeledSelectSearch
                placeholder='Select user'
                toText={id => (isLoadingUsers ? '' : formatUserName(usersMap[id]))}
                toLabel={id => (isLoadingUsers ? '' : <UserSelectorItem user={usersMap[id]} />)}
                isLoadingSearch={isLoadingUsers}
                options={users.map(v => v.id)}
                searchPlaceholder='Search to filter'
                optionsLengthToRender={optionsLengthToRender}
                dropdownBottomSlot={users.length >= optionsLengthToRender ? dropdownBottomSlot : null}
                ref={ref}
                {...rest}
            />
        );
    },
);
