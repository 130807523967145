import React, { FC, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { IntRoute } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { CreateInvoiceEntrySlideout } from './createInvoiceEntrySlideout/CreateInvoiceEntrySlideout';
import { InvoiceEntriesPage } from './InvoiceEntriesPage';
import { InvoiceEntryDetailsOverlay } from './invoiceEntryDetailsOverlay/InvoiceEntryDetailsOverlay';
import { UpdateInvoiceEntryTaxAddressSlideout } from './updateInvoiceEntryAddressSlideout/UpdateInvoiceEntryTaxAddressSlideout';
import { UpdateInvoiceEntrySlideout } from './updateInvoiceEntrySlideout/UpdateInvoiceEntrySlideout';

export const InvoiceEntriesRouter: FC<InvoicingTabRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const [organizationId, setOrganizationId] = useState<UUID>();

    const invoiceEntriesNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.InvoiceEntries}`;
    const handleOpenInvoiceEntry = (id: number) => {
        history.push(`${invoiceEntriesNavLink}/${id}`);
    };

    const handleEditInvoiceEntry = (id: number) => {
        history.push(`${invoiceEntriesNavLink}/${id}/edit${history.location.search}`);
    };

    const handleAddInvoiceEntry = (organizationId: UUID) => {
        history.push(`${invoiceEntriesNavLink}/add${history.location.search}`);
        setOrganizationId(organizationId);
    };

    const handleNewEntry = (entryId: number) => {
        history.push(`${invoiceEntriesNavLink}/${entryId}`);
    };

    const handleUpdateInvoiceEntryAddress = (id: number) => {
        history.push(`${invoiceEntriesNavLink}/${id}/update-tax-address${history.location.search}`);
    };

    return (
        <Route path={invoiceEntriesNavLink}>
            <InvoiceEntriesPage
                tab={tab}
                tabs={tabs}
                onChangeTab={onChangeTab}
                onAddInvoiceEntry={handleAddInvoiceEntry}
                onOpenInvoiceEntry={handleOpenInvoiceEntry}
            />
            <Route path={`${invoiceEntriesNavLink}/add`}>
                <CreateInvoiceEntrySlideout
                    organizationId={organizationId}
                    basePath={invoiceEntriesNavLink}
                    onClose={() => goBack(invoiceEntriesNavLink)}
                />
            </Route>
            <InvoiceEntryRoute path={`${invoiceEntriesNavLink}/:entryId(\\d+)`}>
                {({ entryId }) => (
                    <InvoiceEntryDetailsOverlay
                        entryId={entryId}
                        onEntryEdit={() => handleEditInvoiceEntry(entryId)}
                        onUpdateTaxAddress={() => handleUpdateInvoiceEntryAddress(entryId)}
                        onNewEntry={handleNewEntry}
                    />
                )}
            </InvoiceEntryRoute>
            <InvoiceEntryRoute path={`${invoiceEntriesNavLink}/:entryId(\\d+)/edit`}>
                {({ entryId }) => (
                    <UpdateInvoiceEntrySlideout
                        basePath={invoiceEntriesNavLink}
                        entryId={entryId}
                        onClose={() => goBack(invoiceEntriesNavLink)}
                    />
                )}
            </InvoiceEntryRoute>
            <InvoiceEntryRoute path={`${invoiceEntriesNavLink}/:entryId(\\d+)/update-tax-address`}>
                {({ entryId }) => (
                    <UpdateInvoiceEntryTaxAddressSlideout
                        basePath={invoiceEntriesNavLink}
                        entryId={entryId}
                        onClose={() => goBack(invoiceEntriesNavLink)}
                    />
                )}
            </InvoiceEntryRoute>
        </Route>
    );
};

const InvoiceEntryRoute = IntRoute('entryId', Route);
