import { cloneDeep, some } from 'lodash';
import { useCallback, useState } from 'react';

import { isNotLoanerOrConsumable, UserAssignmentDto, UserShipmentDto } from '@hofy/api-admin';
import {
    allShipmentTypes,
    AssignmentCollectionReason,
    AssignmentStatus,
    AssignmentType,
    isOrganizationWarehouse,
    ShipmentType,
    shipmentTypeAssignmentStatus,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export type UserAssignmentSelectionRecord = Record<
    number,
    { selected: boolean; assignment: UserAssignmentDto }
>;

export const emptyUserAssignmentSelectionRecord = {};

interface UseAdminAssignmentsSelectionState {
    selected: Record<UUID, UserAssignmentSelectionRecord>;

    toggleSelected(v: UserAssignmentDto[]): void;

    userSelection(userId: UUID): UserAssignmentDto[];

    clearSelection(userId?: UUID): void;
}

export const useAssignmentsSelection = (): UseAdminAssignmentsSelectionState => {
    const [selected, setSelected] = useState<Record<UUID, UserAssignmentSelectionRecord>>(
        emptyUserAssignmentSelectionRecord,
    );

    const toggleSelected = useCallback((assignments: UserAssignmentDto[]) => {
        setSelected(current => {
            const newSelection = cloneDeep(current);

            assignments.forEach(assignment => {
                const userId = assignment.userId;

                if (!newSelection[userId]) {
                    newSelection[userId] = {};
                }

                newSelection[userId][assignment.id] = {
                    selected: !newSelection[userId][assignment.id]?.selected,
                    assignment,
                };
            });

            return newSelection;
        });
    }, []);

    const userSelection = useCallback(
        (userId: UUID): UserAssignmentDto[] => {
            return Object.values(selected[userId] || {})
                .filter(({ selected }) => selected)
                .map(({ assignment }) => assignment);
        },
        [selected],
    );

    const clearSelection = useCallback((userId?: UUID): void => {
        if (!userId) {
            setSelected(emptyUserAssignmentSelectionRecord);
            return;
        }
        setSelected(current => ({
            ...current,
            [userId]: emptyUserAssignmentSelectionRecord,
        }));
    }, []);

    return {
        selected,
        toggleSelected,
        userSelection,
        clearSelection,
    };
};

interface UseInPersonTransferAvailabilityState {
    isPossible: boolean;
    isDisabled: boolean;
}

export const useInPersonTransferAvailability = (
    assignments: UserAssignmentDto[],
    selectedAssignments: UserAssignmentDto[],
    areAssignmentsWithShipment: boolean,
): UseInPersonTransferAvailabilityState => {
    const isWithUserOrCollectionPendingWithoutShipment = (status: AssignmentStatus) =>
        status === AssignmentStatus.WithUser ||
        (status === AssignmentStatus.CollectionPending && !areAssignmentsWithShipment);

    const isPossible = some(
        assignments,
        assignment =>
            isWithUserOrCollectionPendingWithoutShipment(assignment.status) &&
            isNotLoanerOrConsumable(assignment),
    );

    if (!isPossible) {
        return { isPossible, isDisabled: true };
    }

    const assignmentIds = assignments.map(({ id }) => id);
    const selectedAssignmentIds = selectedAssignments.map(({ id }) => id);
    const isSameAssignmentGroup = some(selectedAssignmentIds, id => assignmentIds.includes(id));

    const isDisabled =
        selectedAssignments.length === 0 ||
        (selectedAssignments.length > 0 && !isSameAssignmentGroup) ||
        some(
            selectedAssignments,
            assignment => !isWithUserOrCollectionPendingWithoutShipment(assignment.status),
        );

    return { isPossible, isDisabled };
};

export const useAvailableShipmentTypes = (
    assignments: UserAssignmentDto[],
    selectedAssignments: UserAssignmentDto[],
    shipment?: UserShipmentDto,
) => {
    const shipmentAssignmentsSet = new Set(assignments.map(({ id }) => id));
    const selectedShipmentAssignments = selectedAssignments.filter(({ id }) =>
        shipmentAssignmentsSet.has(id),
    );

    if (selectedShipmentAssignments.length === 0) {
        return [];
    }

    return getAvailableShipmentTypes(selectedShipmentAssignments, shipment);
};

const isAssignmentWithEndOfContractCollectionReason = (assignment: UserAssignmentDto) => {
    return assignment.collectionReason === AssignmentCollectionReason.EndOfContract;
};

const isAssignmentRelatedToOrganizationWarehouse = (assignment: UserAssignmentDto) => {
    return (
        assignment.collectionToOrganizationWarehouse || isOrganizationWarehouse(assignment.item?.warehouse)
    );
};

const areIntendedToTheSameOrganizationWarehouse = (
    currentAssignment: UserAssignmentDto,
    targetAssignment: UserAssignmentDto,
) => {
    return (
        currentAssignment.collectionToOrganizationWarehouse?.idDeprecated ===
        targetAssignment.collectionToOrganizationWarehouse?.idDeprecated
    );
};

const areAssignedItemsAtTheSameWarehouse = (
    currentAssignment: UserAssignmentDto,
    targetAssignment: UserAssignmentDto,
) => {
    return currentAssignment.item?.warehouse?.idDeprecated === targetAssignment.item?.warehouse?.idDeprecated;
};

const getAvailableShipmentTypes = (selectedAssignments: UserAssignmentDto[], shipment?: UserShipmentDto) => {
    return allShipmentTypes.filter(shipmentType =>
        selectedAssignments.every(assignment => {
            if (shipmentType === ShipmentType.Transfer) {
                if (!areIntendedToTheSameOrganizationWarehouse(assignment, selectedAssignments[0])) {
                    return false;
                }
                if (!areAssignedItemsAtTheSameWarehouse(assignment, selectedAssignments[0])) {
                    return false;
                }
                if (
                    !isAssignmentRelatedToOrganizationWarehouse(assignment) &&
                    assignment.status === AssignmentStatus.DeliveryPending
                ) {
                    return false;
                }
                if (shipment || isAssignmentWithEndOfContractCollectionReason(assignment)) {
                    return false;
                }
            }

            if (
                shipmentType !== ShipmentType.Transfer &&
                isAssignmentRelatedToOrganizationWarehouse(assignment)
            ) {
                return false;
            }

            if (
                shipmentType === ShipmentType.Dropshipping &&
                assignment.type === AssignmentType.Redistribution
            ) {
                return false;
            }

            return shipmentTypeAssignmentStatus[shipmentType].includes(assignment.status);
        }),
    );
};
