import React, { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { EnumRoute, IntRoute } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { SalesOrderTab } from '../../../store/invoicing/salesOrders/types/SalesOrderTab';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { SalesOrderDetailsPage } from './salesOrderDetails/SalesOrderDetailsPage';
import { SalesOrdersPage } from './SalesOrdersPage';

export const SalesOrdersRouter: FC<InvoicingTabRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const salesOrdersPath = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.SalesOrders}`;

    const handleOpenSalesOrder = (id: number) => {
        history.push(`${salesOrdersPath}/${id}/${SalesOrderTab.Details}`);
    };

    const handleOpenInvoiceEntry = (id: number) => {
        history.push(`${AdminNavLink.Invoicing}/${AdminInvoicingTab.InvoiceEntries}/${id}`);
    };

    const handleChangeTab = (id: number, tab: SalesOrderTab) => {
        history.push(`${salesOrdersPath}/${id}/${tab}`);
    };

    return (
        <Route path={salesOrdersPath}>
            <Switch>
                <Route path={salesOrdersPath} exact>
                    <SalesOrdersPage
                        tab={tab}
                        onChangeTab={onChangeTab}
                        tabs={tabs}
                        onOpenSalesOrder={handleOpenSalesOrder}
                    />
                </Route>
                <SalesOrderDetailsRoute path={`${salesOrdersPath}/:salesOrderId(\\d+)`}>
                    {({ salesOrderId }) => (
                        <SalesOrderDetailsTabRoute
                            path={`${salesOrdersPath}/:salesOrderId(\\d+)/:salesOrderTab`}
                        >
                            {({ salesOrderTab }) => (
                                <SalesOrderDetailsPage
                                    salesOrderId={salesOrderId}
                                    salesOrderTab={salesOrderTab}
                                    onOpenInvoiceEntry={handleOpenInvoiceEntry}
                                    onChangeTab={salesOrderTab =>
                                        handleChangeTab(salesOrderId, salesOrderTab)
                                    }
                                />
                            )}
                        </SalesOrderDetailsTabRoute>
                    )}
                </SalesOrderDetailsRoute>
            </Switch>
        </Route>
    );
};

const SalesOrderDetailsRoute = IntRoute('salesOrderId', Route);
const SalesOrderDetailsTabRoute = EnumRoute<SalesOrderTab>('salesOrderTab', SalesOrderTab, Route);
