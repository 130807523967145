import { xor } from 'lodash';
import React, { FC, useState } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { useSearch } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../design/blockFilters/SearchableBlockFilter';

interface BillingEntityBlockFilterProps {
    selected?: number[];
    billingEntities: BillingEntityDto[];
    onChange(ids: number[]): void;
}

export const BillingEntityBlockFilter: FC<BillingEntityBlockFilterProps> = ({
    selected,
    billingEntities,
    onChange,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const { results } = useSearch(billingEntities, b => `${b.idDeprecated} ${b.name}`, searchQuery);

    return (
        <SearchableBlockFilter
            title='Billing Entity'
            icon={SvgIcon.Building}
            items={results}
            isSelected={b => !!selected && selected.some(id => id === b.idDeprecated)}
            onFilterClick={b => onChange(xor(selected, [b.idDeprecated]))}
            renderItem={b => b.name}
            searchPlaceholder='Search billing entity'
            search={searchQuery}
            onSearch={setSearchQuery}
        />
    );
};
