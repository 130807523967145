import React, { FC } from 'react';

import { SupplierRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Avatar, Box, Paragraph3 } from '@hofy/ui';

interface AssignedSupplierCellProps {
    // TODO: change to generic supplier DTO when implemented
    supplier: SupplierRefDto;
}

export const AssignedSupplierCell: FC<AssignedSupplierCellProps> = ({ supplier }) => {
    return (
        <Box row>
            <Avatar userId={supplier.uuid} name={supplier.name} marginRight={10} shrink={0} radius={20} />
            <Paragraph3 color={Color.Neutral700}>{supplier.name}</Paragraph3>
        </Box>
    );
};
