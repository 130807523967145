import { useMutation } from '@tanstack/react-query';

import { billingEntitiesService, CollectPaymentsPayload } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useCollectBillingEntityPayments = (billingEntityId: number, onSuccess: () => void) => {
    const { showToast } = useToast();
    const mutation = useMutation({
        mutationFn: (p: CollectPaymentsPayload) => billingEntitiesService.collectPayments(billingEntityId, p),
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Payments collected successfully',
            });
            onSuccess();
        },
    });

    return {
        collectPayments: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
