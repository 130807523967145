import React, { FC } from 'react';

import { useAuthI18n } from '../../../../i18n/useAuthI18n';
import { useOrganizationSignupEmailVerification } from '../../../../store/auth/useOrganizationSignupEmailVerification';
import { AuthPageWrapper } from '../../../components/authPageWrapper/AuthPageWrapper';
import { EmailSentStep } from './EmailSentStep';
import { EmailVerificationStep } from './EmailVerificationStep';

interface VerifyEmailPageProps {}

export const VerifyEmailPage: FC<VerifyEmailPageProps> = () => {
    const { tr } = useAuthI18n();
    const { form, signUpWithPublicSSO, isEmailSent, goFirstStep, isLoading, isError } =
        useOrganizationSignupEmailVerification();

    const content = isEmailSent ? (
        <EmailSentStep goBack={goFirstStep} email={form.values.email} />
    ) : (
        <EmailVerificationStep
            form={form}
            signUpWithPublicSSO={signUpWithPublicSSO}
            isLoading={isLoading}
            isError={isError}
        />
    );

    return (
        <AuthPageWrapper
            pageId='self-sign-up'
            title={!isEmailSent ? tr('sign-up-page.title') : ''}
            showSubHeader={!isEmailSent}
            isSignup
        >
            {content}
        </AuthPageWrapper>
    );
};
