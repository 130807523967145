import React, { FC } from 'react';

import { FormFieldApi, Optional, TestKeyAware } from '@hofy/ui';

import { LabeledHofySubsidiaryProps, LabeledHofySubsidiarySelect } from './LabeledHofySubsidiarySelect';

type FormHofySubsidiarySelectOptionalProps = Optional<LabeledHofySubsidiaryProps, 'onChange'>;
type FormHofySubsidiarySelectOmittedProps = Omit<
    FormHofySubsidiarySelectOptionalProps,
    'errorMessage' | 'value'
>;

interface FormNormalHofySubsidiarySelectProps extends FormHofySubsidiarySelectOmittedProps, TestKeyAware {
    api: FormFieldApi<number>;
    nullable?: false;
}

interface FormNullableHofySubsidiarySelectProps extends FormHofySubsidiarySelectOmittedProps, TestKeyAware {
    api: FormFieldApi<number | null>;
    nullable: true;
}

type FormHofySubsidiarySelectProps =
    | FormNormalHofySubsidiarySelectProps
    | FormNullableHofySubsidiarySelectProps;

export const FormHofySubsidiarySelect: FC<FormHofySubsidiarySelectProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledHofySubsidiarySelect
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={(value: number | null) => {
                api.setValue(value!);
                onChange?.(value!);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
