import { useQuery } from '@tanstack/react-query';

import { paymentService } from '../service/paymentService';
import { paymentsCacheKey } from './cacheKey';

export const usePaymentsByInvoice = (invoiceId: number) => {
    const { data = [], isLoading } = useQuery({
        queryKey: [paymentsCacheKey, invoiceId],
        queryFn: () => paymentService.listForInvoice(invoiceId),
    });

    return {
        paymentData: data,
        isLoading,
    };
};
