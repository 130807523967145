import { AnyProductCategory, StockCondition } from '@hofy/api-shared';
import { Country } from '@hofy/global';
import { useStructMemo } from '@hofy/hooks';
import { useStringQueryParam } from '@hofy/router';

import { Availability, availabilityAll } from './types/Availability';
import { ProductFilters } from './types/ProductFilters';
import { ProductStatus } from './types/ProductStatus';

export const useProductFilters = () => {
    const [category, setCategory] = useStringQueryParam<AnyProductCategory>('categoryFilter');
    const [country, setCountry] = useStringQueryParam<Country>('countryFilter');
    const [productStatus, setProductStatus] = useStringQueryParam<ProductStatus>(
        'productStatusFilter',
        ProductStatus.Active,
    );
    const [availability, setAvailability] = useStringQueryParam<Availability>(
        'availabilityFilter',
        availabilityAll,
    );
    const [stockCondition, setStockCondition] = useStringQueryParam<StockCondition>(
        'stockCondition',
        StockCondition.Any,
    );

    const [search, setSearch] = useStringQueryParam('search', '');

    const filters = useStructMemo<ProductFilters>({
        category,
        country,
        productStatus,
        availability,
        search,
        stockCondition,
    });

    return {
        filters,
        setCategory,
        setCountry,
        setProductStatus,
        setAvailability,
        setStockCondition,
        setSearch,
    };
};
