import React, { FC } from 'react';
import styled from 'styled-components';

import { OrderItemDto } from '@hofy/api-admin';
import { useTrAcquisitionType, useTrPaymentSchema, useTrRentalTerm } from '@hofy/api-shared';
import { usePrice } from '@hofy/hooks';
import { BooleanBadge, Box, FormGridRow, Labeled, LabeledText } from '@hofy/ui';
import { OrderItemStatusChip } from '@hofy/ui-domain';

import { ProductItem } from '../../../components/domain/products/ProductItem';
import { AssignmentDetailsLink } from '../../assignmentsPage/AssignmentDetailsLink';
import { ContractLinkLabel } from '../../contractsPage/ContractLink';

interface RequestItemProps {
    orderItem: OrderItemDto;
}

export const OrderItem: FC<RequestItemProps> = ({ orderItem }) => {
    const trRentalTerm = useTrRentalTerm();
    const trPaymentSchema = useTrPaymentSchema();
    const trAcquisitionType = useTrAcquisitionType();
    const { formatPrice } = usePrice();

    const {
        status,
        assignmentId,
        contractId,
        product,
        variant,
        expressDeliveryRequested,
        contractSetting: { rentalTerm, paymentSchema, acquisitionType },
        budget,
    } = orderItem;

    return (
        <FormGridRow columns={8}>
            <ProductCell>
                <ProductItem
                    image={variant.image.url}
                    imageSize={80}
                    label={product.name}
                    subLabel={
                        <Box column gap={8} marginTop={8}>
                            {variant.style && <LabeledText label='Style' content={variant.style} />}
                            {variant.size && <LabeledText label='Size' content={variant.size} />}
                        </Box>
                    }
                />
            </ProductCell>
            <Labeled label='Status' content={<OrderItemStatusChip status={status} />} />

            <LabeledText label='Acquisition type' content={trAcquisitionType(acquisitionType)} />
            <LabeledText label='Rental term' content={rentalTerm && trRentalTerm(rentalTerm)} />
            <LabeledText
                label='Billing frequency'
                content={paymentSchema && trPaymentSchema(paymentSchema)}
            />

            <LabeledText label='Budget' content={formatPrice(budget)} />
            <Labeled
                label='Express delivery requested'
                content={<BooleanBadge value={expressDeliveryRequested} offColor='grey' />}
            />
            <LabeledText
                label='Assignment id'
                content={
                    assignmentId && (
                        <AssignmentDetailsLink id={assignmentId}>#{assignmentId}</AssignmentDetailsLink>
                    )
                }
            />
            <LabeledText label='Contract id' content={contractId && <ContractLinkLabel id={contractId} />} />
        </FormGridRow>
    );
};

const ProductCell = styled.div`
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
`;
