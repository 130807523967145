import React, { FC } from 'react';

import { emptyAdminInvoiceFilters, useInvoices } from '@hofy/api-admin';

import { InvoicesTable } from '../../../organizationsPage/invoicesTab/InvoicesTable';

interface BillingEntityInvoicesTableProps {
    billingEntityId: number;
    onInvoiceClick(invoiceId: number): void;
}

export const BillingEntityInvoicesTable: FC<BillingEntityInvoicesTableProps> = ({
    billingEntityId,
    onInvoiceClick,
}) => {
    const { invoices, isFetchingNextPage, isLoading, hasNextPage, fetchNextPage } = useInvoices({
        ...emptyAdminInvoiceFilters,
        billingEntity: [billingEntityId],
    });

    return (
        <InvoicesTable
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            invoices={invoices}
            onEntryClick={onInvoiceClick}
        />
    );
};
