import React, { FC } from 'react';

import { formatAdminName, UserRefDto } from '@hofy/api-shared';
import { Avatar, Box, BoxProps, Paragraph3 } from '@hofy/ui';

interface UserCardProps extends BoxProps {
    user: UserRefDto;
    onClick?(): void;
}

export const UserCard: FC<UserCardProps> = ({ user, onClick, ...boxProps }) => {
    return (
        <Box row padding={4} gap={8} rounded onClick={onClick} {...boxProps}>
            <Avatar userId={user.id} name={formatAdminName(user)} shrink={0} />
            <Paragraph3 textNoWrap ellipsis overflow='hidden'>
                {formatAdminName(user)}
            </Paragraph3>
        </Box>
    );
};
