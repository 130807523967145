import React, { FC } from 'react';

import { AddonDetailsDto, AuditableTable, useAddonQuery } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu, Slideout } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, ErrorStatePlaceholder, Heading3, Skeleton } from '@hofy/ui';

import { useUpdateAddon } from '../../../../store/addons/useUpdateAddon';
import { useAuditLogMenuOption } from '../../../../store/auditLogs/useAuditMenuOption';
import { AddonSlideoutContent } from './AddonSlideoutContent';

interface AddonUpdateSlideoutProps {
    addonId: UUID;
    onClose(): void;
}

export const AddonUpdateSlideout: FC<AddonUpdateSlideoutProps> = ({ addonId, onClose }) => {
    const { data: addon, isLoading, isError } = useAddonQuery(addonId);

    return (
        <Slideout width={1150} onClose={onClose}>
            {(() => {
                if (isLoading) {
                    return <Skeleton height='100%' />;
                }

                if (isError || !addon) {
                    return <ErrorStatePlaceholder />;
                }

                return <Content addon={addon} onClose={onClose} />;
            })()}
        </Slideout>
    );
};

interface ContentProps {
    addon: AddonDetailsDto;
    onClose(): void;
}

const Content: FC<ContentProps> = ({ addon, onClose }) => {
    const { form, isLoading, isError } = useUpdateAddon(addon, onClose);
    const { hasPermission } = useSession();

    const [auditMenuItem] = useAuditLogMenuOption(AuditableTable.Addons, undefined, addon.id);
    const menuItems = [auditMenuItem];

    return (
        <AddonSlideoutContent
            title={
                <Box row gap={20}>
                    <Heading3>{addon.name}</Heading3>
                    <MoreMenu items={menuItems} />
                </Box>
            }
            form={form}
            isLoading={isLoading}
            isError={isError}
            isDisabled={!hasPermission(Permission.AdminAddonsUpdate)}
            onClose={onClose}
            addon={addon}
        />
    );
};
