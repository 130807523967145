import React, { FC } from 'react';
import styled from 'styled-components';

import {
    AssignmentCollectionRequestDto,
    UserAssignmentsByShipmentDto,
    UserShipmentDto,
} from '@hofy/api-admin';
import { isShipmentFromOrganizationWarehouse, isWithCourierOrCompletedOrCanceled } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

import { CollectionSurveyStatusButtons } from '../../../../components/domain/shipments/CollectionSurveyStatusButtons';
import { CollectionConfirmedAddressCell } from './cells/CollectionConfirmedAddressCell';
import { CollectionFormLinkToClipboard } from './cells/CollectionFormLinkToClipboard';
import { CollectionParcelsToCollectCell } from './cells/CollectionParcelsToCollectCell';
import { CollectionShipmentLabelsStatusCell } from './cells/CollectionPrintLabelsStatusCell';
import { CollectionRequestStatusCell } from './cells/CollectionRequestStatusCell';
import { CollectionUserAvailabilityCell } from './cells/CollectionUserAvailabilityCell';
import { CollectionUserCanUseDropOffCell } from './cells/CollectionUserCanUseDropOffCell';
import { CollectionUserNotesCell } from './cells/CollectionUserNotesCell';

interface CollectionRequestRowProps {
    collectionRequest: AssignmentCollectionRequestDto;
    shipment: UserShipmentDto;
    user: UserAssignmentsByShipmentDto;
}

export const CollectionRequestRow: FC<CollectionRequestRowProps> = ({
    collectionRequest,
    shipment,
    user,
}) => {
    return (
        <CollectionWorkflowSection row gap={12} padding={12}>
            <CollectionRequestStatusCell
                formFirstSentAt={collectionRequest.formSentAtTimes[0] || null}
                userSubmittedFormAt={collectionRequest.formSubmittedByUserAt}
                isUserUnresponsive={collectionRequest.userUnresponsive}
            />
            {shipment.fromAddress && (
                <CollectionConfirmedAddressCell
                    confirmedAddress={collectionRequest.userConfirmedAddress}
                    shipmentAddress={getCollectionAddress(shipment, user)}
                />
            )}
            {collectionRequest.shipmentLabelsStatus && (
                <CollectionShipmentLabelsStatusCell
                    shipmentLabelsStatus={collectionRequest.shipmentLabelsStatus}
                />
            )}
            {collectionRequest.numberOfParcelsToCollect !== null && (
                <CollectionParcelsToCollectCell
                    numberOfParcelsToCollect={collectionRequest.numberOfParcelsToCollect}
                />
            )}
            {collectionRequest.userAvailability.length > 0 && (
                <CollectionUserAvailabilityCell userAvailability={collectionRequest.userAvailability} />
            )}
            {collectionRequest.additionalNotes && (
                <CollectionUserNotesCell userNotes={collectionRequest.additionalNotes} />
            )}
            {collectionRequest.canUseDropOffPoint && <CollectionUserCanUseDropOffCell />}
            {collectionRequest.formLink && (
                <Box row flex={1} justify='flex-end' gap={8}>
                    {!collectionRequest.formSubmittedByUserAt && (
                        <CollectionFormLinkToClipboard formLink={collectionRequest.formLink} />
                    )}
                    {!isWithCourierOrCompletedOrCanceled(shipment.status) && (
                        <CollectionSurveyStatusButtons
                            collectionRequest={collectionRequest}
                            shipmentId={shipment.idDeprecated}
                        />
                    )}
                </Box>
            )}
        </CollectionWorkflowSection>
    );
};

const getCollectionAddress = (shipment: UserShipmentDto, user: UserAssignmentsByShipmentDto) => {
    if (isShipmentFromOrganizationWarehouse(shipment)) {
        return shipment.fromWarehouse.address!;
    }
    return user.userAddress;
};

const CollectionWorkflowSection = styled(Box)`
    border: 1px dashed ${Color.NonContextualGreySubtle};
    border-radius: 8px;
`;
