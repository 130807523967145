import React, { FC } from 'react';

import { AppliedTransactionDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { BaseTable, Placeholder, SvgIllustration } from '@hofy/ui';
import { InvoiceStatusChip } from '@hofy/ui-domain';

interface AppliedTransactionsTabProps {
    transactions: AppliedTransactionDto[];
    isCreditNote: boolean;
    onOpenInvoice(id: number): void;
}

export const AppliedTransactionsTab: FC<AppliedTransactionsTabProps> = ({
    transactions,
    isCreditNote,
    onOpenInvoice,
}) => {
    const { formatPrice } = usePrice();
    return (
        <BaseTable
            data={transactions}
            toKey={entry => entry.id}
            onRowClick={entry => onOpenInvoice(entry.id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title={'No applied ' + isCreditNote ? 'credit notes' : 'invoices'}
                />
            }
            minWidth={1200}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 80,
                    flexGrow: 0,
                    renderer: t => `#${t.id}`,
                },
                {
                    id: 'reference',
                    flexGrow: 1,
                    header: 'Reference',
                    renderer: t => t.reference,
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    renderer: t => <InvoiceStatusChip status={t.status} isCreditNote={isCreditNote} />,
                },
                {
                    id: 'date',
                    header: 'Date',
                    flexGrow: 1,
                    renderer: t => formatDate(t.date),
                },
                {
                    id: 'amount',
                    header: 'Amount',
                    flexGrow: 1,
                    renderer: t => formatPrice(t.applied),
                },
            ]}
        />
    );
};
