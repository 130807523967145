import React, { FC } from 'react';

import { CodeRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Button, Icon, Paragraph3, Span, SvgIcon } from '@hofy/ui';

import { useShipmentOrderItemUnAssign } from '../../../../store/shipments/useShipmentOrderItemUnAssign';

interface ShipmentOrderItemUnAssignProps {
    assignmentId: number;
    itemCodes: CodeRefDto[];
    onUnassign(): void;
}

export const ShipmentOrderItemUnAssign: FC<ShipmentOrderItemUnAssignProps> = ({
    assignmentId,
    onUnassign,
    itemCodes,
}) => {
    const { unAssign } = useShipmentOrderItemUnAssign(onUnassign);
    return (
        <Box row>
            <Icon size={32} marginRight={20} svg={SvgIcon.CheckCircleFill} color={Color.FoundationPositive} />
            <Paragraph3 bold flex={1} marginRight={20}>
                Item code{' '}
                <Span color={Color.FoundationPositive}>{itemCodes.map(c => c.code).join(', ')}</Span> assigned
                to order{' '}
            </Paragraph3>
            <Button type='secondary' label='Unassign item' onClick={() => unAssign(assignmentId)} />
        </Box>
    );
};
