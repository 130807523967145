import { xor } from 'lodash';
import React, { FC, useState } from 'react';

import {
    allContractFilterTypes,
    allRedistributableFilters,
    allStoreAndReuseStatusFilters,
    ContractFilterType,
    ContractTabFilter,
    RedistributableFilter,
    redistributableFilterFromBoolean,
    redistributableFilterToBoolean,
    StoreAndReuseStatusFilter,
    useContractsQuery,
    useDownloadContractsReport,
    useTrContractFilterType,
    useTrStoreAndReuseStatusFilter,
} from '@hofy/api-admin';
import {
    allContractStatusFilterStatuses,
    allParentProductCategories,
    contractStatusColors,
    ContractStatusFilter,
    useTrContractStatusFilter,
} from '@hofy/api-shared';
import { ExportButton, Page } from '@hofy/common';
import { ProductCategoryTree } from '@hofy/product';
import { Box, FilterChipList, HiddenScroll, PageHeader, SearchInput, SectionTitle2, SvgIcon } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../components/design/blockFilters/EnumBlockFilter';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { OrganizationBlockFilter } from '../../components/domain/filters/OrganizationBlockFilter';
import { OrganizationFilterChip } from '../../components/domain/filters/OrganizationFilterChip';
import { useContractsFilters } from '../../store/contracts/useContractsFilters';
import { useTrRedistributableFilter } from '../../store/contracts/useTrRedistributableFilter';
import { useOrganizationsRefsQuery } from '../../store/organizations/useOrganizationsQuery';
import { ContractPageTabs } from './ContractPageTabs';
import { ContractsTable } from './ContractsTable';

interface ContractsPageProps {
    onOpenContract(id: number): void;
}

export const ContractsPage: FC<ContractsPageProps> = ({ onOpenContract }) => {
    const {
        filters,
        filterCount,
        setSearch,
        setOrganizations,
        setStatus,
        setTab,
        setContractTypes,
        setStoreAndReuseStatus,
        setRedistributable,
        setProductCategory,
    } = useContractsFilters();

    const { contracts, contractsIsLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useContractsQuery(filters);

    const [orgSearchQuery, setOrgSearchQuery] = useState('');

    const { data: organizations, isLoading: organizationsIsLoading } = useOrganizationsRefsQuery(
        filters.organizations,
        orgSearchQuery,
    );
    const { downloadContractReport } = useDownloadContractsReport();
    const trContractStatusFilter = useTrContractStatusFilter();
    const trContractType = useTrContractFilterType();
    const trStoreAndReuseStatusFilter = useTrStoreAndReuseStatusFilter();
    const trRedistributableFilter = useTrRedistributableFilter();
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    const redistributableStatusToArray = () =>
        filters.redistributable === null
            ? []
            : filters.redistributable
            ? [RedistributableFilter.Yes]
            : [RedistributableFilter.No];

    return (
        <Page>
            <PageHeader
                title='Contracts'
                rightSlot={
                    <>
                        <ExportButton onClick={() => downloadContractReport(filters)} />
                        <SearchInput
                            value={filters.search || ''}
                            onChange={setSearch}
                            placeholder='Organization name, id…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={
                    <ContractPageTabs
                        tab={filters.tab || ContractTabFilter.All}
                        onChange={tab => setTab(tab)}
                    />
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organizations || []}
                    onChange={setOrganizations}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <EnumMultiBlockFilter<ContractStatusFilter>
                    title='Contract status'
                    selected={filters.status || []}
                    onChange={setStatus}
                    items={allContractStatusFilterStatuses}
                    renderItem={trContractStatusFilter}
                />
                <EnumMultiBlockFilter<ContractFilterType>
                    title='Contract type'
                    icon={SvgIcon.Star}
                    selected={filters.contractType || []}
                    onChange={setContractTypes}
                    items={allContractFilterTypes}
                    renderItem={trContractType}
                />
                <EnumMultiBlockFilter<StoreAndReuseStatusFilter>
                    title='Store and reuse status'
                    selected={filters.storeAndReuseStatus || []}
                    onChange={setStoreAndReuseStatus}
                    items={allStoreAndReuseStatusFilters}
                    renderItem={trStoreAndReuseStatusFilter}
                />
                <EnumBlockFilter<RedistributableFilter>
                    title='Redistributable'
                    selected={redistributableFilterFromBoolean(filters.redistributable)}
                    onChange={t => setRedistributable(redistributableFilterToBoolean(t))}
                    items={allRedistributableFilters}
                    renderItem={trRedistributableFilter}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <OrganizationFilterChip
                    value={filters.organizations || []}
                    organizations={organizations}
                    onChange={setOrganizations}
                />

                <FilterChipList<ContractFilterType>
                    color='blue'
                    toKey={id => id}
                    selected={filters.contractType || []}
                    toLabel={trContractType}
                    onClear={cleared => setContractTypes(xor(filters.contractType, [cleared]))}
                />
                <FilterChipList<StoreAndReuseStatusFilter>
                    color='green'
                    toKey={id => id}
                    selected={filters.storeAndReuseStatus || []}
                    toLabel={trStoreAndReuseStatusFilter}
                    onClear={cleared => setStoreAndReuseStatus(xor(filters.storeAndReuseStatus, [cleared]))}
                />
                <FilterChipList<RedistributableFilter>
                    color='orange'
                    toKey={id => id}
                    selected={redistributableStatusToArray()}
                    toLabel={trRedistributableFilter}
                    onClear={() => setRedistributable(null)}
                />
                <FilterChipList
                    color={t => contractStatusColors[t]}
                    toKey={id => id}
                    selected={filters.status || []}
                    toLabel={trContractStatusFilter}
                    onClear={cleared => setStatus((filters.status || []).filter(id => id !== cleared))}
                />
            </BlockFilterChipContainer>
            <Box relative flex='auto' row alignItems='stretch'>
                <HiddenScroll paddingBottom={24} paddingLeft={30} paddingRight={30} width={300} borderRight>
                    <SectionTitle2 paddingVertical={24} paddingLeft={10}>
                        Category
                    </SectionTitle2>
                    <ProductCategoryTree
                        category={filters.productCategory}
                        onChange={setProductCategory}
                        parentProductCategories={allParentProductCategories}
                    />
                </HiddenScroll>
                <ContractsTable
                    contracts={contracts}
                    onOpenContract={onOpenContract}
                    infinityScroll={{
                        hasMore: hasNextPage,
                        isLoading: organizationsIsLoading || contractsIsLoading,
                        isLoadingMore: isFetchingNextPage,
                        loadMore: fetchNextPage,
                    }}
                />
            </Box>
        </Page>
    );
};
