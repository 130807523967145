import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssignItemPayload, assignmentsCacheKey, assignmentService } from '@hofy/api-admin';

export const useAssignItem = (assignmentId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (p: AssignItemPayload) => assignmentService.assignItem(assignmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });
};
