import { ColorDye } from '@hofy/global';

export enum CollectionSurveyStatus {
    NotSent = 'not_sent',
    PendingResponse = 'pending_response',
    NoResponse = 'no_response',
    Completed = 'completed',
    RequiresAction = 'requires_action',
}

export const collectionSurveyStatusFilters = [
    CollectionSurveyStatus.NotSent,
    CollectionSurveyStatus.PendingResponse,
    CollectionSurveyStatus.NoResponse,
    CollectionSurveyStatus.Completed,
    CollectionSurveyStatus.RequiresAction,
];

export const collectionSurveyStatusColors: Record<CollectionSurveyStatus, ColorDye> = {
    [CollectionSurveyStatus.NotSent]: 'orange',
    [CollectionSurveyStatus.PendingResponse]: 'magenta',
    [CollectionSurveyStatus.NoResponse]: 'magenta',
    [CollectionSurveyStatus.Completed]: 'green',
    [CollectionSurveyStatus.RequiresAction]: 'red',
};
