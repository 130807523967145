import React, { FC } from 'react';

import { contractBasePrice, contractPaymentSchema, contractPrice, ContractUnionDto } from '@hofy/api-admin';
import {
    ContractType,
    formatVariant,
    PaymentSchema,
    useTrAddonItem,
    useTrPaymentSchema,
} from '@hofy/api-shared';
import { usePrice } from '@hofy/hooks';
import {
    Badge,
    BooleanBadge,
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
    TwoLineSmallCell,
} from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

import { ContractTypeLabel } from '../../components/domain/contracts/ContractTypeLabel';
import { StoreAndReuseStatusChip } from '../../components/domain/contracts/StoreAndReuseStatusChip';
import { ProductItem } from '../../components/domain/products/ProductItem';

interface RentalAgreementsTableProps {
    contracts: ContractUnionDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenContract(id: number): void;
}

export const ContractsTable: FC<RentalAgreementsTableProps> = ({
    contracts,
    infinityScroll,
    onOpenContract,
}) => {
    const { formatPrice } = usePrice();
    const trPaymentSchema = useTrPaymentSchema();
    const trAddonItemType = useTrAddonItem();
    const trOptionalSchema = (v: PaymentSchema | null) => {
        if (!v) {
            return '--';
        }
        return trPaymentSchema(v);
    };
    return (
        <InfinityScrollTable
            data={contracts}
            toKey={contract => contract.id}
            onRowClick={v => onOpenContract(v.id)}
            infinityScroll={infinityScroll}
            emptyContent={<Placeholder illustration={SvgIllustration.ContractSearch} title='No contracts' />}
            flex='auto'
            minWidth={1200}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 90,
                    flexGrow: 0,
                    renderer: contract => (
                        <TwoLineSmallCell line1={`#${contract.id}`} line2={contract.publicId} />
                    ),
                },
                {
                    id: 'organization',
                    header: 'Organization',
                    width: 150,
                    flexGrow: 0,
                    renderer: contract => `${contract.organization.name}`,
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 0,
                    width: 120,
                    renderer: contract => <ContractStatusChip status={contract.status} />,
                },
                {
                    id: 'product',
                    header: 'Product',
                    flexGrow: 1,
                    width: 250,
                    renderer: contract => (
                        <ProductItem
                            image={contract.variant.image}
                            label={contract.product.name}
                            subLabel={formatVariant(contract.variant)}
                        />
                    ),
                },
                {
                    id: 'redistribution',
                    header: 'Redistr.',
                    width: 80,
                    flexGrow: 0,
                    renderer: contract => (
                        <BooleanBadge
                            value={
                                contract.type === ContractType.Rental &&
                                contract.rentalContract.possibleRedistribution
                            }
                            offColor='grey'
                        />
                    ),
                },
                {
                    id: 'storeAndReuse',
                    header: 'Store & reuse',
                    width: 140,
                    flexGrow: 0,
                    renderer: contract => (
                        <StoreAndReuseStatusChip
                            status={contract.rentalContract?.storeAndReuse?.status || null}
                        />
                    ),
                },
                {
                    id: 'rental-length',
                    header: 'Contract',
                    width: 120,
                    flexGrow: 0,
                    renderer: contract => {
                        return <ContractTypeLabel contract={contract} />;
                    },
                },
                {
                    id: 'payment-schema',
                    header: 'Payment',
                    width: 110,
                    flexGrow: 0,
                    renderer: contract => trOptionalSchema(contractPaymentSchema(contract)),
                },
                {
                    id: 'base-price',
                    header: 'Base price',
                    width: 100,
                    flexGrow: 0,
                    renderer: contract => formatPrice(contractBasePrice(contract)),
                },
                {
                    id: 'final-price',
                    header: 'Price',
                    width: 100,
                    flexGrow: 0,
                    renderer: contract => formatPrice(contractPrice(contract)),
                },
                {
                    id: 'type',
                    header: 'Service package',
                    width: 400,
                    flexGrow: 0,
                    renderer: contract => (
                        <Box row wrap gap={4}>
                            {contract.activeAddons.map(addon =>
                                addon.addonItems.map(item => (
                                    <Badge key={item} color='green' label={trAddonItemType(item)} />
                                )),
                            )}
                            {contract.pendingAddons.map(addon =>
                                addon.addonItems.map(item => (
                                    <Badge key={item} color='yellow' label={trAddonItemType(item)} />
                                )),
                            )}
                        </Box>
                    ),
                },
            ]}
        />
    );
};
