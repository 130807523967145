import { useQuery } from '@tanstack/react-query';

import { Role, UserRefDto } from '@hofy/api-shared';

import { userService } from '../service/userService';
import { usersCacheKey } from './cacheKey';

const emptyAdmins: UserRefDto[] = [];

export const useAdminsQuery = (role = Role.FulfillmentAdmin, getOffBoardedUsers = false) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [usersCacheKey, role, getOffBoardedUsers],

        queryFn: () => userService.getAdmins(role, getOffBoardedUsers),
    });

    return {
        isLoading,
        isError,
        data: data || emptyAdmins,
    };
};
