import React, { FC } from 'react';

import { useInspectionQuery } from '@hofy/api-admin';
import { NoteSource } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutTabbedHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, ErrorStatePlaceholder, Heading3, Skeleton, Tab, Tabs } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { NotesTab } from '../../../components/domain/notes/NotesTab';
import {
    getInspectionTabs,
    InspectionTab,
    inspectionTabLabels,
} from '../../../store/inspections/InspectionTab';
import { InspectionAdminSelector } from '../components/InspectionAdminSelector';
import { InspectionDetailsTab } from './DetailsTab';
import { DeviceCheckProgressTab } from './DeviceCheckProgressTab';
import { InspectionProgressTab } from './InspectionProgressTab';

interface InspectionSlideoutProps {
    uuid: UUID;
    tab: InspectionTab;
    onTabChange(tab: InspectionTab): void;
    onClose(): void;
}

export const InspectionSlideout: FC<InspectionSlideoutProps> = ({ uuid, tab, onTabChange, onClose }) => {
    const { data: inspection, isLoading, isError } = useInspectionQuery(uuid);

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutTabbedHeader
                title={
                    <Box row justify='space-between' fullWidth>
                        <Heading3 row>{`Inspection ${inspection?.publicId ?? ''}`}</Heading3>
                        {inspection && (
                            <InspectionAdminSelector
                                inspectionId={inspection.uuid}
                                assignedTo={inspection.assignedTo}
                            />
                        )}
                    </Box>
                }
                tabsSlot={
                    inspection && (
                        <Tabs active={tab} onChange={onTabChange}>
                            {getInspectionTabs({
                                isDeviceCheckRequired: !!inspection.deviceCheck,
                            }).map(tab => (
                                <Tab key={tab} id={tab} label={inspectionTabLabels[tab]} />
                            ))}
                        </Tabs>
                    )
                }
            />
            <SlideoutContent column gap={20} paddingVertical={20}>
                {(() => {
                    if (isLoading) {
                        return <Skeleton height='100%' />;
                    }

                    if (isError || !inspection) {
                        return <ErrorStatePlaceholder />;
                    }

                    return (
                        <>
                            {tab === InspectionTab.Details && (
                                <InspectionDetailsTab inspection={inspection} />
                            )}
                            {tab === InspectionTab.Inspection && (
                                <InspectionProgressTab inspection={inspection} />
                            )}
                            {tab === InspectionTab.DeviceCheck && inspection.deviceCheck && (
                                <DeviceCheckProgressTab inspection={inspection} />
                            )}
                            {tab === InspectionTab.Notes && (
                                <NotesTab source={NoteSource.Inspections} entityId={inspection.uuid} />
                            )}
                        </>
                    );
                })()}
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};
