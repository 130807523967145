import React, { FC } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { useGoBack } from '@hofy/hooks';
import { IntRoute } from '@hofy/router';

import { AuditLogsDetailsSlideout } from './AuditLogDetailsSlideout';
import { AuditLogsPage } from './AuditLogsPage';

export const AuditLogsRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { goBack } = useGoBack();

    const handleOpenAuditDetails = (id: number) => {
        history.push(`${path}/audit/${id}${history.location.search}`);
    };
    const handleSlideoutClose = () => {
        goBack(path);
    };

    return (
        <>
            <AuditLogsPage onOpenLog={handleOpenAuditDetails} />
            <Switch>
                <AuditIdRoute path={`${path}/audit/:auditId`} exact>
                    {({ auditId }) => (
                        <AuditLogsDetailsSlideout auditId={auditId} onClose={handleSlideoutClose} />
                    )}
                </AuditIdRoute>
            </Switch>
        </>
    );
};

const AuditIdRoute = IntRoute('auditId', Route);
