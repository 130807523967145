import React, { FC, useState } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import {
    isInitialRepairStatus,
    isResolvedRepairStatus,
    Permission,
    RepairStatus,
    useTrItemStatus,
    validStatusesFromRepair,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    Box,
    BoxProps,
    Button,
    ConfirmModal,
    FormContainer,
    FormModal,
    FormSelect,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Modals,
    Paragraph2,
    SubmitButton,
    SvgIcon,
} from '@hofy/ui';

import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { useCancelRepair } from '../../../store/repairs/useCancelRepair';
import { useRevertRepairStatus } from '../../../store/repairs/useRevertRepairStatus';

interface RepairMenuProps extends BoxProps {
    id: number;
    status: RepairStatus;
    isCancelled: boolean;
}

export const RepairMenu: FC<RepairMenuProps> = ({ id, status, isCancelled, ...boxProps }) => {
    const trItemStatus = useTrItemStatus();

    const [modal, setModal] = useState<RepairModal>();
    const closeModal = () => setModal(undefined);

    const { hasPermission } = useSession();

    // revert repair status
    const canRevertRepairStatus =
        hasPermission(Permission.AdminRepairsUpdate) &&
        !isInitialRepairStatus(status) &&
        !isResolvedRepairStatus(status) &&
        !isCancelled;
    const { revertRepairStatus } = useRevertRepairStatus(id);

    // cancel repair
    const canCancelRepair =
        hasPermission(Permission.AdminRepairsUpdate) && !isResolvedRepairStatus(status) && !isCancelled;
    const { form, isLoading, isError } = useCancelRepair(id, closeModal);

    const [auditMenuItem] = useAuditLogMenuOption(AuditableTable.Repair, id);

    const menuItems = [
        {
            icon: SvgIcon.Undo,
            action: () => setModal(RepairModal.RevertRepairStatusModal),
            label: 'Revert repair status',
            visible: canRevertRepairStatus,
        },
        {
            icon: SvgIcon.Cross,
            color: Color.ContentNegative,
            action: () => setModal(RepairModal.CancelRepairModal),
            label: 'Cancel repair',
            visible: canCancelRepair,
        },
        auditMenuItem,
    ];

    return (
        <Box {...boxProps}>
            <MoreMenu items={menuItems} />
            <Modals>
                {modal === RepairModal.RevertRepairStatusModal && (
                    <ConfirmModal
                        keyPrefix='revert-repair-status'
                        onClose={closeModal}
                        onConfirm={revertRepairStatus}
                    />
                )}
                {modal === RepairModal.CancelRepairModal && (
                    <FormModal
                        width={600}
                        onSubmit={form.submit}
                        onClose={closeModal}
                        isLoading={isLoading}
                        isError={isError}
                    >
                        <ModalHeader title='Cancel repair?' borderBottom />
                        <ModalContent>
                            <FormContainer column gap={24}>
                                <Paragraph2>Please set the item status after the cancellation</Paragraph2>
                                <FormSelect
                                    options={validStatusesFromRepair}
                                    toText={trItemStatus}
                                    api={form.fields.itemStatus}
                                    label='Item status'
                                    isRequired
                                />
                            </FormContainer>
                        </ModalContent>
                        <ModalFooter borderTop>
                            <Button type='secondary' onClick={closeModal} label='Back' />
                            <SubmitButton label='Cancel repair' />
                        </ModalFooter>
                    </FormModal>
                )}
            </Modals>
        </Box>
    );
};

enum RepairModal {
    RevertRepairStatusModal,
    CancelRepairModal,
}
