import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, ReportShipmentIssuePayload } from '@hofy/api-admin';
import { AssignmentShipmentIssue } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

interface ReportShipmentIssueParams {
    issueType: AssignmentShipmentIssue;
    createCollection: boolean;
    createReplacement: boolean;
    createLoaner: boolean;
    issueNotes: string | null;
    deliveryAddressId: UUID | null;
}

export const useReportShipmentIssue = (assignmentId: number, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: ReportShipmentIssuePayload) =>
            assignmentService.reportShipmentIssue(assignmentId, payload),
        onSuccess: (_, variables) => {
            let createdOrders = null;
            if (variables.createCollection) {
                createdOrders = 'collection';
            }
            if (variables.createReplacement) {
                if (createdOrders) {
                    createdOrders = `${createdOrders} and `;
                }
                createdOrders = `${createdOrders} replacement`;
            }
            const message = createdOrders ? `A ${createdOrders} order have been created` : '';

            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                title: 'Shipment issue reported',
                message,
            });
            onSuccess();
        },
    });

    const reportShipmentIssue = ({
        issueType,
        createCollection,
        createReplacement,
        createLoaner,
        issueNotes,
        deliveryAddressId,
    }: ReportShipmentIssueParams) => {
        if (issueType !== AssignmentShipmentIssue.Other) {
            issueNotes = null;
        } else {
            issueNotes = `Shipment Issue: ${issueNotes}`;
        }
        mutation.mutate({
            issueType,
            createCollection,
            createReplacement,
            createLoaner,
            issueNotes,
            deliveryAddressId,
        });
    };

    return {
        reportShipmentIssue,
    };
};
