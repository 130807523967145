import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { ProductDetailsDto, ProductPayload, VariantPayload } from '@hofy/api-admin';
import {
    allRentalTerms,
    getProductOperatingSystems,
    isProductConfigurable,
    RentalTerm,
    useTrOperatingSystem,
    useTrRentalTermShort,
} from '@hofy/api-shared';
import { Price } from '@hofy/global';
import { FallbackCategoryImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import {
    ArrayField,
    Box,
    FormCountryRegionSelect,
    FormFieldApi,
    FormFieldRecord,
    FormInput,
    FormPriceInput,
    FormSelect,
    FormSwitch,
    IconButton,
    ImageDropdown,
    ImageDropdownImage,
    Paragraph3,
    Paragraph4,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    UseForm,
    useFormObjectField,
} from '@hofy/ui';

import { NetsuiteStatusChip } from '../../../components/domain/netsuite/NetsuiteStatusChip';
import { FormOrganizationSelect } from '../../../components/domain/organizations/FormOrganizationSelect';

interface VariantsFormProps {
    form: UseForm<ProductPayload>;
    variants: ArrayField<VariantPayload>;
    activeImages: ImageDropdownImage[];
    product: ProductDetailsDto | null;
    lockVariantPrices: boolean;
}

export const VariantsForm: FC<VariantsFormProps> = ({
    form,
    lockVariantPrices,
    variants,
    activeImages,
    product,
}) => {
    if (form.values.variants.length === 0) {
        return (
            <Placeholder
                illustration={SvgIllustration.AssetsSearch}
                title='No variants'
                message='No variants for current product'
            />
        );
    }
    return (
        <>
            {variants.fields.map(v => (
                <VariantForm
                    variants={variants}
                    form={form}
                    product={product}
                    activeImages={activeImages}
                    api={v.api}
                    onDelete={() => variants.remove(v.key)}
                    onDuplicate={() =>
                        variants.add({
                            id: null,
                            sku: '',
                            manufacturerPartCode: '',
                            recommendedRetailPrice: v.api.recommendedRetailPrice.value,
                            isActive: v.api.isActive.value,
                            isInternal: v.api.isInternal.value,
                            isAvailableForStoreAndReuse: v.api.isAvailableForStoreAndReuse.value,
                            availability: v.api.availability.value,
                            price: v.api.price.value,
                            unbundledPrice: v.api.unbundledPrice.value,
                            purchasePrice: v.api.purchasePrice.value,
                            unbundledPurchasePrice: v.api.unbundledPurchasePrice.value,
                            style: v.api.style.value,
                            size: v.api.size.value,
                            os: v.api.os.value,
                            organizationId: v.api.organizationId.value,
                            image: v.api.image.value,
                        })
                    }
                    key={v.key}
                    lockVariantPrices={lockVariantPrices}
                />
            ))}
        </>
    );
};

interface VariantFormProps {
    api: FormFieldRecord<VariantPayload>;
    form: UseForm<ProductPayload>;
    activeImages: ImageDropdownImage[];
    product: ProductDetailsDto | null;
    variants: ArrayField<VariantPayload>;
    lockVariantPrices: boolean;
    onDelete(): void;
    onDuplicate(): void;
}

const VariantForm: FC<VariantFormProps> = ({
    api,
    lockVariantPrices,
    variants,
    form,
    onDelete,
    activeImages,
    product,
    onDuplicate,
}) => {
    const trOperatingSystem = useTrOperatingSystem();
    const onChangeBundledPurchasePrice = (value: Price) => {
        if (!lockVariantPrices) {
            return;
        }
        variants.fields.forEach(v => v.api.purchasePrice.setValue(value));
    };
    const onChangeUnbundledPurchasePrice = (value: Price) => {
        if (!lockVariantPrices) {
            return;
        }
        variants.fields.forEach(v => v.api.unbundledPurchasePrice.setValue(value));
    };
    const onChangeBundledRentalPrice = (value: Price, term: RentalTerm) => {
        if (!lockVariantPrices) {
            return;
        }
        variants.fields.forEach(v =>
            v.api.price.setValue({
                ...v.api.price.value,
                [term]: value,
            }),
        );
    };
    const onChangeUnbundledRentalPrice = (value: Price, term: RentalTerm) => {
        if (!lockVariantPrices) {
            return;
        }
        variants.fields.forEach(v =>
            v.api.unbundledPrice.setValue({
                ...v.api.unbundledPrice.value,
                [term]: value,
            }),
        );
    };
    return (
        <FormCard margin={40} padding={20} column gap={20}>
            <Box row>
                <CardTitle api={api} activeImages={activeImages} product={product} />
                <Box row alignItems='center' gap={20}>
                    <FormSwitch label='Active for new orders' api={api.isActive} />
                    <FormSwitch label='Active for store and reuse' api={api.isAvailableForStoreAndReuse} />
                    <FormSwitch label='Internal' api={api.isInternal} />
                    <IconButton disabled={!!api.id.value} icon={SvgIcon.Trash} onClick={onDelete} />
                    <IconButton icon={SvgIcon.Copy} onClick={onDuplicate} />
                </Box>
            </Box>
            <Row>
                <FormInput label='Style' placeholder='Style' api={api.style} nullable />
                <FormInput label='Size' placeholder='Size' api={api.size} nullable />
                <FormInput label='SKU' placeholder='SKU' api={api.sku} nullable isRequired />
                <FormInput label='MPC' placeholder='MPC' api={api.manufacturerPartCode} nullable />
                <FormSelect
                    label='Operating system'
                    options={getProductOperatingSystems(form.values.category)}
                    toText={trOperatingSystem}
                    api={api.os}
                    disabled={!isProductConfigurable(form.values.category)}
                />
                <FormCountryRegionSelect label='Availability' api={api.availability} />
                <FormOrganizationSelect label='Organization' api={api.organizationId} nullable />
            </Row>
            <PriceRow
                onChangePurchasePrice={onChangeBundledPurchasePrice}
                onChangeRentalPrice={onChangeBundledRentalPrice}
                purchasePrice={api.purchasePrice}
                rentalPrice={api.price}
                label='Bundled pricing'
            />
            <PriceRow
                onChangePurchasePrice={onChangeUnbundledPurchasePrice}
                onChangeRentalPrice={onChangeUnbundledRentalPrice}
                purchasePrice={api.unbundledPurchasePrice}
                rentalPrice={api.unbundledPrice}
                label='Unbundled pricing'
            />
            <FormPriceInput
                label='Recommended retail price'
                api={form.values.isRefurbished ? api.recommendedRetailPrice : api.unbundledPurchasePrice}
                disabled={!form.values.isRefurbished}
                helperText='This field will be used to calculate the service addon fee'
                nullable
                isRequired
            />
        </FormCard>
    );
};

interface CardTitleProps {
    activeImages: ImageDropdownImage[];
    api: FormFieldRecord<VariantPayload>;
    product: ProductDetailsDto | null;
}

const CardTitle: FC<CardTitleProps> = ({ api, activeImages, product }) => {
    const netsuite = (() => {
        if (!product || api.id.value === null) {
            return null;
        }
        return product.variants.find(variant => variant.id === api.id.value)?.netsuite || null;
    })();
    return (
        <Box row alignItems='center' gap={12} flex={1}>
            <ImageDropdown
                onChange={image => api.image.setValue(image)}
                images={activeImages}
                selected={api.image.value}
                fallback={<FallbackCategoryImage />}
                isError={!!api.image.error}
            />
            <Paragraph3 bold color={Color.ContentPrimary}>{`Variant #${api.id.value ?? 0}`}</Paragraph3>
            {netsuite && (
                <Box row>
                    <Paragraph4 color={Color.ContentSecondary} marginLeft={10} marginRight={4}>
                        Netsuite sync
                    </Paragraph4>
                    <NetsuiteStatusChip status={netsuite} />
                </Box>
            )}
        </Box>
    );
};

interface PriceRowProps {
    label: string;
    purchasePrice: FormFieldApi<Price>;
    rentalPrice: FormFieldApi<Record<RentalTerm, Price>>;
    onChangePurchasePrice(value: Price): void;
    onChangeRentalPrice(value: Price, rentalTerm: RentalTerm): void;
}

const PriceRow: FC<PriceRowProps> = ({
    purchasePrice,
    onChangePurchasePrice,
    onChangeRentalPrice,
    rentalPrice,
    label,
}) => {
    const trRentalTerm = useTrRentalTermShort();
    const rentals = useFormObjectField(rentalPrice);
    return (
        <>
            <Paragraph3 bold color={Color.ContentPrimary}>
                {label}
            </Paragraph3>
            <Row>
                <FormPriceInput
                    label='Purchase price'
                    onChange={onChangePurchasePrice}
                    api={purchasePrice}
                    nullable
                    isRequired
                />
                {allRentalTerms.map(term => (
                    <FormPriceInput
                        key={term}
                        onChange={(v: Price) => onChangeRentalPrice(v, term)}
                        label={trRentalTerm(term)}
                        api={rentals[term]}
                        nullable
                        isRequired
                    />
                ))}
            </Row>
        </>
    );
};

const FormCard = styled(Box)`
    background-color: ${Color.BackgroundDefault};
    border-radius: 8px;
    border: 1px solid ${Color.NonContextualBorderDivider};
    ${p =>
        !!p.onClick &&
        css`
            :hover {
                background-color: ${Color.InteractionDefaultHover};
            }

            cursor: pointer;
        `}
`;

const Row = styled(Box)`
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(5, 1fr);
`;
