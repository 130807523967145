import { omit } from 'lodash';

import { AdminInvoiceFilters, InvoicingEntityFilter, PaymentStatusFilter } from '@hofy/api-admin';
import { InvoiceStatus, NetsuiteSyncStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useDateRangeQueryParam, useStringQueryParam } from '@hofy/router';

export const useInvoicesFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');

    const [organization, setOrganization] = useArrayQueryParam<UUID>('organization');
    const [billingEntity, setBillingEntity] = useArrayQueryParam<number>('billingEntity');
    const [netsuiteStatus, setNetsuiteStatus] = useArrayQueryParam<NetsuiteSyncStatus>('netsuiteStatus');
    const [invoiceStatus, setInvoiceStatus] = useArrayQueryParam<InvoiceStatus>('invoiceStatus');
    const [paymentStatus, setPaymentStatus] = useArrayQueryParam<PaymentStatusFilter>('paymentStatus');
    const [invoicingEntity, setInvoicingEntity] =
        useStringQueryParam<InvoicingEntityFilter>('invoicingEntity');
    const [invoiceDate, setInvoiceDate] = useDateRangeQueryParam('invoiceDate');

    const filters = useStructMemo<AdminInvoiceFilters>({
        search,
        organization,
        billingEntity,
        netsuiteStatus,
        invoiceStatus,
        paymentStatus,
        invoicingEntity,
        invoiceDate,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setSearch,
        setOrganization,
        setBillingEntity,
        setNetsuiteStatus,
        setInvoiceStatus,
        setPaymentStatus,
        setInvoiceDate,
        setInvoicingEntity,
    };
};
