export const activityLogEventKindI18n = {
    'activity-log-event-kind.asset-created-with-user': 'Asset created',
    'activity-log-event-kind.asset-created-at-warehouse': 'Asset created',
    'activity-log-event-kind.asset-pending-delivery': 'Asset pending delivery',
    'activity-log-event-kind.asset-pending-collection': 'Asset pending collection',
    'activity-log-event-kind.asset-pending-transfer': 'Asset pending transfer',
    'activity-log-event-kind.asset-transferred-between-users': 'Asset transferred',
    'activity-log-event-kind.asset-archived': 'Asset archived',
    'activity-log-event-kind.asset-collected-to-warehouse': 'Asset collected',
    'activity-log-event-kind.asset-delivered-from-warehouse': 'Asset delivered',
    'activity-log-event-kind.asset-pending-repair': 'Asset pending repair',
    'activity-log-event-kind.asset-repair-resolved': 'Asset out of repair',
};
