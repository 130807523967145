import React, { FC } from 'react';

import { StorageAssignmentUnionDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { BaseTable, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import {
    StorageAssignmentStatusChip,
    storageAssignmentStatusDate,
} from '../../../components/domain/contracts/StorageAssignmentStatusChip';
import { WarehouseChip } from '../../../components/domain/warehouses/WarehouseChip';
import { AssignmentDetailsLink } from '../../assignmentsPage/AssignmentDetailsLink';
import { ItemDetailsLink } from '../../itemsPage/ItemDetailsLink';

interface StorageAssignmentsTableProps {
    storageAssignments: StorageAssignmentUnionDto[];
}

export const StorageAssignmentsTable: FC<StorageAssignmentsTableProps> = ({ storageAssignments }) => {
    return (
        <BaseTable
            data={storageAssignments}
            toKey={entry => entry.id}
            emptyContent={
                <Placeholder illustration={SvgIllustration.Storage} title='No storage assignments' />
            }
            minWidth={1200}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 80,
                    flexGrow: 0,
                    renderer: entry => `#${entry.id}`,
                },
                {
                    id: 'status',
                    header: 'Status',
                    width: 200,
                    flexGrow: 0,
                    renderer: entry => <StorageAssignmentStatusChip status={entry.status} />,
                },
                {
                    id: 'updated_at',
                    header: 'Status updated at',
                    width: 200,
                    flexGrow: 0,
                    renderer: entry => formatDate(storageAssignmentStatusDate(entry)),
                },
                {
                    id: 'warehouse',
                    header: 'Warehouse',
                    width: 200,
                    flexGrow: 0,
                    renderer: entry => <WarehouseChip name={entry.warehouse.name} />,
                },

                {
                    id: 'deliveryAssignment',
                    header: 'Delivery assignment',
                    width: 200,
                    flexGrow: 0,
                    renderer: entry => (
                        <TextCell>
                            {entry.deliveryAssignment ? (
                                <AssignmentDetailsLink
                                    id={entry.deliveryAssignment.idDeprecated}
                                >{`#${entry.deliveryAssignment.idDeprecated}`}</AssignmentDetailsLink>
                            ) : (
                                `--`
                            )}
                        </TextCell>
                    ),
                },

                {
                    id: 'collectionAssignment',
                    header: 'Collection assignment',
                    width: 200,
                    flexGrow: 0,
                    renderer: entry => (
                        <TextCell>
                            {entry.collectionAssignment ? (
                                <ItemDetailsLink
                                    id={entry.collectionAssignment.idDeprecated}
                                >{`#${entry.collectionAssignment.idDeprecated}`}</ItemDetailsLink>
                            ) : (
                                `--`
                            )}
                        </TextCell>
                    ),
                },

                {
                    id: 'Item',
                    header: 'Item',
                    flexGrow: 0,
                    renderer: entry => (
                        <TextCell>
                            {entry.item?.idDeprecated ? (
                                <ItemDetailsLink
                                    id={entry.item.idDeprecated}
                                >{`#${entry.item.idDeprecated}`}</ItemDetailsLink>
                            ) : (
                                `--`
                            )}
                        </TextCell>
                    ),
                },
                {
                    id: 'warehouse',
                    header: 'Warehouse',
                    width: 200,
                    flexGrow: 0,
                    renderer: entry => <WarehouseChip name={entry.warehouse.name} />,
                },
            ]}
        />
    );
};
