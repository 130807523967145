import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { useGoBack } from '@hofy/hooks';
import { Box, Button, SvgIcon } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { CreateExchangeRatesSlideout } from './CreateExchangeRatesSlideout';
import { ExchangeRatesPage } from './ExchangeRatesPage';

interface ExchangeRatesRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const ExchangeRatesRouter: FC<ExchangeRatesRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const { goBack } = useGoBack();
    const { hasPermission } = useSession();

    const canCreate = hasPermission(Permission.AdminExchangeRateCreate);

    const handleAddExchangeRate = () => {
        if (canCreate) {
            history.push(`${AdminNavLink.Settings}/${AdminSettingsTab.ExchangeRates}/add`);
        }
    };

    return (
        <Box column flex='auto'>
            <SettingsHeader
                tabs={tabs}
                tab={tab}
                onChangeTab={onChangeTab}
                titleContent={
                    canCreate && (
                        <Button
                            leftIcon={SvgIcon.Add}
                            label='Add exchange rate'
                            disabled={!hasPermission(Permission.AdminExchangeRateCreate)}
                            onClick={handleAddExchangeRate}
                        />
                    )
                }
            />
            <ExchangeRatesPage />
            <Route path={`${AdminNavLink.Settings}/${AdminSettingsTab.ExchangeRates}/add`}>
                <CreateExchangeRatesSlideout onClose={goBack} />
            </Route>
        </Box>
    );
};
