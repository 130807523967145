import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, CreateConsumablePayload } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useCreateConsumable = (attachedToAssignmentId: number, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: CreateConsumablePayload) =>
            assignmentService.createConsumable(attachedToAssignmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess();
            showToast({
                type: 'positive',
                message: 'Consumable assignment created',
            });
        },
    });

    return {
        createConsumable: mutation.mutate,
        isPending: mutation.isPending,
        isError: mutation.isError,
    };
};
