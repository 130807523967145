import React, { FC } from 'react';

import { useBaseI18n } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

interface ExportButtonProps extends BoxProps {
    onClick(): void;
}

export const ExportButton: FC<ExportButtonProps> = ({ onClick, ...boxProps }) => {
    const { tr } = useBaseI18n();
    return (
        <Box row pointer onClick={onClick} {...boxProps}>
            <Icon svg={SvgIcon.Download} size={16} color={Color.Neutral700} marginRight={10} />
            <Paragraph3 color={Color.Neutral700}>{tr('general.xlsx')}</Paragraph3>
        </Box>
    );
};
