import { ActivityLogEventKind } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

const AssetActivityLogIcons: Record<ActivityLogEventKind, Svg> = {
    [ActivityLogEventKind.AssetCreatedWithUser]: SvgIcon.User,
    [ActivityLogEventKind.AssetCreatedAtWarehouse]: SvgIcon.Warehouse,
    [ActivityLogEventKind.AssetPendingDelivery]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetPendingCollection]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetPendingTransfer]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetTransferredBetweenUsers]: SvgIcon.Send,
    [ActivityLogEventKind.AssetArchived]: SvgIcon.Box,
    [ActivityLogEventKind.AssetCollectedToWarehouse]: SvgIcon.Warehouse,
    [ActivityLogEventKind.AssetDeliveredFromWarehouse]: SvgIcon.User,
    [ActivityLogEventKind.AssetPendingRepair]: SvgIcon.Tool,
    [ActivityLogEventKind.AssetRepairResolved]: SvgIcon.Tool,
};

export const getAssetActivityLogEventIcon = (kind: ActivityLogEventKind): Svg => {
    return AssetActivityLogIcons[kind];
};
