import React, { FC } from 'react';

import { useRepairQuery } from '@hofy/api-admin';
import { NoteSource } from '@hofy/api-shared';
import {
    ComponentLoader,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutTabbedHeader,
} from '@hofy/common';
import { Box, Heading3, Tab, Tabs } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { NotesTab } from '../../../../components/domain/notes/NotesTab';
import {
    allRepairSlideoutTabs,
    RepairSlideoutTab,
    repairSlideoutTabLabels,
} from '../../../../store/repairs/repairSlideoutTab';
import { RepairAdminSelector } from '../../components/RepairAdminSelector';
import { RepairMenu } from '../../components/RepairMenu';
import { RepairStatusChip } from '../../components/RepairStatusChip';
import { RepairDetailsTab } from './RepairDetailsTab';
import { RepairProgressTab } from './RepairProgressTab';

interface RepairSlideoutProps {
    repairId: number;
    activeTab: RepairSlideoutTab;
    onTabChange(tab: RepairSlideoutTab): void;
    onClose(): void;
}

export const RepairSlideout: FC<RepairSlideoutProps> = ({ repairId, activeTab, onTabChange, onClose }) => {
    const { repair, isLoading } = useRepairQuery(repairId);
    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutTabbedHeader
                title={
                    <Box flex={1} row gap={20}>
                        <Heading3>{`Repair #${repairId}`}</Heading3>
                        {repair && (
                            <Box row flex={1}>
                                <Box row flex={1} gap={10}>
                                    <RepairStatusChip
                                        status={repair.status}
                                        isCancelled={!!repair.cancelledAt}
                                    />
                                    <RepairMenu
                                        width={20}
                                        id={repairId}
                                        status={repair.status}
                                        isCancelled={!!repair.cancelledAt}
                                    />
                                </Box>
                                <RepairAdminSelector repairId={repairId} user={repair.assignedUser} />
                            </Box>
                        )}
                    </Box>
                }
                tabsSlot={
                    <Tabs active={activeTab} onChange={onTabChange}>
                        {allRepairSlideoutTabs.map(tab => (
                            <Tab key={tab} id={tab} label={repairSlideoutTabLabels[tab]} />
                        ))}
                    </Tabs>
                }
            />
            <SlideoutContent paddingVertical={20}>
                {isLoading && (
                    <Box fullHeight flex='auto' relative>
                        <ComponentLoader />
                    </Box>
                )}

                {repair && (
                    <>
                        {activeTab === RepairSlideoutTab.Details && <RepairDetailsTab repair={repair} />}
                        {activeTab === RepairSlideoutTab.Progress && <RepairProgressTab repair={repair} />}
                        {activeTab === RepairSlideoutTab.Notes && (
                            <NotesTab source={NoteSource.Repairs} entityId={repair.uuid} />
                        )}
                    </>
                )}
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};
