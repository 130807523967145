import React, { FC, useState } from 'react';

import { useTrEmailType } from '@hofy/api-shared';
import { RadioGroup } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { Email } from '../types/Email';

interface ResetPasswordModalProps {
    onClose(): void;
    onResetPassword(email: string): void;
    emails: Email[];
}

export const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ onClose, onResetPassword, emails }) => {
    const trEmailType = useTrEmailType();

    const [selectedEmail, setSelectedEmail] = useState<Email>(emails[0]);

    const handleResetPassword = () => {
        onResetPassword(selectedEmail.address);
        onClose();
    };

    return (
        <Modal onClose={onClose} width={600} data-test-key='remove-asset-modal'>
            <ModalHeader title='Reset password' />
            <ModalContent>
                <RadioGroup
                    items={emails}
                    value={selectedEmail}
                    onChange={email => setSelectedEmail(email)}
                    labelFormatter={email => (
                        <Box flex={1} row>
                            <Paragraph3 flex={1} marginLeft={10}>
                                {trEmailType(email.type)}
                            </Paragraph3>
                            <Paragraph3 flex={1} color={Color.ContentPrimary} marginLeft={10}>
                                ({email.address})
                            </Paragraph3>
                        </Box>
                    )}
                    orientation='vertical'
                    spacing={20}
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <Button
                    label='Reset password'
                    onClick={handleResetPassword}
                    testKey='user-reset-password--reset-password-invite-button'
                />
            </ModalFooter>
        </Modal>
    );
};
