import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { isShipmentFromSupplierToUser, isShipmentFromUserToUser, ShipmentStatus } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, FormContainer, FormDateInput, FormSection } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useCompleteDeliveryShipment } from '../../../store/shipments/useCompleteDeliveryShipment';
import { CourierDetails } from '../components/CourierDetails';
import { SendNotificationForm } from '../components/SendNotificationForm';
import { ShipmentReceiveDropshipItems } from '../components/ShipmentReceiveDropshipItems';
import { ShipmentStatusTransitionPane } from '../components/ShipmentStatusTransitionPane';

interface DeliveryCompleteSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const DeliveryCompleteSlideout: FC<DeliveryCompleteSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading, setItemSerialNumber } = useCompleteDeliveryShipment(shipment, onClose);

    const { courierReference, courier, trackingLink, shipmentClass } = shipment;

    const notificationNotesTitle = !isShipmentFromUserToUser(shipment)
        ? 'Notification notes'
        : 'Notification notes for delivery user';

    return (
        <Slideout width={600} onClose={onClose} slideoutId='shipment-mark-as-completed'>
            <SlideoutHeader title='Complete shipment' />
            <SlideoutContent>
                <ShipmentStatusTransitionPane
                    marginVertical={30}
                    shipment={shipment}
                    toStatus={ShipmentStatus.Completed}
                />
                <FormContainer marginBottom={30}>
                    {isShipmentFromSupplierToUser(shipment) && (
                        <FormSection label='Items serial numbers' flex={1}>
                            <ShipmentReceiveDropshipItems
                                values={form.values.items}
                                shipment={shipment}
                                onSerialNumberChanged={setItemSerialNumber}
                            />
                        </FormSection>
                    )}
                    <FormSection label='Courier details' flex={1}>
                        <CourierDetails
                            courierReference={courierReference}
                            courier={courier}
                            trackingLink={trackingLink}
                            shipmentClass={shipmentClass}
                        />
                    </FormSection>
                    <FormDateInput label='Completed on' api={form.fields.completedOn} />
                    <SendNotificationForm
                        form={form}
                        switchLabel='Send notification'
                        textareaLabel={notificationNotesTitle}
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton isLoading={isLoading} onClick={form.submit} label='Mark as completed' />
            </SlideoutFooter>
        </Slideout>
    );
};
