import { useMutation } from '@tanstack/react-query';

import {
    CreateWarehouseBinFormData,
    CreateWarehouseBinPayload,
    initialCreateWarehouseBinFormData,
    warehouseService,
} from '@hofy/api-admin';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

export const useCreateHofyWarehouseBinForm = (
    warehouseId: number,
    onSuccess?: (id: number, payload: CreateWarehouseBinPayload) => void,
) => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: CreateWarehouseBinPayload) =>
            warehouseService.createWarehouseBin(warehouseId, payload),
        onSuccess: ({ id }, payload) => {
            showToast({
                type: 'positive',
                message: 'New bin added successfully',
            });
            onSuccess?.(id, payload);
        },
    });

    const form = useForm<CreateWarehouseBinFormData, CreateWarehouseBinPayload>({
        initial: initialCreateWarehouseBinFormData,
        onSubmit: data => mutation.mutate({ identifier: data.identifier.trim() }),
        validate: validator<CreateWarehouseBinFormData>({
            identifier: isRequired('Identifier is required'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
