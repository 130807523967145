export const authErrorI18n = {
    'auth-error.archived': 'Organization is archived',
    'auth-error.bad-credentials': 'The email address or password is incorrect',
    'auth-error.google-sso-not-allowed': 'Google SSO not allowed',
    'auth-error.incorrect-sso-config': 'Invalid SSO config',
    'auth-error.incorrect-two-factor-code': 'Incorrect two factor code',
    'auth-error.invalid-api-key': 'Invalid API key',
    'auth-error.duplicate-api-key': 'Duplicate API key',
    'auth-error.invalid-token': 'Invalid token',
    'auth-error.invalid-password': 'Invalid password',
    'auth-error.microsoft-sso-not-allowed': 'Microsoft SSO not allowed',
    'auth-error.missing-email': 'Missing email',
    'auth-error.no-roles': 'No required roles',
    'auth-error.not-found': 'You are not invited to Hofy',
    'auth-error.not-invited': 'You are not invited to Hofy',
    'auth-error.not-verified': 'User not verified',
    'auth-error.non-supported-email': 'Non supported email',
    'auth-error.offboarded': 'You have been offboarded from Hofy',
    'auth-error.sso-not-allowed': 'SSO login not allowed',
    'auth-error.required-sso-login': 'SSO login required',
    'auth-error.team-member-in-manager-only-org': 'Manager only organization',
    'auth-error.two-factor-already-set': 'Two factor already set',
    'auth-error.two-factor-required': 'Two factor required',
    'auth-error.unauthorized': 'Unauthorized',
    'auth-error.unknown': 'Cannot authenticate, please contact your administrator',
    'auth-error.passwords-do-not-match': 'Passwords do not match',
};
