import { useState } from 'react';

import { useAddressValidator } from '@hofy/address';
import { BillingEntityDto, ContractAddonDto, UpdateContractPayload } from '@hofy/api-admin';
import { Currency, Multiplier, Percent, Price, toCurrency } from '@hofy/global';
import { isRequired, useForm, validator } from '@hofy/ui';

import { usePriceManipulation } from '../invoiceEntries/usePriceManipulation';

export const useManualContractForm = (
    initial: UpdateContractPayload,
    onSubmit: (v: UpdateContractPayload) => void,
) => {
    const [lockBasePriceUpdate, setLockBasePriceUpdate] = useState(false);
    const { multipliedPrice, preMultipliedPrice, preDiscountedPrice, discountedPrice, sumDiscounts } =
        usePriceManipulation();
    const validateAddress = useAddressValidator();

    const form = useForm<UpdateContractPayload>({
        initial,
        onSubmit,
        validate: validator<UpdateContractPayload>({
            billingEntityId: isRequired('Please choose billing entity'),
            hofySubsidiaryId: isRequired('Please choose subsidiary'),
            billingToAddress: validateAddress,
        }),
    });

    const setBasePrice = (basePrice: Price) => {
        if (lockBasePriceUpdate) {
            const unitPrice = multipliedPrice(basePrice, form.values.multiplier);
            const price = discountedPrice(
                unitPrice,
                sumDiscounts(form.values.paymentDiscount, form.values.customDiscount),
            );
            form.setValues({
                basePrice,
                unitPrice,
                price,
            });
        } else {
            form.setValues({
                basePrice,
            });
        }
    };

    const setUnitPrice = (unitPrice: Price) => {
        if (lockBasePriceUpdate) {
            const basePrice = preMultipliedPrice(unitPrice, form.values.multiplier);
            const price = discountedPrice(
                unitPrice,
                sumDiscounts(form.values.paymentDiscount, form.values.customDiscount),
            );
            form.setValues({
                basePrice,
                unitPrice,
                price,
            });
        } else {
            const price = discountedPrice(
                unitPrice,
                sumDiscounts(form.values.paymentDiscount, form.values.customDiscount),
            );
            form.setValues({
                unitPrice,
                price,
            });
        }
    };

    const setPaymentDiscount = (paymentDiscount: Percent) => {
        const discount = sumDiscounts(paymentDiscount, form.values.customDiscount);
        const price = discountedPrice(form.values.unitPrice, discount);
        form.setValues({
            paymentDiscount,
            price,
        });
    };

    const setCustomDiscount = (customDiscount: Percent) => {
        const discount = sumDiscounts(customDiscount, form.values.paymentDiscount);
        const price = discountedPrice(form.values.unitPrice, discount);
        form.setValues({
            customDiscount,
            price,
        });
    };

    const setMultiplier = (multiplier: Multiplier) => {
        if (lockBasePriceUpdate) {
            const unitPrice = multipliedPrice(form.values.basePrice, multiplier);
            const discount = sumDiscounts(form.values.paymentDiscount, form.values.customDiscount);
            const price = discountedPrice(unitPrice, discount);
            form.setValues({
                multiplier,
                unitPrice,
                price,
            });
        } else {
            form.setValues({
                multiplier,
            });
        }
    };

    const setPrice = (price: Price) => {
        if (lockBasePriceUpdate) {
            const discount = sumDiscounts(form.values.paymentDiscount, form.values.customDiscount);
            const unitPrice = preDiscountedPrice(price, discount);
            const basePrice = preMultipliedPrice(unitPrice, form.values.multiplier);
            form.setValues({
                price,
                unitPrice,
                basePrice,
            });
        } else {
            const discount = sumDiscounts(form.values.paymentDiscount, form.values.customDiscount);
            const unitPrice = preDiscountedPrice(price, discount);
            form.setValues({
                price,
                unitPrice,
            });
        }
    };

    const setAddon = (index: number, addon: ContractAddonDto) =>
        form.setValues({
            addons: form.values.addons.map((item, i) => {
                if (i !== index) {
                    return item;
                }
                if (item.discount !== addon.discount || item.unitPrice !== addon.unitPrice) {
                    addon.price = discountedPrice(addon.unitPrice, addon.discount);
                } else if (item.price !== addon.price) {
                    addon.unitPrice = preDiscountedPrice(addon.price, addon.discount);
                }
                return addon;
            }),
        });

    const changeBillingEntity = (billingEntity: BillingEntityDto) => {
        const newCurrency = billingEntity?.currency || Currency.USD;
        form.setValues({
            billingEntityId: billingEntity?.idDeprecated!,
            basePrice: toCurrency(form.values.basePrice, newCurrency),
            price: toCurrency(form.values.price, newCurrency),
            unitPrice: toCurrency(form.values.unitPrice, newCurrency),
            rolloverPrice: form.values.rolloverPrice
                ? toCurrency(form.values.rolloverPrice, newCurrency)
                : null,
            addons: form.values.addons.map(addon => {
                addon.price = toCurrency(addon.price, newCurrency);
                addon.unitPrice = toCurrency(addon.unitPrice, newCurrency);
                return addon;
            }),
        });
    };

    return {
        lockBasePriceUpdate,
        setLockBasePriceUpdate,
        form,
        setUnitPrice,
        setPaymentDiscount,
        setCustomDiscount,
        setPrice,
        setMultiplier,
        setBasePrice,
        setAddon,
        changeBillingEntity,
    };
};

export type UseContractForm = ReturnType<typeof useManualContractForm>;
