import { useQuery } from '@tanstack/react-query';

import { billingEntitiesService } from '../service/billingEntityService';
import { billingEntitiesCacheKey } from './cacheKey';

export const useBillingEntityInvoiceContacts = (billingEntityId: number) => {
    const { data, isLoading } = useQuery({
        queryKey: [billingEntitiesCacheKey, billingEntityId],
        queryFn: () => billingEntitiesService.getBillingEntityInvoiceContacts(billingEntityId),
    });
    return {
        contacts: data || [],
        isLoading,
    };
};
