import { useMutation } from '@tanstack/react-query';
import { isBoolean } from 'lodash';
import { useMemo } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { authService, decodeToken, SignupTokenPayload } from '@hofy/api-auth';
import { OrganizationSize } from '@hofy/api-shared';
import { Country, Currency } from '@hofy/global';
import { useI18nGeneric } from '@hofy/i18n';
import { useStringQueryParam } from '@hofy/router';
import {
    FormErrors,
    isRequired,
    isValidEmailAddress,
    RequiredKeys,
    UseForm,
    useForm,
    useMultipartForm,
    UseMultipartFormForms,
    validator,
} from '@hofy/ui';

import { useAuthAnalytics } from '../../hooks/useAnalytics';
import { PasswordPayloadValidation } from './types/PasswordPayload';
import { useValidatePassword } from './useValidatePassword';

export interface EmailVerificationFormPayload {
    email: string;
}

interface EmailPasswordFormData {
    email: string;
    password: string;
}

export interface PersonalDetailsFormData {
    firstName: string;
    lastName: string;
}

export interface OrganizationDetailsFormData {
    organizationName: string;
    organizationSize: OrganizationSize | null;
    currency: Currency | null;
    billingCountry: Country | null;
    termsAndConditionsAccepted: boolean;
}

type EmailPasswordFormErrors = FormErrors<EmailPasswordFormData> & PasswordPayloadValidation;
export type EmailPasswordForm = UseForm<EmailPasswordFormData, EmailPasswordFormErrors>;

interface OrganizationSignupFormData extends UseMultipartFormForms {
    emailPassword: EmailPasswordForm | boolean;
    personalDetails: UseForm<PersonalDetailsFormData>;
    organizationDetails: UseForm<OrganizationDetailsFormData>;
}

interface OrganizationSignupFormDataSubmission extends UseMultipartFormForms {
    emailPassword: EmailPasswordForm | boolean;
    personalDetails: UseForm<RequiredKeys<PersonalDetailsFormData>>;
    organizationDetails: UseForm<RequiredKeys<OrganizationDetailsFormData>>;
}

export const useOrganizationSignUp = () => {
    const { tr } = useI18nGeneric();
    const validatePassword = useValidatePassword({ keyPrefix: 'password-form' });
    const [token] = useStringQueryParam('token');
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { trackSignUpCompleted } = useAuthAnalytics();

    const {
        email,
        provider,
        first_name: firstName,
        last_name: lastName,
    } = useMemo(
        () => (token ? decodeToken<SignupTokenPayload>(token) : ({} as Partial<SignupTokenPayload>)),
        [token],
    );

    const isSsoSignup = provider !== 'password';

    const emailPasswordForm = useForm<EmailPasswordFormData, EmailPasswordFormData, EmailPasswordFormErrors>({
        initial: {
            email: email ?? '',
            password: '',
        },
        validate: formValues => {
            const passwordValidation = validatePassword(formValues);
            const emailValidation = validator<EmailPasswordFormData, EmailPasswordFormData>({
                email: [
                    isRequired(tr('auth.form.email.errors.not-empty')),
                    isValidEmailAddress(tr('auth.form.email.errors.not-valid')),
                ],
            })(formValues);

            return {
                ...passwordValidation,
                ...emailValidation,
            };
        },
    });

    const personalDetailsForm = useForm<PersonalDetailsFormData>({
        initial: {
            firstName: firstName ?? '',
            lastName: lastName ?? '',
        },
        validate: validator<PersonalDetailsFormData>({
            firstName: isRequired(tr('org-sign-up-page.personal-details-form.errors.first-name')),
            lastName: isRequired(tr('org-sign-up-page.personal-details-form.errors.last-name')),
        }),
    });

    const organizationDetailsForm = useForm<OrganizationDetailsFormData>({
        initial: {
            organizationName: '',
            organizationSize: null,
            currency: null,
            billingCountry: null,
            termsAndConditionsAccepted: false,
        },
        validate: validator<OrganizationDetailsFormData>({
            organizationName: isRequired(
                tr('org-sign-up-page.organization-details-form.errors.company-name'),
            ),
            organizationSize: isRequired(
                tr('org-sign-up-page.organization-details-form.errors.company-size'),
            ),
            currency: isRequired(tr('org-sign-up-page.organization-details-form.errors.currency')),
            billingCountry: isRequired(tr('org-sign-up-page.organization-details-form.errors.country')),
            termsAndConditionsAccepted: isRequired(
                tr('org-sign-up-page.organization-details-form.errors.tac'),
            ),
        }),
    });

    const form = useMultipartForm<OrganizationSignupFormData, OrganizationSignupFormDataSubmission>(
        {
            emailPassword: isSsoSignup ? false : emailPasswordForm,
            personalDetails: personalDetailsForm,
            organizationDetails: organizationDetailsForm,
        },
        {
            onSubmit: async values => {
                const recaptchaToken = executeRecaptcha ? await executeRecaptcha() : '';
                organizationSignUpMutation.mutate({
                    password: isBoolean(values.emailPassword) ? '' : values.emailPassword?.password,
                    token: token || '',
                    firstName: values.personalDetails.firstName,
                    lastName: values.personalDetails.lastName,
                    organizationName: values.organizationDetails.organizationName,
                    organizationSize: values.organizationDetails.organizationSize,
                    currency: values.organizationDetails.currency,
                    billingCountry: values.organizationDetails.billingCountry,
                    recaptchaToken,
                });
            },
        },
    );

    const organizationSignUpMutation = useMutation({
        mutationFn: authService.organizationSignUp,
        onSuccess: () => {
            trackSignUpCompleted({
                organizationSize: organizationDetailsForm.values.organizationSize,
                currency: organizationDetailsForm.values.currency,
                billingCountry: organizationDetailsForm.values.billingCountry,
            });
        },
    });

    return {
        form,
        isLoading: organizationSignUpMutation.isPending,
        isError: organizationSignUpMutation.isError,
    };
};
