import React, { FC } from 'react';

import { Labeled, TwoLineSmallCell } from '@hofy/ui';

import { getSupplierLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';

interface SupplierLinkProps {
    supplierId?: number;
    supplierName?: string;
}

export const SupplierLink: FC<SupplierLinkProps> = ({ supplierId, supplierName }) => {
    const name = supplierName ?? '--';
    return (
        <Labeled
            label='Supplier'
            content={
                supplierId ? (
                    <Link to={getSupplierLink(supplierId)} underline={false}>
                        <TwoLineSmallCell line1={name} line2={`#${supplierId}`} />
                    </Link>
                ) : (
                    <TwoLineSmallCell line1={name} line2='' />
                )
            }
        />
    );
};
