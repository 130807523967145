import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Spinner } from '@hofy/ui';

import { useSyncJournalsToNetsuite } from '../../../store/journals/useSyncJournalsToNetsuite';

export const JournalsPageMenu: FC = () => {
    const { hasPermission } = useSession();

    const { syncToNetsuite, syncToNetsuiteIsLoading } = useSyncJournalsToNetsuite();

    if (syncToNetsuiteIsLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: syncToNetsuite,
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                },
            ]}
        />
    );
};
