import { BYODOrdersFilter, emptyBYODOrdersFilter } from '@hofy/api-admin';
import { BYODOrderStatus } from '@hofy/api-shared';
import { useFilters } from '@hofy/ui';

import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useStatusFilterDefinition } from '../filters/useStatusFilterDefinition';
import { useWarehousesFilterDefinition } from '../warehouses/filters/useWarehousesFilterDefinition';
import { useTrBYODOrderStatus } from './useTrBYODOrderStatus';

export const useBYODOrdersFilters = () => {
    const search = useSearchFilterDefinition();
    const warehouses = useWarehousesFilterDefinition('Location');
    const statuses = useStatusFilterDefinition({
        statusEnum: BYODOrderStatus,
        toLabel: useTrBYODOrderStatus(),
    });

    return useFilters<BYODOrdersFilter>(
        {
            search,
            warehouses,
            statuses,
        },
        emptyBYODOrdersFilter,
    );
};
