import React, { FC } from 'react';

import { ItemStatus } from '@hofy/api-shared';
import { Box, ConfirmModal, Paragraph3 } from '@hofy/ui';

import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { useAssignmentDispose } from '../../../store/assignments/useAssignmentDispose';

interface OrderItemDisposedProps {
    assignmentId: number;
    onSuccess(): void;
}

export const OrderItemDisposedModal: FC<OrderItemDisposedProps> = ({ assignmentId, onSuccess }) => {
    const { dispose, isLoading } = useAssignmentDispose(onSuccess);

    return (
        <ConfirmModal
            keyPrefix='item-disposed-modal'
            onClose={onSuccess}
            onConfirm={() => dispose(assignmentId)}
            isLoading={isLoading}
        >
            <Box marginTop={10} marginBottom={10} row gap={4}>
                <Paragraph3>Item status will be changed to</Paragraph3>
                <ItemStatusChip status={ItemStatus.LeftWithUser} />
            </Box>
        </ConfirmModal>
    );
};
