import { sortBy, xor } from 'lodash';
import React, { FC, useMemo, useState } from 'react';

import { HofyWarehouseDetailsDto } from '@hofy/api-admin';
import { useSearch } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../design/blockFilters/SearchableBlockFilter';

interface WarehouseBlockFilterProps {
    warehouses: HofyWarehouseDetailsDto[];
    selected: number[];
    onChange(warehouseId: number[]): void;
}

export const WarehouseBlockFilter: FC<WarehouseBlockFilterProps> = ({ warehouses, selected, onChange }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const sortedWarehouses = useMemo(() => sortBy(warehouses, item => item.name), [warehouses]);
    const { results } = useSearch(sortedWarehouses, warehouse => warehouse.name, searchQuery);

    return (
        <SearchableBlockFilter
            title='Warehouse'
            icon={SvgIcon.Home}
            items={results}
            isSelected={warehouse => selected.includes(warehouse.idDeprecated)}
            onFilterClick={warehouse => onChange(xor(selected, [warehouse.idDeprecated]))}
            renderItem={warehouse => warehouse.name}
            searchPlaceholder='Search warehouse'
            search={searchQuery}
            onSearch={setSearchQuery}
        />
    );
};
