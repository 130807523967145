import { difference, sortBy } from 'lodash';
import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import {
    HofyWarehouseDetailsDto,
    PurchaseOrderType,
    useVariantsWithProductAndPrice,
    VariantWithProductAndPriceMap,
} from '@hofy/api-admin';
import { AddressDto, AddressType, emptyAddressPayload } from '@hofy/api-shared';
import { FormDropdown, FormInput, FormTextarea } from '@hofy/common';
import { CountryLabel } from '@hofy/core';
import { allCurrencies, coreCurrencies, Country } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { useCopyToClipboard, useDecimal, usePrice } from '@hofy/hooks';
import { useTrCurrency } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import {
    BaseTable,
    Box,
    FormContainer,
    FormDateInput,
    FormSection,
    GradientSkeleton,
    IconButton,
    Labeled,
    LabeledInput,
    LabeledText,
    Paragraph3,
    SvgIcon,
    UseForm,
} from '@hofy/ui';

import { FormHofySubsidiarySelect } from '../../../../components/domain/hofySubsidiaries/FormHofySubsidiarySelect';
import { PurchaseOrderTypeChip } from '../../../../components/domain/purchaseOrders/PurchaseOrderTypeChip';
import { SupplierDropdown } from '../../../../components/domain/supplier/SupplierDropdown';
import { WarehouseDropdown } from '../../../../components/domain/warehouses/WarehouseDropdown';
import { PurchaseOrderFormData } from '../../../../store/purchaseOrders/types/PurchaseOrderFormData';
import { CreatePurchaseOrderForm } from '../../../../store/purchaseOrders/useCreatePurchaseOrder';
import { PurchaseOrderFormValidation } from '../../../../store/purchaseOrders/usePurchaseOrderForm';
import { PurchaseOrderSummaryLine } from './components/PurchaseOrderSummaryLine';
import { VariantDisplay } from './components/VariantDisplay';
import { EditAllowedOnStep, NavigationStep } from './hooks/useAllowedEditStep';
import { ItemsForm } from './ItemsForm';

interface CreateUpdatePurchaseOrderFormProps {
    form: CreatePurchaseOrderForm;
    step: number;
    isEdit?: boolean;
    isEditAllowedOnSteps?: EditAllowedOnStep[];
}

export const CreateUpdatePurchaseOrderForm: FC<CreateUpdatePurchaseOrderFormProps> = ({
    form,
    step,
    isEdit,
    isEditAllowedOnSteps,
}) => {
    const isEditAllowedOnStep = (step: number) => {
        const stepAllowedEntry = isEditAllowedOnSteps?.find(allowedOnStep => allowedOnStep.step === step);
        if (stepAllowedEntry) {
            return stepAllowedEntry.allowed;
        }

        return true;
    };
    const { isLoading, isPending, variantsById } = useVariantsWithProductAndPrice(
        {
            country: form.supplier?.billingAddress.country!,
            currency: form.form.values.currency,
            purchaseOrderType: form.form.values.isDropship
                ? PurchaseOrderType.Dropship
                : PurchaseOrderType.Warehouse,
        },
        form.supplier !== undefined,
    );
    const content = () => {
        switch (step) {
            case NavigationStep.Basic:
                return (
                    <BasicDataForm
                        form={form.form}
                        country={form.deliveryAddress?.country || null}
                        disableFields={!isEditAllowedOnStep(step)}
                    />
                );
            case NavigationStep.Items:
                // todo HOF-9955 change to check `isLoading` on supplier instead
                // `isPending` will be true while we are waiting for the supplier query to finish
                if (isPending) {
                    return (
                        <Box
                            flex='auto'
                            overflow='hidden'
                            paddingVertical='mainMarginVertical'
                            paddingHorizontal='mainMarginHorizontal'
                        >
                            <GradientSkeleton height={500} />
                        </Box>
                    );
                }
                return (
                    <ItemsForm
                        variantsById={variantsById}
                        orderForm={form}
                        isEdit={isEdit}
                        disableFields={!isEditAllowedOnStep(step)}
                        isLoading={isLoading}
                    />
                );
            case NavigationStep.Summary:
                return (
                    <SummaryForm
                        variantsById={variantsById}
                        form={form.form}
                        warehouse={form.warehouse}
                        deliveryAddress={form.deliveryAddress}
                        isLoading={isLoading}
                    />
                );
        }
    };

    return <Box paddingVertical={10}>{content()}</Box>;
};

interface BasicDataFormProps {
    form: UseForm<PurchaseOrderFormData, Partial<PurchaseOrderFormValidation>>;
    country: Country | null;
    disableFields: boolean;
}

const BasicDataForm: FC<BasicDataFormProps> = ({ form, country, disableFields }) => {
    const trCurrency = useTrCurrency();
    const { copyToClipboard } = useCopyToClipboard(form.values.purchaseOrderReference ?? '');

    return (
        <FormContainer marginTop={10} marginBottom={10}>
            <FormInput
                label='Purchase order reference'
                value={form.values.purchaseOrderReference}
                disabled
                isRequired
                nullable
                onChangeText={() => {}}
            >
                <IconButton icon={SvgIcon.Copy} onClick={copyToClipboard} marginLeft={10} />
            </FormInput>
            <LabeledInput
                label='Supplier reference'
                value={form.values.purchaseOrderSupplierReference}
                nullable
                onChange={purchaseOrderSupplierReference =>
                    form.setValues({
                        purchaseOrderSupplierReference,
                    })
                }
                disabled={disableFields}
            />
            <SupplierDropdown
                label='Supplier'
                value={form.values.supplierId}
                onChange={supplier =>
                    form.setValues({
                        supplierId: supplier.id,
                    })
                }
                marginRight={10}
                isRequired
                isError={form.errors.supplierId}
                filterSuppliers={supplier =>
                    !form.values.isDropship ? true : country !== null && supplier.countries.includes(country)
                }
                disabled={disableFields}
            />
            <FormDropdown
                label='Currency'
                items={sortBy(difference(allCurrencies, coreCurrencies), c => trCurrency(c))}
                fixedItems={sortBy(coreCurrencies, c => trCurrency(c))}
                searchable
                toSearchLabel={c => [trCurrency(c).toLowerCase(), trCurrency(c).toUpperCase()]}
                value={form.values.currency}
                onChange={currency =>
                    form.setValues({
                        currency,
                    })
                }
                labelFormatter={trCurrency}
                isError={form.errors.currency}
                isRequired
                disabled={disableFields}
            />
            {!form.values.isDropship && (
                <WarehouseDropdown
                    value={form.values.toWarehouseId}
                    onChange={warehouseId =>
                        form.setValues({
                            toWarehouseId: warehouseId,
                        })
                    }
                    isRequired
                    label='Warehouse'
                    emptyContent='No warehouse'
                    disabled={form.values.isDropship || disableFields}
                    isError={form.errors.toWarehouseId}
                />
            )}
            {form.values.isDropship && (
                <FormInput
                    flex={1}
                    value={`${form.values.toUser?.firstName} ${form.values.toUser?.lastName}`}
                    disabled
                    label='User'
                    onChangeText={() => {}}
                />
            )}
            <FormHofySubsidiarySelect
                api={form.fields.hofySubsidiaryId}
                label='Subsidiary'
                nullable
                disabled
            />
            <FormDateInput
                label='Order date'
                api={form.fields.orderedOn}
                disabled={disableFields}
                isRequired
            />
            <FormDateInput
                label='Delivery date'
                api={form.fields.estimatedReceiptOn}
                disabled={disableFields}
                isRequired
                nullable
            />
            <FormTextarea
                label='Notes'
                flex={1}
                value={form.values.notes ?? undefined}
                onChangeText={note =>
                    form.setValues({
                        notes: note,
                    })
                }
            />

            {form.errors.basicGenericError && (
                <Paragraph3 flex={1} justify='flex-end' color={Color.FoundationNegative} row marginRight={24}>
                    {form.errors.basicGenericError}
                </Paragraph3>
            )}
        </FormContainer>
    );
};

interface SummaryFormProps {
    form: UseForm<PurchaseOrderFormData, Partial<PurchaseOrderFormValidation>>;
    warehouse?: HofyWarehouseDetailsDto;
    deliveryAddress?: AddressDto | null;
    variantsById: VariantWithProductAndPriceMap;
    isLoading: boolean;
}

const SummaryForm: FC<SummaryFormProps> = ({ form, warehouse, deliveryAddress, variantsById, isLoading }) => {
    const { formatPrice } = usePrice();
    const { formatPercent } = useDecimal();

    const supplierAddress = form.values.supplier?.shippingAddress;
    return (
        <FormContainer marginTop={10} marginBottom={10}>
            <FormSection marginTop={30} label='Order details'>
                <Box column>
                    <Box row alignContent='flex-start' paddingBottom={12}>
                        <LabeledText flex={1} label='Supplier' content={form.values.supplier?.name} />
                        <LabeledText
                            flex={1}
                            label='Hofy subsidiary'
                            content={form.values.hofySubsidiary?.name}
                        />
                        <LabeledText flex={1} label='Currency' content={form.values.currency} />
                    </Box>
                    <Box row alignContent='flex-start' paddingBottom={12}>
                        <LabeledText
                            flex={1}
                            label='Order date'
                            content={formatDate(form.values.orderedOn)}
                        />
                        <LabeledText
                            flex={1}
                            label='Delivery date'
                            content={formatDate(form.values.estimatedReceiptOn)}
                        />
                        <Box flex={1}>
                            <Labeled
                                label='Purchase order type'
                                content={<PurchaseOrderTypeChip isDropship={form.values.isDropship} />}
                            />
                        </Box>
                    </Box>
                </Box>
            </FormSection>
            <FormSection marginTop={30} label='Shipping'>
                <Box row alignContent='flex-start'>
                    <LabeledText flex={1} label='Warehouse' content={warehouse?.name} />
                    <Labeled
                        flex={1}
                        content={<CountryLabel country={supplierAddress?.country} />}
                        label='From country'
                    />
                    <Labeled
                        flex={1}
                        content={<CountryLabel country={deliveryAddress?.country} />}
                        label='To country'
                    />
                </Box>
                <Box row alignContent='flex-start'>
                    <LabeledText
                        flex={1}
                        label='User'
                        content={`${form.values.toUser?.firstName ?? '--'} ${
                            form.values.toUser?.lastName ?? ''
                        }`}
                    />
                    <LabeledText
                        flex={1}
                        label='From address'
                        content={
                            <PostAddressView
                                address={supplierAddress || emptyAddressPayload(AddressType.Billing)}
                            />
                        }
                    />
                    <LabeledText
                        flex={1}
                        label='To address'
                        content={
                            <PostAddressView
                                address={deliveryAddress || emptyAddressPayload(AddressType.Billing)}
                            />
                        }
                    />
                </Box>
            </FormSection>
            <BaseTable
                data={form.values.items}
                toKey={(_, index) => index}
                rowHorizontalPadding={0}
                headerHorizontalPadding={0}
                emptyContent={<Paragraph3>No item</Paragraph3>}
                fullSize
                isLoading={isLoading}
                columns={[
                    {
                        id: 'variant',
                        header: 'SKU',
                        flexGrow: 1,
                        renderer: v => (
                            <VariantDisplay flex={1} name={v.name} variant={variantsById[v.variant!]} />
                        ),
                    },
                    {
                        id: 'name',
                        header: 'Name',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.supplierCode ?? '--',
                    },
                    {
                        id: 'estimatedReceiveAt',
                        header: 'Delivery',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatDate(v.estimatedReceiveOn),
                    },
                    {
                        id: 'warranty',
                        header: 'Warranty',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.warranty ?? '--',
                    },
                    {
                        id: 'quantity',
                        header: 'Quantity',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.quantity,
                    },
                    {
                        id: 'unitNetPrice',
                        header: 'Unit net price',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatPrice(v.unitNetPrice),
                    },
                    {
                        id: 'tax',
                        header: 'Tax',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatPercent(v.taxRate) ?? '--',
                    },
                    {
                        id: 'totalGrossPrice',
                        header: 'Total gross price',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => formatPrice(v.totalGrossPrice),
                    },
                    {
                        id: 'notes',
                        header: 'Notes',
                        width: 100,
                        flexGrow: 0,
                        renderer: v => v.notes,
                    },
                ]}
            />
            <PurchaseOrderSummaryLine label='Shipping/handling cost:' value={form.values.totalHandlingCost} />
            <PurchaseOrderSummaryLine
                label='Total order value:'
                subLabel='Incl. tax and shipping cost'
                value={form.values.totalValue}
            />
        </FormContainer>
    );
};
