import { first, isArray, isString } from 'lodash';

import { ErrorItem, FormErrors } from './formTypes';

export type Validator<T extends object, K extends keyof T> = (
    value: T[K],
    formValues: T,
) => ErrorItem | { [key: string]: ErrorItem } | { [key: string]: ErrorItem }[] | undefined;

type ArrayValidator<T extends object, K extends keyof T> = Validator<T, K>[];

export type Validators<T extends object> = Partial<{
    [K in keyof T]: Validator<T, K> | ArrayValidator<T, K>;
}>;

/**
 * Create a validator for the useForm hook
 * @param validators - object with keys matching the form values and values being the validation function, or an array of validation functions
 * @param customValidate - custom validation function that will be called after the default validation
 */
export const validator =
    <T extends object, E = FormErrors<T>>(
        validators: Validators<T>,
        customValidate?: (formValues: T) => Partial<E>,
    ) =>
    (formValues: T): E => {
        const errors = {} as E;

        const firstArrayError = (validator: ArrayValidator<T, keyof T>, value: T[keyof T]) => {
            return first(validator.map(validatorItem => validatorItem(value, formValues)).filter(isString));
        };

        Object.keys(validators).forEach(objKey => {
            const key = objKey as keyof T;
            const errorKey = objKey as keyof E;

            const fieldValidator = validators[key];
            const value = formValues[key];

            if (!fieldValidator) {
                return;
            }

            if (isArray(fieldValidator)) {
                const error = firstArrayError(fieldValidator, value);

                if (error) {
                    errors[errorKey] = error as any;
                }

                return;
            }

            const error = fieldValidator(value, formValues);
            if (error) {
                errors[errorKey] = error as any;
            }
        });

        if (customValidate) {
            const customErrors = customValidate(formValues);
            return {
                ...errors,
                ...customErrors,
            };
        }

        return errors;
    };
/**
 * Validate array field
 * @param selfRules - validates the array itself, you can use it to validate the array as a whole eg. min length
 * @param fieldsValidator - validates each item in the array
 */
export const validateArrayField =
    <T extends object, K extends keyof T>({
        selfRules,
        fieldsValidator,
    }: {
        selfRules?: Validator<T, K> | ArrayValidator<T, K>;
        fieldsValidator: T[K] extends (infer E)[]
            ? (fieldValues: E) => Partial<Record<keyof E, ErrorItem>>
            : never;
    }): Validator<any, any> =>
    (value, formValues) => {
        const firstArrayError = (validator: ArrayValidator<T, K>, value: T[K]) => {
            return first(validator.map(validatorItem => validatorItem(value, formValues)).filter(isString));
        };

        if (selfRules) {
            const error = isArray(selfRules)
                ? firstArrayError(selfRules, value)
                : selfRules(value, formValues);
            if (error) {
                return error;
            }
        }
        return (value as any[]).map(field => fieldsValidator(field));
    };
