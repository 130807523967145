import React, { FC, useState } from 'react';

import { NameAware } from '@hofy/helpers';
import { Box, Button, FormFieldApi, Modals, Paragraph3 } from '@hofy/ui';

import { CreateWarehouseBinModal } from './CreateWarehouseBinModal';
import { WarehouseBinInput } from './WarehouseBinInput';

interface WarehouseBinSelectorProps {
    api: FormFieldApi<string | null>;
    warehouse: NameAware;
    isRequired?: boolean;
    isAddBinPromptEnabled?: boolean;
    width?: number;
}

export const WarehouseBinSelector: FC<WarehouseBinSelectorProps> = ({
    api,
    warehouse,
    isRequired = false,
    isAddBinPromptEnabled = false,
    width,
}) => {
    const [modal, setModal] = useState(false);

    return (
        <Box column gap={4}>
            <WarehouseBinInput api={api} warehouseId={warehouse.id} isRequired={isRequired} width={width} />
            {isAddBinPromptEnabled && (
                <>
                    <Box row gap={4}>
                        <Paragraph3>Missing warehouse bin?</Paragraph3>
                        <Button type='plain' label='Add a new bin' onClick={() => setModal(true)} />
                    </Box>
                    <Modals>
                        {modal && (
                            <CreateWarehouseBinModal
                                warehouse={warehouse}
                                onCancel={() => setModal(false)}
                                onSuccess={hofyWarehouseBinIdentifier => {
                                    api.setValue(hofyWarehouseBinIdentifier);
                                    setModal(false);
                                }}
                            />
                        )}
                    </Modals>
                </>
            )}
        </Box>
    );
};
