import React, { FC, ReactNode, useMemo } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { FormDropdown } from '@hofy/common';
import { UUID } from '@hofy/global';
import { TestKeyAware } from '@hofy/ui';

import { useOptionalOrganizationBillingEntities } from '../../../../store/invoicing/billingEntities/useBillingEntities';

interface BillingEntityDropdownProps extends TestKeyAware {
    value: number | null;
    organizationId?: UUID;
    label: string;
    onChange(o: number | null, s: BillingEntityDto | null): void;
    emptyContent?: ReactNode;

    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
}

export const BillingEntityDropdown: FC<BillingEntityDropdownProps> = ({
    value,
    label,
    organizationId,
    onChange,
    emptyContent = false,
    isError,
    isRequired,
    testKey,
    disabled,
}) => {
    const { billingEntities } = useOptionalOrganizationBillingEntities(organizationId);

    const toLabel = (v: BillingEntityDto) => v.name;
    const itemValue = useMemo(
        () => billingEntities.find(u => u.idDeprecated === value),
        [billingEntities, value],
    );
    return (
        <FormDropdown
            items={billingEntities}
            labelFormatter={toLabel}
            onChange={i => onChange(i?.idDeprecated || null, i || null)}
            label={label}
            disabled={disabled}
            value={itemValue}
            toSearchLabel={toLabel}
            searchable
            emptyContent={emptyContent}
            isRequired={isRequired}
            isError={isError}
            testKey={testKey}
        />
    );
};
