import React, { FC } from 'react';

import { ZendeskStatusDto, ZendeskTicketStatusDto } from '@hofy/api-shared';
import { APP_CONFIG } from '@hofy/config';
import { FormGridRow, FormSection, Labeled, LabeledText, Link } from '@hofy/ui';

import { ZendeskStatusChip } from './ZendeskStatusChip';
import { ZendeskTicketStatusChip } from './ZendeskTicketStatusChip';

export enum LinkType {
    IssuePage,
    UserPage,
    OrganizationPage,
}

interface ZendeskLinkFlagProps {
    linkType: LinkType;
    zendesk: ZendeskStatusDto | ZendeskTicketStatusDto;
}

const buildLink = (linkType: LinkType, zendeskId: number | null) => {
    if (!APP_CONFIG.zendeskUrl) {
        return undefined;
    }
    if (!zendeskId) {
        return undefined;
    }
    switch (linkType) {
        case LinkType.IssuePage:
            return `${APP_CONFIG.zendeskUrl}/tickets/${zendeskId}`;
        case LinkType.OrganizationPage:
            return `${APP_CONFIG.zendeskUrl}/organizations/${zendeskId}`;
        case LinkType.UserPage:
            return `${APP_CONFIG.zendeskUrl}/users/${zendeskId}`;
    }
};

export const ZendeskSection: FC<ZendeskLinkFlagProps> = ({ linkType, zendesk }) => {
    const idLink = buildLink(linkType, zendesk.id);
    return (
        <FormSection label='Zendesk'>
            <FormGridRow columns={3}>
                <Labeled
                    flex={1}
                    label='Status'
                    content={<ZendeskStatusChip status={zendesk.syncStatus} />}
                />
                {idLink && (
                    <Labeled flex={1} label='Id' content={<Link to={idLink}>{`#${zendesk.id}`}</Link>} />
                )}
                {zendesk.error && <LabeledText flex={1} label='Sync error' content={zendesk.error} />}
                {'ticketStatus' in zendesk && zendesk.ticketStatus && (
                    <LabeledText
                        flex={1}
                        label='Ticket status'
                        content={
                            <ZendeskTicketStatusChip
                                status={(zendesk as ZendeskTicketStatusDto).ticketStatus}
                            />
                        }
                    />
                )}
            </FormGridRow>
        </FormSection>
    );
};
