import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { nowDate } from '@hofy/helpers';
import { Spinner } from '@hofy/ui';

import { useGenerateRevenueRecognition } from '../../../store/invoices/useGenerateRevenueRecognition';

export const RevenueRecognitionMenu: FC = () => {
    const { hasPermission } = useSession();
    const { generateRevenueRecognition, generateRevenueRecognitionIsLoading } =
        useGenerateRevenueRecognition();
    const endOfLastMonth = nowDate()
        .startOf('month')
        .minus({
            month: 1,
        })
        .endOf('month');
    if (generateRevenueRecognitionIsLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: () => generateRevenueRecognition(endOfLastMonth.toISODate()!),
                    label: 'Generate revenue recognition',
                    visible: hasPermission(Permission.AdminRevenueRecognitionGenerate),
                },
            ]}
        />
    );
};
