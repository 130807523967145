import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { CancelRepairPayload } from './types/CancelRepairPayload';
import { CreateRepairPayload } from './types/CreateRepairPayload';
import { RepairDetailsDto } from './types/RepairDetailsDto';
import { RepairDto } from './types/RepairDto';
import { RepairListFilters } from './types/RepairListFilters';
import { RepairListSorting } from './types/RepairListSorting';
import { UpdateRepairAssigneePayload } from './types/UpdateRepairAssigneePayload';
import { UpdateRepairStatusPayload } from './types/UpdateRepairStatusPayload';

class RepairsService {
    // repairs
    public createRepair = async (payload: CreateRepairPayload): Promise<void> => {
        return restClient.post('/api/admin/repairs', payload);
    };

    public listRepairs = async (
        filters: RepairListFilters,
        sorting: RepairListSorting,
        page: PageRequest,
    ): Promise<PageResponse<RepairDto>> => {
        return restClient.getJson<PageResponse<RepairDto>>(
            stringifyUrl({
                url: '/api/admin/repairs',
                query: {
                    ...filters,
                    ...sorting,
                    ...page,
                },
            }),
        );
    };

    public getRepair = async (id: number): Promise<RepairDetailsDto> => {
        return await restClient.getJson<RepairDetailsDto>(`/api/admin/repairs/${id}`);
    };

    public updateRepairStatus = async (id: number, payload: UpdateRepairStatusPayload): Promise<void> => {
        return restClient.put(`/api/admin/repairs/${id}/update-status`, payload);
    };

    public revertRepairStatus = async (id: number): Promise<void> => {
        return restClient.put(`/api/admin/repairs/${id}/revert-status`);
    };

    public updateRepairAssignee = async (id: number, payload: UpdateRepairAssigneePayload): Promise<void> => {
        return restClient.put(`/api/admin/repairs/${id}/update-assignee`, payload);
    };

    public cancelRepair = async (id: number, payload: CancelRepairPayload): Promise<void> => {
        return restClient.put(`/api/admin/repairs/${id}/cancel`, payload);
    };
}

export const repairsService = new RepairsService();
