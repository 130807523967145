export interface PasswordPayload {
    password: string;
    confirmedPassword?: string;
}

export const emptyPasswordPayload: PasswordPayload = {
    password: '',
    confirmedPassword: '',
};

export interface PasswordPayloadValidation {
    v8Characters: boolean;
    vCapitalCharacters: boolean;
    vNumberCharacters: boolean;
    vSymbolCharacters: boolean;
    password?: string;
    confirmedPassword?: string;
}
