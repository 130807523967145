import React, {
    ElementRef,
    forwardRef,
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
    ReactNode,
    useId,
} from 'react';
import styled from 'styled-components';

import { Color, fontFamily, fontSpacing, NumberValues } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { renderTextNode } from '../../../helpers';
import { asNullable } from '../../../helpers/String';
import { TestKeyAware } from '../../../types';
import { Box, OuterBoxProps, Paragraph3 } from '../../base';
import { IconButton, SvgIcon } from '../../icon';
import { FieldContainer } from '../shared/FieldContainer';

export interface BaseInputProps extends OuterBoxProps, TestKeyAware {
    type?: HTMLInputTypeAttribute;
    id?: string;
    onBlur?(): void;
    onFocus?(): void;
    onKeyDown?(e: React.KeyboardEvent): void;

    /** If defined, this function will be used to update value on blur */
    refine?(value: string): string;
    /** Remove leading and trailing whitespace, default is true for all types except password */
    trim?: boolean;

    placeholder?: string;
    disabled?: boolean;
    clearable?: boolean;
    autoFocus?: boolean;
    isError?: boolean;

    leftSlot?: ReactNode;
    /** If true, this slot will be interactive, so you can click on it and it will not focus the input */
    leftSlotInteractive?: boolean;
    rightSlot?: ReactNode;
    /** If true, this slot will be interactive, so you can click on it and it will not focus the input */
    rightSlotInteractive?: boolean;

    inputRawProps?: InputHTMLAttributes<HTMLInputElement>;
    paddingLeft?: NumberValues;
}

export interface InputOnlyStringProps extends BaseInputProps {
    nullable?: false;
    value: string;
    onChange(value: string): void;
}

export interface InputNullableStringProps extends BaseInputProps {
    nullable: true;
    value: string | null;
    onChange(v: string | null): void;
}

export type InputProps = InputOnlyStringProps | InputNullableStringProps;

export const Input = forwardRef<ElementRef<'input'>, InputProps>(
    (
        {
            value,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
            refine,
            id,
            type = 'text',
            trim = type !== 'password',
            nullable,
            placeholder,
            disabled: inputDisabled,
            clearable,
            autoFocus,
            isError,
            leftSlot,
            leftSlotInteractive,
            rightSlot,
            rightSlotInteractive,
            testKey,
            inputRawProps,
            ...rest
        },
        ref,
    ) => {
        const disabled = useIsDisabled(inputDisabled);

        const change = (value: string) => {
            if (nullable) {
                onChange(asNullable(value));
            } else {
                onChange(value);
            }
        };

        const blur = (value: string) => {
            if (trim || refine) {
                let val = value;
                if (trim) {
                    val = value.trim();
                }
                if (refine) {
                    val = refine(value);
                }
                change(val);
            }
            onBlur?.();
        };

        const clear = () => {
            change('');
        };

        const tmpId = useId();
        const inputId = id || tmpId;

        const clearNode = clearable && value && !disabled && (
            <SlotContainerBox interactive>
                <IconButton icon={SvgIcon.Cross} onClick={clear} />
            </SlotContainerBox>
        );

        const leftSlotNode = <InputSlot interactive={leftSlotInteractive}>{leftSlot}</InputSlot>;
        const rightSlotNode = <InputSlot interactive={rightSlotInteractive}>{rightSlot}</InputSlot>;

        return (
            <FieldContainer paddingHorizontal={16} disabled={disabled} isError={isError} {...rest}>
                {leftSlotNode}
                <InvisibleInput
                    ref={ref}
                    value={value || ''}
                    onChange={e => change(e.target.value)}
                    onBlur={e => blur(e.target.value)}
                    onFocus={() => onFocus?.()}
                    onKeyDown={onKeyDown}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    id={inputId}
                    autoFocus={autoFocus}
                    data-test-key={testKey}
                    {...inputRawProps}
                />
                {clearNode}
                {rightSlotNode}
                <LabelFill htmlFor={inputId} />
            </FieldContainer>
        );
    },
);

interface InputSlotProps {
    children?: ReactNode;
    interactive?: boolean;
}

const InputSlot = ({ children, interactive }: InputSlotProps) => {
    if (!children) {
        return null;
    }
    return (
        <SlotContainerBox row gap={8} interactive={interactive}>
            {renderTextNode(children, text => (
                <Paragraph3>{text}</Paragraph3>
            ))}
        </SlotContainerBox>
    );
};

const SlotContainerBox = styled(Box)<{ interactive?: boolean }>`
    position: relative;
    z-index: 2;
    pointer-events: ${props => (props.interactive ? 'auto' : 'none')};
`;

export const InvisibleInput = styled.input`
    position: relative;
    z-index: 2;
    display: block;
    color: ${Color.ContentPrimary};
    background: none;
    border: none;
    outline: none;
    padding: 0 4px;
    margin: 4px -4px;
    height: calc(100% - 8px);
    border-radius: 4px;
    flex: 1;
    font-family: ${fontFamily};
    font-size: 14px;
    letter-spacing: ${fontSpacing};

    &::placeholder {
        color: ${Color.ContentTertiary};
    }
`;

const LabelFill = styled.label`
    position: absolute;
    inset: 0;
    z-index: 1;
`;
