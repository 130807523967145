import React, { FC, MouseEvent, useState } from 'react';

import { Color } from '@hofy/theme';

import { IconButton, SvgIcon } from '../../icon';
import { FormInputProps } from './FormInput';
import { LabeledInput } from './LabeledInput';

export const FormPasswordInput: FC<FormInputProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsVisible(!isVisible);
    };

    return (
        <LabeledInput
            {...inputProps}
            ref={api.ref}
            type={isVisible ? 'text' : 'password'}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
            rightSlotInteractive
            rightSlot={
                <IconButton
                    size={16}
                    color={Color.ContentPrimary}
                    onClick={toggleVisibility}
                    icon={isVisible ? SvgIcon.Eye : SvgIcon.EyeOff}
                />
            }
        />
    );
};
