import React, { FC } from 'react';

import { Form, FormEmailInput, isRequired, SubmitButton, useForm, validator } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { useForgotPassword } from '../../store/auth/useForgotPassword';

export const ForgotPasswordForm: FC = () => {
    const { tr } = useAuthI18n();
    const { forgotPassword, isLoading, isSuccess } = useForgotPassword();

    const { submit, fields } = useForm({
        initial: {
            email: '',
        },
        validate: validator<{ email: string }>({
            email: isRequired(tr('forgot-password-page.form.email.error.not-empty')),
        }),
        onSubmit: forgotPassword,
    });

    return (
        <Form onSubmit={submit} submitOnEnter isLoading={isLoading} marginTop={30}>
            <FormEmailInput
                label={tr('forgot-password-page.form.email')}
                api={fields.email}
                marginBottom={30}
                testKey='email-input'
            />
            <SubmitButton
                label={
                    isSuccess
                        ? tr('forgot-password-page.form.re-submit')
                        : tr('forgot-password-page.form.submit')
                }
                fullWidth
                testKey='send-button'
            />
        </Form>
    );
};
