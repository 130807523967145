import React, { FC } from 'react';

import { HofyWarehouseDetailsDto } from '@hofy/api-admin';
import { SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterItem } from '../../../components/design/blockFilters/BlockFilterItem';

interface WarehouseBlockFilterProps {
    selected: number | null;
    onChange(ids: number | null): void;
    warehouses: HofyWarehouseDetailsDto[];
}

export const WarehouseBlockFilter: FC<WarehouseBlockFilterProps> = ({ warehouses, selected, onChange }) => {
    return (
        <BlockFilter title='Warehouse' icon={SvgIcon.Home}>
            {warehouses.map(item => (
                <BlockFilterItem
                    key={item.idDeprecated}
                    name={item.name}
                    selected={selected === item.idDeprecated}
                    onClick={() => onChange(selected === item.idDeprecated ? null : item.idDeprecated)}
                />
            ))}
        </BlockFilter>
    );
};
