import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, shipmentsCacheKey, shipmentService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useEnableTrackingRequest = (shipmentId: number) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => shipmentService.enableTrackingRequest(shipmentId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey, 'shipment', shipmentId] });
            queryClient.invalidateQueries({ queryKey: [shipmentsCacheKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Shipment tracking is enabled.',
            });
        },
    });

    return {
        mutate: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
