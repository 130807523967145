import { DateString, zeroUuid } from '@hofy/global';

import { DeelUserDto } from './DeelUserDto';
import { PublicRefDto } from './PublicRefDto';

export interface UserRefDto extends PublicRefDto {
    firstName: string;
    lastName: string;
    isOffBoarded: boolean | null;
    pictureUrl: string | null;
    newJoinerStartsOn: DateString | null;
    deelUser: DeelUserDto;
}

export const formatAdminName = (v: UserRefDto) =>
    `${v.firstName} ${v.lastName}${v.isOffBoarded ? ' (Offboarded)' : ''}`;
export const unassignedUser = zeroUuid;
