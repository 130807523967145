import React, { FC } from 'react';

import { emptyInvoiceEntriesFilters, useInvoiceEntries } from '@hofy/api-admin';

import { InvoiceEntriesTable } from '../../../invoiceEntries/components/InvoiceEntriesTable';

interface SalesOrderInvoiceEntriesTabContentProps {
    salesOrderId: number;
    onOpenInvoiceEntry(id: number): void;
}

export const SalesOrderInvoiceEntriesTabContent: FC<SalesOrderInvoiceEntriesTabContentProps> = ({
    salesOrderId,
    onOpenInvoiceEntry,
}) => {
    const { invoiceEntries, isFetchingNextPage, isLoading, hasNextPage, fetchNextPage } = useInvoiceEntries({
        ...emptyInvoiceEntriesFilters,
        salesOrder: salesOrderId,
    });

    return (
        <InvoiceEntriesTable
            entries={invoiceEntries}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            onRowClick={entry => onOpenInvoiceEntry(entry.id)}
        />
    );
};
