import React, { FC } from 'react';

import { OrderDetailsDto } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { RequestDecisionStatusChip } from '@hofy/core';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { BooleanBadge, Box, InfoTooltipIcon, Labeled, LabeledText, Link, Paragraph3 } from '@hofy/ui';

import { AddressTooltipOverlay } from '../../../components/domain/address/AddressTooltipOverlay';
import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { SalesOrderTab } from '../../../store/invoicing/salesOrders/types/SalesOrderTab';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { UserTab } from '../../../store/users/types/UserTab';

interface RequestDetailsContentProps {
    request: OrderDetailsDto;
}

export const OrderDetailsContent: FC<RequestDetailsContentProps> = ({ request }) => {
    const { formatPrice } = usePrice();
    const {
        publicId,
        uuid,
        note,
        isManagerOrder,
        status,
        createdAt,
        decisionAt,
        decisionUser,
        autoApproved,
        deliveryAddress,
        deliveryFee,
        expressDeliveryFee,
        organization,
        user,
        salesOrderId,
    } = request;

    return (
        <>
            <LabeledText label='Public id' content={publicId} />
            <LabeledText label='Uuid' content={uuid} />
            <Labeled label='Status' content={<RequestDecisionStatusChip status={status} />} />
            <Labeled label='Auto-approved' content={<BooleanBadge value={autoApproved} offColor='grey' />} />
            <Labeled
                label='Created by manager'
                content={<BooleanBadge value={isManagerOrder} offColor='grey' />}
            />
            <Labeled
                label='Organization'
                content={
                    <Link to={`${AdminNavLink.Organizations}/${organization.id}/details`}>
                        {organization.name}
                    </Link>
                }
            />
            <Labeled
                label='User'
                content={
                    <Link to={`${AdminNavLink.Users}/${user.id}/${UserTab.Details}`}>
                        {formatUserName(user)}
                    </Link>
                }
            />
            <Labeled
                label='Delivery address'
                content={
                    <Box row gap={8}>
                        <Paragraph3>Address</Paragraph3>
                        <InfoTooltipIcon
                            placement='top'
                            bodySlot={<AddressTooltipOverlay address={deliveryAddress} />}
                            maxWidth='auto'
                            interactive
                        />
                    </Box>
                }
            />
            <LabeledText label='Note' content={note} />
            <LabeledText label='Created date' content={formatDate(createdAt)} />
            <LabeledText label='Decision date' content={formatDate(decisionAt)} />
            <Labeled
                label='Decision by'
                content={
                    decisionUser && (
                        <Link to={`${AdminNavLink.Users}/${decisionUser.id}/${UserTab.Details}`}>
                            {formatUserName(decisionUser)}
                        </Link>
                    )
                }
            />
            <Labeled
                label='Sales order'
                content={
                    salesOrderId && (
                        <Link
                            to={`${AdminNavLink.Invoicing}/${AdminInvoicingTab.SalesOrders}/${salesOrderId}/${SalesOrderTab.Details}`}
                        >
                            #{salesOrderId}
                        </Link>
                    )
                }
            />
            <LabeledText label='Delivery fee' content={formatPrice(deliveryFee)} />
            <LabeledText label='Express delivery fee' content={formatPrice(expressDeliveryFee)} />
        </>
    );
};
