import { useMemo } from 'react';

import { PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { PurchaseOrderStatus } from '@hofy/api-shared';

export enum NavigationStep {
    Basic = 0,
    Items = 1,
    Summary = 2,
}

export interface EditAllowedOnStep {
    step: number;
    allowed: boolean;
}

const defaultEditableSteps = [
    {
        step: NavigationStep.Basic,
        allowed: true,
    },
    {
        step: NavigationStep.Items,
        allowed: true,
    },
];

export const useAllowedEditStep = (
    purchaseOrder: PurchaseOrderDetailsDto | undefined,
): EditAllowedOnStep[] => {
    return useMemo(() => {
        if (purchaseOrder && purchaseOrder.status === PurchaseOrderStatus.PendingReceipt) {
            return defaultEditableSteps.flatMap(editableStep => {
                if (editableStep.step === NavigationStep.Basic) {
                    return {
                        step: editableStep.step,
                        allowed: false,
                    };
                }

                return editableStep;
            });
        }

        return defaultEditableSteps;
    }, [purchaseOrder]);
};
