import { useMutation } from '@tanstack/react-query';

import { authService, ResetPasswordPayload } from '@hofy/api-auth';

export const useForgotPassword = () => {
    const forgotPassword = (p: ResetPasswordPayload) => {
        mutation.mutate(p);
    };

    const mutation = useMutation({
        mutationFn: authService.forgotPassword,
    });

    return {
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        forgotPassword,
    };
};
