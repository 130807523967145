import React, { FC } from 'react';

import { CompleteShipmentItemPayload, ShipmentDetailsDto } from '@hofy/api-admin';
import { FormInput } from '@hofy/common';
import { Box } from '@hofy/ui';

import { VariantDisplay } from '../../inventoryPage/purchaseOrders/purchaseOrderCreateEditSlideout/components/VariantDisplay';

interface ShipmentReceiveDropshipItemsProps {
    shipment: ShipmentDetailsDto;
    values: CompleteShipmentItemPayload[];
    onSerialNumberChanged(variantId: number, serial: string | null): void;
}

export const ShipmentReceiveDropshipItems: FC<ShipmentReceiveDropshipItemsProps> = ({
    shipment,
    values,
    onSerialNumberChanged,
}) => (
    <Box>
        {shipment.assignments.map((a, index) => (
            <Box key={a.id} row gap={10} marginBottom={8}>
                <VariantDisplay variant={a.variant} name={a.product.name} imageSize={50} />
                <Box flex={1} />
                <Box minWidth={200} width={200}>
                    <FormInput
                        flex={1}
                        value={values[index].serialNumber}
                        onChangeText={serial => onSerialNumberChanged(a.id, serial)}
                        nullable
                    />
                </Box>
            </Box>
        ))}
    </Box>
);
