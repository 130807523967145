import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { productsService } from '../service/productService';
import { ListProductsOptions } from '../service/types/ListProductsOptions';
import { productCacheKey } from './productCacheKey';

export const useListProducts = (options: ListProductsOptions) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [productCacheKey, options],
        queryFn: param =>
            productsService.listProducts(options, {
                page: param.pageParam,
                pageSize: 20,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const products = useMemo(() => data?.pages.flatMap(v => v.content) || [], [data]);

    return {
        products,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
