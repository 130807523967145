import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { InspectionUnionDto, ListInspectionsFilter } from '@hofy/api-admin';
import {
    getInspectionDeviceCheckStatusColorDye,
    InspectionDeviceCheckStatus,
    Ownership,
    useTrInspectionDeviceCheckStatus,
} from '@hofy/api-shared';
import { IconItem } from '@hofy/common';
import { ProductImage } from '@hofy/product';
import { Color, getColorFromColorDye } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon, Tooltip } from '@hofy/ui';

import { Link } from '../../../components/routing/Link';
import {
    getInspectionDeviceCheckTypeIcon,
    getInspectionDeviceCheckTypeLabel,
    InspectionDeviceCheckType,
} from '../../../store/inspections/InspectionDeviceCheckType';
import { InspectionAdminSelector } from '../components/InspectionAdminSelector';
import { InspectionStatusChip } from '../components/InspectionStatusChip';
import { useInspectionsPageLinks } from './InspectionsPageContext';

interface InspectionCardProps {
    inspection: InspectionUnionDto;
    viewType: 'rows' | 'columns';
    filters: ListInspectionsFilter;
}

export const InspectionCard: FC<InspectionCardProps> = ({
    inspection: { uuid, publicId, status, item, assignment, assignedTo, variant, product, deviceCheck },
    viewType,
    filters,
}) => {
    const history = useHistory();

    const { getInspectionLink, getDeviceCheckLink, getItemLink, getAssignmentLink } =
        useInspectionsPageLinks();

    const inspectionLink = filters.withDeviceCheckOnly ? getDeviceCheckLink(uuid) : getInspectionLink(uuid);

    return (
        <InspectionWrapper
            key={uuid}
            column
            bg={Color.BackgroundDefault}
            padding={10}
            gap={4}
            isHofyOwned={item.ownership === Ownership.Hofy}
            onClick={() => history.push(inspectionLink)}
        >
            <Box row gap={10} justify='space-between'>
                <Box row gap={10}>
                    <Link to={inspectionLink} color={Color.ContentPrimary}>
                        <IconItem icon={SvgIcon.Search} bold textNoWrap>
                            {publicId}
                        </IconItem>
                    </Link>
                    {viewType === 'rows' && (
                        <>
                            {!filters.withDeviceCheckOnly && <InspectionStatusChip status={status} />}
                            {filters.withDeviceCheckOnly && deviceCheck && (
                                <InspectionStatusChip status={deviceCheck.deviceCheckStatus} />
                            )}
                        </>
                    )}
                </Box>
                <Box row gap={10}>
                    {filters.withDeviceCheckOnly && deviceCheck && (
                        <Box row gap={6}>
                            {deviceCheck.activationLockRemovalStatus && (
                                <DeviceCheckIndicator
                                    type={InspectionDeviceCheckType.ActivationLockRemoval}
                                    status={deviceCheck.activationLockRemovalStatus}
                                />
                            )}
                            <DeviceCheckIndicator
                                type={InspectionDeviceCheckType.UserAccountRemoval}
                                status={deviceCheck.userAccountRemovalStatus}
                            />
                            {deviceCheck.orgMDMRemovalStatus && (
                                <DeviceCheckIndicator
                                    type={InspectionDeviceCheckType.OrgMDMRemoval}
                                    status={deviceCheck.orgMDMRemovalStatus}
                                />
                            )}
                            {deviceCheck.pinLockRemovalStatus && (
                                <DeviceCheckIndicator
                                    type={InspectionDeviceCheckType.PinLockRemoval}
                                    status={deviceCheck.pinLockRemovalStatus}
                                />
                            )}
                        </Box>
                    )}
                    <Link to={getAssignmentLink(assignment.idDeprecated)} color={Color.ContentPrimary}>
                        <IconItem icon={SvgIcon.Box} textNoWrap>
                            {assignment?.publicId}
                        </IconItem>
                    </Link>
                </Box>
            </Box>
            <Box row justify='space-between' gap={10}>
                <Box row gap={10}>
                    <ProductImage image={variant.image} size={40} />
                    <Link to={getItemLink(item.idDeprecated)} color={Color.ContentPrimary}>
                        <Paragraph3 color={Color.ContentPrimary}>{product.name}</Paragraph3>
                    </Link>
                </Box>
                <Box onClick={e => e.stopPropagation()}>
                    <InspectionAdminSelector inspectionId={uuid} assignedTo={assignedTo} />
                </Box>
            </Box>
        </InspectionWrapper>
    );
};

const InspectionWrapper = styled(Box)<{ isHofyOwned: boolean }>`
    border-left: 4px solid ${p => (p.isHofyOwned ? Color.ContentBrand : Color.ContentWarning)};
    border-radius: 4px;

    :hover {
        background: ${Color.BackgroundSurfaceNeutral};
    }
`;

interface InspectionDeviceCheckStatusIndicatorProps {
    type: InspectionDeviceCheckType;
    status: InspectionDeviceCheckStatus;
}

const DeviceCheckIndicator: FC<InspectionDeviceCheckStatusIndicatorProps> = ({ type, status }) => {
    const trStatus = useTrInspectionDeviceCheckStatus();
    const color = getColorFromColorDye(getInspectionDeviceCheckStatusColorDye(status), 'main');
    const bgColor = getColorFromColorDye(getInspectionDeviceCheckStatusColorDye(status), 'subtle');
    return (
        <Box
            column
            alignItems='center'
            justify='center'
            border
            borderColor={color}
            bg={bgColor}
            rounded={8}
            height={20}
            width={20}
        >
            <Tooltip
                placement='top-start'
                title={`${getInspectionDeviceCheckTypeLabel(type)} (${trStatus(status)})`}
                maxWidth='auto'
            >
                <Icon svg={getInspectionDeviceCheckTypeIcon(type)} color={color} size={12} />
            </Tooltip>
        </Box>
    );
};
