import { useMutation, useQueryClient } from '@tanstack/react-query';

import { collectionRequestService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from '../shipments/useShipmentCollectionRequestQuery';

export const useSendCollectionRequestSurvey = (shipmentId: number) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: ({ shipmentId, sendTextMessage }: { shipmentId: number; sendTextMessage: boolean }) =>
            collectionRequestService.sendCollectionRequestSurvey(shipmentId, { sendTextMessage }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Survey sent successfully',
            });
        },
    });

    const sendCollectionRequestSurvey = (sendTextMessage: boolean) =>
        mutation.mutate({ shipmentId, sendTextMessage });

    return {
        sendCollectionRequestSurvey,
        sendingInProgress: mutation.isPending,
    };
};
