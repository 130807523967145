import { useEffect } from 'react';

import { OrganizationSize } from '@hofy/api-shared';
import { analytics } from '@hofy/external-services';
import { Country, Currency } from '@hofy/global';

export const useAuthAnalytics = () => {
    const commonProperties = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const track = (trackName: string, properties?: Record<string, any>) => {
        analytics?.track(trackName, {
            ...commonProperties,
            ...properties,
        });
    };

    const page = (pageName: string, properties?: Record<string, any>) => {
        analytics?.page(pageName, {
            ...commonProperties,
            ...properties,
        });
    };

    const trackIncorrectEmailEntered = () => {
        track(AnalyticsSelfSignUpEvent.IncorrectEmailEntered);
    };

    const trackSignUpAttepted = () => {
        track(AnalyticsSelfSignUpEvent.SignUpAttepted);
    };

    const trackSignUpCompleted = (data: {
        organizationSize: OrganizationSize | null;
        currency: Currency | null;
        billingCountry: Country | null;
    }) => {
        track(AnalyticsSelfSignUpEvent.SignUpCompleted, data);
    };

    return { track, page, trackIncorrectEmailEntered, trackSignUpAttepted, trackSignUpCompleted };
};

export const useAuthAnalyticsPageOnce = (action: string) => {
    const { page } = useAuthAnalytics();
    useEffect(() => {
        page(action);
    }, []);
};

export enum AnalyticsSelfSignUpEvent {
    SignUpPageViewed = 'Sign up page viewed',
    SignUpAttepted = 'Sign up attempted',
    IncorrectEmailEntered = 'Sign up incorrect email entered',
    PasswordCreationStepViewed = 'Sign up password creation step viewed',
    PersonalDetailsStepViewed = 'Sign up personal details step viewed',
    OrganizationDetailsStepViewed = 'Sign up organization details step viewed',
    SignUpCompleted = 'Sign up completed',
}
