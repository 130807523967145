import { keyBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import {
    FulfilmentCountrySettingDto,
    FulfilmentCountrySettingsFilter,
    HofyWarehouseDetailsDto,
    useHofyWarehousesQuery,
} from '@hofy/api-admin';
import { HofyWarehouseType, Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { CountryLabelView, FlagLabelWrapper } from '@hofy/core';
import { Country } from '@hofy/global';
import {
    FilterApiRecord,
    FilterHeaderCell,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
} from '@hofy/ui';
import { HofyWarehouseLocationCard } from '@hofy/ui-domain';

import { FilterCountryHeaderCell } from '../../../components/domain/country/FilterCountryHeaderCell';
import { useTrHofyWarehouseType } from '../../../store/warehouses/useTrHofyWarehouseType';

interface FulfilmentCountrySettingsTableProps {
    settings: FulfilmentCountrySettingDto[];
    filters: FilterApiRecord<FulfilmentCountrySettingsFilter>;
    onUpdateClick(country: Country): void;
    infinityScroll: InfiniteScrollConfig;
}

export const FulfilmentCountrySettingTable: FC<FulfilmentCountrySettingsTableProps> = ({
    settings,
    filters,
    onUpdateClick,
    infinityScroll,
}) => {
    const { hasPermission } = useSession();
    const editable = hasPermission(Permission.AdminFulfilmentCountrySettingsUpdate);
    const { data: warehouses } = useHofyWarehousesQuery();
    const handleRowClick = (setting: FulfilmentCountrySettingDto): void => onUpdateClick(setting.country);

    const warehouseMap = useMemo(() => keyBy(warehouses, 'id'), [warehouses]);
    const getWarehouseDetails = (warehouseId?: number) =>
        typeof warehouseId !== 'undefined' ? warehouseMap[warehouseId] : undefined;

    return (
        <InfinityScrollTable
            data={settings}
            toKey={setting => setting.country}
            onRowClick={editable ? handleRowClick : undefined}
            hoverable={editable}
            infinityScroll={infinityScroll}
            flex='auto'
            rowVerticalPadding={15}
            columns={[
                {
                    id: 'country',
                    width: 200,
                    flexGrow: 1,
                    header: <FilterCountryHeaderCell label='Country' filter={filters.countries} />,
                    renderer: setting => (
                        <FlagLabelWrapper>
                            <CountryLabelView country={setting.country} textNoWrap />
                        </FlagLabelWrapper>
                    ),
                },
                {
                    id: 'defaultDeliverFromWarehouse',
                    width: 200,
                    flexGrow: 1,
                    header: (
                        <FilterHeaderCell
                            label='Default delivery'
                            filter={filters.defaultDeliverFromWarehouseIds}
                        />
                    ),
                    renderer: setting => (
                        <WarehouseCell warehouse={warehouseMap[setting.defaultDeliverFromWarehouse.id]} />
                    ),
                },
                {
                    id: 'defaultCollectToWarehouse',
                    width: 200,
                    flexGrow: 1,
                    header: (
                        <FilterHeaderCell
                            label='Default collection'
                            filter={filters.defaultCollectToWarehouseIds}
                        />
                    ),
                    renderer: setting => (
                        <WarehouseCell warehouse={warehouseMap[setting.defaultCollectToWarehouse.id]} />
                    ),
                },
                {
                    id: 'storeAndReuseCollectToLocalWarehouse',
                    width: 200,
                    flexGrow: 1,
                    header: (
                        <FilterHeaderCell
                            label='Store and reuse local hub'
                            filter={filters.storeAndReuseCollectToLocalWarehouseIds}
                        />
                    ),
                    renderer: setting => (
                        <WarehouseCell
                            warehouse={getWarehouseDetails(setting.storeAndReuseCollectToLocalWarehouse?.id)}
                        />
                    ),
                },
                {
                    id: 'storeAndReuseCollectToHubWarehouse',
                    width: 200,
                    flexGrow: 1,
                    header: (
                        <FilterHeaderCell
                            label='Store and reuse main hub'
                            filter={filters.storeAndReuseCollectToHubWarehouseIds}
                        />
                    ),
                    renderer: setting => (
                        <WarehouseCell
                            warehouse={getWarehouseDetails(setting.storeAndReuseCollectToHubWarehouse?.id)}
                        />
                    ),
                },
            ]}
        />
    );
};

interface WarehouseCellProps {
    warehouse?: HofyWarehouseDetailsDto;
}

const WarehouseCell: FC<WarehouseCellProps> = ({ warehouse }) => {
    const trOwnership = useTrHofyWarehouseType();

    if (!warehouse) {
        return <Paragraph3>--</Paragraph3>;
    }

    return (
        <HofyWarehouseLocationCard
            name={warehouse.name}
            label={`${trOwnership(warehouse.hofyWarehouse.type)} warehouse`}
            country={warehouse.address?.country}
            iconVariant={warehouse.hofyWarehouse?.type === HofyWarehouseType.Main ? 'vivid' : 'normal'}
        />
    );
};
