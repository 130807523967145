import { SessionDto } from '@hofy/api-auth';
import { Role } from '@hofy/api-shared';

export interface SessionState {
    session?: SessionDto;
    roles: Role[];
    isInitialized: boolean;
    provider: string;
}

export const emptySessionState: SessionState = {
    isInitialized: false,
    roles: [],
    provider: 'password',
};
