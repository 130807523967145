import React, { FC } from 'react';

import { Price } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, LabeledPriceInput, MarginBoxProps, Paragraph3, SectionTitle2, TestKeyAware } from '@hofy/ui';

interface PurchaseOrderPriceInputMandatoryProps extends MarginBoxProps, TestKeyAware {
    label: string;
    value: Price;
    onChange(v: Price): void;
    disabled: boolean;
}

interface PurchaseOrderInputOptionalProps {
    subLabel: string;
    width: number | string;
    errorMessage: string;
}

type PurchaseOrderPriceInputProps = PurchaseOrderPriceInputMandatoryProps &
    Partial<PurchaseOrderInputOptionalProps>;

export const PurchaseOrderPriceInput: FC<PurchaseOrderPriceInputProps> = ({
    label,
    subLabel,
    value,
    width = 'auto',
    onChange,
    disabled,
    errorMessage,
    ...margins
}) => {
    return (
        <Box
            paddingVertical={10}
            paddingHorizontal={10}
            width={width}
            flex='auto'
            direction='row'
            bg={Color.BackgroundDefault}
            border
            rounded
            {...margins}
        >
            <Box column paddingHorizontal={15} flex={1}>
                <SectionTitle2 color={Color.Neutral500}>{label}</SectionTitle2>
                {subLabel && <Paragraph3 color={Color.Neutral300}>{subLabel}</Paragraph3>}
            </Box>
            <LabeledPriceInput
                label=''
                value={value}
                onChange={onChange}
                disabled={disabled}
                errorMessage={errorMessage}
            />
        </Box>
    );
};
