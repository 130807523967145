import { useEffect } from 'react';

import { useStringQueryParam } from '@hofy/router';
import { isRequired, isRequiredIf, isValidEmailAddress, UseForm, useForm, validator } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { useAuth } from './useAuth';
import { useAuthBrokering } from './useAuthBrokering';

interface SignInFormData {
    authStage: AuthStage;
    email: string;
    password: string;
    code: string;
}

export type SingInForm = UseForm<SignInFormData>;

export type AuthStage = 'brokering' | 'password' | 'two-fa';

export const useSignIn = () => {
    const { signIn, signInError, signInWithPublicSSO, signInWithConnection, signInIsLoading } = useAuth();
    const { tr } = useAuthI18n();
    const [provider] = useStringQueryParam('provider');

    const { connectionLoaded, connectionName, brokeringIsLoading, getConnection } = useAuthBrokering({
        onConnectionLoad: (connection: string | undefined) => {
            if (connection) {
                signInWithConnection(connection, null);
            } else {
                signInForm.fields.authStage.setValue('password');
            }
        },
    });

    useEffect(() => {
        if (provider) {
            signInWithConnection(provider, null);
        }
    }, [provider]);

    const signInForm: SingInForm = useForm<SignInFormData>({
        initial: {
            authStage: 'brokering',
            email: '',
            password: '',
            code: '',
        },
        validate: validator<SignInFormData>({
            email: [
                isRequired(tr('auth.form.email.errors.not-empty')),
                isValidEmailAddress(tr('auth.form.email.errors.not-valid')),
            ],
            password: isRequiredIf(
                ({ authStage }) => authStage === 'password',
                tr('sign-in-page.form.password.errors.not-empty'),
            ),
            code: isRequiredIf(
                ({ authStage }) => authStage === 'two-fa',
                tr('sign-in-page.form.code.errors.not-empty'),
            ),
        }),
        onSubmit: ({ authStage, email, password, code }) => {
            switch (authStage) {
                case 'brokering':
                    getConnection(email);
                    break;
                case 'password':
                case 'two-fa':
                    signIn(
                        {
                            email,
                            password,
                            code,
                        },
                        () => {
                            signInForm.fields.authStage.setValue('two-fa');
                        },
                    );
                    break;
            }
        },
    });

    return {
        connectionLoaded,
        signInError,
        signIn,
        signInWithPublicSSO,
        signInForm,
        brokeringIsLoading: brokeringIsLoading || !!connectionName,
        signInIsLoading,
        authStage: signInForm.values.authStage,
    };
};
