import React, { FC } from 'react';

import { HofyWarehouseDetailsDto, useAdminsQuery, useHofyWarehousesQuery } from '@hofy/api-admin';
import {
    formatAdminName,
    getRepairStatusColor,
    getRepairStatusLabel,
    Permission,
    Role,
    UserRefDto,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { Page } from '@hofy/common';
import { Button, FilterChip, PageHeader, SearchInput } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { UsersBlockFilter } from '../../components/domain/user/UsersBlockFilter';
import { WarehouseBlockFilter } from '../../components/domain/warehouses/WarehouseBlockFilter';
import { RepairsPageTab } from '../../store/repairs/repairsPageTab';
import { useListRepairsFilters } from '../../store/repairs/useListRepairsFilters';
import { useListRepairsSorting } from '../../store/repairs/useListRepairsSorting';
import { RepairFilterBlockFilter } from './components/RepairFilterBlockFilter';
import { RepairStatusBlockFilter } from './components/RepairStatusBlockFilter';
import { RepairsList } from './RepairsList';
import { RepairsPageTabs } from './RepairsPageTabs';
import { useRepairsLinks } from './useRepairsLinks';

interface RepairsPageProps {
    activeTab: RepairsPageTab;
    onTabChange(tab: RepairsPageTab): void;
}

export const RepairsPage: FC<RepairsPageProps> = ({ activeTab, onTabChange }) => {
    const { data: warehouses } = useHofyWarehousesQuery();
    const { data: adminUsers } = useAdminsQuery(Role.FulfillmentAdmin, true);

    const { showFilters, toggleShowFilters } = useBlockFilters();
    const filters = useListRepairsFilters(activeTab);
    const sorting = useListRepairsSorting(activeTab);

    return (
        <Page>
            <PageHeader
                title='Repairs'
                rightSlot={
                    <RepairsHeaderContent
                        showFilters={showFilters}
                        toggleShowFilters={toggleShowFilters}
                        filters={filters}
                    />
                }
                tabsSlot={<RepairsPageTabs tab={activeTab} onChange={onTabChange} />}
            />
            <RepairsBlockFilters
                showFilters={showFilters}
                filters={filters}
                adminUsers={adminUsers}
                warehouses={warehouses}
            />
            <RepairsFilterChips adminUsers={adminUsers} warehouses={warehouses} filters={filters} />
            <RepairsList filters={filters.values} sorting={sorting.values} />
        </Page>
    );
};

interface RepairsHeaderContentProps {
    showFilters: boolean;
    toggleShowFilters(): void;
    filters: ReturnType<typeof useListRepairsFilters>;
}

const RepairsHeaderContent: FC<RepairsHeaderContentProps> = ({ showFilters, toggleShowFilters, filters }) => {
    const { createNewRepairLink } = useRepairsLinks();

    const { hasPermission } = useSession();
    const canCreateRepairs = hasPermission(Permission.AdminRepairsCreate);

    return (
        <>
            <SearchInput
                value={filters.values.search}
                onChange={filters.controls.setSearch}
                placeholder='Search by repair, item or user…'
                autoFocus
            />
            <BlockFilterButton onClick={toggleShowFilters} isOpened={showFilters} count={filters.count} />
            {canCreateRepairs && <Button label='Create repair' to={createNewRepairLink} />}
        </>
    );
};

interface RepairsBlockFiltersProps {
    showFilters: boolean;
    filters: ReturnType<typeof useListRepairsFilters>;
    adminUsers: UserRefDto[];
    warehouses: HofyWarehouseDetailsDto[];
}

const RepairsBlockFilters: FC<RepairsBlockFiltersProps> = ({
    showFilters,
    filters,
    adminUsers,
    warehouses,
}) => (
    <BlockFilterContainer show={showFilters}>
        <UsersBlockFilter
            users={adminUsers}
            selected={filters.values.assignedUsers}
            onChange={filters.controls.setAssignedUsers}
        />
        <RepairStatusBlockFilter
            selected={filters.values.repairStatuses}
            onChange={filters.controls.setRepairStatuses}
        />
        <WarehouseBlockFilter
            warehouses={warehouses}
            selected={filters.values.warehouses}
            onChange={filters.controls.setWarehouses}
        />
        <RepairFilterBlockFilter
            hasUnassignedUser={!!filters.values.hasUnassignedUser}
            onHasUnassignedUserChange={filters.controls.toggleHasUnassignedUser}
        />
    </BlockFilterContainer>
);

interface RepairsFilterChipsProps {
    adminUsers: UserRefDto[];
    warehouses: HofyWarehouseDetailsDto[];
    filters: ReturnType<typeof useListRepairsFilters>;
}

const RepairsFilterChips: FC<RepairsFilterChipsProps> = ({ adminUsers, warehouses, filters }) => (
    <BlockFilterChipContainer show={filters.count > 0}>
        {adminUsers
            .filter(user => filters.values.assignedUsers.includes(user.id))
            .map(admin => (
                <FilterChip
                    key={admin.id}
                    label={formatAdminName(admin)}
                    onClear={() => filters.controls.toggleAssignedUser(admin.id)}
                    color='blue'
                />
            ))}
        {filters.values.repairStatuses.map(status => (
            <FilterChip
                key={status}
                label={getRepairStatusLabel(status)}
                onClear={() => filters.controls.toggleRepairStatus(status)}
                color={getRepairStatusColor(status)}
            />
        ))}
        {warehouses
            .filter(warehouse => filters.values.warehouses.includes(warehouse.idDeprecated))
            .map(warehouse => (
                <FilterChip
                    key={warehouse.idDeprecated}
                    label={warehouse.name}
                    onClear={() => filters.controls.toggleWarehouse(warehouse.idDeprecated)}
                    color='grape'
                />
            ))}
        {filters.values.hasUnassignedUser && (
            <FilterChip label='Unassigned' onClear={filters.controls.toggleHasUnassignedUser} color='red' />
        )}
    </BlockFilterChipContainer>
);
