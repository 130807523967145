import React, { FC, memo } from 'react';

import { SubscriptionDto, useTrSubscriptionBillingFrequency, useTrSubscriptionTerm } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { InfiniteScrollConfig, InfinityScrollTable, Placeholder, SvgIllustration } from '@hofy/ui';

interface SubscriptionsTableProps {
    subscriptions: SubscriptionDto[];
    infinityScroll: InfiniteScrollConfig;
    onClick(id: number): void;
}

const SubscriptionsTableComponent: FC<SubscriptionsTableProps> = ({
    subscriptions,
    infinityScroll,
    onClick,
}) => {
    const { formatPrice } = usePrice();
    const trSubscriptionBillingFrequency = useTrSubscriptionBillingFrequency();
    const trTerm = useTrSubscriptionTerm();

    return (
        <InfinityScrollTable
            data={subscriptions}
            toKey={subscription => subscription.id}
            infinityScroll={infinityScroll}
            onRowClick={({ id }) => onClick(id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.List}
                    title='No subscriptions'
                    message='No subscriptions for organization'
                />
            }
            flex='auto'
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    flexGrow: 0,
                    width: 100,
                    renderer: subscription => subscription.id,
                },
                {
                    id: 'billingFrequency',
                    header: 'Billing frequency',
                    width: 300,
                    renderer: subscription => trSubscriptionBillingFrequency(subscription.billingFrequency),
                },
                {
                    id: 'term',
                    header: 'Term',
                    width: 200,
                    renderer: subscription => trTerm(subscription.term),
                },
                {
                    id: 'price',
                    header: 'Price',
                    width: 200,
                    renderer: subscription => formatPrice(subscription.price),
                },
                {
                    id: 'startOn',
                    header: 'Start on',
                    width: 200,
                    renderer: subscription => formatDate(subscription.startOn),
                },
                {
                    id: 'endOn',
                    header: 'End on',
                    width: 200,
                    renderer: subscription => formatDate(subscription.endOn),
                },
            ]}
        />
    );
};

export const SubscriptionsTable = memo(SubscriptionsTableComponent);
