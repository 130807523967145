import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { useBaseI18n, useTrCountryShortname } from '@hofy/i18n';
import { Color } from '@hofy/theme';

import { Box, Paragraph3 } from '../../../base/index';
import { CountryFlag } from '../../../flag/index';

interface CountryLabelProps {
    country: Country | null;
}

export const CountryLabel: FC<CountryLabelProps> = ({ country }) => {
    const trCountry = useTrCountryShortname();
    const { tr } = useBaseI18n();
    return (
        <Box row gap={8}>
            <CountryFlag country={country} />
            <Paragraph3 color={Color.ContentPrimary} data-test-key={country}>
                {country ? trCountry(country) : tr('country.none')}
            </Paragraph3>
        </Box>
    );
};
