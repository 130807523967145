import React, { FC } from 'react';

import { useOrganizationSignUp } from '../../../../store/auth/useOrganizationSignUp';
import { AuthPageWrapper } from '../../../components/authPageWrapper/AuthPageWrapper';
import { EmailPasswordStep } from './EmailPasswordStep';
import { OrganizationDetailsStep } from './OrganizationDetailsStep';
import { PersonalDetailsStep } from './PersonalDetailsStep';

export const CompleteOrganizationSignupPage: FC = () => {
    const { form, isLoading, isError } = useOrganizationSignUp();

    const content = () => {
        switch (form.step) {
            case 'emailPassword':
                return (
                    typeof form.forms.emailPassword !== 'boolean' && (
                        <EmailPasswordStep form={form.forms.emailPassword} onSubmit={form.next} />
                    )
                );
            case 'personalDetails':
                return <PersonalDetailsStep form={form.forms.personalDetails} onSubmit={form.next} />;
            case 'organizationDetails':
                return (
                    <OrganizationDetailsStep
                        form={form.forms.organizationDetails}
                        onSubmit={form.next}
                        isLoading={isLoading}
                        isError={isError}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <AuthPageWrapper pageId='self-sign-up' title=''>
            {content()}
        </AuthPageWrapper>
    );
};
