import React, { FC } from 'react';

import { IssueDto } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { BaseTable, BooleanBadge, Placeholder, SvgIllustration } from '@hofy/ui';

import { ZendeskTicketStatusChip } from '../../../components/design/zendesk/ZendeskTicketStatusChip';

interface IssueTableProps {
    isLoading: boolean;
    issues: IssueDto[];
    onOpenIssue(id: UUID): void;
}

export const IssueTable: FC<IssueTableProps> = ({ issues, onOpenIssue, isLoading }) => {
    return (
        <BaseTable
            data={issues}
            toKey={issue => issue.id}
            onRowClick={issue => onOpenIssue(issue.id)}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No issues' />}
            isLoading={isLoading}
            flex='auto'
            columns={[
                {
                    id: 'issue',
                    header: '#Id',
                    width: 150,
                    flexGrow: 0,
                    renderer: issue => issue.publicId,
                },
                {
                    id: 'organisationName',
                    header: 'Organisation name',
                    flexGrow: 1,
                    renderer: issue => issue.organization.name,
                },
                {
                    id: 'user',
                    header: 'User',
                    flexGrow: 1,
                    renderer: issue => formatUserName(issue.user),
                },
                {
                    id: 'createdAt',
                    header: 'Created at',
                    flexGrow: 1,
                    renderer: issue => formatDate(issue.createdAt),
                },
                {
                    id: 'description',
                    header: 'Description',
                    flexGrow: 3,
                    renderer: issue => issue.description,
                },
                {
                    id: 'zendeskTicketStatus',
                    header: 'Zendesk status',
                    width: 150,
                    flexGrow: 0,
                    renderer: issue => <ZendeskTicketStatusChip status={issue.zendesk.ticketStatus} />,
                },
                {
                    id: 'resolved',
                    header: 'Resolved',
                    width: 100,
                    flexGrow: 0,
                    renderer: issue => <BooleanBadge value={!!issue.resolvedOn} />,
                },
            ]}
        />
    );
};
