import React from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';
import { FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { InspectionStatusChip } from '../components/InspectionStatusChip';

interface InspectionDetailsSectionProps {
    inspection: InspectionDetailsUnionDto;
}

export const InspectionDetailsSection: React.FC<InspectionDetailsSectionProps> = ({
    inspection: { idDeprecated, uuid, publicId, status },
}) => {
    return (
        <FormSection label='Inspection details'>
            <FormGridRow columns={4}>
                <Labeled label='Status' content={<InspectionStatusChip status={status} />} />
                <LabeledText label='Id' content={`#${idDeprecated}`} />
                <LabeledText label='UUID' content={`${uuid}`} />
                <LabeledText label='Public ID' content={`${publicId}`} />
            </FormGridRow>
        </FormSection>
    );
};
