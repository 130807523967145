import React, { FC, ReactNode } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import { MoreMenu, MoreMenuItem } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, Button, PageHeader, Paragraph3, Switch, Tab, Tabs } from '@hofy/ui';

import { useAuditLogMenuOption } from '../../../../store/auditLogs/useAuditMenuOption';
import { ContractTab } from '../../../../store/contracts/types/ContractTab';
import { useTrContactTab } from '../../../../store/contracts/useTrContactTab';

interface CommonContractHeaderProps {
    contractTitle: string;
    contractId: number;
    organizationId: UUID;
    tab: ContractTab;
    tabs: ContractTab[];
    onChangeTab(t: ContractTab): void;
    onSetIncludeDeleted?(v: boolean): void;
    onOpenAddInvoiceEntrySlideout(organizationId: UUID): void;
    includeDeleted?: boolean;
    optionButtons?: ReactNode;
    menuOptions?: MoreMenuItem[];
}

export const CommonContractHeader: FC<CommonContractHeaderProps> = ({
    contractId,
    organizationId,
    contractTitle,
    tab,
    tabs,
    onChangeTab,
    onOpenAddInvoiceEntrySlideout,
    menuOptions = [],
    optionButtons,
    includeDeleted,
    onSetIncludeDeleted,
}) => {
    const trTab = useTrContactTab();
    const [auditLink] = useAuditLogMenuOption(AuditableTable.Contracts, contractId);

    return (
        <PageHeader
            title={contractTitle}
            rightSlot={
                <>
                    {tab === ContractTab.InvoiceEntries && (
                        <Box gap={8} row>
                            <Switch checked={includeDeleted === true} onChange={onSetIncludeDeleted} />
                            <Paragraph3>Include deleted</Paragraph3>
                        </Box>
                    )}
                    {tab === ContractTab.InvoiceEntries && (
                        <Button
                            type='secondary'
                            label='Add entry'
                            onClick={() => onOpenAddInvoiceEntrySlideout(organizationId)}
                        />
                    )}
                    {optionButtons !== undefined && optionButtons}
                    <MoreMenu items={[...menuOptions, auditLink]} />
                </>
            }
            tabsSlot={
                <Tabs active={tab} onChange={onChangeTab}>
                    {tabs.map(item => (
                        <Tab key={item} id={item} label={trTab(item)} />
                    ))}
                </Tabs>
            }
        />
    );
};
