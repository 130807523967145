import React, { FC } from 'react';

import { AuthError, PublicSSO } from '@hofy/api-auth';

import { AuthStage, SingInForm } from '../../store/auth/useSignIn';
import { SignInPageForm } from './SignInPageForm';
import { TwoFaForm } from './TwoFaForm';

interface SignInFormProps {
    authStage: AuthStage;
    authError?: AuthError;
    form: SingInForm;
    signInWithPublicSSO(sso: PublicSSO): void;
    isLoading: boolean;
}

export const SignInForm: FC<SignInFormProps> = props => {
    const { form, authError, signInWithPublicSSO, isLoading, authStage } = props;
    switch (authStage) {
        case 'brokering':
            return (
                <SignInPageForm
                    form={form}
                    signInWithPublicSSO={signInWithPublicSSO}
                    isLoading={isLoading}
                    isBrokeringMode
                />
            );
        case 'password':
            return (
                <SignInPageForm
                    form={form}
                    signInWithPublicSSO={signInWithPublicSSO}
                    isLoading={isLoading}
                    authError={authError}
                    isBrokeringMode={false}
                />
            );
        case 'two-fa':
            return <TwoFaForm form={form} isLoading={isLoading} authError={authError} />;
    }
};
