import { Country } from '@hofy/global';

export interface FulfilmentCountrySettingsFilter {
    search: string;
    countries: Country[];
    defaultDeliverFromWarehouseIds: number[];
    defaultCollectToWarehouseIds: number[];
    storeAndReuseCollectToLocalWarehouseIds: number[];
    storeAndReuseCollectToHubWarehouseIds: number[];
}

export const emptyFulfilmentCountrySettingsFilter: FulfilmentCountrySettingsFilter = {
    search: '',
    countries: [],
    defaultDeliverFromWarehouseIds: [],
    defaultCollectToWarehouseIds: [],
    storeAndReuseCollectToLocalWarehouseIds: [],
    storeAndReuseCollectToHubWarehouseIds: [],
};
