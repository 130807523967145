import React, { FC, ReactNode } from 'react';

import { AssignmentProductDetailsDto } from '@hofy/api-admin';
import { useTrOperatingSystem, useTrProductCategory } from '@hofy/api-shared';
import { CopiableText } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, FormGridRow, LabeledText, Paragraph3, SectionTitle2, Span, useToast } from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';

interface ProductDetailsProps {
    product: AssignmentProductDetailsDto;
    children?: ReactNode;
}

export const ProductDetails: FC<ProductDetailsProps> = ({ product, children }) => {
    const trProductCategory = useTrProductCategory();
    const trOperatingSystem = useTrOperatingSystem();

    const { showToast } = useToast();

    const onCopyGUID: () => void = () => {
        showToast({
            type: 'positive',
            message: 'GUID copied successfully',
        });
    };

    return (
        <Box>
            <Box row>
                <ProductItem
                    row
                    image={product.image?.url}
                    imageSize={100}
                    flex={1}
                    label={
                        <SectionTitle2 marginBottom={5} color={Color.Neutral900}>
                            {product.name}
                        </SectionTitle2>
                    }
                    subLabel={
                        <>
                            <Paragraph3 color={Color.Neutral700} marginBottom={5}>
                                <Span color={Color.Neutral300}>By</Span> {product.brand}
                            </Paragraph3>
                            <Paragraph3 color={Color.Neutral300}>
                                {trProductCategory(product.category)}
                            </Paragraph3>
                        </>
                    }
                />
                {children}
            </Box>
            <FormGridRow columns={4} marginTop={20}>
                <LabeledText label='Style' content={product.style} />
                <LabeledText label='Size' content={product.size} />
                {product.os && <LabeledText label='OS' content={trOperatingSystem(product.os)} />}
            </FormGridRow>
            <FormGridRow columns={4} marginTop={20}>
                <LabeledText label='SKU' content={product.sku} />
                <LabeledText label='MPN' content={product.manufacturerPartCode} />
                <LabeledText
                    label='UUID'
                    content={<CopiableText text={product.variantId} onCopy={onCopyGUID} />}
                />
            </FormGridRow>
        </Box>
    );
};
