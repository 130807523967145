import { useMemo } from 'react';

import { useAuth } from '../auth/useAuth';
import { useSessionQuery } from './useSessionQuery';

export const useSessionState = () => {
    const sessionQueryState = useSessionQuery();
    const { storageToken } = useAuth();

    return useMemo(
        () => ({
            ...sessionQueryState,
            roles: storageToken?.roles || [],
            provider: storageToken?.provider || 'password',
        }),
        [storageToken, sessionQueryState],
    );
};
