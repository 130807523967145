import React, { FC, ReactNode, useMemo } from 'react';

import { HofyWarehouseDetailsDto, useHofyWarehousesQuery } from '@hofy/api-admin';
import { FormDropdown } from '@hofy/common';
import { Box, BoxProps, TestKeyAware } from '@hofy/ui';

import { useHofyWarehouse } from '../../../store/warehouses/useHofyWarehouse';

interface WarehouseDropdownProps extends TestKeyAware, BoxProps {
    value: number | null | undefined;
    label?: string;
    onChange(o: number | undefined): void;
    isError?: boolean | string;
    isRequired?: boolean;
    saveLastValue?: boolean;
    disabled?: boolean;
    emptyContent?: ReactNode;
    clearable?: boolean;
}

/** @deprecated use `WarehouseSelect` from `@hofy/ui` instead */
export const WarehouseDropdown: FC<WarehouseDropdownProps> = ({
    value,
    label = '',
    onChange,
    isError,
    isRequired,
    testKey,
    disabled,
    emptyContent,
    clearable = true,
    ...boxProps
}) => {
    const [, saveWarehouse] = useHofyWarehouse();
    const { data: allWarehouses } = useHofyWarehousesQuery();
    const setWarehouse = (warehouse: HofyWarehouseDetailsDto | undefined) => {
        saveWarehouse(warehouse);
        onChange(warehouse ? warehouse.idDeprecated : undefined);
    };
    const itemValue = useMemo(
        () => allWarehouses.find(({ idDeprecated }) => idDeprecated === value),
        [allWarehouses, value],
    );

    return (
        <Box {...boxProps}>
            <FormDropdown
                clearable={clearable}
                items={allWarehouses}
                disabled={disabled}
                onChange={setWarehouse}
                label={label}
                labelFormatter={({ name }) => name}
                value={itemValue}
                isRequired={isRequired}
                isError={isError}
                testKey={testKey}
                emptyContent={emptyContent}
            />
        </Box>
    );
};
