import React, { FC } from 'react';

import { OrderDto } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { RequestDecisionStatusChip } from '@hofy/core';
import { formatDate } from '@hofy/helpers';
import { InfiniteScrollConfig, InfinityScrollTable, Placeholder, SvgIllustration } from '@hofy/ui';

interface RequestsTableProps {
    orders: OrderDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenOrder(id: number): void;
}

export const OrdersTable: FC<RequestsTableProps> = ({ orders, infinityScroll, onOpenOrder }) => {
    return (
        <InfinityScrollTable
            data={orders}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Requests}
                    title='No requests'
                    message='There are no requests'
                />
            }
            onRowClick={({ id }) => onOpenOrder(id)}
            columns={[
                {
                    id: 'publicId',
                    header: 'Public id',
                    width: 180,
                    flexGrow: 0,
                    renderer: ({ publicId }) => publicId,
                },
                {
                    id: 'createdAt',
                    header: 'Request date',
                    flexGrow: 1,
                    renderer: ({ createdAt }) => formatDate(createdAt),
                },
                {
                    id: 'decisionAt',
                    header: 'Decision date',
                    flexShrink: 1,
                    renderer: request => formatDate(request.decisionAt),
                },
                {
                    id: 'organization',
                    header: 'Organization',
                    flexGrow: 1,
                    renderer: ({ organization }) => organization.name,
                },
                {
                    id: 'user',
                    header: 'User',
                    flexGrow: 1,
                    renderer: ({ user }) => formatUserName(user),
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    renderer: ({ status }) => <RequestDecisionStatusChip status={status} />,
                },
            ]}
            toKey={({ id }) => id}
        />
    );
};
