import { useMutation, useQueryClient } from '@tanstack/react-query';

import { itemCacheKey, itemsService, UpdateItemPayload } from '@hofy/api-admin';

export const useItemMutation = (itemId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (v: UpdateItemPayload) => itemsService.updateItem(itemId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            onSuccess?.();
        },
    });
};
