import { omit } from 'lodash';

import { AdminUserFilters } from '@hofy/api-admin';
import { Role } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

export const useAdminUsersFilters = (initialOrganizations?: UUID[]) => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [organizations, setOrganizations] = useArrayQueryParam<UUID>('organizations', initialOrganizations);
    const [roles, setRoles] = useArrayQueryParam<Role>('roles');

    const filters = useStructMemo<AdminUserFilters>({
        search,
        organizations,
        roles,
        redistributableIn: null,
        includeOffboarded: true,
    });
    const filtersToCount = omit(filters, 'search', 'includeOffboarded');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        setSearch,
        setRoles,
        setOrganizations,
        organizations,
        filterCount,
    };
};
