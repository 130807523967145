import React, { FC } from 'react';

import { ShipmentCollectionRequestDto, ShipmentDetailsDto } from '@hofy/api-admin';
import { Alert, FormSection } from '@hofy/ui';

import { MissingItemAction } from './MissingItemAction';
import { PackagingAction } from './PackagingAction';

interface RequiredActionsSectionProps {
    shipment: ShipmentDetailsDto;
    collectionRequest: ShipmentCollectionRequestDto;
    onAssignmentClick(assignmentId: number): void;
}

export const RequiredActionsSection: FC<RequiredActionsSectionProps> = ({
    shipment,
    collectionRequest: {
        id: collectionRequestId,
        actions: { handleMissingItems, resolvePackagingAndLabels, updateTrackingData },
    },
    onAssignmentClick,
}) => {
    const showMissingItem = handleMissingItems.enabled;
    const showPackagingAssistance = updateTrackingData.enabled;

    return (
        <FormSection label='Required actions'>
            {showPackagingAssistance && (
                <PackagingAction
                    key='packaging-action'
                    shipmentId={shipment.idDeprecated}
                    collectionRequestId={collectionRequestId}
                    updateTrackingDataAction={updateTrackingData}
                    resolvePackagingAction={resolvePackagingAndLabels}
                />
            )}
            {showMissingItem && (
                <MissingItemAction
                    key='missing-item-action'
                    shipment={shipment}
                    missingItems={handleMissingItems.missingItems}
                    onAssignmentClick={onAssignmentClick}
                />
            )}
            {!showMissingItem && !showPackagingAssistance && (
                <Alert type='positive' description='No required actions' />
            )}
        </FormSection>
    );
};
