import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    AuditableTable,
    emptySupplierContactPayload,
    SupplierContactPayload,
    useSupplierDetailsQuery,
} from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { ComponentLoader, MoreMenu, Overlay, Page } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    Box,
    Button,
    LabeledSwitch,
    PageHeader,
    Paragraph3,
    SearchInput,
    SvgIcon,
    Tab,
    Tabs,
} from '@hofy/ui';

import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { useAuditLogMenuOption } from '../../../../store/auditLogs/useAuditMenuOption';
import { InventoryTab } from '../../../../store/inventory/types/InventoryTab';
import { useAdminPurchaseOrderFilters } from '../../../../store/purchaseOrders/useAdminPurchaseOrderFilters';
import { allSupplierTabs, SupplierTab } from '../../../../store/suppliers/types/SupplierTab';
import { useAddSupplierContact } from '../../../../store/suppliers/useAddSupplierContact';
import { useTrSupplierTab } from '../../../../store/suppliers/useTrSupplierTab';
import { SupplierContactFormSlideout } from '../components/SupplierContactFormSlideout';
import { SupplierContactsTab } from './components/SupplierContactsTab';
import { SupplierDetailsTab } from './components/SupplierDetailsTab';
import { SupplierOrdersTab } from './components/SupplierOrdersTab';

interface SupplierDetailsOverlayProps {
    supplierId: number;
    supplierTab: SupplierTab;
    onChangeTab(supplierTab: SupplierTab): void;
    onOpenEditSupplier(supplierId: number): void;
}

export const SupplierDetailsOverlay: FC<SupplierDetailsOverlayProps> = ({
    supplierId,
    supplierTab,
    onChangeTab,
    onOpenEditSupplier,
}) => {
    const [showContactFormOverlay, setShowContactFormOverlay] = useState(false);
    const { addContact } = useAddSupplierContact(supplierId);

    const { filters, setSearch, toggleIsDropship } = useAdminPurchaseOrderFilters([supplierId]);

    const [auditNavLink] = useAuditLogMenuOption(AuditableTable.Supplier, supplierId);

    const { hasPermission } = useSession();
    const trTab = useTrSupplierTab();
    const history = useHistory();

    const { data: supplier, isLoading } = useSupplierDetailsQuery(supplierId);

    if (isLoading || !supplier) {
        return (
            <Box relative flex={1}>
                <ComponentLoader />
            </Box>
        );
    }

    const onAddContact = () => {
        setShowContactFormOverlay(true);
    };

    const onSaveContact = (p: SupplierContactPayload) => {
        addContact(p);
        setShowContactFormOverlay(false);
    };

    const onNewSupplierPO = (supplierId: number) => {
        history.push(
            `${AdminNavLink.Inventory}/${InventoryTab.Suppliers}/${supplierId}/${SupplierTab.Orders}/new?supplierId=${supplierId}`,
        );
    };

    const renderContent = () => {
        switch (supplierTab) {
            case SupplierTab.Details:
                return <SupplierDetailsTab supplier={supplier} />;
            case SupplierTab.Contacts:
                return <SupplierContactsTab supplierId={supplierId} search={filters.search} />;
            case SupplierTab.Orders:
                return <SupplierOrdersTab filters={filters} />;
        }
    };

    return (
        <>
            <Overlay column flex='auto' bg={Color.BackgroundDefault}>
                <Page pageId='supplier-details'>
                    <PageHeader
                        title={supplier.name}
                        rightSlot={
                            <>
                                {supplierTab === SupplierTab.Details && (
                                    <Button
                                        type='secondary'
                                        label='Supplier settings'
                                        leftIcon={SvgIcon.Settings}
                                        onClick={() => onOpenEditSupplier(supplierId)}
                                        disabled={!hasPermission(Permission.AdminSuppliersUpdate)}
                                    />
                                )}
                                {supplierTab === SupplierTab.Contacts && (
                                    <Button
                                        type='secondary'
                                        label='Add contact'
                                        leftIcon={SvgIcon.Add}
                                        onClick={onAddContact}
                                        disabled={!hasPermission(Permission.AdminSuppliersUpdate)}
                                    />
                                )}
                                {supplierTab === SupplierTab.Orders && (
                                    <>
                                        <LabeledSwitch
                                            label={
                                                <Paragraph3 color={Color.ContentPrimary}>
                                                    Is dropship
                                                </Paragraph3>
                                            }
                                            size='small'
                                            checked={filters.isDropship}
                                            onChange={value => toggleIsDropship(value)}
                                        />
                                        <Button
                                            type='secondary'
                                            label='New purchase order'
                                            leftIcon={SvgIcon.Add}
                                            onClick={() => onNewSupplierPO(supplierId)}
                                            disabled={!hasPermission(Permission.AdminPurchaseOrdersCreate)}
                                        />
                                    </>
                                )}
                                {supplierTab !== SupplierTab.Details && (
                                    <SearchInput value={filters.search} onChange={setSearch} />
                                )}
                                <MoreMenu items={[auditNavLink]} />
                            </>
                        }
                        tabsSlot={
                            <Tabs active={supplierTab} onChange={onChangeTab}>
                                {allSupplierTabs.map(tab => (
                                    <Tab key={tab} id={tab} label={trTab(tab)} />
                                ))}
                            </Tabs>
                        }
                    />
                    <Box flex={1} overflow='auto'>
                        {renderContent()}
                    </Box>
                </Page>
            </Overlay>
            {showContactFormOverlay && (
                <SupplierContactFormSlideout
                    payload={emptySupplierContactPayload}
                    onSave={onSaveContact}
                    onClose={() => setShowContactFormOverlay(false)}
                />
            )}
        </>
    );
};
