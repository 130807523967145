import React, { FC } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { ItemSlideoutRouter } from '../../itemsPage/ItemSlideoutRouter';
import { AddItemSlideout } from './addItem/AddItemSlideout';
import { ReceiveItemSlideout } from './receiveItem/ReceiveItemSlideout';
import { StockLevelsPage } from './StockLevelsPage';

export const StockLevelsRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const handleOpenItemDetails = (id: number) => {
        history.push(`${path}/item/${id}`);
    };
    const handleOpenItemNewSlideout = () => {
        history.push(`${path}/item/new`);
    };
    const handleOpenItemReceive = () => {
        history.push(`${path}/item/receive`);
    };
    const handleSlideoutClose = () => {
        history.push(path);
    };

    return (
        <>
            <StockLevelsPage
                onOpenItemDetails={handleOpenItemDetails}
                onOpenNewItem={handleOpenItemNewSlideout}
                onReceiveItem={handleOpenItemReceive}
            />

            <Switch>
                <Route path={`${path}/item/new`} exact>
                    <AddItemSlideout onClose={handleSlideoutClose} />
                </Route>
                <Route path={`${path}/item/receive`} exact>
                    <ReceiveItemSlideout onClose={handleSlideoutClose} />
                </Route>
                <ItemSlideoutRouter base={`${path}/item`} />
            </Switch>
        </>
    );
};
