import React, { FC } from 'react';

import { AuthError } from '@hofy/api-auth';
import { Color } from '@hofy/theme';
import { Font, Form, SubmitButton, UseForm } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { PasswordPayload, PasswordPayloadValidation } from '../../store/auth/types/PasswordPayload';
import { useTrAuthError } from '../../store/auth/useTrAuthError';
import { PasswordForm } from '../components/PasswordForm';

interface SetPasswordFormProps {
    form: UseForm<PasswordPayload, PasswordPayloadValidation>;
    authError?: AuthError;
}

export const SetPasswordForm: FC<SetPasswordFormProps> = ({ form, authError }) => {
    const { tr } = useAuthI18n();
    const trAuthError = useTrAuthError();

    return (
        <Form onSubmit={form.submit} submitOnEnter marginTop={30}>
            <PasswordForm form={form} keyPrefix='password-form' />
            {!!authError && (
                <Font color={Color.FoundationNegative} size={12} marginTop={12} weight='normal'>
                    {trAuthError(authError)}
                </Font>
            )}
            <SubmitButton
                label={tr('set-password-page.set-password')}
                testKey='submit-button'
                fullWidth
                marginTop={24}
            />
        </Form>
    );
};
