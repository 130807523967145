import { getEnumValues } from '@hofy/global';

export enum SubscriptionBillingFrequency {
    Monthly = 'monthly',
    Annual = 'annual',
    Upfront = 'upfront',
}

export const allSubscriptionBillingFrequencies = getEnumValues<SubscriptionBillingFrequency>(
    SubscriptionBillingFrequency,
);
