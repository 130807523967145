import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { ComponentLoader } from '@hofy/common';
import { pathUuid, UUID } from '@hofy/global';
import { IntRoute, UUIDRoute } from '@hofy/router';
import { Box, Placeholder, SvgIllustration } from '@hofy/ui';

import { EmailPreviewSlideout } from '../../components/domain/emailLogs/EmailPreviewSlideout';
import { UserTab } from '../../store/users/types/UserTab';
import { useUser } from '../../store/users/useUser';
import { UpdateUserSlideout } from './updateUserSlideout/UpdateUserSlideout';
import { UserDetailsOverlay } from './UserDetailsOverlay';
import { UserMessagePreviewSlideout } from './userMessagePreviewSlideout/UserMessagePreviewSlideout';
import { UserSlideoutRouter } from './UserSlideoutRouter';

interface UserTabsRouterProps {
    detailsBasePath: string;
    ordersBasePath: string;
    emailsBasePath: string;
    messageBasePath: string;
    userId: UUID;
    userTab: UserTab;
    onChangeTab(tab: UserTab): void;
    onOpenOrder(id: number): void;
    onOpenOrganization(id: UUID): void;
    onOpenEmail(id: UUID): void;
    onOpenMessage(id: number): void;
    onOpenUpdateUser(id: UUID): void;
    onCloseEmail(): void;
    onCloseMessage(): void;
    onCloseUpdateUser(): void;
    onOpenRequest(id: number): void;
}

const DetailsSlideoutRoute = UUIDRoute('id', Route);
const MessageDetailsSlideoutRoute = IntRoute('id', Route);

export const UserTabsRouter: FC<UserTabsRouterProps> = ({
    detailsBasePath,
    ordersBasePath,
    emailsBasePath,
    messageBasePath,
    userId,
    userTab,
    onChangeTab,
    onOpenOrder,
    onOpenEmail,
    onOpenMessage,
    onOpenOrganization,
    onOpenUpdateUser,
    onCloseEmail,
    onCloseMessage,
    onCloseUpdateUser,
    onOpenRequest,
}) => {
    const { user, isLoading } = useUser(userId);

    if (isLoading) {
        return <ComponentLoader />;
    }

    if (user === undefined) {
        return (
            <Placeholder
                illustration={SvgIllustration.UserSearch}
                title='No user'
                message='No user with selected identifier'
            />
        );
    }

    const renderContent = () => {
        switch (userTab) {
            case UserTab.Details:
                return (
                    <Route path={`${detailsBasePath}/edit`}>
                        <UpdateUserSlideout user={user} onClose={onCloseUpdateUser} />
                    </Route>
                );
            case UserTab.Emails:
                return (
                    <DetailsSlideoutRoute path={`${emailsBasePath}/:id(${pathUuid})`} exact>
                        {({ id }) => <EmailPreviewSlideout emailId={id} onClose={onCloseEmail} />}
                    </DetailsSlideoutRoute>
                );
            case UserTab.Messages:
                return (
                    <MessageDetailsSlideoutRoute path={`${messageBasePath}/:id(\\d+)`} exact>
                        {({ id }) => <UserMessagePreviewSlideout messageId={id} onClose={onCloseMessage} />}
                    </MessageDetailsSlideoutRoute>
                );
            case UserTab.Assignments:
                return <UserSlideoutRouter ordersBasePath={ordersBasePath} />;
        }
    };

    return (
        <Box flex='auto' relative>
            <UserDetailsOverlay
                userId={user.id}
                userTab={userTab}
                onChangeTab={onChangeTab}
                onOpenEmail={onOpenEmail}
                onOpenMessage={onOpenMessage}
                onOpenAssignment={onOpenOrder}
                onOpenOrganization={onOpenOrganization}
                onOpenUpdateUser={onOpenUpdateUser}
                onOpenOrder={onOpenRequest}
            />
            {renderContent()}
        </Box>
    );
};
