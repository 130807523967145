import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { suppliersService } from '../service/suppliersService';
import { AdminSuppliersFilters } from '../service/types/AdminSuppliersFilters';
import { SupplierListItemDto } from '../service/types/SupplierListItemDto';
import { suppliersCacheKey } from './cacheKey';

const emptySuppliers: SupplierListItemDto[] = [];

export const useSuppliersQuery = (filters?: AdminSuppliersFilters) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [suppliersCacheKey, { filters }],
        queryFn: param =>
            suppliersService.listSuppliers(
                {
                    page: param.pageParam,
                    pageSize: 50,
                },
                filters,
            ),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const suppliers = useMemo<SupplierListItemDto[]>(
        () => (data ? data.pages.flatMap(v => v.content) : emptySuppliers),
        [data],
    );

    return {
        data: suppliers,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
