import { identity, isString, omit, values } from 'lodash';
import React, { FC, useState } from 'react';

import { emptyVariantPayload, ProductDetailsDto, ProductPayload } from '@hofy/api-admin';
import { getProductDefaultOperatingSystem, Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { BackButton, Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    Button,
    FormSwitch,
    LabeledSwitch,
    LabelError,
    PageHeader,
    SvgIcon,
    UseForm,
    useFormArrayField,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { Footer } from '../../../components/design/layout/Footer';
import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { ProductDetailsTab } from '../../../store/products/types/ProductDetailsTab';
import { ProductPreviewSlideout } from '../productPreviewSlideout/ProductPreviewSlideout';
import { ProductDetailsMenu } from './ProductDetailsMenu';
import { ProductDetailsTabs } from './ProductDetailsTabs';
import { ProductForm } from './ProductForm';
import { VariantsForm } from './VariantsForm';

interface ProductDetailsProps {
    title: string;
    id?: UUID;
    tab: ProductDetailsTab;
    product: ProductDetailsDto | null;
    isLoading: boolean;
    lockVariantPrices: boolean;
    form: UseForm<ProductPayload>;
    onTabChange(v: ProductDetailsTab): void;
    onLockVariantPricesChange(v: boolean): void;
}

const permission = Permission.AdminProductsUpdate;

export const ProductDetails: FC<ProductDetailsProps> = ({
    id,
    tab,
    onTabChange,
    title,
    form,
    isLoading,
    lockVariantPrices,
    onLockVariantPricesChange,
    product,
}) => {
    const [preview, setPreview] = useState(false);
    const { hasPermission } = useSession();
    const variants = useFormArrayField(form.fields.variants, emptyVariantPayload);
    const addVariant = () =>
        variants.add({
            ...emptyVariantPayload,
            os: getProductDefaultOperatingSystem(form.values) ?? null,
        });
    const getHeaderContent = () => {
        switch (tab) {
            case ProductDetailsTab.Details:
                return null;
            case ProductDetailsTab.Variants:
                return (
                    <>
                        <LabeledSwitch
                            label='Lock prices'
                            checked={lockVariantPrices}
                            onChange={onLockVariantPricesChange}
                        />
                        <Button leftIcon={SvgIcon.Add} label='Add variant' onClick={addVariant} />
                    </>
                );
        }
    };

    const getContent = () => {
        switch (tab) {
            case ProductDetailsTab.Details:
                return <ProductForm form={form} />;
            case ProductDetailsTab.Variants:
                return (
                    <VariantsForm
                        form={form}
                        activeImages={form.fields.images.value.filter(image => image.isActive)}
                        product={product}
                        variants={variants}
                        lockVariantPrices={lockVariantPrices}
                    />
                );
        }
    };
    // this is not super elegant, but in order to do it correct we would have to migrate to multipart
    const detailsErrorCount = values(omit(form.errors, 'variants', 'specifications')).filter(identity).length;
    return (
        <>
            <Overlay column flex='auto' bg={Color.BackgroundDefault}>
                <PageHeader
                    title={title}
                    rightSlot={
                        <>
                            <PermissionWrapper permission={permission}>
                                <FormSwitch
                                    label='Internal'
                                    api={form.fields.isInternal}
                                    disabled={form.values.variants.every(v => v.isInternal)}
                                />
                                <FormSwitch label='Active' api={form.fields.isActive} />
                                {getHeaderContent()}
                            </PermissionWrapper>
                            <Button leftIcon={SvgIcon.Eye} label='Preview' onClick={() => setPreview(true)} />
                            <ProductDetailsMenu productId={id} />
                        </>
                    }
                    tabsSlot={
                        <ProductDetailsTabs
                            detailsErrorCount={detailsErrorCount}
                            tab={tab}
                            onChange={onTabChange}
                        />
                    }
                />
                <Box flex='auto' overflow='auto'>
                    <PermissionWrapper permission={permission}>{getContent()}</PermissionWrapper>
                </Box>
                <Footer gap={20}>
                    <BackButton
                        defaultNavigation={AdminNavLink.Products}
                        label='Back'
                        leftIcon={SvgIcon.ChevronLeft}
                    />
                    <Box flex={1} />
                    {form.fields.variants.shouldShowError() && isString(form.errors.variants) && (
                        <LabelError message={form.errors.variants} />
                    )}
                    <PermissionWrapper permission={permission}>
                        <FormSwitch label='Send update notification(s)' api={form.fields.sendNotification} />
                        <AsyncButton
                            isLoading={isLoading}
                            label='Save'
                            onClick={form.submit}
                            disableCheck
                            disabled={!hasPermission(permission)}
                        />
                    </PermissionWrapper>
                </Footer>
            </Overlay>
            {preview && <ProductPreviewSlideout form={form} onClose={() => setPreview(false)} />}
        </>
    );
};
