import React, { FC, ReactNode, useMemo } from 'react';

import { useHofySubsidiaryRefs } from '@hofy/api-admin';
import { HofySubsidiaryRef } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { TestKeyAware } from '@hofy/ui';

export interface HofySubsidiaryDropdownProps extends TestKeyAware {
    value: number | null;
    label: string;
    onChange(o: number | null): void;
    emptyContent?: ReactNode;

    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
}

/** @deprecated use `FormHofySubsidiarySelect` and apply filters */

export const HofySubsidiaryDropdown: FC<HofySubsidiaryDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired,
    testKey,
    disabled = false,
}) => {
    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const toLabel = (v: HofySubsidiaryRef) => v.name;
    const itemValue = useMemo(() => hofySubsidiaries.find(u => u.id === value), [hofySubsidiaries, value]);
    return (
        <FormDropdown
            items={hofySubsidiaries}
            labelFormatter={toLabel}
            onChange={i => onChange(i?.id || null)}
            label={label}
            value={itemValue}
            toSearchLabel={toLabel}
            searchable
            disabled={disabled}
            emptyContent={emptyContent}
            isRequired={isRequired}
            isError={isError}
            testKey={testKey}
        />
    );
};
