import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceEntriesQueryKey, invoiceEntryService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useRecreateInvoiceEntry = (invoiceEntryId: number, onSuccess: (id: number) => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: invoiceEntryService.recreateInvoiceEntry,
        onSuccess: (p: { id: number }) => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry credited and re-created',
            });
            onSuccess(p.id);
        },
    });

    const recreateEntry = () => {
        mutation.mutate(invoiceEntryId);
    };

    return {
        recreateEntry,
    };
};
