import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { sessionService } from '@hofy/api-auth';

interface UseAuthBrokeringOptions {
    onConnectionLoad?(connectionName?: string): void;
    initial?: { email: string };
}

export const useAuthBrokering = ({ onConnectionLoad }: UseAuthBrokeringOptions) => {
    const [connectionName, setConnectionName] = useState<string>();
    const [connectionLoaded, setConnectionLoaded] = useState<boolean>(false);

    const mutation = useMutation({
        mutationFn: sessionService.getSignInProvider,
        onError: () => {
            setConnectionName(undefined);
            setConnectionLoaded(true);
        },
        onSuccess: c => {
            setConnectionName(c);
            setConnectionLoaded(true);
            if (onConnectionLoad) {
                onConnectionLoad(c);
            }
        },
    });

    const getConnection = (email: string) => {
        mutation.mutate(email);
    };

    const resetConnectionName = () => {
        mutation.reset();
    };

    return {
        isPasswordAuth: connectionLoaded && !connectionName,
        brokeringIsLoading: mutation.isPending,
        connectionName,
        connectionLoaded,
        getConnection,
        resetConnectionName,
    };
};
