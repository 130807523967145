import React from 'react';
import { useBoolean } from 'react-use';

import { formatUserName } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { isDevOrLocal } from '@hofy/helpers';
import { Modals, NavigationProfile, NavigationProfileItem, SvgIcon } from '@hofy/ui';

import { useAdminI18n } from '../../i18n/useAdminI18n';
import { DevFlagsModal } from './DevFlagsModal';

interface AdminNavigationProfileProps {
    onSignOut(): void;
    onBackToHofy(): void;
}

export const AdminNavigationProfile = ({ onSignOut, onBackToHofy }: AdminNavigationProfileProps) => {
    const { session } = useSession();
    const { tr } = useAdminI18n();

    const [isModalOpen, toggleModal] = useBoolean(false);

    return (
        <>
            <NavigationProfile
                name={formatUserName(session)}
                subtitle={tr('app.header.mode.admin')}
                pictureUrl={session.pictureUrl}
                userId={session.uuid}
            >
                <NavigationProfileItem
                    onClick={toggleModal}
                    icon={SvgIcon.Lightbulb}
                    label='Feature flags'
                    isVisible={isDevOrLocal()}
                />
                <NavigationProfileItem
                    onClick={onBackToHofy}
                    icon={SvgIcon.BookClosed}
                    label='Back to Hofy'
                />
                <NavigationProfileItem onClick={onSignOut} icon={SvgIcon.LogOut} label='Logout' />
            </NavigationProfile>

            {isDevOrLocal() && <Modals>{isModalOpen && <DevFlagsModal onClose={toggleModal} />}</Modals>}
        </>
    );
};
