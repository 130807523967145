import React, { FC, ReactNode } from 'react';
import { RouteProps } from 'react-router';
import { Route, RouteChildrenProps } from 'react-router-dom';

import { AnyEnum, getEnumValues, UUID } from '@hofy/global';

export const getIntParam = (params: Record<string, string>, id: string) => {
    if (params[id]) {
        return parseInt(params[id], 10);
    }

    return null;
};

export const getStringParam = (params: Record<string, string>, id: string) => {
    return params[id] || null;
};

export const getEnumParam = <T extends AnyEnum>(
    params: Record<string, string>,
    id: string,
    enumEntry: any,
): T | null => {
    const allValues = getEnumValues<any>(enumEntry);

    if (params[id] && allValues.includes(params[id])) {
        return params[id] as unknown as T;
    }

    return null;
};

interface IntRouteProps<T extends string> {
    path: string;
    exact?: boolean;
    children(v: { [key in T]: number }): ReactNode;
}

export const IntRoute =
    <T extends string>(key: T, Component: React.ComponentType<RouteProps> = Route): FC<IntRouteProps<T>> =>
    ({ children, ...props }: IntRouteProps<T>) => {
        const renderChildren = (p: RouteChildrenProps) => {
            const v = getIntParam(p.match?.params || {}, key);
            if (v) {
                return children({
                    [key]: v,
                } as { [key in T]: number });
            }
            return null;
        };
        return <Component {...props}>{renderChildren}</Component>;
    };

interface UUIDRouteProps<T extends string> {
    path: string;
    exact?: boolean;
    children(v: { [key in T]: UUID }): ReactNode;
}

export const UUIDRoute =
    <T extends string>(key: T, Component: React.ComponentType<RouteProps> = Route): FC<UUIDRouteProps<T>> =>
    ({ children, ...props }: UUIDRouteProps<T>) => {
        const renderChildren = (p: RouteChildrenProps) => {
            const v = getStringParam(p.match?.params || {}, key);
            if (v) {
                return children({
                    [key]: v,
                } as { [key in T]: UUID });
            }
            return null;
        };
        return <Component {...props}>{renderChildren}</Component>;
    };
interface StringRouteProps<T extends string> {
    path: string;
    exact?: boolean;
    children(v: { [key in T]: string }): ReactNode;
}

export const StringRoute =
    <T extends string>(key: T, Component: React.ComponentType<RouteProps> = Route): FC<StringRouteProps<T>> =>
    ({ children, ...props }: StringRouteProps<T>) => {
        const renderChildren = (p: RouteChildrenProps) => {
            const v = getStringParam(p.match?.params || {}, key);
            if (v) {
                return children({
                    [key]: v,
                } as { [key in T]: string });
            }
            return null;
        };
        return <Component {...props}>{renderChildren}</Component>;
    };

interface EnumRouteProps<T extends string, U extends string> {
    path: string;
    exact?: boolean;
    children(v: { [key in T]: U }): ReactNode;
}

export const EnumRoute =
    <U extends string, T extends string = string>(
        key: T,
        enumEntry: any,
        Component: React.ComponentType<RouteProps> = Route,
    ): FC<EnumRouteProps<T, U>> =>
    ({ children, ...props }: EnumRouteProps<T, U>) => {
        const renderChildren = (p: RouteChildrenProps) => {
            const v = getEnumParam(p.match?.params || {}, key, enumEntry);
            if (v) {
                return children({
                    [key]: v,
                } as { [key in T]: U });
            }
            return null;
        };
        return <Component {...props}>{renderChildren}</Component>;
    };
