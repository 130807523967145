import React, { FC } from 'react';

import { ContractUnionDto } from '@hofy/api-shared';
import { usePrice } from '@hofy/hooks';
import { FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { CustomerAssetStorageLocationChip } from '../../../components/domain/contracts/CustomerStorageLocationChip';

export const ManagementContractSection: FC<{
    contract: ContractUnionDto;
}> = ({ contract }) => {
    const { formatPrice, formatMonthlyPrice } = usePrice();
    if (contract.managementContract === null) {
        return null;
    }
    const { activationFee, storageMonthlyFee, collectionLocation } = contract.managementContract;

    return (
        <FormGridRow columns={4}>
            <Labeled
                label='Storage location'
                content={<CustomerAssetStorageLocationChip storageLocation={collectionLocation} /> ?? '--'}
            />
            <LabeledText flex={1} label='Activation fee' content={formatPrice(activationFee)} />
            <LabeledText flex={1} label='Storage fee' content={formatMonthlyPrice(storageMonthlyFee)} />
        </FormGridRow>
    );
};
