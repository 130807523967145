import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { AuthError, authService, PublicSSO } from '@hofy/api-auth';
import { useI18nGeneric } from '@hofy/i18n';
import { isResponseError } from '@hofy/rest';
import { isRequired, isValidEmailAddress, useForm, validator } from '@hofy/ui';

import { useAuthAnalytics } from '../../hooks/useAnalytics';
import { useAuth } from './useAuth';

interface EmailVerificationFormPayload {
    email: string;
}

export const useOrganizationSignupEmailVerification = () => {
    const { tr } = useI18nGeneric();
    const { signUpWithPublicSSO } = useAuth();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { trackIncorrectEmailEntered, trackSignUpAttepted } = useAuthAnalytics();

    const mutation = useMutation({
        mutationFn: authService.sendEmailVerification,
        onSuccess: () => {
            trackSignUpAttepted();
            setIsEmailSent(true);
        },
        onError: error => {
            if (isResponseError(error) && error.response?.code === AuthError.NonSupportedEmail) {
                trackIncorrectEmailEntered();
            }
        },
    });

    const signUpWithSSO = (sso: PublicSSO) => {
        trackSignUpAttepted();
        signUpWithPublicSSO(sso);
    };

    const form = useForm<EmailVerificationFormPayload>({
        initial: {
            email: '',
        },
        validate: validator<EmailVerificationFormPayload>({
            email: [
                isRequired(tr('auth.form.email.errors.not-empty')),
                isValidEmailAddress(tr('auth.form.email.errors.not-valid')),
            ],
        }),
        onSubmit: async ({ email }) => {
            const token = executeRecaptcha ? await executeRecaptcha() : '';
            mutation.mutate({ email, recaptchaToken: token });
        },
    });

    return {
        form,
        signUpWithPublicSSO: signUpWithSSO,
        isEmailSent,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        goFirstStep: () => setIsEmailSent(false),
    };
};
