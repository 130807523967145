import React, { FC } from 'react';

import { UserAssignmentDto, UserShipmentDto } from '@hofy/api-admin';
import {
    isShipmentFromSupplierToUser,
    Permission,
    ShipmentStatus,
    ShipmentType,
    useTrShipmentType,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { BaseMoreMenu } from '@hofy/common';
import { Box, Button } from '@hofy/ui';
import { DisabledWithTooltipWrapper } from '@hofy/ui-domain';

import {
    useAvailableShipmentTypes,
    useInPersonTransferAvailability,
} from '../../../../../store/assignments/useAssignmentsSelection';
import { useValidateShipmentPurchaseOrder } from '../../../../../store/shipments/useValidateShipmentPurchaseOrder';

interface ShipmentInfo {
    shipment: UserShipmentDto;
    linkToProcessShipment: string;
    linkToBackorderShipment: string;
    linkToShipShipment: string;
    linkToCompleteShipment: string;
    linkToCreatePurchaseOrder: string;
}

interface ShipmentInfoProps {
    shipment: UserShipmentDto;
    linkToProcessShipment: string;
    linkToBackorderShipment: string;
    linkToShipShipment: string;
    linkToCompleteShipment: string;
}

interface ShipmentInfoPurchaseOrderProps {
    shipment: UserShipmentDto;
    linkToCreatePurchaseOrder: string;
}

interface ShipmentActionButtonProps {
    shipmentInfo?: ShipmentInfo;
    assignments: UserAssignmentDto[];
    selectedAssignments: UserAssignmentDto[];
    onCreateShipment(t: ShipmentType): void;
    onInPersonTransfer(): void;
}

export const ShipmentActionButton: FC<ShipmentActionButtonProps> = ({
    shipmentInfo,
    assignments,
    selectedAssignments,
    onCreateShipment,
    onInPersonTransfer,
}) => {
    const trShipmentType = useTrShipmentType();
    const { hasPermission } = useSession();
    const canFulfill = hasPermission(Permission.AdminAssignmentsFulfill);
    const canCreatePurchaseOrder = hasPermission(Permission.AdminPurchaseOrdersCreate);

    const availableShipmentTypes = useAvailableShipmentTypes(
        assignments,
        selectedAssignments,
        shipmentInfo?.shipment,
    );

    const { isPossible: isInPersonTransferPossible, isDisabled: isInPersonTransferDisabled } =
        useInPersonTransferAvailability(assignments, selectedAssignments, !!shipmentInfo);

    if (!shipmentInfo || shipmentInfo.shipment.status === ShipmentStatus.Completed) {
        if (!canFulfill) {
            return null;
        }

        const items = availableShipmentTypes.map(t => ({
            action: () => onCreateShipment(t),
            label: trShipmentType(t),
        }));

        return (
            <Box row gap={8}>
                {isInPersonTransferPossible && (
                    <Button
                        label='In-person transfer'
                        disabled={isInPersonTransferDisabled}
                        width={200}
                        onClick={onInPersonTransfer}
                    />
                )}
                <BaseMoreMenu
                    items={items}
                    placement='bottom'
                    trigger={() => (
                        <Button
                            label='Create shipment'
                            disabled={!availableShipmentTypes.length}
                            width={200}
                        />
                    )}
                    asChild
                />
            </Box>
        );
    }

    return (
        <Box row gap={8}>
            {canCreatePurchaseOrder && <ShipmentPurchaseOrderButton {...shipmentInfo} />}
            {canFulfill && <ShipmentNextStepButton {...shipmentInfo} />}
        </Box>
    );
};

const ShipmentPurchaseOrderButton: FC<ShipmentInfoPurchaseOrderProps> = ({
    shipment,
    linkToCreatePurchaseOrder,
}) => {
    const canCreatePurchaseOrder =
        isShipmentFromSupplierToUser(shipment) &&
        !shipment.purchaseOrder &&
        (shipment.status === ShipmentStatus.Created ||
            shipment.status === ShipmentStatus.Booked ||
            shipment.status === ShipmentStatus.Backorder);

    if (canCreatePurchaseOrder) {
        return <Button to={linkToCreatePurchaseOrder} label='Create PO' width={200} />;
    }

    return null;
};

const ShipmentNextStepButton: FC<ShipmentInfoProps> = ({
    shipment,
    linkToProcessShipment,
    linkToBackorderShipment,
    linkToShipShipment,
    linkToCompleteShipment,
}) => {
    const { isValid: isPurchaseOrderValid, invalidReason } = useValidateShipmentPurchaseOrder(shipment);

    if (shipment.status === ShipmentStatus.Created) {
        return (
            <Box row gap={8}>
                <Button to={linkToBackorderShipment} label='Mark as backorder' width={200} />
                <DisabledWithTooltipWrapper disabled={!isPurchaseOrderValid} tooltip={invalidReason}>
                    <Button to={linkToProcessShipment} label='Mark as processed' width={200} />
                </DisabledWithTooltipWrapper>
            </Box>
        );
    }

    if (shipment.status === ShipmentStatus.Backorder) {
        return (
            <DisabledWithTooltipWrapper disabled={!isPurchaseOrderValid} tooltip={invalidReason}>
                <Button to={linkToProcessShipment} label='Mark as processed' width={200} />
            </DisabledWithTooltipWrapper>
        );
    }

    if (shipment.status === ShipmentStatus.Booked) {
        return (
            <DisabledWithTooltipWrapper disabled={!isPurchaseOrderValid} tooltip={invalidReason}>
                <Button to={linkToShipShipment} label='Mark as shipped' width={200} />
            </DisabledWithTooltipWrapper>
        );
    }

    if (shipment.status === ShipmentStatus.WithCourier) {
        return (
            <DisabledWithTooltipWrapper disabled={!isPurchaseOrderValid} tooltip={invalidReason}>
                <Button to={linkToCompleteShipment} label='Mark as completed' width={200} />
            </DisabledWithTooltipWrapper>
        );
    }

    return null;
};
