import { useState } from 'react';

export const useAssignmentSelection = <T extends { id: number }>(
    selectedAssignments: T[],
): UseAssignmentSelectionState<T> => {
    const [assignments, setAssignments] = useState<T[]>(selectedAssignments);

    const onRemoveAssignment = ({ id }: T) => {
        setAssignments(assignments.filter(o => o.id !== id));
    };

    const onAddAssignment = (a: T[]) => {
        setAssignments([...assignments, ...a]);
    };

    return {
        assignments,
        onRemoveAssignment,
        onAdd: onAddAssignment,
    };
};

export interface UseAssignmentSelectionState<T extends { id: number }> {
    assignments: T[];

    onRemoveAssignment(v: T): void;

    onAdd(v: T[]): void;
}
