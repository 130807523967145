import { omit } from 'lodash';

import { AdminPayoutsFilters, PayoutProvider } from '@hofy/api-admin';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useDateRangeQueryParam, useStringQueryParam } from '@hofy/router';

export const usePayoutsFilters = () => {
    const [search, setSearch] = useStringQueryParam<string>('search', '');
    const [dateRange, setDateRange] = useDateRangeQueryParam('dateRange');
    const [hofySubsidiaryIds, setHofySubsidiaryIds] = useArrayQueryParam<number>('hofySubsidiaryIds', []);
    const [providers, setProviders] = useArrayQueryParam<PayoutProvider>('provider', []);

    const filters = useStructMemo<AdminPayoutsFilters>({
        search,
        dateRange,
        hofySubsidiaryIds,
        providers,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        setSearch,
        filters,
        filterCount,
        setDateRange,
        setHofySubsidiaryIds,
        setProviders,
    };
};
