import React, { FC } from 'react';

import { UserDto } from '@hofy/api-admin';
import { formatUserName, hasAnyManagerRole } from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { UUID } from '@hofy/global';
import {
    BooleanBadge,
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';
import { UserStatusChip } from '@hofy/ui-domain';

interface UsersTableProps {
    adminUsers: UserDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenUser(id: UUID): void;
    search: string | undefined;
}

export const UsersTable: FC<UsersTableProps> = ({ adminUsers, infinityScroll, onOpenUser, search }) => {
    return (
        <InfinityScrollTable
            data={adminUsers}
            toKey={user => user.id}
            infinityScroll={infinityScroll}
            onRowClick={user => onOpenUser(user.id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title={search ? 'No users were found for the current search' : 'No users'}
                />
            }
            minWidth={1200}
            flex='auto'
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 150,
                    flexGrow: 0,
                    renderer: user => `${user.publicId}`,
                },
                {
                    id: 'name',
                    header: 'Name',
                    renderer: user => formatUserName(user),
                },
                {
                    id: 'email',
                    header: 'Email',
                    renderer: user => (
                        <Box column>
                            {user.emails.map((email, i) => (
                                <Paragraph3 wordBreak key={i}>
                                    {email.address}
                                </Paragraph3>
                            ))}
                        </Box>
                    ),
                },
                {
                    id: 'phone-number',
                    header: 'Phone number',
                    renderer: user => (
                        <Box column>
                            <Paragraph3>{user.address.phoneNumber}</Paragraph3>
                        </Box>
                    ),
                },
                {
                    id: 'country',
                    header: 'Country',
                    renderer: user => <CountryLabel country={user.address.country} size={13} />,
                },
                {
                    id: 'organization',
                    header: 'Organization',
                    renderer: user => user.organization.name,
                },
                {
                    id: 'is_manager',
                    header: 'Is Manager',
                    renderer: user => <BooleanBadge offColor='grey' value={hasAnyManagerRole(user.roles)} />,
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: user => <UserStatusChip status={user.status} />,
                },
            ]}
        />
    );
};
