import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { CancelDataErasurePayload } from './types/CancelDataErasurePayload';
import { CompleteDataErasurePayload } from './types/CompleteDataErasurePayload';

class DataErasureService {
    public resetAssignmentDataErasure = async (assignmentId: number): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/data-erasure/reset`);
    };

    public completeAssignmentDataErasure = async (
        assignmentId: number,
        payload: CompleteDataErasurePayload,
    ): Promise<void> => {
        return restClient
            .post(`/api/admin/assignments/${assignmentId}/data-erasure/complete`, payload)
            .then();
    };

    public cancelAssignmentDataErasure = async (
        assignmentId: number,
        payload: CancelDataErasurePayload,
    ): Promise<void> => {
        return restClient.post(`/api/admin/assignments/${assignmentId}/data-erasure/cancel`, payload).then();
    };

    public getAssignmentDataErasureCertificate = async (assignmentId: number): Promise<void> => {
        return restClient
            .downloadFile(`/api/admin/assignments/${assignmentId}/data-erasure/certificate`)
            .then(downloadFileFromResponse);
    };
}

export const dataErasureService = new DataErasureService();
