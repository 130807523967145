import React, { FC } from 'react';

import { RevenueRecognitionDetailDto } from '@hofy/api-admin';
import { formatTwoDates } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    Badge,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
    TextCell,
    TwoLineSmallCell,
} from '@hofy/ui';

import { JournalLinkLabel } from '../../../accountingPage/journals/JournalLink';
import { ContractLinkLabel } from '../../../contractsPage/ContractLink';
import { InvoiceEntryDetailsLinkLabel } from '../../../invoicingPage/invoiceEntries/components/InvoiceEntryDetailsLink';
import { InvoiceEntryTypeChip } from '../../../invoicingPage/invoiceEntries/components/InvoiceEntryTypeChip';
import { InvoiceDetailsLinkLabel } from '../../../invoicingPage/invoices/components/InvoiceDetailsLink';

interface RevenueRecognitionTableProps {
    entries: RevenueRecognitionDetailDto[];
    infinityScroll: InfiniteScrollConfig;
}

export const RevenueRecognitionTable: FC<RevenueRecognitionTableProps> = ({ entries, infinityScroll }) => {
    const { formatPrice } = usePrice();

    return (
        <InfinityScrollTable
            data={entries}
            toKey={entry => entry.id}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No revenue recognition records'
                />
            }
            minWidth={1200}
            flex='auto'
            columns={[
                {
                    id: 'invoice',
                    header: 'Invoice',
                    flexGrow: 0,
                    width: 120,
                    renderer: entry =>
                        entry.invoiceId !== null ? (
                            <TwoLineSmallCell
                                line1={<InvoiceDetailsLinkLabel id={entry.invoiceId} />}
                                line2={`${entry.invoiceReference}`}
                            />
                        ) : (
                            <Badge color='blue' label='Off platform' />
                        ),
                },
                {
                    id: 'invoiceEntryDetail',
                    header: 'Invoice entry',
                    flexGrow: 0,
                    width: 120,
                    renderer: entry => (
                        <TextCell>
                            <InvoiceEntryDetailsLinkLabel id={entry.invoiceEntryId} />
                        </TextCell>
                    ),
                },
                {
                    id: 'contractId',
                    header: 'Contract',
                    flexGrow: 0,
                    width: 120,
                    renderer: entry => (
                        <TextCell>
                            <ContractLinkLabel id={entry.contractId} />
                        </TextCell>
                    ),
                },
                {
                    id: 'invoiceEntryType',
                    header: 'Invoice entry type',
                    flexGrow: 0,
                    flexShrink: 0,
                    width: 160,
                    renderer: entry => <InvoiceEntryTypeChip status={entry.invoiceEntryType} />,
                },
                {
                    id: 'invoiceMonth',
                    header: 'Invoice month',
                    width: 180,
                    renderer: entry => formatTwoDates(entry.monthStartOn, entry.monthEndOn),
                },
                {
                    id: 'revenuePeriod',
                    header: 'Revenue period',
                    width: 180,
                    renderer: entry => formatTwoDates(entry.startOn, entry.endOn),
                },
                {
                    id: 'daysRecognized',
                    header: 'Number of days',
                    width: 180,
                    renderer: entry => entry.daysRecognized,
                },
                {
                    id: 'revenueValue',
                    header: 'Value recognized',
                    width: 180,
                    renderer: entry => formatPrice(entry.revenueValue),
                },
                {
                    id: 'journalId',
                    header: 'Journal',
                    flexGrow: 0,
                    width: 120,
                    renderer: entry => (
                        <TextCell>
                            <JournalLinkLabel id={entry.journalId} />
                        </TextCell>
                    ),
                },
            ]}
        />
    );
};
