import React, { FC } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';

import { BYODOrderTab } from '../../../store/byodOrders/types/BYODOrderTab';
import { NewBYODOrderSlideout } from './byodOrderCreateEditSlideout/NewBYODOrderSlideout';
import { BYODOrdersPage } from './BYODOrdersPage';

export const BYODOrdersRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { goBack } = useGoBack();

    const handleOpenNewBYODOrder = () => {
        history.push(`${path}/new`);
    };

    const handleOpenBYODOrder = (id: UUID) => {
        history.push(`${path}/${id}/${BYODOrderTab.Details}`);
    };

    return (
        <Switch>
            <Route exact path={`${path}/new`}>
                <BYODOrdersPage
                    onOpenBYODOrder={handleOpenBYODOrder}
                    onNewBYODOrder={handleOpenNewBYODOrder}
                />
                <NewBYODOrderSlideout onClose={() => goBack(path)} />
            </Route>
            <Route path={path}>
                <BYODOrdersPage
                    onOpenBYODOrder={handleOpenBYODOrder}
                    onNewBYODOrder={handleOpenNewBYODOrder}
                />
            </Route>
        </Switch>
    );
};
