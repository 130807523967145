import React, { FC, useMemo } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { BaseTable, Placeholder, SvgIllustration } from '@hofy/ui';

import {
    BillingEntityTableListColumn,
    billingEntityTableListColumns,
} from '../../../invoicingPage/billingEntities/components/BillingEntitiesInfinityList';

interface BillingEntitiesTableListProps {
    entries: BillingEntityDto[];
    onOpenBillingEntity(id: number): void;
}

export const BillingEntitiesTable: FC<BillingEntitiesTableListProps> = ({ entries, onOpenBillingEntity }) => {
    const billingEntityOrganizationTableColumns = useMemo(() => {
        return billingEntityTableListColumns.filter(c => BillingEntityTableListColumn.organization !== c.id);
    }, []);

    return (
        <BaseTable
            data={entries}
            toKey={entry => entry.idDeprecated}
            onRowClick={b => onOpenBillingEntity(b.idDeprecated)}
            emptyContent={
                <Placeholder illustration={SvgIllustration.FinanceSearch} title='No billing entities' />
            }
            minWidth={1200}
            columns={billingEntityOrganizationTableColumns}
        />
    );
};
