export const byodOrderErrorI18n = {
    'api-error.invalid-byod-order-status.title': 'Invalid BYOD order status',
    'api-error.invalid-byod-order-status.message': 'Cannot perform this operation',
    'api-error.byod-order-not-updatable.title': 'BYOD order not updatable',
    'api-error.byod-order-not-updatable.message': 'Cannot perform this operation',
    'api-error.received-quantity-higher-than-expected.title': 'Received quantity higher than expected',
    'api-error.received-quantity-higher-than-expected.message': 'Cannot perform this operation',
    'api-error.received-quantity-lower-than-expected.title': 'Received quantity lower than expected',
    'api-error.received-quantity-lower-than-expected.message': 'Cannot perform this operation',
    'api-error.item-code-missing.title': 'Item code missing',
    'api-error.item-code-missing.message': 'Cannot perform this operation',
};
