import React, { FC } from 'react';

import { Box } from '@hofy/ui';

import { useSignUp } from '../../../store/auth/useSignUp';
import { ConnectionSignUpForm } from './ConnectionSignUpForm';
import { EmailPasswordSignUpForm } from './EmailPasswordSignUpForm';

export const SignUpForm: FC = () => {
    const {
        signUpFormState,
        signUpConnectionFormState,
        formType,
        isError,
        email,
        signUpWithPublicSSO,
        isLoading,
    } = useSignUp();

    return (
        <Box>
            {formType === 'email-password' && (
                <EmailPasswordSignUpForm
                    form={signUpFormState}
                    isError={isError}
                    email={email}
                    signUpWithPublicSSO={signUpWithPublicSSO}
                    signUpIsLoading={isLoading}
                />
            )}
            {formType === 'connection' && (
                <ConnectionSignUpForm formState={signUpConnectionFormState} email={email} />
            )}
        </Box>
    );
};
