import { ProductCategory } from '@hofy/api-shared';

export interface StockLevelsFilters {
    warehouses: number[];
    search: string;
    activeVariants: boolean | null;
    category: ProductCategory[];
    filterZeroStock: boolean | null;
}

export const initialStockLevelsFilters: StockLevelsFilters = {
    warehouses: [],
    category: [],
    activeVariants: null,
    search: '',
    filterZeroStock: null,
};
