import React, { FC, useMemo, useState } from 'react';

import { ContractDetailsWithManagement } from '@hofy/api-admin';
import { ContractType, ManagementContractType } from '@hofy/api-shared';
import { Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

import { ContractTab, managementContractTabs } from '../../../store/contracts/types/ContractTab';
import { AssignmentsTable } from '../../itemsPage/AssignmentsTable';
import { AddonsTable, ContractAddonsWithSubContractReference } from './AddonsTable';
import { CommonContractHeader } from './components/CommonContractHeader';
import { ContractInvoiceEntriesTab } from './ContractInvoiceEntriesTab';
import { ManagementContractDetailsTab } from './ManagementContractDetailsTab';
import { ManagementContractsDetails } from './ManagementContractsDetails';
import { RepaymentPlansTab } from './RepaymentPlansTab';
import { StorageAssignmentsTable } from './StorageAssignmentsTable';

interface ManagementDetailsProps {
    tab: ContractTab;
    contract: ContractDetailsWithManagement;
    onChangeTab(t: ContractTab): void;
    onOpenAddInvoiceEntrySlideout(organizationId: UUID): void;
    onInvoiceEntryClick(entryId: number): void;
}

export const ManagementDetails: FC<ManagementDetailsProps> = ({
    tab,
    contract,
    onChangeTab,
    onOpenAddInvoiceEntrySlideout,
    onInvoiceEntryClick,
}) => {
    const [addons, storageAssignments] = useMemo(() => {
        const addons: ContractAddonsWithSubContractReference[] =
            contract.managementContracts?.flatMap(contract =>
                contract.addons.map(addon => {
                    return {
                        ...addon,
                        contractType: ContractType.Management,
                        contractId: contract.idDeprecated,
                        status: contract.status,
                    };
                }),
            ) ?? [];
        const storageAssignments =
            contract.managementContracts?.flatMap(contract => contract.storageAssignments) ?? [];
        return [addons, storageAssignments];
    }, [contract.managementContracts]);

    const [includeDeletedLines, setIncludeDeletedLines] = useState(false);
    const renderContent = () => {
        switch (tab) {
            case ContractTab.Details:
                return <ManagementContractDetailsTab contract={contract} />;
            case ContractTab.InvoiceEntries:
                return (
                    <ContractInvoiceEntriesTab
                        contractId={contract.idDeprecated}
                        includeDeleted={includeDeletedLines}
                        onRowClick={v => onInvoiceEntryClick(v.id)}
                    />
                );
            case ContractTab.ServicePackages:
                return (
                    <ManagementContractsDetails
                        contracts={contract.managementContracts.filter(
                            c => c.type === ManagementContractType.Addon,
                        )}
                    />
                );
            case ContractTab.StoreAndReuse:
                return (
                    <ManagementContractsDetails
                        contracts={contract.managementContracts.filter(
                            c => c.type === ManagementContractType.StoreAndReuse,
                        )}
                    />
                );
            case ContractTab.Addons:
                return <AddonsTable addons={addons} />;
            case ContractTab.Assignments:
                return (
                    <AssignmentsTable maxWidth={2000} assignments={contract.assignments} inSlideout={false} />
                );
            case ContractTab.RepaymentPlans:
                return <RepaymentPlansTab contractId={contract.idDeprecated} />;
            case ContractTab.StorageAssignments:
                return <StorageAssignmentsTable storageAssignments={storageAssignments} />;
        }
    };

    if (!contract) {
        return null;
    }
    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <CommonContractHeader
                contractTitle={`Management #${contract.idDeprecated}`}
                contractId={contract.idDeprecated}
                organizationId={contract.organization.id}
                tab={tab}
                tabs={managementContractTabs}
                onChangeTab={onChangeTab}
                includeDeleted={includeDeletedLines}
                onSetIncludeDeleted={setIncludeDeletedLines}
                onOpenAddInvoiceEntrySlideout={onOpenAddInvoiceEntrySlideout}
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>
        </Overlay>
    );
};
