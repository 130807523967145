import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, shipmentService, UpdateShipmentPayload } from '@hofy/api-admin';

export const useUpdateShipmentMutation = (shipmentId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation<void, unknown, UpdateShipmentPayload>({
        mutationFn: payload => shipmentService.updateShipment(shipmentId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });
};
