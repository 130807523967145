import React, { FC } from 'react';

import { Button, Heading3 } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { useSetPassword } from '../../store/auth/useSetPassword';
import { AuthNavLink } from '../AuthNavLink';
import { AuthPageWrapper } from '../components/authPageWrapper/AuthPageWrapper';
import { SetPasswordForm } from './SetPasswordForm';

export const SetPasswordPage: FC = () => {
    const { tr } = useAuthI18n();
    const { form, tokenValid, authError } = useSetPassword();

    if (!tokenValid) {
        return (
            <AuthPageWrapper pageId='set-password'>
                <Heading3>{tr('set-password-page.invalid-link')}</Heading3>
                <Button
                    to={AuthNavLink.ForgotPassword}
                    label={tr('set-password-page.forgot-password')}
                    fullWidth
                    marginTop={24}
                />
            </AuthPageWrapper>
        );
    }

    return (
        <AuthPageWrapper pageId='set-password' title={tr('set-password-page.title')} showSubHeader>
            <SetPasswordForm form={form} authError={authError} />
        </AuthPageWrapper>
    );
};
