export const forbiddenPageI18n = {
    'forbidden-page.offboarded.title': 'You have been offboarded from Hofy',
    'forbidden-page.offboarded.subtitle':
        'If you think this is a mistake, please contact your account administrator.',
    'forbidden-page.sign-in-with-other': 'Sign in with another email',
    'forbidden-page.goto-hofy': 'Go to Hofy.com',
    'forbidden-page.not_found.title': 'Looks like this account doesn’t exist on Hofy.',
    'forbidden-page.not_found.subtitle':
        'If you think this is a mistake, please contact your account administrator.',

    'forbidden-page.unknown.title': 'An error occurred when you tried to log in',
    'forbidden-page.unknown.subtitle': 'Please contact Hofy support',

    'forbidden-page.archived.title': 'Organization archived',
    'forbidden-page.archived.subtitle': 'Please contact Hofy support',

    'forbidden-page.team_member_in_manager_only_org.title':
        'You don’t have the right permissions to access this page.',
    'forbidden-page.team_member_in_manager_only_org.subtitle': 'Please contact Hofy support',

    'forbidden-page.no_required_roles.title': 'No permission to see this app',
    'forbidden-page.no_required_roles.subtitle': 'Please contact Hofy support',

    'forbidden-page.not_verified.title': 'Email verification required',
    'forbidden-page.not_verified.subtitle':
        'Please check you inbox and follow the verification link to verify your account',
    'forbidden-page.not_invited.title': 'You haven’t been invited to use Hofy',
    'forbidden-page.refresh': 'Refresh',
    'forbidden-page.not_invited.subtitle':
        'If you think this is a mistake, please contact your account administrator.',
    'forbidden-page.invalid_token.title': 'Login failed',
    'forbidden-page.invalid_token.subtitle': 'Please logout and try again.',

    'forbidden-page.sso_login_required.title': 'SSO login required',
    'forbidden-page.sso_login_required.subtitle': 'Please sign in with Google or organization single sign-on',

    'forbidden-page.google_sso_not_allowed.title': 'Google login not allowed',
    'forbidden-page.google_sso_not_allowed.subtitle': 'Please sign in with a different provider',

    'forbidden-page.microsoft_sso_not_allowed.title': 'Microsoft login not allowed',
    'forbidden-page.microsoft_sso_not_allowed.subtitle': 'Please sign in with a different provider',
};
