import { getEnumValues } from '@hofy/global';

export enum AdminItemFilter {
    Redistributable = 'redistributable',
    NotRedistributable = 'not_redistributable',
    IsLoaner = 'is_loaner',
    NeedRepairs = 'need_repairs',
    NoWarehouse = 'no_warehouse',
    AvailableForStoreAndReuse = 'available_for_store_and_reuse',
    WithDamagedParts = 'with_damaged_parts',
    WithMissingParts = 'with_missing_parts',
    IsMissingOriginalPackaging = 'is_missing_original_packaging',
    IsDeviceLocked = 'is_device_locked',
}

export const allAdminItemFilters = getEnumValues<AdminItemFilter>(AdminItemFilter);
