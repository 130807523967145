import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceEntriesQueryKey, invoiceEntryService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useDeleteInvoiceEntry = (invoiceEntryId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const deleteMutation = useMutation({
        mutationFn: (id: number) => invoiceEntryService.deleteInvoiceEntry(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry deleted',
            });
            onSuccess?.();
        },
    });

    const deleteEntry = () => {
        deleteMutation.mutate(invoiceEntryId);
    };

    return {
        deleteEntry,
    };
};
