import { UseForm } from '@hofy/ui';

import { PurchaseOrderFormData } from '../../../../../store/purchaseOrders/types/PurchaseOrderFormData';
import { PurchaseOrderFormValidation } from '../../../../../store/purchaseOrders/usePurchaseOrderForm';
import { NavigationStep } from './useAllowedEditStep';

export const useNavigationSteps = (
    form: UseForm<PurchaseOrderFormData, Partial<PurchaseOrderFormValidation>>,
    setStep: (value: ((prevState: number) => number) | number) => void,
) => {
    return (targetStep: number) => {
        if ([NavigationStep.Items, NavigationStep.Summary].includes(targetStep) && !form.isValid) {
            return form.showErrors();
        }

        return setStep(targetStep);
    };
};
