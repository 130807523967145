import React, { FC } from 'react';

import { HofyWarehouseDetailsDto } from '@hofy/api-admin';
import { allInspectionStatuses, UserRefDto, useTrInspectionStatus } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { UsersBlockFilter } from '../../../components/domain/user/UsersBlockFilter';
import { WarehouseBlockFilter } from '../../../components/domain/warehouses/WarehouseBlockFilter';
import { InspectionsTab } from '../../../store/inspections/inspectionsTab';
import { useInspectionsFilters } from '../../../store/inspections/useInspectionsFilters';

interface InspectionsPageFiltersProps {
    tab: InspectionsTab;
    showFilters: boolean;
    filters: ReturnType<typeof useInspectionsFilters>;
    adminUsers: UserRefDto[];
    hofyWarehouses: HofyWarehouseDetailsDto[];
}

export const InspectionsPageFilters: FC<InspectionsPageFiltersProps> = ({
    tab,
    showFilters,
    filters,
    adminUsers,
    hofyWarehouses,
}) => {
    const trInspectionStatus = useTrInspectionStatus();

    return (
        <BlockFilterContainer show={showFilters}>
            <UsersBlockFilter
                users={adminUsers}
                selected={filters.values.assignedToIds}
                onChange={filters.controls.setAssignedToIds}
            />
            <WarehouseBlockFilter
                warehouses={hofyWarehouses}
                selected={filters.values.warehouseIds}
                onChange={filters.controls.setWarehouseIds}
            />

            {filters.values.viewType === 'rows' && tab === InspectionsTab.Inspections && (
                <ListMultiBlockFilter
                    title='Inspection status'
                    icon={SvgIcon.Search}
                    selected={filters.values.inspectionStatuses}
                    onChange={filters.controls.setInspectionStatuses}
                    items={allInspectionStatuses}
                    renderItem={trInspectionStatus}
                    width={300}
                />
            )}
            {filters.values.viewType === 'rows' && tab === InspectionsTab.DeviceChecks && (
                <ListMultiBlockFilter
                    title='Device check status'
                    icon={SvgIcon.Search}
                    selected={filters.values.deviceCheckStatuses}
                    onChange={filters.controls.setDeviceCheckStatuses}
                    items={allInspectionStatuses}
                    renderItem={trInspectionStatus}
                    width={300}
                />
            )}
        </BlockFilterContainer>
    );
};
