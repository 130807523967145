import { ColorDye, DateTimeString, getEnumValues } from '@hofy/global';

export enum NetsuiteSyncStatus {
    Success = 'success',
    Imported = 'imported',
    Migrated = 'migrated',
    Error = 'error',
}

export const netsuiteStatusColors: Record<NetsuiteSyncStatus, ColorDye> = {
    [NetsuiteSyncStatus.Success]: 'green',
    [NetsuiteSyncStatus.Imported]: 'green',
    [NetsuiteSyncStatus.Migrated]: 'green',
    [NetsuiteSyncStatus.Error]: 'red',
};

export const allNetsuiteSyncStatuses = getEnumValues<NetsuiteSyncStatus>(NetsuiteSyncStatus);

export interface NetsuiteStatusDto {
    status: NetsuiteSyncStatus | null;
    successAt: DateTimeString | null;
    errorAt: DateTimeString | null;
    errorReason: string | null;
}
