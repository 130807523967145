import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, ReplaceAssignmentPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

interface ReplaceAssignmentForm {
    deliveryAddressId: UUID | null;
    isBillable: boolean;
}

export const useReplaceAssignment = (assignmentId: number, addressId: UUID, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (v: ReplaceAssignmentPayload) => assignmentService.replace(assignmentId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Replacement created',
            });
            onSuccess?.();
        },
    });

    const form = useForm<ReplaceAssignmentForm>({
        initial: {
            deliveryAddressId: addressId,
            isBillable: false,
        },
        initialDeps: [addressId],
        onSubmit: mutation.mutate,
        validate: validator<ReplaceAssignmentForm, ReplaceAssignmentPayload>({
            deliveryAddressId: isRequired('Delivery address is required'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
