import { useHistory } from 'react-router-dom';

import { AuditableTable } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenuItem } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';

import { useAuditLink } from './useAuditLink';

export const useAuditLogMenuOption = (
    auditTable: AuditableTable,
    auditTableId?: number,
    auditTableUuid?: UUID,
): [MoreMenuItem, () => boolean, string] => {
    const history = useHistory();
    const { hasPermission } = useSession();
    const auditLink = useAuditLink();
    const auditLogsLink = auditLink({
        auditTableId,
        auditTableUuid,
        auditTableName: auditTable,
    });
    const openLogs = () => {
        if (auditTableId || auditTableUuid) {
            history.push(auditLogsLink);
        }
    };

    const hasAuditPermission = () => {
        return hasPermission(Permission.AdminAuditLogsView);
    };

    const menuLink: MoreMenuItem = {
        action: () => openLogs(),
        color: Color.NonContextualBlueDefault,
        label: `View audit logs`,
        testKey: 'open-logs',
        visible: !!auditTableId && hasAuditPermission(),
    };

    return [menuLink, hasAuditPermission, auditLogsLink];
};
