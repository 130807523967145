import React, { FC } from 'react';

import { useBillingEntitiesListQuery } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { Box, Button, PageHeader, SearchInput, SvgIcon } from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { LabeledOrganizationSelect } from '../../../components/domain/organizations/LabeledOrganizationSelect';
import { useAdminBillingEntityFilters } from '../../../store/invoicing/billingEntities/useAdminBillingEntityFilters';
import { useNavigateBillingEntity } from '../../../store/invoicing/billingEntities/useNavigateBillingEntity';
import { InvoicingTabs } from '../InvoicingTabs';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { BillingEntitiesInfinityList } from './components/BillingEntitiesInfinityList';
import { BillingEntitiesPageMenu } from './components/BillingEntitiesPageMenu';

interface BillingEntitiesPageProps extends InvoicingTabRouterProps {}

export const BillingEntitiesPage: FC<BillingEntitiesPageProps> = ({ tab, tabs, onChangeTab }) => {
    const { filters, setSearch, setOrganizationId } = useAdminBillingEntityFilters();
    const { billingEntities, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
        useBillingEntitiesListQuery(filters);

    const { navigateBillingEntityDetails, getCreateBillingEntityLink } = useNavigateBillingEntity();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Billing entities'
                rightSlot={
                    <>
                        <SearchInput value={filters.search} onChange={setSearch} />
                        <LabeledOrganizationSelect
                            width={240}
                            label=''
                            value={filters.organizationId}
                            placeholder='Choose organization'
                            nullable
                            onChange={setOrganizationId}
                        />
                        <PermissionWrapper
                            permission={Permission.AdminOrganizationUpdateFinancialSettings}
                            tooltip
                        >
                            <Button
                                leftIcon={SvgIcon.Add}
                                label='Add billing entity'
                                to={getCreateBillingEntityLink()}
                            />
                        </PermissionWrapper>
                        <BillingEntitiesPageMenu />
                    </>
                }
                tabsSlot={<InvoicingTabs tabs={tabs} tab={tab} onChangeTab={onChangeTab} />}
            />
            <BillingEntitiesInfinityList
                entries={billingEntities}
                onOpenBillingEntity={navigateBillingEntityDetails}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: isLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Box>
    );
};
