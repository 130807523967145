import { useQuery } from '@tanstack/react-query';

import { itemsService } from '../service/itemsService';
import { itemCacheKey } from './cacheKey';

export const useItemQuery = (id: number | null) => {
    const { data, isLoading } = useQuery({
        queryKey: [itemCacheKey, id],
        queryFn: () => itemsService.getItem(id!),
        enabled: id !== null,
    });

    return {
        data,
        isLoading,
    };
};
