import React, { FC } from 'react';

import { AuthError, PublicSSO } from '@hofy/api-auth';
import { Color } from '@hofy/theme';
import {
    Box,
    BoxProps,
    ExpandHeight,
    Font,
    Form,
    FormEmailInput,
    FormPasswordInput,
    Link,
    SubmitButton,
} from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { SingInForm } from '../../store/auth/useSignIn';
import { useTrAuthError } from '../../store/auth/useTrAuthError';
import { AuthNavLink } from '../AuthNavLink';
import { AuthPageWrapper } from '../components/authPageWrapper/AuthPageWrapper';
import { Or } from '../components/Or';
import { PublicSSOButtons } from '../components/publicSSOButton/PublicSSOButtons';

interface SignInPageFormProps {
    authError?: AuthError;
    form: SingInForm;
    isLoading: boolean;
    isBrokeringMode: boolean;
    signInWithPublicSSO(sso: PublicSSO): void;
}

export const SignInPageForm: FC<SignInPageFormProps> = ({
    form,
    authError,
    signInWithPublicSSO,
    isLoading,
    isBrokeringMode,
}) => {
    const { tr } = useAuthI18n();
    const submitButtonLabel = isBrokeringMode
        ? tr('auth.brokering-form.continue')
        : tr('sign-in-page.email-password-form.sign-in');

    return (
        <AuthPageWrapper pageId='sign-in' title={tr('sign-in-page.title')} showSubHeader={false} signInPage>
            <Box column gap={24} marginTop={24}>
                <Or text={tr('sign-in-page.divider.sso')} />
                <PublicSSOButtons mode='sign-in' onClick={signInWithPublicSSO} />

                <Or text={tr('sign-in-page.divider.email')} />
                <Form onSubmit={form.submit} submitOnEnter isLoading={isLoading} column gap={24}>
                    <FormEmailInput
                        label={tr('sign-in-page.email-password-form.email')}
                        placeholder={tr('sign-in-page.email-password-form.placeholder.email')}
                        api={form.fields.email}
                        testKey='email-input'
                    />
                    {!isBrokeringMode && (
                        <FadeIn>
                            <PasswordBlock authError={authError} form={form} />
                        </FadeIn>
                    )}
                    <SubmitButton
                        label={submitButtonLabel}
                        disableCheck
                        fullWidth
                        testKey={isBrokeringMode ? 'next-button' : 'submit-button'}
                    />
                </Form>
            </Box>
        </AuthPageWrapper>
    );
};

const FadeIn: FC<BoxProps> = ({ children, ...rest }) => {
    return (
        // Give more space for focused input inside ExpandHeight
        <Box marginHorizontal={-8}>
            <ExpandHeight>
                <Box paddingHorizontal={8} {...rest}>
                    {children}
                </Box>
            </ExpandHeight>
        </Box>
    );
};

interface PasswordBlockProps {
    authError?: AuthError;
    form: SingInForm;
}

const PasswordBlock: FC<PasswordBlockProps> = ({ authError, form }) => {
    const { tr } = useAuthI18n();
    const trAuthError = useTrAuthError();
    return (
        <>
            <FormPasswordInput
                label={tr('sign-in-page.email-password-form.password')}
                api={form.fields.password}
                autoFocus
            />
            {!!authError && (
                <Font
                    color={Color.ContentNegative}
                    size={12}
                    marginTop={8}
                    weight='normal'
                    data-test-key={`validation-message-${authError}`}
                >
                    {trAuthError(authError)}
                </Font>
            )}
            <Box marginTop={24} row justify='space-between'>
                <Link to={AuthNavLink.ForgotPassword} testKey='forgot-password-link'>
                    {tr('sign-in-page.email-password-form.forgot-password')}
                </Link>
            </Box>
        </>
    );
};
