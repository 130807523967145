import React, { FC } from 'react';

import { useAuditTablesQuery } from '@hofy/api-admin';
import { BoxProps, Select, TestKeyAware } from '@hofy/ui';

interface AuditTableSelectProps extends TestKeyAware, BoxProps {
    value: string | null;
    onChange(o: string): void;
}

export const AuditTableSelect: FC<AuditTableSelectProps> = ({ value, onChange, ...boxProps }) => {
    const { data } = useAuditTablesQuery();
    return (
        <Select
            options={data}
            toText={v => v as string}
            onChange={onChange}
            placeholder='Choose table'
            value={value}
            nullable
            {...boxProps}
        />
    );
};
