import React, { FC, useMemo } from 'react';

import { AdminAssignmentFilters } from '@hofy/api-admin';
import { unassignedUser } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../../../components/design/blockFilters/ListMultiBlockFilter';

interface FilterBlockFilterProps {
    filter: AdminAssignmentFilters;
    onAllShipmentsChange(value: boolean | null): void;
    onContainingLaptopOnlyChange(value: boolean | null): void;
    onConfigRequiredChange(value: boolean | null): void;
    onUnassignedChange(value: boolean | null): void;
    onNewJoinersChange(value: boolean | null): void;
    onIsLoanerChange(value: boolean | null): void;
    onIsStoreAndReuse(value: boolean | null): void;
    onIsDisposalChange(value: boolean | null): void;
    onScheduledOnlyChange(value: boolean | null): void;
    onPartnerChange(value: boolean | null): void;
    onScannedChange(value: boolean | null): void;
    onUnscannedChange(value: boolean | null): void;
    onIsOnHoldChange(value: boolean | null): void;
}

export const FilterBlockFilter: FC<FilterBlockFilterProps> = ({
    filter,
    onAllShipmentsChange,
    onContainingLaptopOnlyChange,
    onConfigRequiredChange,
    onUnassignedChange,
    onNewJoinersChange,
    onIsLoanerChange,
    onIsStoreAndReuse,
    onIsDisposalChange,
    onScheduledOnlyChange,
    onPartnerChange,
    onScannedChange,
    onUnscannedChange,
    onIsOnHoldChange,
}) => {
    const list = useMemo(
        () => [
            {
                selected: filter.containingLaptopOnly,
                action: onContainingLaptopOnlyChange,
                label: 'Containing laptop',
            },
            {
                selected: filter.configRequired,
                action: onConfigRequiredChange,
                label: 'Config required',
            },
            {
                selected: filter.assignedUsers.includes(unassignedUser),
                action: onUnassignedChange,
                label: 'Unassigned',
            },
            {
                selected: filter.newJoiners,
                action: onNewJoinersChange,
                label: 'New joiners',
            },
            {
                selected: filter.isLoaner,
                action: onIsLoanerChange,
                label: 'Loaner',
            },
            {
                selected: filter.isStoreAndReuse,
                action: onIsStoreAndReuse,
                label: 'Store and reuse',
            },
            {
                selected: filter.isDisposal,
                action: onIsDisposalChange,
                label: ' Only Disposals',
            },
            {
                selected: filter.scheduledOnly,
                action: onScheduledOnlyChange,
                label: 'Scheduled only',
            },
            {
                selected: filter.isPartner,
                action: onPartnerChange,
                label: 'Partner',
            },
            {
                selected: filter.scanned,
                action: onScannedChange,
                label: 'Scanned',
            },
            {
                selected: filter.unscanned,
                action: onUnscannedChange,
                label: 'Unscanned',
            },
            {
                selected: filter.isOnHold,
                action: onIsOnHoldChange,
                label: 'On hold',
            },
            {
                selected: filter.allShipments,
                action: onAllShipmentsChange,
                label: 'All shipments',
            },
        ],
        [filter],
    );

    return (
        <ListMultiBlockFilter
            title='Filter'
            icon={SvgIcon.Filter}
            items={list}
            renderItem={item => item.label}
            selected={list.filter(item => item.selected)}
            onChange={(_items, changedItem) => {
                changedItem.action(changedItem.selected ? null : true);
            }}
        />
    );
};
