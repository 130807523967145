import React, { forwardRef, ReactElement, Ref } from 'react';

import { Labeled } from '../../labeled';
import { NormalSelectProps, NullableSelectProps, Select } from './Select';

interface BaseLabeledSelectProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

type LabeledSelectOnlyStringProps<T> = Omit<NormalSelectProps<T>, 'isError'> & BaseLabeledSelectProps;
type LabeledSelectNullableStringProps<T> = Omit<NullableSelectProps<T>, 'isError'> & BaseLabeledSelectProps;

export type LabeledSelectProps<T> = LabeledSelectOnlyStringProps<T> | LabeledSelectNullableStringProps<T>;

const LabeledSelectComponent = <T,>(
    {
        label,
        isRequired,
        errorMessage,
        helperText,
        options,
        value,
        onChange,
        onBlur,
        onFocus,
        toText,
        toKey,
        toLabel,
        disabled,
        nullable,
        placeholder,
        ...rest
    }: LabeledSelectProps<T>,
    ref: Ref<HTMLDivElement>,
) => {
    return (
        <Labeled
            as='label'
            label={label}
            isRequired={isRequired}
            content={
                <Select
                    ref={ref}
                    options={options}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    toText={toText}
                    toKey={toKey}
                    toLabel={toLabel}
                    disabled={disabled}
                    nullable={nullable}
                    placeholder={placeholder}
                    isError={!!errorMessage}
                />
            }
            errorMessage={errorMessage}
            helperText={helperText}
            {...rest}
        />
    );
};

export const LabeledSelect = forwardRef(LabeledSelectComponent) as <T>(
    props: LabeledSelectProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
