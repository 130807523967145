export const fulfilmentErrorI18n = {
    'api-error.cannot-bill-customer-owned-without-location.title':
        'Cannot bill customer owned without location',
    'api-error.cannot-bill-customer-owned-without-location.message': 'Cannot perform this operation',
    'api-error.cannot-bill-hofy-owned-without-contract.title': 'Cannot bill hofy owned without contract',
    'api-error.cannot-bill-hofy-owned-without-contract.message': 'Cannot perform this operation',
    'api-error.contract-must-be-rental-for-bill.title': 'Contract must be rental for bill',
    'api-error.contract-must-be-rental-for-bill.message': 'Cannot perform this operation',
    'api-error.missing-asset-value-for-token.title': 'Missing asset value for token',
    'api-error.missing-asset-value-for-token.message': 'Cannot perform this operation',
    'api-error.missing-contract-for-bill.title': 'Missing contract for bill',
    'api-error.missing-contract-for-bill.message': 'Cannot perform this operation',
    'api-error.missing-fee-for-bill.title': 'Missing fee for bill',
    'api-error.missing-fee-for-bill.message': 'Cannot perform this operation',
    'api-error.missing-fulfillment-entity-for-bill.title': 'Missing fulfillment entity',
    'api-error.missing-fulfillment-entity-for-bill.message': 'Cannot perform this operation',
    'api-error.missing-invoice-and-token-for-bill.title': 'Missing invoice or token for bill',
    'api-error.missing-invoice-and-token-for-bill.message': 'Cannot perform this operation',
    'api-error.fee-cannot-be-negative.title': 'Fee cannot be negative',
    'api-error.fee-cannot-be-negative.message': 'Cannot perform this operation',
    'api-error.fee-cannot-be-zero.title': 'Fee cannot be zero',
    'api-error.fee-cannot-be-zero.message': 'Cannot perform this operation',
    'api-error.rental-contract-not-ready-for-store-and-reuse.title':
        'Rental contract not ready for store and reuse',
    'api-error.rental-contract-not-ready-for-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.service-not-eligible.title': 'Service not eligible',
    'api-error.service-not-eligible.message': 'Cannot perform this operation',
};
