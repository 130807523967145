import { ContractType, InvoiceEntryType, ProductCategory } from '@hofy/api-shared';
import { DateString, Percent, Price, zeroDollars } from '@hofy/global';
import { endOfMonth, nowISODate, startOfMonth, toISODate } from '@hofy/helpers';

export interface InvoiceEntryPayload {
    type: InvoiceEntryType | null;
    productCategory: ProductCategory | null;
    contractId: number | null;
    contractType: ContractType | null;
    invoiceTime: DateString;
    invoicePeriodFrom: DateString | null;
    invoicePeriodTo: DateString | null;
    description: string;
    quantity: number;

    hofySubsidiaryId: number | null;
    billingEntityId: number | null;
    unitPrice: Price;
    price: Price;
    discount: Percent;
}

export const emptyInvoiceEntryPayload: InvoiceEntryPayload = {
    type: null,
    productCategory: null,
    contractId: null,
    contractType: null,
    invoiceTime: nowISODate(),
    invoicePeriodFrom: null,
    invoicePeriodTo: null,
    description: '',
    quantity: 1,
    unitPrice: zeroDollars,
    price: zeroDollars,
    discount: '0.00',
    hofySubsidiaryId: null,
    billingEntityId: null,
};

export const getEmptyInvoiceEntryAddPayload = (): InvoiceEntryPayload => {
    return {
        type: null,
        productCategory: null,
        contractId: null,
        contractType: null,
        invoiceTime: toISODate(endOfMonth()),
        invoicePeriodFrom: toISODate(startOfMonth()),
        invoicePeriodTo: toISODate(endOfMonth()),
        description: '',
        quantity: 1,
        unitPrice: zeroDollars,
        price: zeroDollars,
        discount: '0.00',
        hofySubsidiaryId: null,
        billingEntityId: null,
    };
};
