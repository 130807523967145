import React, { FC } from 'react';

import { CodeRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Button, Icon, Paragraph3, Span, SvgIcon } from '@hofy/ui';

import { useRevertScan } from '../../../../store/assignments/useRevertScan';

interface ShipmentOrderItemRevertScanProps {
    assignmentId: number;
    itemCodes: CodeRefDto[];
    onRevertScan(): void;
}

export const ShipmentOrderItemRevertScan: FC<ShipmentOrderItemRevertScanProps> = ({
    assignmentId,
    onRevertScan,
    itemCodes,
}) => {
    const { revertScan } = useRevertScan(onRevertScan);
    return (
        <Box row>
            <Icon size={32} marginRight={20} svg={SvgIcon.CheckCircleFill} color={Color.FoundationPositive} />
            <Paragraph3 bold flex={1} marginRight={20}>
                Item code{' '}
                <Span color={Color.FoundationPositive}>{itemCodes.map(c => c.code).join(', ')}</Span> scanned
                to order{' '}
            </Paragraph3>
            <Button type='secondary' label='Revert Scan' onClick={() => revertScan(assignmentId)} />
        </Box>
    );
};
