import { xor } from 'lodash';
import React, { FC, useState } from 'react';

import { useUsersQuery } from '@hofy/api-admin';
import { Role, useTrRole } from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { FilterChipList, PageHeader, SearchInput } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { OrganizationBlockFilter } from '../../components/domain/filters/OrganizationBlockFilter';
import { OrganizationFilterChip } from '../../components/domain/filters/OrganizationFilterChip';
import { useOrganizationsRefsQuery } from '../../store/organizations/useOrganizationsQuery';
import { useAdminUsersFilters } from '../../store/users/useAdminUsersFilters';
import { UsersTable } from './UsersTable';

const allRoles: Role[] = Object.values(Role);

interface UsersPageProps {
    onOpenUser(id: UUID): void;
}

export const UsersPage: FC<UsersPageProps> = ({ onOpenUser }) => {
    const trRole = useTrRole();

    const { filters, filterCount, setSearch, setRoles, setOrganizations } = useAdminUsersFilters();

    const { adminUsers, hasNextPage, isFetchingNextPage, adminUsersIsLoading, fetchNextPage } =
        useUsersQuery(filters);

    const [orgSearchQuery, setOrgSearchQuery] = useState('');
    const { data: organizations, isLoading: organizationsIsLoading } = useOrganizationsRefsQuery(
        filters.organizations,
        orgSearchQuery,
    );
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page>
            <PageHeader
                title='Users'
                rightSlot={
                    <>
                        <SearchInput
                            value={filters.search}
                            onChange={setSearch}
                            placeholder='User name or email…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organizations}
                    onChange={setOrganizations}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <EnumMultiBlockFilter<Role>
                    title='Role'
                    selected={filters.roles}
                    onChange={setRoles}
                    items={allRoles}
                    renderItem={trRole}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <OrganizationFilterChip
                    value={filters.organizations}
                    onChange={setOrganizations}
                    organizations={organizations}
                />
                <FilterChipList<Role>
                    color='violet'
                    toKey={id => id}
                    selected={filters.roles}
                    toLabel={trRole}
                    onClear={cleared => setRoles(xor(filters.roles, [cleared]))}
                />
            </BlockFilterChipContainer>
            <UsersTable
                adminUsers={adminUsers}
                onOpenUser={onOpenUser}
                search={filters.search}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: organizationsIsLoading || adminUsersIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Page>
    );
};
