import React, { FC } from 'react';

import { BYODOrderDto, BYODOrdersFilter } from '@hofy/api-admin';
import { IconItem } from '@hofy/common';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import {
    Box,
    FeaturedIcon,
    FilterApiRecord,
    FilterHeaderCell,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';
import { LocationCard } from '@hofy/ui-domain';

import { BYODOrderStatusChip } from '../../../../../components/domain/byodOrders/BYODOrderStatusChip';

interface BYODOrderTableProps {
    byodOrders: BYODOrderDto[];
    filters: FilterApiRecord<BYODOrdersFilter>;
    onOpenBYODOrder(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
}

export const BYODOrderTable: FC<BYODOrderTableProps> = ({
    byodOrders,
    filters,
    onOpenBYODOrder,
    infinityScroll,
}) => {
    return (
        <InfinityScrollTable
            data={byodOrders}
            toKey={byodOrder => byodOrder.id}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Requests}
                    title='No BYOD orders'
                    message='No BYOD orders for selected criteria'
                />
            }
            onRowClick={byodOrder => onOpenBYODOrder(byodOrder.id)}
            columns={[
                {
                    id: 'byod-order',
                    header: 'BYOD order #',
                    flexGrow: 1,
                    renderer: byodOrder => <TextCell>{byodOrder.publicId}</TextCell>,
                },
                {
                    id: 'trackingLink',
                    header: 'Tracking link',
                    flexGrow: 1,
                    renderer: byodOrder => <TextCell>{byodOrder.trackingLink ?? '--'}</TextCell>,
                },
                {
                    id: 'organization',
                    header: 'Organisation',
                    flexGrow: 1,
                    renderer: byodOrder => byodOrder.organization.name,
                },
                {
                    id: 'location',
                    header: <FilterHeaderCell label='Location' filter={filters.warehouses} />,
                    flexGrow: 1,
                    renderer: ({ toWarehouse }) => (
                        <LocationCard
                            icon={
                                <FeaturedIcon icon={SvgIcon.Hofy} shape='circle' size={32} variant='vivid' />
                            }
                            label={toWarehouse.name}
                            name={toWarehouse.name}
                            country={toWarehouse.address?.country}
                        />
                    ),
                },
                {
                    id: 'expectedItems',
                    header: 'Expected items',
                    flexGrow: 1,
                    renderer: byodOrder =>
                        byodOrder.items.reduce((previous, item) => previous + item.expectedQuantity, 0),
                },
                {
                    id: 'estimatedReceiptAt',
                    header: 'Est. receive at',
                    flexGrow: 1,
                    renderer: byodOrder => (
                        <IconItem icon={SvgIcon.Truck}>{formatDate(byodOrder.estimatedReceiveOn)}</IconItem>
                    ),
                },
                {
                    id: 'createdAt',
                    header: 'Created at',
                    flexGrow: 1,
                    renderer: byodOrder => formatDate(byodOrder.createdAt),
                },
                {
                    id: 'status',
                    header: <FilterHeaderCell label='Status' filter={filters.statuses} />,
                    flexGrow: 1,
                    renderer: byodOrder => (
                        <Box column gap={12}>
                            <BYODOrderStatusChip status={byodOrder.status} dateTime={byodOrder.createdAt} />
                        </Box>
                    ),
                },
            ]}
        />
    );
};
