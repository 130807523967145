import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    purchaseOrdersCacheKey,
    purchaseOrderService,
    stockLevelCacheKey,
} from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { usePurchaseOrderForm } from './usePurchaseOrderForm';

export const useCreatePurchaseOrder = (onSuccess: (id: number) => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: purchaseOrderService.createPurchaseOrder,
        onSuccess: (id: number) => {
            queryClient.invalidateQueries({ queryKey: [purchaseOrdersCacheKey] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [stockLevelCacheKey] });
            showToast({
                type: 'positive',
                message: 'Purchase order created',
            });
            onSuccess(id);
        },
    });

    const {
        form,
        updateItemQuantity,
        updateItemWarranty,
        updateItemUnitPrice,
        updateItemTaxRate,
        updateItemSupplierCode,
        updateItemVariant,
        updateItemEstimatedReceiveOn,
        updateItemNotes,
        deleteItem,
        addItem,
        setValidateItems,
        warehouse,
        deliveryAddress,
        supplier,
    } = usePurchaseOrderForm(mutation.mutate);

    return {
        form,
        updateItemQuantity,
        updateItemWarranty,
        updateItemUnitPrice,
        updateItemTaxRate,
        updateItemSupplierCode,
        updateItemVariant,
        updateItemEstimatedReceiveOn,
        updateItemNotes,
        deleteItem,
        addItem,
        setValidateItems,
        warehouse,
        deliveryAddress,
        isLoadingMutation: mutation.isPending,
        supplier,
    };
};

export type CreatePurchaseOrderForm = ReturnType<typeof useCreatePurchaseOrder>;
