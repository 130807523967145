import React, { FC } from 'react';

import { AsyncButton, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useRetryMandateVerification } from '../../../../store/invoicing/billingEntities/useRetryMandateVerification';

interface RetryMandateVerificationModalProps {
    billingEntityId: number;
    onClose(): void;
}

export const RetryMandateVerificationModal: FC<RetryMandateVerificationModalProps> = ({
    billingEntityId,
    onClose,
}) => {
    const { retryMandateVerification, isLoading } = useRetryMandateVerification(onClose);

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Retry mandate verification' />
            <ModalContent>
                <Paragraph3>
                    This action will collect a payment for the minimum allowed amount for any Direct Debit
                    mandates of this billing entity that were previously rejected.
                </Paragraph3>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Retry'
                    disableCheck
                    onClick={() => retryMandateVerification(billingEntityId)}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
