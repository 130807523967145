import React, { useMemo } from 'react';

import { UpdateLocationPayload } from '@hofy/api-admin';
import { ItemLocation, Permission, useTrItemLocation } from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import { FormNameAwareSelect, FormSelect, UseForm } from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { FormUserSelect } from '../../../components/domain/user/FormUserSelect';
import { WarehouseBinSelector } from '../../../components/domain/warehouses/WarehouseBinSelector';

interface UpdateLocationFormProps<T extends UpdateLocationPayload> {
    form: UseForm<T>;
    warehouses: {
        id: number;
        name: string;
        isWarehouseBinRequired: boolean;
    }[];
    canAddWarehouseBin: boolean;
}

export const UpdateLocationForm = <T extends UpdateLocationPayload>({
    form,
    warehouses,
    canAddWarehouseBin,
}: UpdateLocationFormProps<T>) => {
    const trLocation = useTrItemLocation();
    const selectedWarehouse = useMemo(
        () => warehouses.find(v => v.id === form.fields.warehouseId.value),
        [form.fields.warehouseId, warehouses],
    );
    const handleLocationChange = (v: ItemLocation) => {
        switch (v) {
            case ItemLocation.AtWarehouse:
                form.fields.userId.setValue(null);
                return;
            case ItemLocation.WithUser:
                form.fields.warehouseId.setValue(null);
                form.fields.warehouseBinIdentifier.setValue(null);
                return;
            case ItemLocation.WriteOff:
                form.fields.userId.setValue(null);
                form.fields.warehouseId.setValue(null);
                form.fields.warehouseBinIdentifier.setValue(null);
                return;
        }
    };
    return (
        <>
            <PermissionWrapper permission={Permission.AdminItemsChangeLocation} tooltip>
                <FormSelect
                    label='Location'
                    api={form.fields.location}
                    toText={trLocation}
                    onChange={handleLocationChange}
                    options={[ItemLocation.WithUser, ItemLocation.AtWarehouse, ItemLocation.WithCourier]}
                />
            </PermissionWrapper>
            {form.fields.location.value === ItemLocation.AtWarehouse && (
                <FormRow>
                    <FormNameAwareSelect
                        label='Warehouse'
                        nullable
                        options={warehouses}
                        api={form.fields.warehouseId}
                    />
                    {selectedWarehouse && (
                        <WarehouseBinSelector
                            api={form.fields.warehouseBinIdentifier}
                            warehouse={selectedWarehouse}
                            isRequired={selectedWarehouse.isWarehouseBinRequired}
                            isAddBinPromptEnabled={canAddWarehouseBin}
                        />
                    )}
                </FormRow>
            )}
            {form.fields.location.value === ItemLocation.WithUser && (
                <PermissionWrapper permission={Permission.AdminItemsChangeLocation} tooltip>
                    <FormUserSelect label='Assigned user' api={form.fields.userId} nullable />
                </PermissionWrapper>
            )}
        </>
    );
};
