import React, { FC } from 'react';
import styled from 'styled-components';

import { ProductCategory } from '@hofy/api-shared';
import { Palette } from '@hofy/theme';
import { Box } from '@hofy/ui';

import { categoryIcons } from './categoryIcons';

interface FallbackCategoryImageProps {
    category?: ProductCategory;
}

export const FallbackCategoryImage: FC<FallbackCategoryImageProps> = ({
    category = ProductCategory.Other,
}) => {
    const CategoryIcon = categoryIcons[category];

    return (
        <FallbackBox centered>
            <CategoryIcon />
        </FallbackBox>
    );
};

const FallbackBox = styled(Box)`
    aspect-ratio: 1;
    background: linear-gradient(
        320deg,
        #fff -5.9%,
        rgba(236, 240, 241, 0.75) 64.04%,
        rgba(235, 239, 240, 0.76) 82.8%,
        #b1b3c0 177.25%,
        #c4c4c4 192.75%
    );
    svg {
        width: 50%;
        height: 50%;
        min-width: calc(min(40px, 50%));
        min-height: calc(min(40px, 50%));
        * {
            fill: none;
            stroke: ${Palette.greyAlpha['+2']};
            stroke-width: 0.3px;
        }
    }
`;
