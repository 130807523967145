import { useQuery } from '@tanstack/react-query';

import { contractService, EnrollItemValidationDetailsDto } from '@hofy/api-admin';
import { PaymentSchema } from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';

export const useValidateEnrollDevicesIntoServicePackageQuery = (
    itemIds: number[],
    activeOn: DateString | null,
    addonIds: UUID[],
    paymentSchema: PaymentSchema | null,
    duration: number | null,
) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [
            'admin/contract/validate-enrollment',
            itemIds,
            activeOn,
            addonIds,
            duration,
            paymentSchema,
        ],

        queryFn: () =>
            contractService.validateEnrollItemsIntoServicePackage({
                itemIds: itemIds,
                activeOn: activeOn!,
                addonIds: addonIds,
                duration: duration!,
                paymentSchema: paymentSchema!,
            }),
        enabled: !!activeOn && !!paymentSchema && !!duration,
    });

    return {
        data: data || [],
        isLoading,
        isError,
    };
};

export const enrollmentIsValid = (item: EnrollItemValidationDetailsDto) => {
    return !item.itemFailReason && !item.addonsValidation?.some(a => a.addonFailReason);
};
