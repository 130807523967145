import { useMutation } from '@tanstack/react-query';

import { billingEntitiesService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useRetryMandateVerification = (onSuccess: () => void) => {
    const { showToast } = useToast();
    const mutation = useMutation({
        mutationFn: billingEntitiesService.retryMandateVerification,
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Mandate verification retried',
            });
            onSuccess();
        },
    });

    return {
        retryMandateVerification: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
