import { findKey } from 'lodash';
import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { JobContext } from '@hofy/api-admin';
import { useSession } from '@hofy/auth';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { AdminAccountingTab, adminAccountingTabPermissions } from './AdminAccountingTab';
import { FixedAssetValuationPage } from './fixedAssetValuation/FixedAssetValuationPage';
import { ItemEventsPage } from './itemEvents/ItemEventsPage';
import { ItemValuationPage } from './itemValuation/ItemValuationPage';
import { JournalsPage } from './journals/JournalsPage';
import { PaymentsPage } from './payments/PaymentsPage';
import { PayoutsPage } from './payouts/PayoutsPage';
import { RevenueRecognitionPage } from './revenueRecognition/RevenueRecognitionPage';

export const AccountingRouter: FC = () => {
    const { hasPermission } = useSession();

    let firstTab = findKey(adminAccountingTabPermissions, permission => {
        return hasPermission(permission) ? permission[0] : AdminAccountingTab.Journals;
    });

    const history = useHistory();
    const handleOpenJournalJobs = () => {
        history.push(`${AdminNavLink.Jobs}?context=${JobContext.Journals}`);
    };

    return (
        <Switch>
            <Route path={`${AdminNavLink.Accounting}/${AdminAccountingTab.Journals}`}>
                <JournalsPage onOpenJobs={handleOpenJournalJobs} />
            </Route>
            <Route
                path={`${AdminNavLink.Accounting}/${AdminAccountingTab.ItemEvents}`}
                component={ItemEventsPage}
            />
            <Route
                path={`${AdminNavLink.Accounting}/${AdminAccountingTab.RevenueRecognition}`}
                component={RevenueRecognitionPage}
            />
            <Route
                path={`${AdminNavLink.Accounting}/${AdminAccountingTab.ItemValuation}`}
                component={ItemValuationPage}
            />
            <Route
                path={`${AdminNavLink.Accounting}/${AdminAccountingTab.FixedAssetValuation}`}
                component={FixedAssetValuationPage}
            />
            <Route
                path={`${AdminNavLink.Accounting}/${AdminAccountingTab.Payments}`}
                component={PaymentsPage}
            />
            <Route
                path={`${AdminNavLink.Accounting}/${AdminAccountingTab.Payouts}`}
                component={PayoutsPage}
            />
            <Redirect to={`${AdminNavLink.Accounting}/${firstTab}`} />
        </Switch>
    );
};
