import React, { FC } from 'react';

import { RepaymentPlanDto } from '@hofy/api-admin';
import { repaymentStatusColors } from '@hofy/api-shared';
import { priceToNumber } from '@hofy/global';
import {
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    ProgressBar,
    SvgIllustration,
} from '@hofy/ui';

import { SubsidiaryCell } from '../../../../components/domain/contracts/SubsidiaryCell';
import { RepaymentStatusBadge } from './RepaymentStatusBadge';
import { RepaymentTypeBadge } from './RepaymentTypeBadge';

interface RepaymentPlansTableProps {
    entries: RepaymentPlanDto[];
    infinityScroll: InfiniteScrollConfig;
    onEntryClick(p: number): void;
}

export const RepaymentPlansTable: FC<RepaymentPlansTableProps> = ({
    entries,
    onEntryClick,
    infinityScroll,
}) => {
    return (
        <InfinityScrollTable
            data={entries}
            toKey={entry => entry.id}
            infinityScroll={infinityScroll}
            onRowClick={r => onEntryClick(r.id)}
            emptyContent={
                <Placeholder illustration={SvgIllustration.FinanceSearch} title='No repayment plans' />
            }
            minWidth={1200}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 80,
                    flexGrow: 0,
                    renderer: entry => `#${entry.id}`,
                },
                {
                    id: 'uuid',
                    header: 'Two id',
                    width: 320,
                    flexGrow: 0,
                    renderer: entry => `${entry.twoId || '--'}`,
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 1,
                    width: 180,
                    renderer: entry => <SubsidiaryCell entry={entry} />,
                },
                {
                    id: 'type',
                    header: 'Type',
                    flexGrow: 1,
                    width: 140,
                    renderer: entry => <RepaymentTypeBadge type={entry.type} />,
                },
                {
                    id: 'value',
                    header: 'Value',
                    flexGrow: 1,
                    flexShrink: 0,
                    width: 180,
                    renderer: entry => {
                        const value = priceToNumber(entry.invoicedAmount);
                        const currency = entry.invoicedAmount.currency;
                        const max = priceToNumber(entry.initialAmount);

                        return (
                            <Box flex={1} marginRight={20}>
                                <ProgressBar
                                    progress={value / max}
                                    value={`${value}/${max} ${currency}`}
                                    color={repaymentStatusColors[entry.status]}
                                />
                            </Box>
                        );
                    },
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    width: 180,
                    renderer: entry => <RepaymentStatusBadge status={entry.status} />,
                },
            ]}
        />
    );
};
