import { useMutation } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { AuthError, authService, isAuthError, isValidToken } from '@hofy/api-auth';
import { useStringQueryParam } from '@hofy/router';
import { useForm } from '@hofy/ui';

import { emptyPasswordPayload, PasswordPayload, PasswordPayloadValidation } from './types/PasswordPayload';
import { useValidatePassword } from './useValidatePassword';

export const useSetPassword = () => {
    const [token] = useStringQueryParam('token');
    const validatePassword = useValidatePassword({ keyPrefix: 'password-form', confirmationRequired: true });
    const [authError, setAuthError] = useState<AuthError>();

    const form = useForm<PasswordPayload, PasswordPayload, PasswordPayloadValidation>({
        initial: emptyPasswordPayload,
        validate: validatePassword,
        onSubmit: ({ password }) => {
            setAuthError(undefined);
            mutation.mutate({
                password,
                token: token!,
            });
        },
    });

    const mutation = useMutation({
        mutationFn: authService.setPassword,
        onSuccess: tokens => {
            if (isAuthError(tokens)) {
                setAuthError(tokens);
            }
        },
        onError: () => {
            setAuthError(AuthError.Unknown);
        },
    });

    const tokenValid = useMemo(() => {
        if (!token) {
            return false;
        }
        return isValidToken(token);
    }, [token]);

    return {
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        authError,
        form,
        tokenValid,
    };
};
