import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, BoxProps } from '../../base';

interface InnerCardProps extends BoxProps {
    children?: ReactNode;
}

export const InnerCard: FC<InnerCardProps> = ({ children, ...rest }) => {
    return (
        <Box
            padding={24}
            border
            rounded={8}
            bg={Color.BackgroundSurfaceNeutral}
            data-test-key='inner-card'
            {...rest}
        >
            {children}
        </Box>
    );
};

export const InnerCardHeader: FC<InnerCardProps> = ({ children, ...rest }) => {
    return (
        <Box
            marginTop={-24}
            marginHorizontal={-24}
            marginBottom={24}
            padding={24}
            borderBottom
            data-test-key='inner-card-header'
            {...rest}
        >
            {children}
        </Box>
    );
};

export const InnerCardFooter: FC<InnerCardProps> = ({ children, ...rest }) => {
    return (
        <Box
            marginBottom={-24}
            marginHorizontal={-24}
            marginTop={24}
            padding={24}
            borderTop
            data-test-key='inner-card-footer'
            {...rest}
        >
            {children}
        </Box>
    );
};
