import React, { FC, useState } from 'react';

import { PasswordInput } from '@hofy/common';
import { Box, Tooltip, UseForm } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { PasswordPayload, PasswordPayloadValidation } from '../../store/auth/types/PasswordPayload';
import { PasswordHints } from './PasswordHints';

interface PasswordFormProps {
    form: UseForm<PasswordPayload, PasswordPayloadValidation>;
    keyPrefix: string;
}

export const PasswordForm: FC<PasswordFormProps> = ({ form, keyPrefix }) => {
    const { trParts } = useAuthI18n();
    const [showHints, setShowHints] = useState(false);

    return (
        <Box column gap={24}>
            <Box column gap={12}>
                <Tooltip body={trParts(keyPrefix, 'password.tooltip')} maxWidth={300}>
                    <PasswordInput
                        label={trParts(keyPrefix, 'password')}
                        value={form.values.password}
                        isError={form.errors.password}
                        onChangeText={password => {
                            form.setValues({ password });
                            setShowHints(true);
                        }}
                    />
                </Tooltip>
                {showHints && (
                    <PasswordHints
                        errors={form.errors}
                        currentErrors={form.currentErrors}
                        keyPrefix={keyPrefix}
                    />
                )}
            </Box>
            <PasswordInput
                label={trParts(keyPrefix, 'confirmed-password')}
                value={form.values.confirmedPassword || ''}
                isError={form.errors.confirmedPassword}
                onChangeText={confirmedPassword => form.setValues({ confirmedPassword })}
            />
        </Box>
    );
};
