import React, { FC } from 'react';

import { EmailInput, FormCheckbox } from '@hofy/common';
import { Box, Form, Link, SubmitButton, UseForm } from '@hofy/ui';

import { useAuthI18n } from '../../../i18n/useAuthI18n';
import { SignUpConnectionFormPayload } from '../../../store/auth/types/SignUpFormPayload';

interface ConnectionSignUpFormProps {
    email: string | null;
    formState: UseForm<SignUpConnectionFormPayload>;
}

export const ConnectionSignUpForm: FC<ConnectionSignUpFormProps> = ({ formState, email }) => {
    const { tr } = useAuthI18n();

    return (
        <Form onSubmit={formState.submit} submitOnEnter marginTop={30}>
            <EmailInput
                label={tr('sign-up-page.form.email')}
                value={email || ''}
                isError={false}
                marginBottom={35}
                onChangeText={() => {}}
                testKey='email-input'
                disabled
                name='email'
            />
            <Box marginBottom={25} marginTop={25}>
                <FormCheckbox
                    checked={formState.values.termsAndConditions}
                    onChange={termsAndConditions => formState.setValues({ termsAndConditions })}
                    isError={formState.errors.termsAndConditions}
                    fontSize='paragraph4'
                    fontWeight='bold'
                >
                    <TermsAndConditionsLabel />
                </FormCheckbox>
            </Box>
            <SubmitButton
                disabled={!formState.values.termsAndConditions}
                label={tr('sign-up-page.form.sign-up')}
                testKey='sign-up-button'
                fullWidth
            />
        </Form>
    );
};

export enum SignupAuthLinks {
    TermsAndConditions = 'https://www.hofy.com/terms-of-service',
    PrivacyPolicy = 'https://www.hofy.com/customer-privacy-policy',
}

export const TermsAndConditionsLabel = () => {
    const { tr } = useAuthI18n();
    return (
        <>
            {tr('sign-up-page.form.terms-and-conditions')}{' '}
            <Link to={SignupAuthLinks.TermsAndConditions} inline>
                {tr('sign-up-page.form.terms-and-conditions.link')}
            </Link>{' '}
            {tr('sign-up-page.form.terms-and-conditions.and')}{' '}
            <Link to={SignupAuthLinks.PrivacyPolicy} inline>
                {tr('sign-up-page.form.privacy-policy.link')}
            </Link>
        </>
    );
};
