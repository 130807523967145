import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { ShipmentAction } from '../../../shipmentSlideout/ShipmentAction';
import { NewPurchaseOrderSlideout } from './NewPurchaseOrderSlideout';

interface CreatePurchaseOrderSlideoutRouterProps {
    base: string;
    onCloseBase: string;
}

export const CreatePurchaseOrderForShipmentSlideoutRouter: FC<CreatePurchaseOrderSlideoutRouterProps> = ({
    base,
    onCloseBase,
}) => {
    const history = useHistory();

    return (
        <Route path={`${base}/${ShipmentAction.CreatePurchaseOrder}`} exact>
            <NewPurchaseOrderSlideout onClose={() => history.push(onCloseBase)} />
        </Route>
    );
};
