import { upperFirst } from 'lodash';
import React, { FC, memo } from 'react';

import { ProductDto } from '@hofy/api-admin';
import {
    allParentProductCategories,
    allStockCondition,
    catalogueCategoriesHierarchy,
    Permission,
    useTrProductCategory,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { FilterContainer, FilterDivider, Page, RadioFilter } from '@hofy/common';
import { CountryFormDropdown } from '@hofy/core';
import { UUID } from '@hofy/global';
import { ProductCategoryTree } from '@hofy/product';
import {
    BaseTable,
    Box,
    Button,
    HiddenScroll,
    PageHeader,
    Placeholder,
    SearchInput,
    SectionTitle2,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';

import { ProductOverview } from '../../components/domain/products/ProductOverview';
import { allAvailabilityOptions } from '../../store/products/types/Availability';
import { allProductStatuses } from '../../store/products/types/ProductStatus';
import { useProductFilters } from '../../store/products/useProductFilters';
import { useProducts } from '../../store/products/useProducts';
import { useTrAvailability } from '../../store/products/useTrAvailability';
import { useTrProductStatus } from '../../store/products/useTrProductStatus';
import { ActiveProductChip } from '../inventoryPage/stockLevels/addItem/ActiveProductChip';
import { InternalProductChip } from '../inventoryPage/stockLevels/addItem/InternalProductChip';
import { ProductsPageMenu } from './components/ProductsPageMenu';

interface ProductsPageProps {
    onOpenProduct(id: UUID): void;
    onAddProduct(): void;
}

export const ProductsPage: FC<ProductsPageProps> = ({ onOpenProduct, onAddProduct }) => {
    const { hasPermission } = useSession();
    const {
        filters,
        setCategory,
        setCountry,
        setProductStatus,
        setStockCondition,
        setAvailability,
        setSearch,
    } = useProductFilters();

    const { data, isLoading } = useProducts(filters);

    const trProductStatus = useTrProductStatus();
    const trAvailability = useTrAvailability();

    const canAddProduct = hasPermission(Permission.AdminProductsCreate);

    return (
        <Page>
            <PageHeader
                title='Products'
                rightSlot={
                    <>
                        <SearchInput
                            value={filters.search}
                            onChange={setSearch}
                            placeholder='Search products…'
                            autoFocus
                        />
                        {canAddProduct && (
                            <Button leftIcon={SvgIcon.Add} label='Add product' onClick={onAddProduct} />
                        )}
                        <ProductsPageMenu />
                    </>
                }
            />
            <Box relative flex='auto' row alignItems='stretch'>
                <HiddenScroll paddingHorizontal={30} paddingVertical={24} borderRight width={300}>
                    <SectionTitle2 paddingBottom={24} paddingLeft={10}>
                        Category
                    </SectionTitle2>
                    <ProductCategoryTree
                        category={filters.category}
                        onChange={setCategory}
                        parentProductCategories={allParentProductCategories}
                        hierarchy={catalogueCategoriesHierarchy}
                    />
                    <FilterDivider />
                    <RadioFilter
                        options={allProductStatuses}
                        selected={filters.productStatus}
                        onChange={setProductStatus}
                        title='Product/Variant status'
                        optionToText={trProductStatus}
                    />
                    <FilterDivider />
                    <RadioFilter
                        options={allStockCondition}
                        selected={filters.stockCondition}
                        onChange={setStockCondition}
                        title='Stock condition'
                        optionToText={upperFirst}
                    />
                    <FilterDivider />
                    <FilterContainer title='Country'>
                        <CountryFormDropdown
                            label=''
                            emptyContent='Select Country'
                            value={filters.country}
                            onChange={setCountry}
                        />
                    </FilterContainer>
                    <FilterDivider />
                    <RadioFilter
                        options={allAvailabilityOptions}
                        selected={filters.availability}
                        onChange={setAvailability}
                        title='Availability'
                        optionToText={trAvailability}
                    />
                </HiddenScroll>
                <ProductTable products={data} onOpenProduct={onOpenProduct} isLoading={isLoading} />
            </Box>
        </Page>
    );
};

interface ProductTableProps {
    isLoading: boolean;
    products: ProductDto[];
    onOpenProduct(id: UUID): void;
}

const ProductTable = memo<ProductTableProps>(({ products, onOpenProduct, isLoading }) => {
    const trProductCategory = useTrProductCategory();
    return (
        <BaseTable
            data={products}
            toKey={product => product.id}
            onRowClick={v => onOpenProduct(v.id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.AssetsSearch}
                    title='No products'
                    message='No products for selected criteria'
                />
            }
            isLoading={isLoading}
            minWidth={900}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    width: 150,
                    flexGrow: 0,
                    renderer: product => `${product.publicId}`,
                },
                {
                    id: 'product',
                    header: 'Product',
                    flexGrow: 1,
                    renderer: product => (
                        <ProductOverview product={product} images={product.variants[0].image} />
                    ),
                },
                {
                    id: 'category',
                    header: 'Category',
                    width: 120,
                    flexGrow: 0,
                    renderer: product => trProductCategory(product.category),
                },
                {
                    id: 'variants',
                    header: 'Variants',
                    width: 120,
                    flexGrow: 0,
                    renderer: product => `${product.variants.length} variants`,
                },
                {
                    id: 'status',
                    header: 'Status',
                    width: 100,
                    flexGrow: 0,
                    renderer: product => (
                        <Box column gap={4}>
                            <ActiveProductChip value={product.isActive} />
                            <InternalProductChip value={product.isInternal} />
                        </Box>
                    ),
                },
            ]}
        />
    );
});
