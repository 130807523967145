import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { Pressable } from '../base';
import { SummaryProps } from './Summary';
import { SummaryInnerContent } from './SummaryInnerContent';

interface SummaryButtonProps extends SummaryProps {
    onClick?(): void;
    active?: boolean;
}

export const SummaryButton: FC<SummaryButtonProps> = ({
    title,
    tooltip,
    children,
    onClick,
    active,
    ...rest
}) => {
    return (
        <ContainerPressable
            onClick={onClick}
            flex={1}
            padding={16}
            bg={Color.BackgroundSubtleNeutral}
            border
            borderColor={Color.NonContextualGreySurfaceBorder}
            rounded={8}
            active={active}
            pointer
            {...rest}
        >
            <SummaryInnerContent title={title} tooltip={tooltip}>
                {children}
            </SummaryInnerContent>
        </ContainerPressable>
    );
};

const ContainerPressable = styled(Pressable)<{ active?: boolean }>`
    &:hover {
        background-color: ${Color.NonContextualVioletSurface};
        border-color: ${Color.NonContextualVioletMuted};
    }

    ${p =>
        p.active &&
        css`
            background-color: ${Color.NonContextualVioletSubtle} !important;
            border-color: ${Color.NonContextualVioletMuted} !important;
        `}
`;
