import { capitalize, identity, isEmpty, isString } from 'lodash';
import React, { FC, PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';

import {
    AddonConstraintDto,
    AddonDataDependenciesDto,
    AddonDetailsDto,
    AddonPoolDataDto,
    emptyConstraintsPayload,
    emptyDependenciesPayload,
} from '@hofy/api-admin';
import {
    AddonItem,
    AddonTarget,
    allAddonItems,
    allAddonTargets,
    allAddonTypes,
    allCategories,
    allContractTypes,
    allParentProductCategories,
    allPaymentSchemas,
    allPaymentSchemasWithoutDeposit,
    allPlatformTiers,
    allProductBrands,
    allRentalTerms,
    ContractType,
    PaymentSchema,
    Permission,
    PlatformTier,
    productCategoriesHierarchy,
    ProductCategory,
    useTrAddonItem,
    useTrAddonTarget,
    useTrAddonType,
    useTrContractType,
    useTrParentProductCategory,
    useTrPaymentSchema,
    useTrProductBrand,
    useTrProductCategory,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import {
    MultiSelectDropdown,
    RadioGroup,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { Currency, decimalToNumber, UUID, zeroDollars, zeroPercent, zeroUuid } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Alert,
    ArrayFieldRecord,
    Box,
    Button,
    Form,
    FormCheckboxGroup,
    FormDecimalInput,
    FormGridRow,
    FormInput,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    FormSelect,
    FormSwitch,
    IconButton,
    Labeled,
    LabelError,
    Paragraph3,
    Paragraph4,
    SubmitButton,
    SvgIcon,
    UseForm,
    useFormArrayField,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import {
    AddonConstraint,
    getAddonConstraintLabel,
    getAddonConstraintsByTarget,
} from '../../../../store/addons/types/AddonConstraint';
import {
    AddonDataDependency,
    getAddonDataDependenciesByTarget,
    getAddonDataDependencyLabel,
    getSanitizedAddonDataDependencyValues,
} from '../../../../store/addons/types/AddonDataDependency';
import { AddonForm } from '../../../../store/addons/types/AddonForm';
import { useAddonPoolForm } from '../../../../store/addons/useAddonPoolForm';
import { useTrPlatformTier } from '../../../../store/organizations/useTrPlatformTier';

interface AddonSlideoutContentProps {
    title: ReactNode;
    form: UseForm<AddonForm>;
    isLoading: boolean;
    isError: boolean;
    isDisabled: boolean;
    onClose(): void;
    addon?: AddonDetailsDto;
}

export const AddonSlideoutContent: FC<AddonSlideoutContentProps> = ({
    title,
    form,
    isLoading,
    isError,
    isDisabled,
    onClose,
    addon,
}) => {
    const trAddonType = useTrAddonType();
    const trAddonTarget = useTrAddonTarget();
    const trAddonItem = useTrAddonItem();

    const constraints = useFormArrayField(form.fields.constraints, {
        ...emptyConstraintsPayload,
    });

    const pricings = useFormArrayField(form.fields.pricings, {
        ...emptyDependenciesPayload,
        price: zeroDollars,
    });

    const discounts = useFormArrayField(form.fields.discounts, {
        ...emptyDependenciesPayload,
        discount: zeroPercent,
    });

    const pools = useFormArrayField(form.fields.pools, {
        id: zeroUuid,
        name: 'New pool',
        services: [],
        isRatio: false,
        isMonthly: false,
        quantities: [],
        multipliers: [],
    });

    const poolActionWrapper = (action: (poolKey: string) => void, poolKey: string, poolId: UUID | null) =>
        addon?.pools.some(pool => pool.id === poolId) ? undefined : () => action(poolKey);

    const durations = useMemo(
        () => [
            ...new Set(
                constraints.fields.flatMap(
                    constraint =>
                        constraint.api.durations.value ?? Array.from({ length: 36 }, (_, i) => i + 1),
                ),
            ),
        ],
        [constraints.fields],
    );

    const paymentSchemas = useMemo(
        () => [
            ...new Set(
                constraints.fields.flatMap(
                    constraint => constraint.api.paymentSchemas.value ?? allPaymentSchemasWithoutDeposit,
                ),
            ),
        ],
        [constraints.fields],
    );

    const productCategories = useMemo(
        () => [
            ...new Set(
                constraints.fields.flatMap(constraint =>
                    constraint.api.productParentCategories.value
                        ? constraint.api.productParentCategories.value.flatMap(p =>
                              productCategoriesHierarchy[p].filter(
                                  c => constraint.api.productCategories.value?.includes(c) ?? true,
                              ),
                          )
                        : constraint.api.productCategories.value ?? allCategories,
                ),
            ),
        ],
        [constraints.fields],
    );

    const platformTiers = useMemo(
        () => [
            ...new Set(
                constraints.fields.flatMap(
                    constraint => constraint.api.platformTiers.value ?? allPlatformTiers,
                ),
            ),
        ],
        [constraints.fields],
    );

    const { hasPermission } = useSession();
    const canUpdate = hasPermission(Permission.AdminAddonsUpdate);

    return (
        <Form
            onSubmit={form.submit}
            isLoading={isLoading}
            isError={isError}
            disabled={isDisabled}
            column
            fullHeight
        >
            <SlideoutHeader title={title} />

            <SlideoutContent column gap={30} paddingVertical={20} flex='auto'>
                <FormSection label='Addon details'>
                    <FormGridRow columns={3}>
                        <FormInput label='Name' api={form.fields.name} />
                        <FormSelect
                            label='Linked to'
                            api={form.fields.target}
                            options={allAddonTargets}
                            toText={trAddonTarget}
                        />
                        <FormSelect
                            label='Type'
                            api={form.fields.type}
                            options={allAddonTypes}
                            toText={trAddonType}
                        />
                    </FormGridRow>
                    <FormGridRow columns={1}>
                        <MultiSelectDropdown
                            label='Services'
                            items={allAddonItems}
                            labelFormatter={trAddonItem}
                            value={form.values.services}
                            onChange={services => form.setValues({ services })}
                            clearable
                        />
                    </FormGridRow>
                    <FormGridRow columns={3}>
                        <FormSwitch label='Public' api={form.fields.isPublic} />
                        <FormSwitch label='Premium' api={form.fields.isPremium} />
                    </FormGridRow>
                </FormSection>

                <FormSection label='Addon constraints'>
                    <FormCheckboxGroup
                        label=''
                        options={getAddonConstraintsByTarget(form.values.target)}
                        toKey={identity}
                        toLabel={getAddonConstraintLabel}
                        api={form.fields.constraintDependencies}
                        horizontal
                    />
                    <Box column gap={10} alignItems='flex-start'>
                        {constraints.fields.map(constraint => (
                            <AddonConstraintsForms
                                key={constraint.key}
                                constraints={form.values.constraintDependencies}
                                arrayField={constraint}
                                onDuplicate={() => constraints.duplicate(constraint.key)}
                                onRemove={() => constraints.remove(constraint.key)}
                            />
                        ))}
                        {form.fields.constraints.shouldShowError() && isString(form.errors.constraints) && (
                            <Alert type='negative' description={form.errors.constraints} />
                        )}
                        <Box row gap={10}>
                            <Button label='Add constraint' onClick={() => constraints.add()} />
                            {constraints.fields.length > 0 && (
                                <Button
                                    label='Clear all'
                                    type='secondary'
                                    action='destructive'
                                    onClick={() => constraints.clear()}
                                />
                            )}
                        </Box>
                    </Box>
                </FormSection>

                <FormSection label='Addon pricing'>
                    <FormCheckboxGroup
                        label='Dependencies'
                        options={getAddonDataDependenciesByTarget(form.values.target)}
                        toKey={identity}
                        toLabel={getAddonDataDependencyLabel}
                        api={form.fields.pricingDependencies}
                        horizontal
                    />
                    <Box column gap={10} alignItems='flex-start' overflow='auto'>
                        {pricings.fields.map(pricing => (
                            <AddonDataForms
                                key={pricing.key}
                                durations={durations}
                                paymentSchemas={paymentSchemas}
                                productCategories={productCategories}
                                platformTiers={platformTiers}
                                data={form.values.pricingDependencies}
                                arrayField={pricing}
                                onDuplicate={() => pricings.duplicate(pricing.key)}
                                onRemove={() => pricings.remove(pricing.key)}
                            >
                                <FormPriceInput
                                    label='Price'
                                    api={pricing.api.price}
                                    defaultCurrency={Currency.USD}
                                    nullable
                                />
                            </AddonDataForms>
                        ))}
                        <Box row gap={10}>
                            <Button label='Add price' onClick={() => pricings.add()} />
                            {pricings.fields.length > 0 && (
                                <Button
                                    label='Clear all'
                                    type='secondary'
                                    action='destructive'
                                    onClick={() => pricings.clear()}
                                />
                            )}
                        </Box>
                    </Box>
                </FormSection>

                <FormSection label='Addon discounts'>
                    <FormCheckboxGroup
                        label='Dependencies'
                        options={getAddonDataDependenciesByTarget(form.values.target)}
                        toKey={identity}
                        toLabel={getAddonDataDependencyLabel}
                        api={form.fields.discountDependencies}
                        horizontal
                    />

                    <Box column gap={10} alignItems='flex-start' overflow='auto'>
                        {discounts.fields.map(discount => (
                            <AddonDataForms
                                key={discount.key}
                                durations={durations}
                                paymentSchemas={paymentSchemas}
                                productCategories={productCategories}
                                platformTiers={platformTiers}
                                data={form.values.discountDependencies}
                                arrayField={discount}
                                onDuplicate={() => discounts.duplicate(discount.key)}
                                onRemove={() => discounts.remove(discount.key)}
                            >
                                <FormDecimalInput
                                    label='Discount'
                                    api={discount.api.discount}
                                    unit='%'
                                    precision={2}
                                    nullable
                                />
                            </AddonDataForms>
                        ))}
                        <Box row gap={10}>
                            <Button label='Add discount' onClick={() => discounts.add()} />
                            {discounts.fields.length > 0 && (
                                <Button
                                    label='Clear all'
                                    type='secondary'
                                    onClick={() => discounts.clear()}
                                    action='destructive'
                                />
                            )}
                        </Box>
                    </Box>
                </FormSection>

                <FormSection label='Addon pools'>
                    <Box column gap={10}>
                        {pools.fields.map(pool => (
                            <AddonPoolsForms
                                key={pool.key}
                                addonTarget={form.values.target}
                                addonServices={form.values.services}
                                durations={durations}
                                paymentSchemas={paymentSchemas}
                                productCategories={productCategories}
                                platformTiers={platformTiers}
                                pool={pool}
                                onDuplicate={() =>
                                    pools.duplicate(pool.key, v => ({
                                        ...v,
                                        id: zeroUuid,
                                        name: `${v.name} (copy)`,
                                    }))
                                }
                                onRemove={poolActionWrapper(pools.remove, pool.key, pool.api.id.value)}
                            />
                        ))}
                        <Box row gap={10}>
                            <Button label='Add pool' onClick={() => pools.add()} />
                        </Box>
                    </Box>
                </FormSection>
            </SlideoutContent>

            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
                <SubmitButton label='Save' disabled={!canUpdate} />
            </SlideoutFooter>
        </Form>
    );
};

interface AddonConstraintsFormsProps extends PropsWithChildren {
    constraints: AddonConstraint[];
    arrayField: ArrayFieldRecord<AddonConstraintDto>;
    onDuplicate(): void;
    onRemove(): void;
}

const AddonConstraintsForms: FC<AddonConstraintsFormsProps> = ({
    constraints,
    arrayField,
    onDuplicate,
    onRemove,
    children,
}) => {
    const trContractType = useTrContractType();
    const trRentalTerm = useTrRentalTerm();
    const trPaymentSchema = useTrPaymentSchema();
    const trProductParentCategory = useTrParentProductCategory();
    const trProductCategory = useTrProductCategory();
    const trProductBrand = useTrProductBrand();
    const trPlatformTier = useTrPlatformTier();

    useEffect(() => {
        if (arrayField.api.contractType.value !== ContractType.Rental) {
            arrayField.api.rentalTerm.setValue(null);
        }
    }, [arrayField.api.contractType.value]);

    return (
        <Box
            row
            gap={10}
            alignItems='flex-start'
            justify='space-between'
            borderTop
            borderColor={Color.InteractionDefaultSubtleBorder}
            paddingVertical={12}
            fullWidth
        >
            <Box row gap={10} wrap alignItems='flex-start'>
                {constraints.includes(AddonConstraint.ContractType) && (
                    <FormSelect
                        label={getAddonConstraintLabel(AddonConstraint.ContractType)}
                        options={allContractTypes}
                        api={arrayField.api.contractType}
                        toText={trContractType}
                        width={160}
                        nullable
                    />
                )}
                {constraints.includes(AddonConstraint.RentalTerm) && (
                    <FormSelect
                        label={getAddonConstraintLabel(AddonConstraint.RentalTerm)}
                        options={allRentalTerms}
                        api={arrayField.api.rentalTerm}
                        toText={trRentalTerm}
                        width={160}
                        disabled={arrayField.api.contractType.value !== ContractType.Rental}
                        nullable
                    />
                )}
                {constraints.includes(AddonConstraint.Durations) && (
                    <MultiSelectDropdown
                        label={getAddonConstraintLabel(AddonConstraint.Durations)}
                        items={Array.from({ length: 36 }, (_, i) => i + 1)}
                        labelFormatter={String}
                        value={arrayField.api.durations.value ?? []}
                        onChange={values =>
                            arrayField.api.durations.setValue(isEmpty(values) ? null : values)
                        }
                        width={220}
                        clearable
                    />
                )}
                {constraints.includes(AddonConstraint.PaymentSchemas) && (
                    <MultiSelectDropdown
                        label={getAddonConstraintLabel(AddonConstraint.PaymentSchemas)}
                        items={allPaymentSchemas}
                        labelFormatter={trPaymentSchema}
                        value={arrayField.api.paymentSchemas.value ?? []}
                        onChange={values =>
                            arrayField.api.paymentSchemas.setValue(isEmpty(values) ? null : values)
                        }
                        width={220}
                        clearable
                    />
                )}
                {constraints.includes(AddonConstraint.ProductParentCategories) && (
                    <MultiSelectDropdown
                        label={getAddonConstraintLabel(AddonConstraint.ProductParentCategories)}
                        items={allParentProductCategories}
                        labelFormatter={trProductParentCategory}
                        value={arrayField.api.productParentCategories.value ?? []}
                        onChange={values =>
                            arrayField.api.productParentCategories.setValue(isEmpty(values) ? null : values)
                        }
                        width={220}
                        clearable
                    />
                )}
                {constraints.includes(AddonConstraint.ProductCategories) && (
                    <MultiSelectDropdown
                        label={getAddonConstraintLabel(AddonConstraint.ProductCategories)}
                        items={allCategories.filter(
                            category =>
                                !arrayField.api.productParentCategories.value ||
                                arrayField.api.productParentCategories.value.some(p =>
                                    productCategoriesHierarchy[p].includes(category),
                                ),
                        )}
                        labelFormatter={trProductCategory}
                        value={arrayField.api.productCategories.value ?? []}
                        onChange={values =>
                            arrayField.api.productCategories.setValue(isEmpty(values) ? null : values)
                        }
                        width={330}
                        clearable
                    />
                )}
                {constraints.includes(AddonConstraint.ProductBrands) && (
                    <MultiSelectDropdown
                        label={getAddonConstraintLabel(AddonConstraint.ProductBrands)}
                        items={allProductBrands}
                        labelFormatter={trProductBrand}
                        value={arrayField.api.productBrands.value ?? []}
                        onChange={values =>
                            arrayField.api.productBrands.setValue(isEmpty(values) ? null : values)
                        }
                        width={330}
                        clearable
                    />
                )}
                {constraints.includes(AddonConstraint.AssetAgeMax) && (
                    <FormNumberInput
                        label={getAddonConstraintLabel(AddonConstraint.AssetAgeMax)}
                        api={arrayField.api.assetAgeMax}
                        nullable
                        width={220}
                    />
                )}
                {constraints.includes(AddonConstraint.PlatformTiers) && (
                    <MultiSelectDropdown
                        label={getAddonConstraintLabel(AddonConstraint.PlatformTiers)}
                        items={allPlatformTiers}
                        labelFormatter={trPlatformTier}
                        value={arrayField.api.platformTiers.value ?? []}
                        onChange={values =>
                            arrayField.api.platformTiers.setValue(isEmpty(values) ? null : values)
                        }
                        width={330}
                        clearable
                    />
                )}

                {children}
            </Box>
            <Box row gap={10} marginTop={40} minWidth={60}>
                <IconButton icon={SvgIcon.Copy} onClick={onDuplicate} color={Color.ContentSecondary} />
                <IconButton icon={SvgIcon.Trash} onClick={onRemove} color={Color.ContentNegative} />
            </Box>
        </Box>
    );
};

interface AddonDataFormsProps extends PropsWithChildren {
    durations: number[];
    paymentSchemas: PaymentSchema[];
    productCategories: ProductCategory[];
    platformTiers: PlatformTier[];
    data: AddonDataDependency[];
    arrayField: ArrayFieldRecord<AddonDataDependenciesDto>;
    onDuplicate?(): void;
    onRemove?(): void;
}

const AddonDataForms: FC<AddonDataFormsProps> = ({
    durations,
    paymentSchemas,
    productCategories,
    platformTiers,
    data,
    arrayField,
    onDuplicate,
    onRemove,
    children,
}) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trProductCategory = useTrProductCategory();
    const trPlatformTier = useTrPlatformTier();

    return (
        <Box
            row
            gap={10}
            alignItems='flex-start'
            justify='space-between'
            borderTop
            borderColor={Color.InteractionDefaultSubtleBorder}
            paddingVertical={12}
            fullWidth
        >
            <Box row gap={10} wrap alignItems='flex-start'>
                {data.includes(AddonDataDependency.Durations) && (
                    <MultiSelectDropdown
                        label={getAddonDataDependencyLabel(AddonDataDependency.Durations)}
                        items={durations}
                        labelFormatter={String}
                        value={arrayField.api.durations.value ?? []}
                        onChange={values =>
                            arrayField.api.durations.setValue(isEmpty(values) ? null : values)
                        }
                        width={220}
                        clearable
                    />
                )}
                {data.includes(AddonDataDependency.PaymentSchemas) && (
                    <MultiSelectDropdown
                        label={getAddonDataDependencyLabel(AddonDataDependency.PaymentSchemas)}
                        items={paymentSchemas}
                        labelFormatter={trPaymentSchema}
                        value={arrayField.api.paymentSchemas.value ?? []}
                        onChange={values =>
                            arrayField.api.paymentSchemas.setValue(isEmpty(values) ? null : values)
                        }
                        width={330}
                        clearable
                    />
                )}
                {data.includes(AddonDataDependency.ProductCategories) && (
                    <MultiSelectDropdown
                        label={getAddonDataDependencyLabel(AddonDataDependency.ProductCategories)}
                        items={productCategories}
                        labelFormatter={trProductCategory}
                        value={arrayField.api.productCategories.value ?? []}
                        onChange={values =>
                            arrayField.api.productCategories.setValue(isEmpty(values) ? null : values)
                        }
                        width={330}
                        clearable
                    />
                )}
                {data.includes(AddonDataDependency.AssetValue) && (
                    <FormPriceInput
                        label={`${getAddonDataDependencyLabel(AddonDataDependency.AssetValue)} from`}
                        api={arrayField.api.assetValue}
                        defaultCurrency={Currency.USD}
                        nullable
                        width={220}
                    />
                )}
                {data.includes(AddonDataDependency.PlatformTiers) && (
                    <MultiSelectDropdown
                        label={getAddonDataDependencyLabel(AddonDataDependency.PlatformTiers)}
                        items={platformTiers}
                        labelFormatter={trPlatformTier}
                        value={arrayField.api.platformTiers.value ?? []}
                        onChange={values =>
                            arrayField.api.platformTiers.setValue(isEmpty(values) ? null : values)
                        }
                        width={330}
                        clearable
                    />
                )}
                {children}
            </Box>
            <Box row gap={10} marginTop={40} minWidth={60}>
                <IconButton icon={SvgIcon.Copy} onClick={onDuplicate} color={Color.ContentSecondary} />
                <IconButton icon={SvgIcon.Trash} onClick={onRemove} color={Color.ContentNegative} />
            </Box>
        </Box>
    );
};

interface AddonPoolFormsProps {
    addonTarget: AddonTarget;
    addonServices: AddonItem[];
    durations: number[];
    paymentSchemas: PaymentSchema[];
    productCategories: ProductCategory[];
    platformTiers: PlatformTier[];
    pool: ArrayFieldRecord<AddonPoolDataDto>;
    onDuplicate?(): void;
    onRemove?(): void;
}

const AddonPoolsForms: FC<AddonPoolFormsProps> = ({
    addonTarget,
    addonServices,
    durations,
    paymentSchemas,
    productCategories,
    platformTiers,
    pool,
    onDuplicate,
    onRemove,
}) => {
    const trAddonItem = useTrAddonItem();

    return (
        <Box column gap={20} padding={20} rounded border bg={Color.BackgroundSubtleNeutral}>
            <FormGridRow columns={2}>
                <FormInput label='Name' api={pool.api.name} />
                <Box column gap={8}>
                    <MultiSelectDropdown
                        label='Services'
                        items={addonServices}
                        labelFormatter={trAddonItem}
                        value={pool.api.services.value}
                        onChange={services => pool.api.services.setValue(services)}
                    />
                    {pool.api.services.error && pool.api.services.errorMessage && (
                        <LabelError message={pool.api.services.errorMessage} />
                    )}
                </Box>
            </FormGridRow>
            <AddonPoolDataForms
                addonTarget={addonTarget}
                durations={durations}
                paymentSchemas={paymentSchemas}
                productCategories={productCategories}
                platformTiers={platformTiers}
                pool={pool}
            />
            <Box row gap={10} minWidth={60}>
                {onDuplicate && <Button label='Duplicate pool' onClick={onDuplicate} type='secondary' />}
                {onRemove && (
                    <Button label='Delete pool' onClick={onRemove} type='secondary' action='destructive' />
                )}
            </Box>
        </Box>
    );
};

interface AddonPoolDataFormsProps {
    addonTarget: AddonTarget;
    durations: number[];
    paymentSchemas: PaymentSchema[];
    productCategories: ProductCategory[];
    platformTiers: PlatformTier[];
    pool: ArrayFieldRecord<AddonPoolDataDto>;
}

const AddonPoolDataForms: FC<AddonPoolDataFormsProps> = ({
    addonTarget,
    durations,
    paymentSchemas,
    productCategories,
    platformTiers,
    pool,
}) => {
    const form = useAddonPoolForm({
        addonTarget,
        quantities: pool.api.quantities.value,
        multipliers: pool.api.multipliers.value,
    });

    const quantities = useFormArrayField(form.fields.quantities, {
        ...emptyDependenciesPayload,
        quantity: '0',
    });

    useEffect(() => {
        pool.api.quantities.setValue(
            form.values.quantities.map(quantity => ({
                ...quantity,
                ...getSanitizedAddonDataDependencyValues(
                    addonTarget,
                    quantity,
                    form.values.quantityDependencies,
                ),
            })),
        );
    }, [form.values.quantities]);

    const multipliers = useFormArrayField(form.fields.multipliers, {
        ...emptyDependenciesPayload,
        multiplier: '1',
    });

    useEffect(() => {
        pool.api.multipliers.setValue(
            form.values.multipliers.map(multiplier => ({
                ...multiplier,
                ...getSanitizedAddonDataDependencyValues(
                    addonTarget,
                    multiplier,
                    form.values.multiplierDependencies,
                ),
            })),
        );
    }, [form.values.multipliers]);

    useEffect(() => {
        if (addonTarget === AddonTarget.Organization) {
            pool.api.isRatio.setValue(false);
        }
    }, [addonTarget]);

    return (
        <Box column gap={30} padding={20} rounded border bg={Color.BackgroundDefault}>
            <FormSection label='Generated token quantities'>
                <FormGridRow columns={4}>
                    <Labeled
                        label='Quantity type'
                        content={
                            <RadioGroup<'fixed' | 'ratio'>
                                orientation='horizontal'
                                gap={10}
                                items={['fixed', 'ratio']}
                                onChange={v => pool.api.isRatio.setValue(v === 'ratio')}
                                value={pool.api.isRatio.value ? 'ratio' : 'fixed'}
                                labelFormatter={option => <Paragraph3>{capitalize(option)}</Paragraph3>}
                                disabled={addonTarget === AddonTarget.Organization}
                            />
                        }
                    />
                    <Labeled
                        label='Generation rate'
                        content={
                            <RadioGroup<'single allocation' | 'per month'>
                                orientation='horizontal'
                                gap={10}
                                items={['single allocation', 'per month']}
                                onChange={v => pool.api.isMonthly.setValue(v === 'per month')}
                                value={pool.api.isMonthly.value ? 'per month' : 'single allocation'}
                                labelFormatter={option => <Paragraph3>{capitalize(option)}</Paragraph3>}
                                width={300}
                            />
                        }
                    />
                </FormGridRow>
                <FormCheckboxGroup
                    label='Dependencies'
                    options={getAddonDataDependenciesByTarget(addonTarget)}
                    toKey={identity}
                    toLabel={getAddonDataDependencyLabel}
                    api={form.fields.quantityDependencies}
                    horizontal
                />
                <Box column gap={10} alignItems='flex-start' overflow='auto'>
                    {quantities.fields.map(quantity => (
                        <Box column fullWidth key={quantity.key}>
                            <AddonDataForms
                                durations={durations}
                                paymentSchemas={paymentSchemas}
                                productCategories={productCategories}
                                platformTiers={platformTiers}
                                data={form.values.quantityDependencies}
                                arrayField={quantity}
                                onDuplicate={() => quantities.duplicate(quantity.key)}
                                onRemove={() => quantities.remove(quantity.key)}
                            >
                                <FormDecimalInput
                                    label='Quantity*'
                                    api={quantity.api.quantity}
                                    precision={5}
                                    unit={pool.api.isRatio.value ? '%' : '#'}
                                    width={220}
                                />
                            </AddonDataForms>
                            <Paragraph4 paddingLeft={10}>
                                {pool.api.isRatio.value && pool.api.isMonthly.value && (
                                    <>
                                        *{(decimalToNumber(quantity.api.quantity.value) * 36).toFixed(2)}% of
                                        total assets over 3 years, or{' '}
                                        {Math.round(decimalToNumber(quantity.api.quantity.value) * 36)}{' '}
                                        token(s) for 100 assets over 3 years
                                    </>
                                )}
                                {pool.api.isRatio.value && !pool.api.isMonthly.value && (
                                    <>
                                        *{decimalToNumber(quantity.api.quantity.value).toFixed(2)}% of total
                                        assets in total, or{' '}
                                        {Math.round(decimalToNumber(quantity.api.quantity.value))} token(s)
                                        for 100 assets in total
                                    </>
                                )}
                                {!pool.api.isRatio.value && pool.api.isMonthly.value && (
                                    <>
                                        *{Math.round(decimalToNumber(quantity.api.quantity.value) * 36)}{' '}
                                        token(s) over 3 years
                                    </>
                                )}
                                {!pool.api.isRatio.value && !pool.api.isMonthly.value && (
                                    <>
                                        *{Math.round(decimalToNumber(quantity.api.quantity.value))} token(s)
                                        in total
                                    </>
                                )}
                            </Paragraph4>
                        </Box>
                    ))}
                    {pool.api.quantities.shouldShowError() && isString(pool.api.quantities.error) && (
                        <Alert type='negative' description={pool.api.quantities.error} />
                    )}
                    <Box row gap={10}>
                        <Button label='Add quantity' type='secondary' onClick={() => quantities.add()} />
                        {quantities.fields.length > 0 && (
                            <Button
                                label='Clear all'
                                type='secondary'
                                action='destructive'
                                onClick={() => quantities.clear()}
                            />
                        )}
                    </Box>
                </Box>
            </FormSection>

            <FormSection label='Consumed token multipliers'>
                <FormCheckboxGroup
                    label='Dependencies'
                    options={getAddonDataDependenciesByTarget(addonTarget)}
                    toKey={identity}
                    toLabel={getAddonDataDependencyLabel}
                    api={form.fields.multiplierDependencies}
                    horizontal
                />
                <Box column gap={10} alignItems='flex-start' overflow='auto'>
                    {multipliers.fields.map(multiplier => (
                        <AddonDataForms
                            key={multiplier.key}
                            durations={durations}
                            paymentSchemas={paymentSchemas}
                            productCategories={productCategories}
                            platformTiers={platformTiers}
                            data={form.values.multiplierDependencies}
                            arrayField={multiplier}
                            onDuplicate={() => multipliers.duplicate(multiplier.key)}
                            onRemove={() => multipliers.remove(multiplier.key)}
                        >
                            <FormDecimalInput
                                label='Multiplier'
                                api={multiplier.api.multiplier}
                                precision={5}
                                width={220}
                            />
                        </AddonDataForms>
                    ))}
                    {pool.api.multipliers.shouldShowError() && isString(pool.api.multipliers.error) && (
                        <Alert type='negative' description={pool.api.multipliers.error} />
                    )}
                    <Box row gap={10}>
                        <Button label='Add multiplier' type='secondary' onClick={() => multipliers.add()} />
                        {multipliers.fields.length > 0 && (
                            <Button
                                label='Clear all'
                                type='secondary'
                                action='destructive'
                                onClick={() => multipliers.clear()}
                            />
                        )}
                    </Box>
                </Box>
            </FormSection>
        </Box>
    );
};
