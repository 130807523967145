import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AdminUserFilters } from '../service/types/AdminUserFilters';
import { UserDto } from '../service/types/UserDto';
import { userService } from '../service/userService';
import { usersCacheKey } from './cacheKey';

export const useUsersQuery = (filters: AdminUserFilters) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [usersCacheKey, filters],
        queryFn: param =>
            userService.listUsers(filters, {
                page: param.pageParam,
                pageSize: 30,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const adminUsers: UserDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        adminUsers,
        adminUsersIsLoading: isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
