import { isEmpty } from 'lodash';
import React, { FC } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { FormDropdown, FormInput } from '@hofy/common';
import { coreCurrencies } from '@hofy/global';
import { errorMap } from '@hofy/helpers';
import { useTrCurrency } from '@hofy/i18n';
import {
    Alert,
    FormContainer,
    FormModal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
    useForm,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { useAdminForkBillingEntity } from '../../../../store/invoicing/billingEntities/useAdminForkBillingEntity';

interface ForkBillingEntityModalProps {
    billingEntity: BillingEntityDto;

    onClose(): void;
}

export const ForkBillingEntityModal: FC<ForkBillingEntityModalProps> = ({ billingEntity, onClose }) => {
    const { forkBillingEntity, isLoading, isError } = useAdminForkBillingEntity(
        billingEntity.idDeprecated,
        onClose,
    );

    const trCurrency = useTrCurrency();
    const { tr } = useAdminI18n();

    const form = useForm({
        initial: {
            name: billingEntity.name,
            currency: billingEntity.currency,
        },
        validate: ({ name, currency }) => ({
            name: errorMap(
                [isEmpty(name), tr('billing-entity-page.fork-modal.validation.empty-name')],
                [name === billingEntity.name, tr('billing-entity-page.fork-modal.validation.same-name')],
            ),
            currency: errorMap([
                currency === billingEntity.currency,
                tr('billing-entity-page.fork-modal.validation.same-currency'),
            ]),
        }),
        onSubmit: forkBillingEntity,
        onDiscard: onClose,
    });

    return (
        <FormModal
            width={600}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='Update currency' borderBottom />
            <ModalContent>
                <Alert
                    marginBottom={16}
                    type='warning'
                    title={tr('billing-entity-page.fork-modal.title')}
                    description={tr('billing-entity-page.fork-modal.description')}
                />
                <FormContainer>
                    <FormInput
                        label={tr('billing-entity-page.fork-modal.name-field')}
                        value={form.values.name}
                        onChangeText={name =>
                            form.setValues({
                                name: name ?? '',
                            })
                        }
                        nullable
                        isRequired
                        errorMessage={form.errors.name}
                        isError={!!form.errors.name}
                    />
                    <FormDropdown
                        items={coreCurrencies}
                        label={tr('billing-entity-page.currency-update-modal.currency-field')}
                        labelFormatter={trCurrency}
                        value={form.values.currency}
                        onChange={currency =>
                            form.setValues({
                                currency,
                            })
                        }
                        clearable={false}
                        isRequired
                        isError={form.errors.currency}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={form.discard} />
                <SubmitButton
                    label={tr('billing-entity-page.fork-modal.confirm')}
                    disabled={form.values.currency === billingEntity.currency}
                />
            </ModalFooter>
        </FormModal>
    );
};
