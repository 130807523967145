import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { SalesOrderDetailsDto } from './types/SalesOrderDetailsDto';
import { SalesOrderDto } from './types/SalesOrderDto';
import { SalesOrderFilters } from './types/SalesOrderFilters';

class SalesOrderService {
    public listSalesOrders = async (
        filters: SalesOrderFilters,
        page: PageRequest,
    ): Promise<PageResponse<SalesOrderDto>> => {
        return await restClient.getJson<PageResponse<SalesOrderDto>>(
            stringifyUrl({
                url: '/api/admin/sales-orders',
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public getSalesOrderDetails = async (id: number): Promise<SalesOrderDetailsDto> => {
        return restClient.getJson<SalesOrderDetailsDto>(
            stringifyUrl({
                url: `/api/admin/sales-orders/${id}`,
            }),
        );
    };

    public cancelOrder = async (id: number) => {
        return restClient.delete(
            stringifyUrl({
                url: `/api/admin/sales-orders/${id}`,
            }),
        );
    };

    public downloadSalesOrderFile = async (id: number): Promise<void> => {
        await restClient
            .downloadFile(`/api/admin/sales-orders/${id}/download-pdf`)
            .then(downloadFileFromResponse);
    };
}

export const salesOrderService = new SalesOrderService();
