import React, { FC } from 'react';

import { PublicSSO } from '@hofy/api-auth';
import { Box, BoxProps } from '@hofy/ui';

import { PublicSSOButton } from './PublicSSOButton';

interface PublicSSOButtonsProps extends BoxProps {
    onClick(sso: PublicSSO): void;
    mode: 'sign-in' | 'sign-up';
}

export const PublicSSOButtons: FC<PublicSSOButtonsProps> = ({ onClick, mode }) => {
    return (
        <Box column gap={24}>
            <PublicSSOButton onClick={() => onClick(PublicSSO.Google)} mode={mode} type={PublicSSO.Google} />
            <PublicSSOButton
                onClick={() => onClick(PublicSSO.Microsoft)}
                mode={mode}
                type={PublicSSO.Microsoft}
            />
        </Box>
    );
};
