import { isEqual, xorWith } from 'lodash';
import { useState } from 'react';

export const useShipmentsSelected = () => {
    const [shipmentSelectedIds, setShipmentSelectedIds] = useState<number[]>([]);

    const handlerAddShipmentId = (shipmentId: number) => {
        setShipmentSelectedIds(xorWith([...shipmentSelectedIds], [shipmentId], isEqual));
    };

    return {
        shipmentSelectedIds,
        handlerAddShipmentId,
        setShipmentSelectedIds,
    };
};
