import { useMutation, useQueryClient } from '@tanstack/react-query';

import { itemCacheKey, ItemCodePayload, ItemCodesPayload, itemsService, useItemQuery } from '@hofy/api-admin';
import { isRequired, useForm, useToast, validateArrayField, validator } from '@hofy/ui';

export const useUpdateItemCode = (
    itemId: number,
    onSuccess: (code: string) => void,
    onDiscard: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const { data: item } = useItemQuery(itemId);

    const mutation = useMutation({
        mutationFn: (v: ItemCodesPayload) => itemsService.updateItemCodes(itemId, v),
        onSuccess: (_, v: ItemCodesPayload) => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            showToast({
                type: 'positive',
                message: 'Assigned new code successfully',
            });
            onSuccess(v ? v.codes[0].code : '');
        },
    });

    const form = useForm<ItemCodesPayload>({
        initial: {
            codes: (item?.itemCodes || []).map(v => ({ code: v.code })),
        },
        initialDeps: [item],
        onSubmit: mutation.mutate,
        onDiscard: onDiscard,
        validate: validator<ItemCodesPayload>({
            codes: validateArrayField<ItemCodesPayload, 'codes'>({
                fieldsValidator: validator<ItemCodePayload>({
                    code: isRequired('Item code is required'),
                }),
            }),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
