import React, { FC } from 'react';

import { useSignIn } from '../../store/auth/useSignIn';
import { SignInForm } from './SignInForm';

export const SignInPage: FC = () => {
    const { signInForm, signInError, signInWithPublicSSO, signInIsLoading, brokeringIsLoading, authStage } =
        useSignIn();

    return (
        <SignInForm
            authStage={authStage}
            form={signInForm}
            authError={signInError}
            signInWithPublicSSO={sso => signInWithPublicSSO(sso, null)}
            isLoading={signInIsLoading || brokeringIsLoading}
        />
    );
};
