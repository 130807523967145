import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { Page } from '@hofy/common';
import { EnumRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { AdminInvoicingTab, allInvoicingTabs } from '../../store/invoicing/types/AdminInvoicingTab';
import { BillingEntitiesRouter } from './billingEntities/BillingEntitiesRouter';
import { InvoiceEntriesRouter } from './invoiceEntries/InvoiceEntriesRouter';
import { InvoicesRouter } from './invoices/InvoicesRouter';
import { RepaymentPlansRouter } from './repaymentPlans/RepaymentPlansRouter';
import { SalesOrdersRouter } from './salesOrders/SalesOrdersRouter';

const allInvoicingTabsParams = allInvoicingTabs.join('|');

export const InvoicingRouter: FC = () => {
    const history = useHistory();

    const invoicingPath = AdminNavLink.Invoicing;

    const handleTabChange = (tab: AdminInvoicingTab) => {
        history.push(`${invoicingPath}/${tab}`);
    };

    const { hasPermission } = useSession();

    const permissions = {
        [AdminInvoicingTab.Invoices]: hasPermission(Permission.AdminInvoicesView),
        [AdminInvoicingTab.InvoiceEntries]: hasPermission(Permission.AdminInvoicesEntriesView),
        [AdminInvoicingTab.RepaymentPlans]: hasPermission(Permission.AdminRepaymentPlanView),
        [AdminInvoicingTab.BillingEntities]: hasPermission(Permission.AdminBillingEntityView),
        [AdminInvoicingTab.SalesOrders]: hasPermission(Permission.AdminSalesOrdersView),
    };
    const invoicingTabs = allInvoicingTabs.filter(tab => permissions[tab]);

    const content = (tab: AdminInvoicingTab) => {
        switch (tab) {
            case AdminInvoicingTab.Invoices:
                return <InvoicesRouter tab={tab} tabs={invoicingTabs} onChangeTab={handleTabChange} />;
            case AdminInvoicingTab.InvoiceEntries:
                return <InvoiceEntriesRouter tab={tab} tabs={invoicingTabs} onChangeTab={handleTabChange} />;
            case AdminInvoicingTab.RepaymentPlans:
                return <RepaymentPlansRouter tab={tab} tabs={invoicingTabs} onChangeTab={handleTabChange} />;
            case AdminInvoicingTab.BillingEntities:
                return <BillingEntitiesRouter tab={tab} tabs={invoicingTabs} onChangeTab={handleTabChange} />;
            case AdminInvoicingTab.SalesOrders:
                return <SalesOrdersRouter tab={tab} tabs={invoicingTabs} onChangeTab={handleTabChange} />;
        }
    };

    return (
        <Page>
            <Switch>
                <InvoicingTabRoute path={`${invoicingPath}/:tab(${allInvoicingTabsParams})`}>
                    {({ tab }) => content(tab)}
                </InvoicingTabRoute>
                <Redirect to={`${invoicingPath}/${allInvoicingTabs[0]}`} from={invoicingPath} />
            </Switch>
        </Page>
    );
};

const InvoicingTabRoute = EnumRoute<AdminInvoicingTab>('tab', AdminInvoicingTab, Route);
