import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService } from '@hofy/api-admin';
import { UpdateOtdReason } from '@hofy/api-shared';
import { useToast } from '@hofy/ui';

interface UpdateOtdPayload {
    otd: string;
    reason: UpdateOtdReason;
}

export const useAdminAssignmentOtd = (assignmentId: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({ otd, reason }: UpdateOtdPayload) =>
            assignmentService.updateOtd(assignmentId, otd, reason),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'OTD updated',
            });
        },
    });

    const updateAssignmentOTD = (otd: string | null, reason: UpdateOtdReason) => {
        if (!otd) {
            return;
        }

        mutation.mutate({ otd, reason });
    };

    return {
        updateAssignmentOTD,
    };
};
