import { useMutation, useQueryClient } from '@tanstack/react-query';

import { salesOrderService } from '../service/salesOrderService';
import { salesOrdersQueryKey } from './salesOrderQueryKey';

export const useCancelSalesOrder = (id: number, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => salesOrderService.cancelOrder(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [salesOrdersQueryKey, id] });
            onSuccess();
        },
    });

    return {
        cancelOrder: mutation.mutate,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
