import React, { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkDirective from 'remark-directive';
import remarkGfm from 'remark-gfm';

import {
    Box,
    Em,
    Heading2,
    Heading3,
    Li,
    Link,
    Ol,
    OuterBoxProps,
    Paragraph3,
    SectionTitle1,
    SectionTitle2,
    SectionTitle3,
    Strong,
    Table,
    Ul,
} from '@hofy/ui';

import { remarkYouTubeDirective } from './plugins/remarkYouTubeDirective';

interface MarkdownProps extends OuterBoxProps {
    children: string;
}

const allowedHostnames = ['www.youtube.com'];

const components: Components = {
    h1: Heading2,
    h2: Heading3,
    h3: SectionTitle1,
    h4: SectionTitle2,
    h5: SectionTitle3,

    p: ({ children }) => <Paragraph3 wordBreak>{children}</Paragraph3>,

    strong: Strong,
    b: Strong,
    em: Em,
    i: Em,

    ul: Ul,
    ol: Ol,
    li: ({ children }) => <Li wordBreak>{children}</Li>,

    table: Table as any,
    thead: 'thead',
    tbody: 'tbody',
    tr: 'tr',
    td: 'td',
    th: 'th',

    br: 'br',

    iframe: ({ src }) => {
        if (!src) {
            return null;
        }

        const url = new URL(src);

        if (!allowedHostnames.includes(url.hostname)) {
            return null;
        }

        return (
            <Box
                as='iframe'
                width='100%'
                height='auto'
                rounded={8}
                overflow='hidden'
                style={{ aspectRatio: '16 / 9' }}
                src={src}
                allowFullScreen
            />
        );
    },

    a: ({ href = '/', children }) => (
        <Link to={href} inline>
            {children}
        </Link>
    ),
};

const allowedTags = Object.keys(components);
export const Markdown: FC<MarkdownProps> = ({ children, ...rest }) => {
    return (
        <Box column gap={16} {...rest}>
            <ReactMarkdown
                components={components}
                allowElement={el => allowedTags.includes(el.tagName)}
                remarkPlugins={[
                    //
                    remarkGfm,
                    remarkBreaks,
                    remarkDirective,
                    remarkYouTubeDirective,
                ]}
            >
                {children}
            </ReactMarkdown>
        </Box>
    );
};
