import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

import { getInspectionsLink } from '../../../components/routing/adminLinks';
import { InspectionsTab } from '../../../store/inspections/inspectionsTab';
import { InspectionTab } from '../../../store/inspections/InspectionTab';

interface InspectionsPageContext {
    tab: InspectionsTab;
}

const InspectionsPageCtx = createContext<InspectionsPageContext | null>(null);

export const InspectionsPageContextProvider: FC<PropsWithChildren<InspectionsPageContext>> = ({
    tab,
    children,
}) => {
    const value = useMemo(() => ({ tab }), [tab]);

    return <InspectionsPageCtx.Provider value={value}>{children}</InspectionsPageCtx.Provider>;
};

const useInspectionsPageContext = (): InspectionsTab => {
    const ctx = useContext(InspectionsPageCtx);

    if (ctx === null) {
        throw new Error('useInspectionsPageContext should be called within InspectionsPageContextProvider');
    }

    return ctx.tab;
};

export const useInspectionsPageLinks = () => {
    const { location } = useHistory();

    const tab = useInspectionsPageContext();
    const base = getInspectionsLink(tab);

    const link = useCallback((path: string) => `${path}${location.search}`, [location.search]);

    const getInspectionLink = useCallback((uuid: UUID) => link(`${base}/inspection/${uuid}`), [link, base]);
    const getDeviceCheckLink = useCallback(
        (uuid: UUID) => link(`${base}/inspection/${uuid}/${InspectionTab.DeviceCheck}`),
        [link, base],
    );
    const getShipmentLink = useCallback((id: number) => link(`${base}/shipment/${id}`), [link, base]);
    const getAssignmentLink = useCallback((id: number) => link(`${base}/assignment/${id}`), [link, base]);
    const getItemLink = useCallback((id: number) => link(`${base}/item/${id}`), [link, base]);

    return {
        getInspectionLink,
        getDeviceCheckLink,
        getShipmentLink,
        getAssignmentLink,
        getItemLink,
    };
};
