import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    CancelShipmentPayload,
    ShipmentDetailsDto,
    shipmentService,
} from '@hofy/api-admin';
import { errorMap, isEmpty, nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

const validateCancelShipmentForm = (formState: CancelShipmentPayload) => {
    return {
        canceledOn: errorMap([isEmpty(formState.canceledOn), 'Cancel date is required']),
    };
};

export const useCancelShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, CancelShipmentPayload>({
        mutationFn: payload => shipmentService.cancelShipment(shipment.idDeprecated, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            onSuccess?.();
        },
    });

    const form = useForm<CancelShipmentPayload>({
        initial: {
            canceledOn: nowISODate(),
        },
        onSubmit: mutation.mutate,
        validate: validateCancelShipmentForm,
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
