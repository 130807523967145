import { noop } from 'lodash';
import React, { FC } from 'react';

import {
    PurchaseOrderType,
    useVariantsWithProductAndPrice,
    VariantWithProductAndPriceMap,
} from '@hofy/api-admin';
import { SeparatedContainer } from '@hofy/common';
import { Country, Currency } from '@hofy/global';
import { ProductImage } from '@hofy/product';
import {
    ArrayField,
    Box,
    Button,
    FormFieldRecord,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    IconButton,
    LabeledInput,
    Placeholder,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';

import {
    BYODOrderFormItemData,
    emptyBYODOrderItemFormData,
} from '../../../../store/byodOrders/types/BYODOrderFormData';
import { VariantSelect } from './components/VariantDropdown';

interface ItemsFormProps {
    items: ArrayField<BYODOrderFormItemData>;
    currency: Currency;
}

export const BYODItemsForm: FC<ItemsFormProps> = ({ items, currency }) => {
    // TODO: use a simpler product query without a price calculations, or country multiplier
    const { isLoading, variantsById } = useVariantsWithProductAndPrice(
        {
            country: Country.UnitedKingdom,
            // country: form.warehouseId?.address?.country!, // country is used to apply multiplier
            // currency: form.values.currency,  // converts variant prices to this currency
            currency: Currency.GBP,
            purchaseOrderType: PurchaseOrderType.Warehouse, // multiplies price * .91 for warehouse orders
        },
        true,
    );

    if (isLoading) {
        return (
            <Placeholder
                illustration={SvgIllustration.List}
                title='Fetching variants'
                message='Please wait'
            />
        );
    }

    return (
        <FormSection label='Products' column>
            {items.fields.length && (
                <SeparatedContainer lined orientation='vertical' spacing={20}>
                    {items.fields.map(field => (
                        <ProductRowForm
                            key={field.key}
                            item={field.api}
                            variantsById={variantsById}
                            onDeleteItem={() => items.remove(field.key)}
                            canBeDeleted={items.fields.length > 1}
                        />
                    ))}
                </SeparatedContainer>
            )}
            <Button
                label='Add new item'
                type='secondary'
                leftIcon={SvgIcon.Add}
                onClick={() => items.add(emptyBYODOrderItemFormData(currency))}
            />
        </FormSection>
    );
};

interface ProductRowFormProps {
    item: FormFieldRecord<BYODOrderFormItemData>;
    variantsById: VariantWithProductAndPriceMap;
    canBeDeleted: boolean;
    onDeleteItem(): void;
}

const ProductRowForm: FC<ProductRowFormProps> = ({ item, variantsById, canBeDeleted, onDeleteItem }) => {
    return (
        <Box flex='auto' gap={20}>
            <ProductImage
                image={item.variantId.value ? variantsById[item.variantId.value].image : null}
                size={155}
            />
            <Box flex={1}>
                <VariantSelect api={item.variantId} marginVertical={10} />
                <LabeledInput
                    value={item.variantId.value ? variantsById[item.variantId.value].productName : null}
                    label='Product'
                    disabled
                    nullable
                    onChange={noop}
                />
            </Box>
            <Box flex={1}>
                <FormNumberInput api={item.expectedQuantity} label='Quantity' marginVertical={10} />
                <FormPriceInput api={item.unitPurchasePrice} label='Unit purchase price' />
            </Box>

            <Box alignItems='flex-end'>
                <IconButton icon={SvgIcon.Trash} onClick={onDeleteItem} disabled={!canBeDeleted} />
            </Box>
        </Box>
    );
};
