import { useMutation } from '@tanstack/react-query';

import { salesOrderService } from '@hofy/api-admin';

export const useDownloadSalesOrder = () => {
    const mutation = useMutation({
        mutationFn: salesOrderService.downloadSalesOrderFile,
    });

    const download = (salesOrderId: number) => {
        mutation.mutate(salesOrderId);
    };

    return {
        download,
        isDownloadSalesOrderLoading: mutation.isPending,
    };
};
