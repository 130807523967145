import React, { FC, memo } from 'react';

import { ItemValuationFilters, useItemValuationListQuery } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { InfinityScrollTable, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import { ItemStatusChip } from '../../../../components/domain/items/ItemStatusChip';
import { ItemLocationCell } from '../../../itemsPage/components/ItemLocationCell';
import { ItemDetailsLink } from '../../../itemsPage/ItemDetailsLink';

interface ItemValuationTableProps {
    filters: ItemValuationFilters;
}

const ItemValuationTableComponent: FC<ItemValuationTableProps> = ({ filters }) => {
    const { itemValuations, itemValuationIsLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useItemValuationListQuery(filters);
    const { formatPrice } = usePrice();
    return (
        <InfinityScrollTable
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.ContractSearch}
                    title='No item valuations'
                    message='No item valuations for selected criteria'
                />
            }
            flex='auto'
            data={itemValuations}
            toKey={itemValuation => itemValuation.itemId}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: itemValuationIsLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            columns={[
                {
                    id: 'item',
                    header: 'Item',
                    flexGrow: 1,
                    renderer: itemValuation => (
                        <TextCell>
                            <ItemDetailsLink
                                id={itemValuation.itemId}
                            >{`#${itemValuation.itemPublicId}`}</ItemDetailsLink>
                        </TextCell>
                    ),
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    renderer: itemValuation => <ItemStatusChip status={itemValuation.itemStatus} />,
                },
                {
                    id: 'hofySubsidiary',
                    header: 'Hofy subsidiary',
                    flexGrow: 1,
                    renderer: itemValuation => itemValuation.hofySubsidiary?.name || '--',
                },
                {
                    id: 'location',
                    header: 'Location',
                    flexGrow: 1,
                    renderer: itemValuation => <ItemLocationCell location={itemValuation.location} />,
                },
                {
                    id: 'cost',
                    header: 'Cost',
                    flexGrow: 1,
                    renderer: itemValuation => formatPrice(itemValuation.initialCost),
                },
                {
                    id: 'value',
                    header: 'Value',
                    flexGrow: 1,
                    renderer: itemValuation => formatPrice(itemValuation.currentValue),
                },
                {
                    id: 'stateOn',
                    header: 'State date',
                    flexGrow: 1,
                    renderer: itemValuation => formatDate(itemValuation.stateOn),
                },
            ]}
        />
    );
};

export const ItemValuationTable = memo(ItemValuationTableComponent);
