import React, { FC } from 'react';

import { UploadFile } from '@hofy/common';
import {
    FormContainer,
    FormModal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useUploadInvoice } from '../../../store/invoices/useUploadInvoice';

interface UploadInvoiceModalProps {
    onCancel(): void;
    invoiceId: number;
}

export const UploadInvoiceModal: FC<UploadInvoiceModalProps> = ({ onCancel, invoiceId }) => {
    const { form, isLoading, isError } = useUploadInvoice(invoiceId, onCancel);

    return (
        <FormModal
            width={420}
            onSubmit={form.submit}
            onClose={onCancel}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='Upload invoice' />
            <ModalContent>
                <FormContainer>
                    <Paragraph3 paddingBottom={10}>Choose file for invoice #{invoiceId} to upload</Paragraph3>
                    <UploadFile
                        label='Choose files to upload'
                        value={form.values.file}
                        accept='application/pdf'
                        note='Accepted files: .pdf'
                        onChange={file =>
                            form.setValues({
                                file,
                            })
                        }
                        disabled={isLoading}
                        isLoading={false}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={form.discard} />
                <SubmitButton label='Upload' />
            </ModalFooter>
        </FormModal>
    );
};
