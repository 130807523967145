import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { IntRoute } from '@hofy/router';

import { CreateRepairSlideout } from './CreateRepairSlideout';

interface CreateRepairSlideoutRouterProps {
    base: string;
    onClose(): void;
}

export const CreateRepairForItemSlideoutRouter: FC<CreateRepairSlideoutRouterProps> = ({ base, onClose }) => {
    return (
        <RepairIdRoute path={`${base}/:itemId(\\d+)`} exact>
            {({ itemId }) => <CreateRepairSlideout itemId={itemId} onClose={onClose} />}
        </RepairIdRoute>
    );
};

const RepairIdRoute = IntRoute('itemId', Route);
