import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { useGenerateInvoices } from '../../../../store/invoices/useGenerateInvoices';
import { GenerateInvoicesModal } from '../../invoices/components/GenerateInvoicesModal';

interface GenerateBillingEntityInvoicesModalProps {
    onClose(): void;
    billingEntityId: number;
}

export const GenerateBillingEntityInvoicesModal: FC<GenerateBillingEntityInvoicesModalProps> = ({
    billingEntityId,
    onClose,
}) => {
    const { generateInvoices, isLoading } = useGenerateInvoices(onClose);

    const handleSubmit = (invoiceDate: DateTime) => {
        generateInvoices({
            month: invoiceDate.toISODate()!,
            billingEntityId: billingEntityId,
        });
    };

    return <GenerateInvoicesModal onClose={onClose} isLoading={isLoading} onSubmit={handleSubmit} />;
};
