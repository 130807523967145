export enum AuthError {
    Archived = 'archived',
    BadCredentials = 'bad_credentials',
    DuplicateApiKey = 'duplicate_api_key',
    GoogleSsoNotAllowed = 'google_sso_not_allowed',
    IncorrectSsoConfig = 'incorrect_sso_config',
    IncorrectTwoFactorCode = 'incorrect_two_factor_code',
    InvalidApiKey = 'invalid_api_key',
    InvalidPassword = 'invalid_password',
    InvalidToken = 'invalid_token',
    MicrosoftSsoNotAllowed = 'microsoft_sso_not_allowed',
    MissingEmail = 'missing_email',
    NonSupportedEmail = 'non_supported_email',
    NoRoles = 'no_required_roles',
    NotFound = 'not_found',
    NotInvited = 'not_invited',
    NotVerified = 'not_verified',
    Offboarded = 'offboarded',
    RequiredSsoLogin = 'sso_login_required',
    SSONotAllowed = 'sso_not_allowed',
    TeamMemberInManagerOnlyOrg = 'team_member_in_manager_only_org',
    TwoFactorAlreadySet = 'two_factor_already_set',
    TwoFactorRequired = 'two_factor_required',
    Unauthorized = 'unauthorized',
    Unknown = 'unknown',
}

export const isAuthError = (v: any): v is AuthError => {
    return Object.values(AuthError).includes(v);
};

export const isTwoFactorAuthError = (v: any): boolean => {
    return v === AuthError.TwoFactorRequired;
};
