import { ItemGrade, ItemLocation, ItemStatus, Ownership, ProductCategory } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';

import { AdminItemFilter } from './AdminItemFilter';

export interface ItemsFilters {
    warehouseId: number | null;
    grade: ItemGrade[];
    organization: UUID[];
    search: string | null;
    category: ProductCategory[];
    variant: UUID[];
    redistributableIn: Country | null;
    status: ItemStatus[];
    location: ItemLocation[];
    filter: AdminItemFilter | null;
    ownership: Ownership | null;
}

export const emptyItemsFilters: ItemsFilters = {
    warehouseId: null,
    grade: [],
    organization: [],
    search: null,
    category: [],
    variant: [],
    redistributableIn: null,
    status: [],
    location: [],
    filter: null,
    ownership: null,
};
