import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useAddressValidator } from '@hofy/address';
import {
    AutoUpdateContractPayload,
    ContractDetailsDtoWithRental,
    contractsCacheKey,
    contractService,
    emptyAutoContractPayload,
    emptyContractPayload,
    getMainSubContract,
    invoiceEntriesQueryKey,
    RentalSubContractDto,
    UpdateContractPayload,
} from '@hofy/api-admin';
import { addressToPayloadWithDefault, AddressType } from '@hofy/api-shared';
import { useForm, validator } from '@hofy/ui';

import { ContractUpdateMode } from './types/ContractUpdateMode';
import { useManualContractForm } from './useManualContractForm';

const useAdminManualUpdateRentalContract = (
    contract: ContractDetailsDtoWithRental,
    mainSubContract: RentalSubContractDto | null,
    onClose: (id: number) => void,
    withCredit?: boolean,
) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (p: UpdateContractPayload) => {
            return withCredit
                ? contractService.updateContractWithCredit(contract?.idDeprecated!, p)
                : contractService.updateContract(contract?.idDeprecated!, p);
        },
        onSuccess: v => {
            onClose(v);
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
            queryClient.invalidateQueries({
                queryKey: [invoiceEntriesQueryKey, contract?.idDeprecated!],
            });
        },
    });

    const initialPayload = mainSubContract
        ? {
              billingToAddress: addressToPayloadWithDefault(
                  mainSubContract.billingToAddress,
                  AddressType.Billing,
              ),
              rentalTerm: contract.rentalTerm,
              basePrice: mainSubContract.basePrice,
              unitPrice: mainSubContract.unitPrice,
              rolloverPrice: contract.rentalContract?.rolloverPrice,
              price: mainSubContract.price,
              multiplier: mainSubContract.countryMultiplier,
              paymentSchema: mainSubContract.paymentSchema,
              customDiscount: mainSubContract.customDiscount,
              paymentDiscount: mainSubContract.paymentDiscount,
              discountReason: mainSubContract.discountReason,
              hofySubsidiaryId: mainSubContract.hofySubsidiary.id,
              billingEntityId: mainSubContract.billingEntity.id,
              addons: mainSubContract.addons,
          }
        : emptyContractPayload;

    const formState = useManualContractForm(initialPayload, mutation.mutate);

    return {
        isLoading: mutation.isPending,
        formState,
    };
};

const useAdminAutoUpdateRentalContract = (
    contract: ContractDetailsDtoWithRental,
    mainSubContract: RentalSubContractDto | null,
    onClose: (id: number) => void,
) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (p: AutoUpdateContractPayload) =>
            contractService.autoUpdateContract(contract?.idDeprecated!, p),
        onSuccess: v => {
            onClose(v);
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
            queryClient.invalidateQueries({
                queryKey: [invoiceEntriesQueryKey, contract?.idDeprecated!],
            });
        },
    });

    const initialPayload = mainSubContract
        ? {
              billingToAddress: addressToPayloadWithDefault(
                  mainSubContract.billingToAddress,
                  AddressType.Billing,
              ),
              rentalTerm: contract.rentalTerm!,
              paymentSchema: mainSubContract.paymentSchema,
          }
        : emptyAutoContractPayload;
    const validateAddress = useAddressValidator();
    const formState = useForm<AutoUpdateContractPayload>({
        initial: initialPayload,
        onSubmit: mutation.mutate,
        validate: validator<AutoUpdateContractPayload>({
            billingToAddress: validateAddress,
        }),
    });

    return {
        isLoading: mutation.isPending,
        formState,
    };
};

export const useUpdateRentalContract = (
    contract: ContractDetailsDtoWithRental,
    onClose: (id: number) => void,
) => {
    const [updateMode, setUpdateMode] = useState<ContractUpdateMode>(ContractUpdateMode.Manual);
    const mainSubContract = getMainSubContract(contract);
    const { formState: manualFormState, isLoading: manualIsLoading } = useAdminManualUpdateRentalContract(
        contract,
        mainSubContract,
        onClose,
        updateMode === ContractUpdateMode.WithCredit,
    );
    const { formState: autoFormState, isLoading: autoIsLoading } = useAdminAutoUpdateRentalContract(
        contract,
        mainSubContract,
        onClose,
    );

    const submit = () => {
        switch (updateMode) {
            case ContractUpdateMode.Manual:
            case ContractUpdateMode.WithCredit:
                return manualFormState.form.submit();
            case ContractUpdateMode.Auto:
                return autoFormState.submit();
        }
    };

    return {
        updateMode,
        setUpdateMode,
        contract,
        mainSubContract,
        manualFormState,
        autoFormState,
        isLoading: manualIsLoading || autoIsLoading,
        organizationId: contract.organization.id,
        submit,
    };
};
