import { keyBy } from 'lodash';
import React, { useMemo } from 'react';

import { NameAware } from '@hofy/helpers';

import { FormFieldApi } from '../../../hooks';
import { FormSelectSearch } from './FormSelectSearch';

interface FormNormalSelectProps<T extends NameAware> {
    api: FormFieldApi<number>;
    nullable?: false;
    options: T[];
    label: string;
}

interface FormNullableSelectProps<T extends NameAware> {
    api: FormFieldApi<number | null>;
    nullable: true;
    options: T[];
    label: string;
}

type FormSelectProps<T extends NameAware> = FormNormalSelectProps<T> | FormNullableSelectProps<T>;

export const FormNameAwareSelect = <T extends NameAware>({ options, ...rest }: FormSelectProps<T>) => {
    const labels = useMemo(() => keyBy(options, k => k.id), [options]);
    const ids = options.map(v => v.id);

    return <FormSelectSearch<number | null> toText={v => labels[v]?.name} options={ids} {...rest} />;
};
