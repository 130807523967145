import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { emptyRepaymentPlansFilters, useRepaymentPlansQuery } from '@hofy/api-admin';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { RepaymentPlanDetailsTab } from '../../../store/repaymentPlan/types/RepaymentPlanDetailsTab';
import { RepaymentPlansTable } from '../../invoicingPage/repaymentPlans/components/RepaymentPlansTable';

interface RepaymentPlansTabProps {
    contractId: number;
}

export const RepaymentPlansTab: FC<RepaymentPlansTabProps> = ({ contractId }) => {
    const {
        repaymentPlans,
        response: { isLoading, isFetchingNextPage, hasNextPage, fetchNextPage },
    } = useRepaymentPlansQuery({ ...emptyRepaymentPlansFilters, contractId });
    const history = useHistory();
    const repaymentPlansNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.RepaymentPlans}`;
    const handleOpenPlan = (id: number, tab: RepaymentPlanDetailsTab, method: 'push' | 'replace') => {
        history[method](`${repaymentPlansNavLink}/${id}/${tab}`);
    };

    return (
        <RepaymentPlansTable
            entries={repaymentPlans}
            onEntryClick={id => handleOpenPlan(id, RepaymentPlanDetailsTab.Details, 'push')}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
        />
    );
};
