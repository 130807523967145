import React, { FC, ReactNode } from 'react';

import { VisualType } from '@hofy/global';
import { toColorDye, toContentColor } from '@hofy/theme';

import { Box, OuterBoxProps, Paragraph3 } from '../base';
import { Icon } from '../icon';

interface CompactAlertProps extends OuterBoxProps {
    title?: string;
    description: string;
    type: VisualType;
    icon?: Svg;
    children?: ReactNode;
}

export const CompactAlert: FC<CompactAlertProps> = ({
    description,
    type,
    title,
    icon,
    children,
    ...boxProps
}) => {
    const { surface, surfaceBorder } = toColorDye(type);
    const textColor = toContentColor(type);

    return (
        <Box
            row
            gap={16}
            bg={surface}
            paddingHorizontal={16}
            paddingVertical={8}
            border
            borderColor={surfaceBorder}
            rounded={8}
            {...boxProps}
        >
            {icon && <Icon svg={icon} color={textColor} />}
            <Box column gap={2} flex={1}>
                {title && (
                    <Paragraph3 bold color={textColor}>
                        {title}
                    </Paragraph3>
                )}
                <Paragraph3 color={textColor}>{description}</Paragraph3>
            </Box>
            {children && (
                <Box row gap={16}>
                    {children}
                </Box>
            )}
        </Box>
    );
};
