export const organizationStatusUpdateI18n = {
    'organization-page.status-update.title': 'Update organization status',
    'organization-page.status-update.subtitle': 'This operation will update the organization status.',
    'organization-page.status-update.modal.confirm': 'Save',
    'organization-page.status-update.modal.cancel': 'Cancel',
    'organization-page.status-update.modal.trial-end': 'Trial end date',
    'organization-page.status-update.modal.set-org-message': 'Set organization message',
    'organization-page.status-update.modal.set-org-message-helper':
        'The message that will be displayed as a banner on top of every page',
};
