import React, { FC } from 'react';

import { ContractDetailsDtoWithRental, RentalSubContractDto } from '@hofy/api-admin';
import { DateString } from '@hofy/global';
import { parseDateTime } from '@hofy/helpers';
import {
    FormContainer,
    FormDateInput,
    FormModal,
    FormPriceInput,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useEndContract } from '../../../store/contracts/useEndContract';

interface EndContractModalProps {
    contract: ContractDetailsDtoWithRental;
    mainSubContract: RentalSubContractDto;
    onClose(): void;
}

export const EndContractModal: FC<EndContractModalProps> = ({ contract, mainSubContract, onClose }) => {
    const { form, isLoading, isError } = useEndContract(contract, mainSubContract, onClose);

    const isEndOfMonth = (dateString: DateString) => {
        const date = parseDateTime(dateString);
        return date.day === date.endOf('month').day;
    };

    const purchaseFeeLabel = contract.isUnbundled
        ? 'Purchase fee - Unbundled'
        : 'Purchase fee - Bundled (Rollover price * 9 - discount)';

    return (
        <FormModal
            width={600}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title={`End contract #${contract.idDeprecated}`} />
            <ModalContent>
                <FormContainer>
                    <FormDateInput
                        label='End of contract time'
                        api={form.fields.endOfContractTime}
                        filterDate={isEndOfMonth}
                        isRequired
                    />
                    <FormPriceInput label='Cancellation fee' api={form.fields.cancellationFee} />
                    <FormPriceInput label={purchaseFeeLabel} api={form.fields.purchaseFee} />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Cancel' onClick={form.discard} />
                <SubmitButton label='End' />
            </ModalFooter>
        </FormModal>
    );
};
