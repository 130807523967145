import { filter, includes, map, xor } from 'lodash';
import React, { FC } from 'react';

import {
    allItemConditions,
    HofyWarehouseDetailsDto,
    ItemCondition,
    useHofySubsidiaryRefs,
    useHofyWarehousesQuery,
} from '@hofy/api-admin';
import { HofySubsidiaryRef, ItemLocation, useTrItemLocation } from '@hofy/api-shared';
import { MonthDropdown } from '@hofy/common';
import { endOfPreviousMonth, toISODate } from '@hofy/helpers';
import { Box, DateInput, FilterChip, FilterChipList, PageHeader, SearchInput, SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../../components/design/blockFilters/EnumBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { useItemValuationFilters } from '../../../store/itemEvents/useItemValuationFilters';
import { useTrItemCondition } from '../../../store/items/useTrItemCondition';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { ItemValuationTable } from './components/ItemValuationTable';
import { ItemValuationTotals } from './components/ItemValuationTotals';

export const ItemValuationPage: FC = () => {
    const {
        search,
        filters,
        filterCount,
        setSearch,
        setHofySubsidiaries,
        setWarehouses,
        setUpToMonth,
        setLocation,
        setCondition,
    } = useItemValuationFilters();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const subsidiaryName = (id: number) =>
        hofySubsidiaries.find(subsidiary => subsidiary.id === id)?.name || '';

    const { data: warehouses } = useHofyWarehousesQuery();
    const warehouseName = (id: number) =>
        warehouses.find(warehouse => warehouse.idDeprecated === id)?.name || '';

    const trItemLocation = useTrItemLocation();
    const trItemCondition = useTrItemCondition();
    const itemLocationsRelevantToItemValuation = [ItemLocation.AtWarehouse, ItemLocation.WithCourier];

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Item valuation'
                rightSlot={
                    <>
                        <SearchInput value={search} onChange={setSearch} placeholder='Search' autoFocus />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={<AccountingTabs tab={AdminAccountingTab.ItemValuation} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <ListMultiBlockFilter
                    title='Subsidiary'
                    selected={filter(hofySubsidiaries, subsidiary =>
                        includes(filters.hofySubsidiaries, subsidiary.id),
                    )}
                    items={hofySubsidiaries}
                    onChange={filter =>
                        setHofySubsidiaries(
                            map<HofySubsidiaryRef, number>(filter, subsidiary => subsidiary.id),
                        )
                    }
                    renderItem={subsidiary => subsidiary.name}
                />
                <ListMultiBlockFilter
                    title='Warehouse'
                    selected={filter(warehouses, warehouse =>
                        includes(filters.warehouses, warehouse.idDeprecated),
                    )}
                    items={warehouses}
                    onChange={filter =>
                        setWarehouses(
                            map<HofyWarehouseDetailsDto, number>(filter, warehouse => warehouse.idDeprecated),
                        )
                    }
                    renderItem={warehouse => warehouse.name}
                />
                <EnumBlockFilter<ItemLocation>
                    title='Item location'
                    icon={SvgIcon.Globe}
                    selected={filters.location}
                    onChange={setLocation}
                    items={itemLocationsRelevantToItemValuation}
                    renderItem={trItemLocation}
                />
                <EnumBlockFilter<ItemCondition>
                    title='Item condition'
                    icon={SvgIcon.Laptop}
                    selected={filters.condition}
                    onChange={setCondition}
                    items={allItemConditions}
                    renderItem={trItemCondition}
                />
                <BlockFilter title='Up to month' icon={SvgIcon.Calendar}>
                    <MonthDropdown
                        onChange={setUpToMonth}
                        date={filters.upToMonth}
                        maxDate={toISODate(endOfPreviousMonth())}
                    />
                    <DateInput type='month' value={filters.upToMonth} onChange={setUpToMonth} />
                </BlockFilter>
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={subsidiary => subsidiary}
                    selected={filters.hofySubsidiaries}
                    toLabel={subsidiaryName}
                    onClear={value => setHofySubsidiaries(xor(filters.hofySubsidiaries, [value]))}
                    color='blue'
                />
                <FilterChipList
                    toKey={warehouse => warehouse}
                    selected={filters.warehouses}
                    toLabel={warehouseName}
                    onClear={value => setWarehouses(xor(filters.warehouses, [value]))}
                    color='red'
                />
                {filters.location && (
                    <FilterChip
                        label={trItemLocation(filters.location)}
                        onClear={() => setLocation(null)}
                        color='purple'
                    />
                )}
                {filters.condition && (
                    <FilterChip
                        label={trItemCondition(filters.condition)}
                        onClear={() => setCondition(null)}
                        color='green'
                    />
                )}
            </BlockFilterChipContainer>

            <ItemValuationTotals filters={filters} />
            <ItemValuationTable filters={filters} />
        </Box>
    );
};
