import React, { FC, useState } from 'react';

import { AddressDto, ItemStatus } from '@hofy/api-shared';
import { Switch } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, ConfirmModal, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { useAssignmentStolen } from '../../../store/assignments/useAssignmentStolen';
import { SelectAddress } from './SelectAddress';

interface AssignmentStolenModalProps {
    assignmentId: number;
    userAddress: AddressDto;
    deliveryAddress: AddressDto;
    onSuccess(): void;
}

export const AssignmentStolenModal: FC<AssignmentStolenModalProps> = ({
    assignmentId,
    userAddress,
    deliveryAddress,
    onSuccess,
}) => {
    const { stolen } = useAssignmentStolen(assignmentId, onSuccess);
    const [createReplacementOrder, setCreateReplacementOrder] = useState(true);

    const [addressToDeliver, setAddressToDeliver] = useState(deliveryAddress);

    return (
        <ConfirmModal
            keyPrefix='item-stolen-modal'
            onClose={onSuccess}
            onConfirm={() => stolen(createReplacementOrder, addressToDeliver.id)}
            width={userAddress.id === deliveryAddress.id ? 450 : 800}
        >
            <Box row gap={10} marginTop={10}>
                <Paragraph3>Item status will be changed to</Paragraph3>
                <ItemStatusChip status={ItemStatus.Stolen} />
            </Box>
            <Switch
                marginTop={10}
                label='Create replacement order'
                checked={createReplacementOrder}
                onChange={setCreateReplacementOrder}
            />
            {createReplacementOrder && (
                <>
                    <Box row marginTop={10}>
                        <Icon
                            svg={SvgIcon.AlertTriangle}
                            size={20}
                            marginRight={12}
                            color={Color.FoundationWarning}
                        />
                        <Paragraph3 color={Color.FoundationWarning} marginTop={2}>
                            A replacement order will be created
                        </Paragraph3>
                    </Box>
                    <Box marginTop={16}>
                        <SelectAddress
                            userAddress={userAddress}
                            deliveryAddress={deliveryAddress}
                            onSelect={setAddressToDeliver}
                        />
                    </Box>
                </>
            )}
        </ConfirmModal>
    );
};
