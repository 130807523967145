import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    ContractDetailsDtoWithPurchase,
    contractsCacheKey,
    contractService,
    UpdatePurchaseContractPayload,
} from '@hofy/api-admin';
import { isRequired, useForm, validator } from '@hofy/ui';

export const useUpdatePurchaseContract = (
    contract: ContractDetailsDtoWithPurchase,
    onClose: (id: number) => void,
) => {
    const purchaseContract = contract.purchaseContract;
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (p: UpdatePurchaseContractPayload) =>
            contractService.updatePurchaseContract(contract.idDeprecated, p),
        onSuccess: v => {
            onClose(v);
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
        },
    });

    const form = useForm<UpdatePurchaseContractPayload>({
        initial: {
            basePrice: purchaseContract.basePrice,
            unitPrice: purchaseContract.unitPrice,
            price: purchaseContract.price,
            countryMultiplier: purchaseContract.countryMultiplier,
            storefrontFee: purchaseContract.storefrontFee,
        },
        validate: validator<UpdatePurchaseContractPayload>({
            basePrice: isRequired('Base price is required'),
            unitPrice: isRequired('Unit price is required'),
            price: isRequired('Price is required'),
            countryMultiplier: isRequired('Country multiplier is required'),
            storefrontFee: isRequired('Storefront fee is required'),
        }),
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
        submit: form.submit,
    };
};
