import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, itemsService } from '@hofy/api-admin';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

import { useCheckInMutation } from '../items/useCheckInMutation';

interface CheckInItemFormData {
    code: string | null;
    warehouseBinIdentifier: string | null;
}

export const useItemCheckInCoreWarehouse = (
    itemId: number,
    assignmentId: number,
    scannedCode: string | null,
    onSuccess?: () => void,
) => {
    return useItemCheckIn({
        itemId,
        assignmentId,
        codeRequired: true,
        scannedCode,
        warehouseBinIdentifierRequired: true,
        onSuccess,
    });
};

export const useItemCheckIn3rdPartyWarehouse = (
    itemId: number,
    assignmentId: number,
    onSuccess?: () => void,
) => {
    return useItemCheckIn({
        itemId,
        assignmentId,
        codeRequired: false,
        scannedCode: null,
        warehouseBinIdentifierRequired: false,
        onSuccess,
    });
};

interface ItemCheckInParams {
    itemId: number;
    assignmentId: number;
    codeRequired: boolean;
    scannedCode: string | null;
    warehouseBinIdentifierRequired: boolean;
    onSuccess?(): void;
}

const useItemCheckIn = ({
    itemId,
    assignmentId,
    codeRequired,
    scannedCode,
    warehouseBinIdentifierRequired,
    onSuccess,
}: ItemCheckInParams) => {
    const queryClient = useQueryClient();

    const codeMutation = useMutation({
        mutationFn: itemsService.getItemByCode,
    });

    const mutation = useCheckInMutation(assignmentId, () => {
        queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
        onSuccess?.();
    });

    const form = useForm<CheckInItemFormData>({
        initial: {
            code: scannedCode,
            warehouseBinIdentifier: null,
        },
        onSubmit: ({ warehouseBinIdentifier }) => {
            mutation.mutate({
                itemId,
                warehouseBinIdentifier,
            });
        },
        validate: ({ code, warehouseBinIdentifier }) => ({
            code: errorMap([codeRequired && isEmpty(code), 'Invalid item code']),
            warehouseBinIdentifier: errorMap([
                warehouseBinIdentifierRequired && isEmpty(warehouseBinIdentifier),
                'Invalid bin identifier',
            ]),
        }),
        validateDeps: [codeMutation.error],
    });

    return {
        form,
        isLoading: codeMutation.isPending || mutation.isPending,
    };
};
