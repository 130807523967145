import { SupplierContactDto, useSupplierDetailsQuery } from '@hofy/api-admin';

const emptySupplierContacts: SupplierContactDto[] = [];
export const useSupplierContactsQuery = (supplierId: number, query?: string) => {
    const { data: supplier, isLoading } = useSupplierDetailsQuery(supplierId);

    const filteredContacts = supplier?.contacts.filter(
        contact =>
            contact.name.toLowerCase().includes(query?.toLowerCase() ?? '') ||
            contact.lastname.toLowerCase().includes(query?.toLowerCase() ?? ''),
    );

    return { isLoading, data: filteredContacts ?? emptySupplierContacts };
};
