import React, { FC } from 'react';
import { useToggle } from 'react-use';

import { RepairDetailsDto } from '@hofy/api-admin';
import {
    allItemGrades,
    getNextValidRepairStatuses,
    getRepairStatusLabel,
    getValidItemStatusesWhenResolvingRepair,
    isResolvedRepairStatus,
    isResolvedRepairStatusWithItemRepaired,
    Permission,
    useTrItemStatus,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { FormDropdown } from '@hofy/common';
import { allCurrencies, Currency, toCurrency } from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import {
    AsyncButton,
    Box,
    Chevron,
    ExpandHeight,
    FormCheckbox,
    FormDateInput,
    FormGridRow,
    FormPriceInput,
    FormSection,
    FormSelect,
    Pressable,
    SectionTitle2,
    SvgIcon,
} from '@hofy/ui';

import { useTrItemGrade } from '../../../../store/items/useTrItemGrade';
import { useUpdateRepairStatus } from '../../../../store/repairs/useUpdateRepairStatus';
import { RepairTimeline } from '../../components/RepairTimeline';

interface RepairProgressTabProps {
    repair: RepairDetailsDto;
}

export const RepairProgressTab: FC<RepairProgressTabProps> = ({ repair }) => {
    const { hasPermission } = useSession();

    const canUpdateRepairStatus =
        hasPermission(Permission.AdminRepairsUpdate) &&
        !isResolvedRepairStatus(repair.status) &&
        !repair.cancelledAt;

    return (
        <Box column gap={20}>
            {canUpdateRepairStatus && (
                <Box column gap={10}>
                    {canUpdateRepairStatus && <UpdateStatusForm repair={repair} />}
                </Box>
            )}
            <TimelineSection repair={repair} />
        </Box>
    );
};

const UpdateStatusForm: FC<RepairProgressTabProps> = ({ repair }) => {
    const [isPanelExpanded, toggleIsPanelExpanded] = useToggle(false);

    const setCurrency = (currency: Currency) => {
        form.setValues({
            currency,
            repairFee: toCurrency(form.fields.repairFee.value, currency),
        });
    };

    const trItemGrade = useTrItemGrade();
    const trItemStatus = useTrItemStatus();
    const trCurrency = useTrCurrency();

    const { form, isSubmitting } = useUpdateRepairStatus(repair.idDeprecated, () => form.reset());

    return (
        <Box column border rounded>
            <Pressable row onClick={() => toggleIsPanelExpanded()} padding={20}>
                <SectionTitle2 flex={1}>Change status</SectionTitle2>
                <Chevron isOpen={isPanelExpanded} />
            </Pressable>
            <ExpandHeight>
                {isPanelExpanded && (
                    <Box column gap={20} paddingHorizontal={20} paddingBottom={20}>
                        <FormDropdown
                            label='Repair status'
                            emptyContent='Select new repair status'
                            items={getNextValidRepairStatuses(repair.status)}
                            value={form.values.status}
                            onChange={status =>
                                form.setValues({
                                    status,
                                })
                            }
                            labelFormatter={getRepairStatusLabel}
                            labelListFormatter={getRepairStatusLabel}
                            isRequired
                            isError={form.errors.status}
                        />
                        <FormDateInput
                            label='Status changed on'
                            api={form.fields.updatedOn}
                            minDate={repair.statusUpdatedOn}
                            isRequired
                        />
                        {form.values.status && isResolvedRepairStatus(form.values.status) && (
                            <>
                                <FormSelect
                                    options={getValidItemStatusesWhenResolvingRepair(
                                        isResolvedRepairStatusWithItemRepaired(form.values.status),
                                    )}
                                    toText={trItemStatus}
                                    api={form.fields.itemStatus}
                                    label='Item status'
                                    isRequired
                                />
                                <FormSelect
                                    options={allItemGrades}
                                    toText={trItemGrade}
                                    api={form.fields.grade}
                                    label='Item condition'
                                    isRequired
                                />

                                <FormCheckbox
                                    label='Bill customer for repair?'
                                    api={form.fields.isBillable}
                                    disabled={!repair.canBeBilled}
                                    helperText='Only items on a contract or customer-owned can be billed.'
                                />

                                {form.fields.isBillable.value && (
                                    <FormGridRow columns={2}>
                                        <FormSelect
                                            options={allCurrencies}
                                            label='Currency'
                                            api={form.fields.currency}
                                            toText={trCurrency}
                                            onChange={setCurrency}
                                            isRequired
                                        />
                                        <FormPriceInput
                                            api={form.fields.repairFee}
                                            label='Repair fee'
                                            isRequired
                                        />
                                    </FormGridRow>
                                )}
                            </>
                        )}
                        <AsyncButton
                            label='Update status'
                            leftIcon={SvgIcon.Refresh}
                            onClick={form.submit}
                            isLoading={isSubmitting}
                        />
                    </Box>
                )}
            </ExpandHeight>
        </Box>
    );
};

const TimelineSection: FC<RepairProgressTabProps> = ({ repair }) => {
    return (
        <FormSection label='Timeline' column gap={20} labelGap={0}>
            <RepairTimeline statusUpdates={repair.statusUpdates} />
        </FormSection>
    );
};
