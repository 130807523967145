import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, Paragraph3, Span } from '@hofy/ui';

interface SubsidiaryCellProps {
    entry: {
        hofySubsidiary: {
            id: number;
            name: string;
        };
        billingEntity: {
            id: number;
            name?: string | null;
        };
    };
}

export const SubsidiaryCell: FC<SubsidiaryCellProps> = ({ entry }) => {
    return (
        <Box>
            <Paragraph3 color={Color.ContentPrimary}>
                From: <Span bold>{entry.hofySubsidiary.name}</Span>
            </Paragraph3>
            <Paragraph3 color={Color.ContentPrimary}>
                To: <Span bold>{entry.billingEntity.name || '--'}</Span>
            </Paragraph3>
        </Box>
    );
};
