import React, { FC } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { useSearch } from '@hofy/common';
import { Placeholder, SvgIllustration } from '@hofy/ui';

import { BillingEntitiesTable } from './components/BillingEntitiesTable';

interface BillingEntitiesTabProps {
    billingEntities: BillingEntityDto[];
    onOpenBillingEntity(billingEntityId: number): void;
    search: string;
}

export const BillingEntitiesTab: FC<BillingEntitiesTabProps> = ({
    billingEntities,
    onOpenBillingEntity,
    search,
}) => {
    const { results: filteredBillingEntities } = useSearch(
        billingEntities,
        s => [s.name, s.vatNumber || ''],
        search,
    );

    if (filteredBillingEntities.length === 0) {
        return (
            <Placeholder
                illustration={SvgIllustration.List}
                title={
                    search ? 'No billing entities were found for the current search' : 'No billing entities'
                }
            />
        );
    }
    return (
        <BillingEntitiesTable entries={filteredBillingEntities} onOpenBillingEntity={onOpenBillingEntity} />
    );
};
