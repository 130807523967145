import React, { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

interface LinkProps {
    to: string;
    children?: ReactNode;
    color?: Color;
    underline?: boolean;
}

export const Link: FC<LinkProps> = ({ to, underline = true, color, children }) => {
    return (
        <LinkWrapper $underline={underline} $color={color} to={to} onClick={e => e.stopPropagation()}>
            {children}
        </LinkWrapper>
    );
};

const LinkWrapper = styled(RouterLink)<{ $color?: Color; $underline: boolean }>`
    :hover {
        text-decoration: ${p => (p.$underline ? 'underline' : 'initial')};
    }
    color: ${p => p.$color};
`;
