import { useState } from 'react';

export const useSelection = <T extends { id: number }>(initialState = {}) => {
    const [selected, setSelected] = useState<Record<string, T | null>>(initialState);

    const onSelect = (item: T, isSet: boolean) => {
        const newSelected = {
            ...selected,
            [item.id]: isSet ? item : null,
        };
        setSelected(newSelected);
    };

    const getSelectionArray = () =>
        Object.keys(selected)
            .map(id => selected[id])
            .filter((item): item is T => !!item);

    const hasSelected = (id?: number) =>
        id ? !!selected[id] : Object.keys(selected).some(key => !!selected[key]);

    return {
        selected,
        onSelect,
        hasSelected,
        getSelectionArray,
    };
};
