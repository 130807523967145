import React, { FC } from 'react';

import { useAuthI18n } from '../../../i18n/useAuthI18n';
import { AuthPageWrapper } from '../../components/authPageWrapper/AuthPageWrapper';
import { SignUpForm } from './SignUpForm';

export const UserSignUpPage: FC = () => {
    const { tr } = useAuthI18n();
    return (
        <AuthPageWrapper pageId='sign-up' title={tr('sign-up-page.title')} showSubHeader>
            <SignUpForm />
        </AuthPageWrapper>
    );
};
