import React, { FC, ReactNode } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import { StatusFlag } from '@hofy/common';
import { usePrice } from '@hofy/hooks';
import { FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { useTrPlatformTier } from '../../../store/organizations/useTrPlatformTier';

interface PlatformTierSectionProps {
    organization: OrganizationDetailsDto;
    priceTooltip: ReactNode;
}

export const PlatformTierSection: FC<PlatformTierSectionProps> = ({ organization, priceTooltip }) => {
    const { unbundlingEnabled, platformTierConfig, pricing } = organization;
    const {
        platformTier,
        managerOnlyOrganizationEnabled,
        billingEntityLimit,
        teamLimit,
        freeLicensesLimit,
        autoApprovalEnabled,
        hrisAutoImportEnabled,
        samlEnabled,
        deviceTrackedLimit,
    } = platformTierConfig;
    const trPlatformTier = useTrPlatformTier();
    const { formatPrice } = usePrice();

    return (
        <FormSection label='Platform tier config'>
            <FormGridRow columns={2}>
                <LabeledText flex={1} label='Platform Tier' content={trPlatformTier(platformTier)} />
                <LabeledText
                    flex={1}
                    label='Platform tier price'
                    labelSlot={priceTooltip}
                    content={formatPrice(pricing.sassFee)}
                />
                <LabeledText flex={1} label='Billing entity limit' content={billingEntityLimit} />
                {unbundlingEnabled && (
                    <LabeledText flex={1} label='Device tracked limit' content={deviceTrackedLimit} />
                )}
                <LabeledText flex={1} label='Team limit' content={teamLimit} />
                {!unbundlingEnabled && (
                    <LabeledText flex={1} label='Free licenses limit' content={freeLicensesLimit} />
                )}
            </FormGridRow>
            <StatusFlag label='Manager only organisation' active={managerOnlyOrganizationEnabled} />
            <StatusFlag label='Auto approval' active={autoApprovalEnabled} />
            <StatusFlag label='HRIS Import Rules' active={hrisAutoImportEnabled} />
            <StatusFlag label='Host agent' active={organization.hostAgentEnabled} />
            <StatusFlag label='SAML' active={samlEnabled} />
        </FormSection>
    );
};
