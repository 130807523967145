import React, { FC } from 'react';

import { usePaymentsByInvoice } from '@hofy/api-admin';
import { usePrice } from '@hofy/hooks';
import { Placeholder, SortFilterDataTable, SvgIllustration } from '@hofy/ui';

import { useTrPaymentMethod } from '../../../../store/payments/useTrPaymentMethod';
import { PaymentDataStatusChip } from './components/PaymentDataStatusChip';
import { PaymentProviderChip } from './components/PaymentProviderChip';

interface PaymentDetailsTabProps {
    invoiceId: number;
}

export const PaymentDetailsTab: FC<PaymentDetailsTabProps> = ({ invoiceId }) => {
    const { paymentData, isLoading } = usePaymentsByInvoice(invoiceId);
    const { formatPrice } = usePrice();
    const { trPaymentMethod } = useTrPaymentMethod();

    return (
        <SortFilterDataTable
            flex='auto'
            data={paymentData}
            toKey={item => item.uuid}
            isLoading={isLoading}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No payment data for invoice'
                />
            }
            columns={[
                {
                    id: 'transactionOn',
                    header: 'Transaction on',
                    renderer: item => item.transactionOn,
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: item => (
                        <PaymentDataStatusChip status={item.status} errorReason={item.errorReason} />
                    ),
                },
                {
                    id: 'amount',
                    header: 'Invoice Amount',
                    renderer: item => formatPrice(item.invoiceAmount),
                },
                {
                    id: 'amount',
                    header: 'Payment Amount',
                    renderer: item => formatPrice(item.totalAmount),
                },
                {
                    id: 'provider',
                    header: 'Payment provider',
                    renderer: item => <PaymentProviderChip provider={item.paymentProvider} />,
                },
                {
                    id: 'method',
                    header: 'Payment method',
                    renderer: item => trPaymentMethod(item.paymentMethod),
                },
            ]}
        />
    );
};
