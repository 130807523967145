import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const usePurchaseAssignment = (onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: assignmentService.purchaseItem,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                title: 'Item Purchased',
                message: 'Item and assignments updated accordingly',
            });
            onSuccess();
        },
    });

    const purchase = (assignmentId: number) => {
        mutation.mutate(assignmentId);
    };

    return {
        purchase,
    };
};
