export const addonErrorI18n = {
    'api-error.invalid-addon-billing-frequency.title': 'Addon has invalid billing frequency',
    'api-error.invalid-addon-billing-frequency.message': 'Cannot perform this operation',
    'api-error.missing-addon-id.title': 'Addon id missing',
    'api-error.missing-addon-id.message': 'Cannot perform this operation',
    'api-error.addon-not-found.title': 'Addon not found',
    'api-error.addon-not-found.message': 'Cannot perform this operation',
    'api-error.invalid-addon-length.title': 'Invalid addon length',
    'api-error.invalid-addon-length.message': 'Cannot perform this operation',
    'api-error.public-addon-cannot-be-inactive.title': 'Public addon cannot be inactive',
    'api-error.public-addon-cannot-be-inactive.message': 'Cannot perform this operation',
};
