import { get, isEqual, partition, sortBy, union } from 'lodash';
import { useMemo } from 'react';

import {
    AuditLogDetailsDto,
    AuditLogOperationType,
    DataRecordEntry,
    isHiddenData,
    useAuditLogQuery,
} from '@hofy/api-admin';

export const useAuditLog = (id: number) => {
    const { data: auditLog, isLoading } = useAuditLogQuery(id);

    const changeRecord = useChangedData(auditLog);

    return {
        auditLog,
        changeRecord,
        isLoading: isLoading,
    };
};

interface FieldEntry {
    key: string; //_.path a.b.c
    value: DataRecordEntry;
}

export interface FieldChangeEntry {
    key: string; //_.path a.b.c
    from: DataRecordEntry;
    to: DataRecordEntry;
}

const objectToKeys = (v: Record<string, any>): string[] => {
    return sortBy(Object.keys(v)).flatMap(k => {
        if (typeof v[k] !== 'object') {
            return [k];
        }
        if (v[k] === null || v[k] === undefined) {
            return [k];
        }
        if (Array.isArray(v[k])) {
            return [k];
        }
        if (isHiddenData(v[k])) {
            return [k];
        }
        return objectToKeys(v[k]).map(keyPath => `${k}.${keyPath}`);
    });
};

const useChangedData = (details: AuditLogDetailsDto | undefined) => {
    const storedData = details?.storedData || {};
    const previousStoredData = details?.previousStoredData || {};

    const storedKeys = useMemo(
        () => objectToKeys(storedData).filter(v => !v.startsWith('AuditableModel.')),
        [details],
    );
    const previousStoredKeys = useMemo(
        () => objectToKeys(previousStoredData).filter(v => !v.startsWith('AuditableModel.')),
        [details],
    );
    const allKeys = useMemo(() => sortBy(union(storedKeys, previousStoredKeys)), [details]);
    const isEqualEntry = (
        data1: DataRecordEntry,
        data2: DataRecordEntry,
        op?: AuditLogOperationType,
    ): boolean => {
        if (op === AuditLogOperationType.Create || op === AuditLogOperationType.Delete) {
            return true;
        }
        if (isHiddenData(data1) && isHiddenData(data2)) {
            return data1.checksum === data2.checksum;
        }

        if (Array.isArray(data1) || Array.isArray(data2)) {
            return isEqual(data1, data2);
        }

        // eslint-disable-next-line eqeqeq
        return data1 == data2;
    };

    return useMemo(() => {
        const [unchangedKeys, changedKeys] = partition(allKeys, k =>
            isEqualEntry(
                get(previousStoredData, k) as DataRecordEntry,
                get(storedData, k) as DataRecordEntry,
                details?.operationType,
            ),
        );

        const newStoredData: FieldEntry[] = storedKeys.map(k => ({
            key: k,
            value: get(storedData, k) as DataRecordEntry,
        }));
        const changedData: FieldChangeEntry[] = changedKeys.map(k => ({
            key: k,
            from: get(previousStoredData, k) as DataRecordEntry,
            to: get(storedData, k) as DataRecordEntry,
        }));

        return {
            changedData,
            changedKeys,
            unchangedKeys,
            storedData: newStoredData,
        };
    }, [allKeys, details]);
};

export type UseChangedData = ReturnType<typeof useChangedData>;
