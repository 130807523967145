import React from 'react';

import { DateRangeStrings, DateString } from '@hofy/global';
import { LabeledDateInput, LabeledDateRangeInput, SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../../../components/design/blockFilters/BlockFilter';

interface AssignmentsDateFilterProps {
    otdDate: DateString | null;
    onChangeOtd(v: DateString | null): void;
    shipDateRange: DateRangeStrings | null;
    onChangeShipRange(v: DateRangeStrings): void;
    deliverDate: DateString | null;
    onChangeDeliver(v: DateString | null): void;
}

export const AssignmentsDateFilter = ({
    onChangeOtd,
    otdDate = null,
    shipDateRange,
    onChangeShipRange,
    deliverDate = null,
    onChangeDeliver,
}: AssignmentsDateFilterProps) => {
    return (
        <BlockFilter title='Date' icon={SvgIcon.Calendar} marginLeft={12} column gap={8}>
            <LabeledDateInput label='OTD before' value={otdDate} onChange={onChangeOtd} nullable />
            <LabeledDateRangeInput
                label='Ship by'
                value={shipDateRange}
                onChange={onChangeShipRange}
                nullable
            />
            <LabeledDateInput
                label='Est. Delivery before'
                value={deliverDate}
                onChange={onChangeDeliver}
                nullable
            />
        </BlockFilter>
    );
};
