import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

interface StockLevelsPageMenuProps {
    onReceiveItem(): void;
    onOpenNewItem(): void;
    onUploadMinAvailability(): void;
}

export const StockLevelsPageMenu: FC<StockLevelsPageMenuProps> = ({
    onReceiveItem,
    onOpenNewItem,
    onUploadMinAvailability,
}) => {
    const { hasPermission } = useSession();

    return (
        <MoreMenu
            items={[
                {
                    label: 'Receive PO item',
                    icon: SvgIcon.Add,
                    action: onReceiveItem,
                    visible: hasPermission(Permission.AdminItemsCreate),
                },
                {
                    label: 'Add new item',
                    icon: SvgIcon.Add,
                    action: onOpenNewItem,
                    visible: hasPermission(Permission.AdminItemsCreate),
                },
                {
                    label: 'Upload min availability',
                    icon: SvgIcon.Upload,
                    action: () => onUploadMinAvailability(),
                    visible: hasPermission(Permission.AdminStockUpdate),
                },
            ]}
        />
    );
};
