import React, { FC } from 'react';

import { decodeToken } from '@hofy/api-auth';
import { useStringQueryParam } from '@hofy/router';

import { OrganizationSignup } from './organizationSignup/OrganizationSignup';
import { UserSignUpPage } from './userSignup/UserSignUpPage';

export const SignUpPage: FC = () => {
    const [token] = useStringQueryParam('token');
    const [error] = useStringQueryParam('error');
    const isOrganizationSignupMode =
        !token || decodeToken(token).type === 'signup' || error === 'user_exists';

    return isOrganizationSignupMode ? <OrganizationSignup /> : <UserSignUpPage />;
};
