import React from 'react';
import styled from 'styled-components';

import { Color, NumberValues } from '@hofy/theme';

import { renderTextNode } from '../../helpers';
import { Box } from '../base';
import { Cell } from './cells/Cell';
import { HeaderCellLabel } from './header/HeaderCellLabel';
import { BaseTableColumnConfig } from './types/BaseTableColumnConfig';

interface BaseTableHeaderProps<T> {
    columns: BaseTableColumnConfig<T>[];
    minWidth?: number | string;
    inline?: boolean;
    paddingHorizontal: NumberValues;
}

export const BaseTableHeader = <T extends any>({
    columns,
    minWidth,
    inline,
    paddingHorizontal,
}: BaseTableHeaderProps<T>) => {
    const hasHeader = columns.some(column => column.header);
    if (!hasHeader) {
        return null;
    }
    const padding = inline ? 0 : paddingHorizontal;
    const margin = inline ? 40 : 0;

    return (
        <ContainerBox
            data-test-key='table-header'
            marginHorizontal={margin}
            minWidth={minWidth}
            flex={0}
            borderBottom
            bg={Color.BackgroundDefault}
        >
            <Box row paddingHorizontal={padding}>
                {columns.map((column, index) => (
                    <Cell
                        data-test-key={column.id}
                        key={index}
                        width={column.width}
                        flex={column.flexGrow}
                        shrink={column.flexShrink}
                    >
                        {typeof column.header === 'function'
                            ? column.header(column)
                            : renderTextNode(column.header, text => <HeaderCellLabel label={text} />)}
                    </Cell>
                ))}
            </Box>
        </ContainerBox>
    );
};

export const ContainerBox = styled(Box)`
    position: sticky;
    top: 0;
    z-index: 2;
`;
