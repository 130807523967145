import React, { FC, ReactNode } from 'react';

import { Link } from '../../routing/Link';

interface ShipmentLinkProps {
    id: number;
    children?: ReactNode;
}

export const ShipmentLink: FC<ShipmentLinkProps> = ({ id, children }) => {
    return <Link to={`/assignments/all/shipment/${id}/details`}>{children}</Link>;
};

export const ShipmentLinkLabel: FC<{ id: number | null }> = ({ id }) => {
    return id ? <ShipmentLink id={id}>{`#${id}`}</ShipmentLink> : <>--</>;
};
