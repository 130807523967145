import React, { FC, useState } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import {
    BackButton,
    MoreMenu,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { UUID } from '@hofy/global';
import { AsyncButton, Box, FormInput, Modals, SvgIcon } from '@hofy/ui';

import { DangerousConfirmModal } from '../../../components/design/modal/DangerousConfirmModal';
import { FormOrganizationSelect } from '../../../components/domain/organizations/FormOrganizationSelect';
import { useUpdateApiKey } from '../../../store/apiKeys/useUpdateApiKey';
import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { LabeledPermissions } from './components/LabeledPermissions';

interface ApiKeysSlideoutProps {
    keyId: UUID;
    onClose(): void;
}

export const UpdateApiKeySlideout: FC<ApiKeysSlideoutProps> = ({ onClose, keyId }) => {
    const { form, isLoading, revokeKey } = useUpdateApiKey(keyId, onClose);
    const [showConfirmRevokeModal, setShowConfirmRevokeModal] = useState(false);

    const [menuLink] = useAuditLogMenuOption(AuditableTable.ApiKeys, undefined, keyId);

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title='Edit API key'>
                <MoreMenu marginLeft={12} items={[menuLink]} />
            </SlideoutHeader>
            <SlideoutContent paddingVertical={20} column gap={20}>
                <FormInput label='Name' api={form.fields.name} isRequired />
                <FormOrganizationSelect
                    label='Organization'
                    api={form.fields.organizationId}
                    nullable
                    placeholder='Choose organization'
                />
                <LabeledPermissions
                    value={form.values.permissions}
                    errorMessage={form.errors.permissions as string}
                    onChange={permissions => form.setValues({ permissions })}
                />
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
                <Box row>
                    <AsyncButton
                        label='Revoke'
                        action='destructive'
                        onClick={() => setShowConfirmRevokeModal(true)}
                        marginRight={20}
                        disableCheck
                    />
                    <AsyncButton isLoading={isLoading} label='Save' onClick={form.submit} disableCheck />
                </Box>
                <Modals>
                    {showConfirmRevokeModal && (
                        <DangerousConfirmModal
                            onClose={() => setShowConfirmRevokeModal(false)}
                            onConfirm={() => revokeKey(keyId)}
                            title={`Revoke API key - ${form.values.name}`}
                            subTitle='This cannot be reversed, are you sure?'
                            cancelButtonLabel='Cancel'
                            confirmButtonLabel='Revoke!'
                            confirmText='revoke'
                            inputLabel='Please enter "revoke" to confirm'
                        />
                    )}
                </Modals>
            </SlideoutFooter>
        </Slideout>
    );
};
