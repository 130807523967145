import React, { FC } from 'react';

import { UserRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3 } from '@hofy/ui';

import { ShipmentAdminSelector } from '../../../../../components/domain/shipments/ShipmentAdminSelector';

interface ShipmentAssigneeCellProps extends BoxProps {
    shipmentId: number;
    user: UserRefDto | null;
}

export const ShipmentAssigneeCell: FC<ShipmentAssigneeCellProps> = ({ shipmentId, user, ...boxProps }) => {
    return (
        <Box flex={1} column alignSelf='flex-start' gap={4} {...boxProps}>
            <Paragraph3 color={Color.Neutral300}>Assigned to</Paragraph3>
            <ShipmentAdminSelector user={user} shipmentId={shipmentId} />
        </Box>
    );
};
