import React, { FC, ReactNode } from 'react';
import { Route, RouteChildrenProps, Switch, useHistory } from 'react-router-dom';

import { getEnumParam, getIntParam, IntRoute } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { InvoiceDetailTabs } from '../../../store/invoices/types/InvoiceDetailTabs';
import { BillingEntityTab } from '../../../store/invoicing/billingEntities/types/BillingEntityTab';
import {
    BillingEntityNavigationMethod,
    useNavigateBillingEntity,
} from '../../../store/invoicing/billingEntities/useNavigateBillingEntity';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { BillingEntitiesPage } from './BillingEntitiesPage';
import { BillingEntityDetailsOverlay } from './billingEntityDetailsOverlay/BillingEntityDetailsOverlay';
import { CreateUpdateBillingEntityOverlay } from './createUpdateBillingEntityOverlay/CreateUpdateBillingEntityOverlay';

export const BillingEntitiesRouter: FC<InvoicingTabRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();

    const {
        navigateUpdateBillingEntity,
        navigateBillingEntityList,
        navigateBillingEntityTab,
        getBillingEntityNavLink,
    } = useNavigateBillingEntity();

    const handleOpenInvoice = (id: number) => {
        history.push(
            `${AdminNavLink.Invoicing}/${AdminInvoicingTab.Invoices}/${id}/${InvoiceDetailTabs.Details}`,
        );
    };

    const billingEntitiesNavLink = getBillingEntityNavLink();

    return (
        <Switch>
            <Route exact path={billingEntitiesNavLink}>
                <BillingEntitiesPage tab={tab} tabs={tabs} onChangeTab={onChangeTab} />
            </Route>
            <BillingEntityRoute
                exact
                path={`${billingEntitiesNavLink}/:billingEntityId(\\d+)/${BillingEntityNavigationMethod.Edit}`}
            >
                {({ billingEntityId }) => (
                    <CreateUpdateBillingEntityOverlay
                        onClose={navigateBillingEntityList}
                        billingEntityId={billingEntityId}
                        organizationId={null}
                    />
                )}
            </BillingEntityRoute>
            <Route exact path={`${billingEntitiesNavLink}/${BillingEntityNavigationMethod.Create}`}>
                <CreateUpdateBillingEntityOverlay organizationId={null} onClose={navigateBillingEntityList} />
            </Route>
            <BillingEntityTabRoute
                path={`${billingEntitiesNavLink}/:billingEntityId(\\d+)/:billingEntityTab`}
            >
                {({ billingEntityId, billingEntityTab }) => (
                    <BillingEntityDetailsOverlay
                        billingEntityId={billingEntityId}
                        billingEntityTab={billingEntityTab}
                        onChangeTab={tab => navigateBillingEntityTab(billingEntityId, tab)}
                        onInvoiceClick={handleOpenInvoice}
                        onUpdateBillingEntity={navigateUpdateBillingEntity}
                        onClose={navigateBillingEntityList}
                    />
                )}
            </BillingEntityTabRoute>
        </Switch>
    );
};

const BillingEntityRoute = IntRoute('billingEntityId', Route);

interface BillingEntityTabRouteProps {
    children(v: { billingEntityTab: BillingEntityTab; billingEntityId: number }): ReactNode;
    path?: string;
}

export const BillingEntityTabRoute: FC<BillingEntityTabRouteProps> = ({ children, ...props }) => {
    const renderChildren = (props: RouteChildrenProps) => {
        const billingEntityId = getIntParam(props.match?.params || {}, 'billingEntityId');
        const billingEntityTab = getEnumParam<BillingEntityTab>(
            props.match?.params || {},
            'billingEntityTab',
            BillingEntityTab,
        );

        if (billingEntityId && billingEntityTab) {
            return children({
                billingEntityId,
                billingEntityTab,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
