import { useQuery } from '@tanstack/react-query';

import { repaymentPlanService } from '../service/repaymentPlanService';
import { repaymentPlansCacheKey } from './cacheKey';

export const useRepaymentPlanQuery = (planId: number) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [repaymentPlansCacheKey, planId],
        queryFn: () => repaymentPlanService.getRepaymentPlan(planId),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
