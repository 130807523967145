import React, { FC } from 'react';

import { PublicSSO } from '@hofy/api-auth';
import { EmailInput, FormCheckbox } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Form, Paragraph4, SubmitButton, UseForm } from '@hofy/ui';

import { useAuthI18n } from '../../../i18n/useAuthI18n';
import { SignUpFormPayload, SignUpFormPayloadValidation } from '../../../store/auth/types/SignUpFormPayload';
import { Or } from '../../components/Or';
import { PasswordForm } from '../../components/PasswordForm';
import { PublicSSOButtons } from '../../components/publicSSOButton/PublicSSOButtons';
import { TermsAndConditionsLabel } from './ConnectionSignUpForm';

interface EmailPasswordSignUpFormProps {
    isError: boolean;
    form: UseForm<SignUpFormPayload, SignUpFormPayloadValidation>;
    email: string | null;
    signUpWithPublicSSO(sso: PublicSSO): void;
    signUpIsLoading: boolean;
}

export const EmailPasswordSignUpForm: FC<EmailPasswordSignUpFormProps> = ({
    form,
    signUpWithPublicSSO,
    isError,
    email,
    signUpIsLoading,
}) => {
    const { tr } = useAuthI18n();

    return (
        <Box column gap={24} marginTop={24}>
            <Or text={tr('sign-up-page.divider.sso')} />
            <PublicSSOButtons onClick={signUpWithPublicSSO} mode='sign-up' />

            <Or text={tr('sign-up-page.divider.email')} />
            <Form onSubmit={form.submit} submitOnEnter isLoading={signUpIsLoading}>
                <EmailInput
                    label={tr('sign-up-page.form.email')}
                    value={email || ''}
                    isError={false}
                    marginBottom={35}
                    onChangeText={() => {}}
                    testKey='sign-up--email'
                    disabled
                    name='email'
                />
                <PasswordForm form={form} keyPrefix='password-form' />
                <Box marginBottom={25} marginTop={25}>
                    <FormCheckbox
                        checked={form.values.termsAndConditions}
                        onChange={termsAndConditions => form.setValues({ termsAndConditions })}
                        isError={form.errors.termsAndConditions}
                        fontSize='paragraph4'
                        fontWeight='bold'
                        testKey='sign-up--terms-and-conditions-checkbox'
                    >
                        <TermsAndConditionsLabel />
                    </FormCheckbox>
                </Box>
                {isError && (
                    <Paragraph4 color={Color.ContentNegative} marginBottom={20}>
                        {tr('sign-up-page.form.error')}
                    </Paragraph4>
                )}
                <SubmitButton
                    label={tr('sign-up-page.form.sign-up')}
                    disabled={!form.values.termsAndConditions}
                    fullWidth
                    testKey='sign-up--submit-button'
                />
            </Form>
        </Box>
    );
};
