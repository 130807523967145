import React, { FC } from 'react';

import { useAssignmentQuery } from '@hofy/api-admin';
import { BlockLoader, Slideout } from '@hofy/common';

import { DataErasureSlideoutContent } from './DataErasureSlideoutContent';

interface DataErasureSlideoutProps {
    assignmentId: number;
    onClose(): void;
}

export const DataErasureSlideout: FC<DataErasureSlideoutProps> = ({ assignmentId, onClose }) => {
    const { data: assignment, isLoading: assignmentIsLoading } = useAssignmentQuery(assignmentId);

    if (assignmentIsLoading || !assignment?.item || !assignment.dataErasure) {
        return (
            <Slideout width={800} onClose={onClose}>
                <BlockLoader fullHeight flex={1} />
            </Slideout>
        );
    }

    return (
        <DataErasureSlideoutContent
            assignment={assignment}
            dataErasure={assignment.dataErasure}
            onClose={onClose}
        />
    );
};
