import React, { FC } from 'react';

import { ContractDetailsUnionDto } from '@hofy/api-admin';
import {
    Alert,
    FormContainer,
    FormDateInput,
    FormModal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useActivateContract } from '../../../store/contracts/useActivateContract';

interface ActivateContractModalProps {
    contract: ContractDetailsUnionDto;
    onClose(): void;
}

export const ActivateContractModal: FC<ActivateContractModalProps> = ({ contract, onClose }) => {
    const { form, isLoading, isError } = useActivateContract(contract, onClose);

    return (
        <FormModal
            width={480}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title={`Activate contract #${contract.idDeprecated}`} />
            <ModalContent>
                <Alert
                    marginBottom={16}
                    type='warning'
                    title='This operation will create invoice entries for this contract'
                    description='Past invoices entries will be combined into the first invoice line'
                />
                <FormContainer>
                    <FormDateInput label='Activation date' api={form.fields.activeOn} />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={form.discard} />
                <SubmitButton label='Activate contract' />
            </ModalFooter>
        </FormModal>
    );
};
