import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    directivesPlugin,
    headingsPlugin,
    InsertTable,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    markdownShortcutPlugin,
    MDXEditor,
    type MDXEditorMethods,
    tablePlugin,
    toolbarPlugin,
    UndoRedo,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import React, { FC, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Color, ZIndex } from '@hofy/theme';
import { Box, OuterBoxProps, useIsDisabled } from '@hofy/ui';

import { YouTubeButton, YoutubeDirectiveDescriptor } from './plugins/YoutubeDirectiveDescriptor';

interface MarkdownEditorProps extends OuterBoxProps {
    value: string;
    onChange(value: string): void;
    onBlur?(): void;
    isError?: boolean;
    disabled?: boolean;
}

export const MarkdownEditor: FC<MarkdownEditorProps> = ({ value, onChange, onBlur, isError, disabled }) => {
    const editorRef = useRef<MDXEditorMethods>(null);
    const isDisabled = useIsDisabled(disabled);

    useEffect(() => {
        if (editorRef.current && editorRef.current.getMarkdown() !== value) {
            editorRef.current.setMarkdown(value);
        }
    }, [value]);

    const borderColor = isError ? Color.InteractionBorderAlert : Color.InteractionBorderNeutralNormal;

    return (
        <ContainerBox rounded border borderColor={borderColor} inactive={isDisabled}>
            <EditorVariables />
            <MDXEditor
                className='markdown-editor'
                markdown={value}
                onChange={isDisabled ? undefined : onChange}
                onBlur={onBlur}
                plugins={[
                    headingsPlugin({ allowedHeadingLevels: [3, 4] }),
                    tablePlugin(),
                    listsPlugin(),
                    linkPlugin(),
                    linkDialogPlugin(),
                    markdownShortcutPlugin(),
                    directivesPlugin({ directiveDescriptors: [YoutubeDirectiveDescriptor] }),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <Box flex={1} row gap={16}>
                                <BoldItalicUnderlineToggles />
                                {divider}
                                <ListsToggle />
                                {divider}
                                <CreateLink />
                                <InsertTable />
                                <YouTubeButton />
                                {divider}
                                <BlockTypeSelect />
                                <Box row flex={1} justify='flex-end'>
                                    <UndoRedo />
                                </Box>
                            </Box>
                        ),
                    }),
                ]}
                ref={editorRef}
            />
        </ContainerBox>
    );
};

const divider = <Box height={20} width={1} bg={Color.InteractionBorderNeutralNormal} />;

const EditorVariables = createGlobalStyle`
    .markdown-editor {
        --baseBg: ${Color.NonContextualBorderDivider};
        --baseBgHover: ${Color.InteractionNeutralSubtleActive};
        --baseBgActive: ${Color.InteractionNeutralSubtleActive};
        --radius-medium: 4px;

        --accentBase: ${Color.ContentBrand}
        --accentBgSubtle: ${Color.BackgroundSubtleBrand}
        --accentBg: ${Color.BackgroundBrand};
        --accentBgHover: ${Color.InteractionNeutralSubtleActive};
        --accentBgActive: ${Color.BackgroundBrand};
        --accentLine: ${Color.ContentBrand};
        --accentBorder: ${Color.InteractionDefaultNormal};
        --accentBorderHover: ${Color.InteractionDefaultHover};
        --accentSolid: ${Color.InteractionDefaultNormal};
        --accentSolidHover: ${Color.InteractionDefaultHover};
        --accentText: ${Color.ContentBrand};
        --accentTextContrast: ${Color.ContentBrand};

        z-index: ${ZIndex.Dropdown};
    }
`;

const ContainerBox = styled(Box)`
    color: ${Color.ContentPrimary};

    h1,
    h2,
    h3 {
        margin-bottom: 24px;
        font-weight: bold;
    }
    h4,
    h5,
    h6 {
        margin-bottom: 16px;
        font-weight: bold;
    }
    p,
    ul,
    ol,
    table {
        margin-bottom: 16px;
    }

    h1 {
        font-size: ${({ theme }) => theme.fontSize.heading1}px;
    }
    h2 {
        font-size: ${({ theme }) => theme.fontSize.heading2}px;
    }
    h3 {
        font-size: ${({ theme }) => theme.fontSize.heading3}px;
    }
    h4 {
        font-size: ${({ theme }) => theme.fontSize.sectionTitle1}px;
    }
    h5 {
        font-size: ${({ theme }) => theme.fontSize.sectionTitle2}px;
    }
    h6 {
        font-size: ${({ theme }) => theme.fontSize.sectionTitle3}px;
    }

    a {
        color: ${Color.InteractionDefaultNormal};
        text-decoration: underline;
        &:hover {
            color: ${Color.InteractionDefaultHover};
        }
        &:active {
            color: ${Color.InteractionDefaultActive};
        }
    }

    ul,
    ol {
        margin-left: 16px;
    }

    ul li {
        list-style: inside;
    }
    ol li {
        list-style: decimal inside;
    }
`;
