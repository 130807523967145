import React, { FC } from 'react';

import { UserDto, useUserAssignmentsQuery } from '@hofy/api-admin';

import { AssignmentsTable } from './AssignmentsTable';

interface AssignmentsTabProps {
    user: UserDto;
    onOpenAssignment(orderId: number): void;
}

export const AssignmentsTab: FC<AssignmentsTabProps> = ({ user, onOpenAssignment }) => {
    const { data: adminUserAssignments, isLoading } = useUserAssignmentsQuery(user.id);

    return (
        <AssignmentsTable
            assignments={adminUserAssignments}
            isLoading={isLoading}
            onOpenAssignment={onOpenAssignment}
        />
    );
};
