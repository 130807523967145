import { filter, includes, xor } from 'lodash';
import React, { FC, useMemo } from 'react';

import { HofyWarehouseDetailsDto } from '@hofy/api-admin';
import { FilterChip } from '@hofy/ui';

interface WarehouseFilterRendererProps {
    warehouses: HofyWarehouseDetailsDto[];
    warehouseIds: number[];
    onChange(ids: number[]): void;
}

export const WarehouseFilterRenderer: FC<WarehouseFilterRendererProps> = ({
    warehouses,
    warehouseIds,
    onChange,
}) => {
    const selectedWarehouses = useMemo<HofyWarehouseDetailsDto[]>(() => {
        return filter(warehouses, wh => includes(warehouseIds, wh.idDeprecated));
    }, [warehouses, warehouseIds]);

    const removeWarehouse = (id: number) => {
        onChange(xor(warehouseIds, [id]));
    };

    return (
        <>
            {selectedWarehouses.map(wh => (
                <FilterChip
                    label={wh.name}
                    onClear={() => removeWarehouse(wh.idDeprecated)}
                    color='orange'
                    key={wh.idDeprecated}
                />
            ))}
        </>
    );
};
