import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { authService, decodeToken, PublicSSO } from '@hofy/api-auth';
import { errorMap } from '@hofy/helpers';
import { useI18nGeneric } from '@hofy/i18n';
import { useStringQueryParam } from '@hofy/router';
import { isRequired, useForm, validator } from '@hofy/ui';

import {
    emptySignUpConnectionFormPayload,
    emptySignUpFormPayload,
    SignUpConnectionFormPayload,
    SignUpFormPayload,
    SignUpFormPayloadValidation,
} from './types/SignUpFormPayload';
import { useAuth } from './useAuth';
import { useValidatePassword } from './useValidatePassword';

type SignUpFormType = 'email-password' | 'connection';

export const useSignUp = () => {
    const { tr } = useI18nGeneric();
    const [token] = useStringQueryParam('token');
    const validatePassword = useValidatePassword({ keyPrefix: 'password-form', confirmationRequired: true });
    const [connection] = useStringQueryParam('connection');
    const [providerParam] = useStringQueryParam('provider');
    // TODO temporary
    const provider = connection || providerParam;
    const { signInWithPublicSSO, signInWithConnection } = useAuth();

    const email = useMemo(() => {
        if (token) {
            return decodeToken(token).email;
        }
        return null;
    }, [token]);

    const signUpWithPublicSSO = async (sso: PublicSSO) => {
        signInWithPublicSSO(sso, token);
    };

    const signUpWithConnection = async () => {
        signInWithConnection(provider!, token);
    };

    const signUpFormState = useForm<SignUpFormPayload, SignUpFormPayload, SignUpFormPayloadValidation>({
        initial: {
            ...emptySignUpFormPayload,
        },
        validate: formValues => ({
            ...validatePassword(formValues),
            termsAndConditions: errorMap([
                !formValues.termsAndConditions,
                tr('sign-up-page.form.terms-and-conditions.required'),
            ]),
        }),
        onSubmit: ({ password }) => {
            mutation.mutate({
                email: email!,
                password: password,
                token: token!,
            });
        },
    });

    const signUpConnectionFormState = useForm({
        initial: {
            ...emptySignUpConnectionFormPayload,
        },
        validate: validator<SignUpConnectionFormPayload>({
            termsAndConditions: isRequired(tr('sign-up-page.form.terms-and-conditions.required')),
        }),
        onSubmit: signUpWithConnection,
    });

    const mutation = useMutation({
        mutationFn: authService.signUp,
    });

    const formType: SignUpFormType = !provider ? 'email-password' : 'connection';

    return {
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        isError: mutation.isError,
        signUpFormState,
        signUpConnectionFormState,
        signUpWithPublicSSO,
        signUpWithConnection,
        email,
        formType,
    };
};
