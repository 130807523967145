import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { userService } from '../service/userService';
import { usersCacheKey } from './cacheKey';

export const useUserRefsQuery = (organizationId?: UUID) => {
    const { data = [], isLoading } = useQuery({
        queryKey: [usersCacheKey, organizationId],
        queryFn: () => userService.getUserRefs(organizationId),
    });
    return {
        data,
        isLoading,
    };
};
