import { errorMap, isInvalidRegex } from '@hofy/helpers';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { PasswordPayload, PasswordPayloadValidation } from './types/PasswordPayload';

const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
const PASSWORD_SYMBOL_REGEX = /^(?=.*[!@#$%^&*])/;
const PASSWORD_CAPITAL_REGEX = /^(?=.*[A-Z])/;
const PASSWORD_NUMBER_REGEX = /^(?=.*[0-9])/;

const isInvalidPasswordLength = (p?: string) => !p || p.length < 8;

export const useValidatePassword = ({
    keyPrefix,
    confirmationRequired,
}: {
    keyPrefix: string;
    confirmationRequired?: boolean;
}) => {
    const { trParts } = useAuthI18n();

    return (v: PasswordPayload): PasswordPayloadValidation => ({
        password: errorMap([
            isInvalidRegex(v.password, PASSWORD_REGEX) || isInvalidPasswordLength(v.password),
            trParts(keyPrefix, 'password.errors.not-valid'),
        ]),
        confirmedPassword: confirmationRequired
            ? errorMap([
                  !!v.password && v.confirmedPassword !== v.password,
                  trParts(keyPrefix, 'confirmed-password.errors.not-match'),
              ])
            : undefined,
        v8Characters: isInvalidPasswordLength(v.password),
        vCapitalCharacters: isInvalidRegex(v.password, PASSWORD_CAPITAL_REGEX),
        vSymbolCharacters: isInvalidRegex(v.password, PASSWORD_SYMBOL_REGEX),
        vNumberCharacters: isInvalidRegex(v.password, PASSWORD_NUMBER_REGEX),
    });
};
