import React, { FC, ReactNode, useMemo } from 'react';

import { useSuppliersRefsQuery } from '@hofy/api-admin';
import { SupplierRefDto } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { BoxProps, TestKeyAware } from '@hofy/ui';

interface SupplierDropdownProps extends TestKeyAware, BoxProps {
    value: number | null | undefined;
    label: string;
    onChange(o: SupplierRefDto): void;
    emptyContent?: ReactNode;
    disabled?: boolean;
    isError?: boolean | string;
    isRequired?: boolean;
    filterSuppliers?(s: SupplierRefDto): boolean;
}

const initialItems: SupplierRefDto[] = [];

export const SupplierDropdown: FC<SupplierDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired,
    testKey,
    filterSuppliers,

    ...boxProps
}) => {
    const { data: suppliers } = useSuppliersRefsQuery();
    const refValue = useMemo(() => {
        if (value && suppliers) {
            return suppliers.find(o => o.id === value);
        }
        return undefined;
    }, [value, suppliers]);

    let filteredSuppliers = suppliers;

    if (filterSuppliers && suppliers) {
        filteredSuppliers = suppliers.filter(filterSuppliers);
    }

    return (
        <FormDropdown
            items={filteredSuppliers || initialItems}
            labelFormatter={v => v.name}
            onChange={onChange}
            label={label}
            value={refValue}
            toSearchLabel={v => v.name}
            searchable
            emptyContent={emptyContent}
            isRequired={isRequired}
            isError={isError}
            testKey={testKey}
            {...boxProps}
        />
    );
};
