import { repaymentPlanService } from '@hofy/api-admin';

import { useSinglePlanMutation } from './useSinglePlanMutation';

export const useDeleteRejectedRepaymentPlan = (planId: number, onSuccess?: () => void) => {
    return useSinglePlanMutation(
        planId,
        repaymentPlanService.deleteRejectedPlan,
        'Rejected repayment plan deleted',
        onSuccess,
    );
};
