import React, { ReactNode, useCallback, useRef } from 'react';

import { useScrollEnd, useTableInitialLoad } from '@hofy/hooks';

import { TestKeyAware } from '../../../types';
import { Box, BoxProps } from '../../base';
import { BaseTableProps, BaseTableWithRef } from '../BaseTable';
import { BaseTableRowSkeleton } from '../BaseTableRow';
import { BaseTableColumnConfig } from '../types/BaseTableColumnConfig';

export interface InfiniteScrollConfig {
    hasMore?: boolean;
    isLoading: boolean;
    isLoadingMore: boolean;
    loadMore(): void;
}

interface InfinityScrollTableProps<T> extends BoxProps, TestKeyAware, BaseTableProps<T> {
    infinityScroll: InfiniteScrollConfig;
    data: T[];
    columns: BaseTableColumnConfig<T>[];
    onRowClick?(v: T): void;
    emptyContent?: ReactNode;
    headerRenderer?(columns: BaseTableColumnConfig<T>[]): ReactNode;
}

export const InfinityScrollTable = <T extends any>({
    infinityScroll,
    columns,
    headerRenderer,
    minWidth,
    rowRenderer,
    rowVerticalPadding = 12,
    rowHorizontalPadding = 20,
    ...restProps
}: InfinityScrollTableProps<T>) => {
    const ref = useRef<HTMLDivElement>(null);
    const { isLoadingMore, isLoading, loadMore, hasMore } = infinityScroll;
    const callback = useCallback(() => {
        if (!isLoadingMore && hasMore) {
            loadMore();
        }
    }, [isLoadingMore, hasMore, loadMore]);
    useScrollEnd(ref, callback);
    useTableInitialLoad(ref, callback, isLoading, isLoadingMore, hasMore || false);

    const bodyRender = (content: ReactNode) => {
        return (
            <Box
                minWidth={minWidth}
                paddingHorizontal={20}
                paddingVertical={10}
                fullWidth
                flex={1}
                column
                alignItems='stretch'
                data-test-key='table-body'
            >
                {content}
                {isLoadingMore && (
                    <BaseTableRowSkeleton
                        data-test-key='table-row-skeleton'
                        rowVerticalPadding={rowVerticalPadding}
                        rowHorizontalPadding={rowHorizontalPadding}
                        columns={columns}
                        isFirst={false}
                    />
                )}
            </Box>
        );
    };

    return (
        <BaseTableWithRef
            ref={ref}
            isLoading={isLoading && !restProps.data.length}
            columns={columns as any}
            minWidth={minWidth}
            headerRenderer={headerRenderer as any}
            rowRenderer={rowRenderer as any}
            bodyRenderer={bodyRender}
            rowVerticalPadding={rowVerticalPadding}
            rowHorizontalPadding={rowHorizontalPadding}
            {...restProps}
        />
    );
};
