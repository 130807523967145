import { useHistory } from 'react-router-dom';

import { useSession } from '@hofy/auth';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import {
    AdminAccountingTab,
    AdminAccountingTabLink,
    adminAccountingTabPermissions,
    allAdminAccountingTabs,
} from '../../views/accountingPage/AdminAccountingTab';

export const useAdminNavigateAccounting = () => {
    const { hasPermission } = useSession();
    const history = useHistory();

    let adminAccountingTabs = allAdminAccountingTabs.filter(tab =>
        hasPermission(adminAccountingTabPermissions[tab]),
    );

    const navigateAccounting = (tab: AdminAccountingTab) => {
        history.push(`${AdminNavLink.Accounting}/${AdminAccountingTabLink[tab]}`);
    };

    return {
        // If there is a single tab to navigate, we return an empty array to hide the tabs
        adminAccountingTabs: adminAccountingTabs.length > 1 ? adminAccountingTabs : [],
        navigateAccounting,
    };
};
