import React, { FC } from 'react';

import { Page } from '@hofy/common';
import { PageHeader, Tab, Tabs } from '@hofy/ui';

import { SalesOrderTab } from '../../../../store/invoicing/salesOrders/types/SalesOrderTab';
import { SalesOrderDetailsPageMenu } from './SalesOrderDetailsPageMenu';
import { SalesOrderDetailsTabContent } from './tabsContent/SalesOrderDetailsTabContent';
import { SalesOrderInvoiceEntriesTabContent } from './tabsContent/SalesOrderInvoiceEntriesTabContent';

interface SalesOrderDetailsPageProps {
    salesOrderId: number;
    salesOrderTab: SalesOrderTab;
    onOpenInvoiceEntry(id: number): void;
    onChangeTab(tab: SalesOrderTab): void;
}

export const SalesOrderDetailsPage: FC<SalesOrderDetailsPageProps> = ({
    salesOrderId,
    salesOrderTab,
    onOpenInvoiceEntry,
    onChangeTab,
}) => {
    const renderContent = () => {
        switch (salesOrderTab) {
            case SalesOrderTab.Details:
                return <SalesOrderDetailsTabContent salesOrderId={salesOrderId} />;
            case SalesOrderTab.InvoiceEntries:
                return (
                    <SalesOrderInvoiceEntriesTabContent
                        salesOrderId={salesOrderId}
                        onOpenInvoiceEntry={onOpenInvoiceEntry}
                    />
                );
        }
    };

    return (
        <Page>
            <PageHeader
                title={`Sales order #${salesOrderId}`}
                rightSlot={<SalesOrderDetailsPageMenu salesOrderId={salesOrderId} />}
                tabsSlot={
                    <Tabs active={salesOrderTab} onChange={onChangeTab}>
                        <Tab id={SalesOrderTab.Details} label='Details' />
                        <Tab id={SalesOrderTab.InvoiceEntries} label='Invoice entries' />
                    </Tabs>
                }
            />
            {renderContent()}
        </Page>
    );
};
