import { useQuery } from '@tanstack/react-query';

import { messagingService } from '../service/messagingService';
import { adminUserMessageQueryKey } from './cacheKey';

export const useMessageQuery = (messageId: number) => {
    const { isLoading: isLoading, data: message } = useQuery({
        queryKey: [adminUserMessageQueryKey, messageId],
        queryFn: () => messagingService.getMessage(messageId),
    });

    return {
        message,
        isLoading: isLoading,
    };
};
