import { filter, includes, map, xor } from 'lodash';
import React, { FC } from 'react';

import {
    AccountKey,
    allAccountKeys,
    JobContext,
    useHofySubsidiaryRefs,
    useJobsSummary,
    useJournals,
} from '@hofy/api-admin';
import {
    allNetsuiteSyncStatuses,
    HofySubsidiaryRef,
    netsuiteStatusColors,
    NetsuiteSyncStatus,
} from '@hofy/api-shared';
import { formatDateRange } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import {
    Box,
    FilterChip,
    FilterChipList,
    LabeledDateRangeInput,
    PageHeader,
    SearchInput,
    SvgIcon,
    TestKeyAware,
} from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { SearchableEnumMultiBlockFilter } from '../../../components/design/blockFilters/SearchableEnumMultiBlockFilter';
import { JobsSummaryIndicator } from '../../../components/domain/job/JobsSummaryIndicator';
import { useTrAccountKey } from '../../../store/accounting/useTrAccountKey';
import { useJournalsFilters } from '../../../store/journals/useJournalsFilters';
import { useTrNetsuiteSyncStatus } from '../../../store/netsuite/useTrNetsuiteSyncStatus';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { JournalsPageMenu } from './JournalsPageMenu';
import { JournalsTable } from './JournalsTable';

interface JournalsPageProps extends TestKeyAware {
    onOpenJobs(): void;
}

export const JournalsPage: FC<JournalsPageProps> = ({ onOpenJobs }) => {
    const {
        search,
        setSearch,
        filters,
        filterCount,
        setDateRange,
        setHofySubsidiaryIds,
        setDebitAccounts,
        setCreditAccounts,
        setNetsuiteStatuses,
    } = useJournalsFilters();

    const { journals, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useJournals(filters);

    const journalsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });

    const trAccountKey = useTrAccountKey();
    const trNetsuiteStatus = useTrNetsuiteSyncStatus();
    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const subsidiaryName = (id: number) => hofySubsidiaries.find(s => s.id === id)?.name || '';

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    const { data: jobsSummary } = useJobsSummary([JobContext.Journals]);

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Journals'
                titleSlot={<JobsSummaryIndicator onClick={onOpenJobs} jobsSummary={jobsSummary} />}
                rightSlot={
                    <>
                        <SearchInput value={search} onChange={setSearch} placeholder='Journal ID' autoFocus />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <JournalsPageMenu />
                    </>
                }
                tabsSlot={<AccountingTabs tab={AdminAccountingTab.Journals} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <ListMultiBlockFilter
                    title='Subsidiary'
                    selected={filter(hofySubsidiaries, s => includes(filters.hofySubsidiaryIds, s.id))}
                    items={hofySubsidiaries}
                    onChange={filter =>
                        setHofySubsidiaryIds(map<HofySubsidiaryRef, number>(filter, s => s.id))
                    }
                    renderItem={s => s?.name || ''}
                />
                <SearchableEnumMultiBlockFilter<AccountKey>
                    title='Debit account'
                    icon={SvgIcon.Tag}
                    selected={filters.debitAccounts}
                    onChange={setDebitAccounts}
                    items={allAccountKeys}
                    renderItem={key => trAccountKey(key)}
                    searchPlaceholder='Search debit account'
                    toText={key => trAccountKey(key) ?? ''}
                />
                <SearchableEnumMultiBlockFilter<AccountKey>
                    title='Credit account'
                    icon={SvgIcon.Tag}
                    selected={filters.creditAccounts}
                    onChange={setCreditAccounts}
                    items={allAccountKeys}
                    renderItem={key => trAccountKey(key)}
                    searchPlaceholder='Search credit account'
                    toText={key => trAccountKey(key) ?? ''}
                />
                <EnumMultiBlockFilter<NetsuiteSyncStatus>
                    title='Netsuite sync'
                    icon={SvgIcon.Link}
                    selected={filters.netsuiteStatuses}
                    onChange={setNetsuiteStatuses}
                    items={allNetsuiteSyncStatuses}
                    renderItem={status => trNetsuiteStatus(status)}
                />
                <BlockFilter title='Date' icon={SvgIcon.Calendar}>
                    <LabeledDateRangeInput
                        label='Transaction date'
                        value={filters.dateRange}
                        onChange={setDateRange}
                        nullable
                        marginLeft={12}
                        marginTop={8}
                    />
                </BlockFilter>
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={key => key}
                    selected={filters.debitAccounts}
                    toLabel={key => `Debit: ${trAccountKey(key)}`}
                    onClear={key => setDebitAccounts(xor(filters.debitAccounts, [key]))}
                    color='orange'
                />
                <FilterChipList
                    toKey={key => key}
                    selected={filters.creditAccounts}
                    toLabel={key => `Credit: ${trAccountKey(key)}`}
                    onClear={key => setCreditAccounts(xor(filters.creditAccounts, [key]))}
                    color='teal'
                />
                <FilterChipList
                    toKey={status => status}
                    selected={filters.netsuiteStatuses}
                    toLabel={trNetsuiteStatus}
                    onClear={value => setNetsuiteStatuses(xor(filters.netsuiteStatuses, [value]))}
                    color={p => netsuiteStatusColors[p]}
                />
                {filters.dateRange && (
                    <FilterChip
                        label={formatDateRange(filters.dateRange)}
                        onClear={() => setDateRange(null)}
                        color='teal'
                    />
                )}
                <FilterChipList
                    toKey={subsidiary => subsidiary}
                    selected={filters.hofySubsidiaryIds}
                    toLabel={subsidiaryName}
                    onClear={value => setHofySubsidiaryIds(xor(filters.hofySubsidiaryIds, [value]))}
                    color='blue'
                />
            </BlockFilterChipContainer>
            <JournalsTable journals={journals} infinityScroll={journalsScrollDetails} />
        </Box>
    );
};
