import React, { FC } from 'react';

import { AuthError } from '@hofy/api-auth';
import { FormInput } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Form, Paragraph3, Paragraph4, SubmitButton } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { SingInForm } from '../../store/auth/useSignIn';
import { useTrAuthError } from '../../store/auth/useTrAuthError';
import { AuthPageWrapper } from '../components/authPageWrapper/AuthPageWrapper';

interface TwoFaFormProps {
    authError?: AuthError;
    form: SingInForm;
    isLoading: boolean;
}

export const TwoFaForm: FC<TwoFaFormProps> = ({ form, authError, isLoading }) => {
    const { tr } = useAuthI18n();
    const trAuthError = useTrAuthError();
    return (
        <AuthPageWrapper pageId='sign-in' title={tr('sign-in-page.2fa.title')}>
            <Form onSubmit={form.submit} submitOnEnter isLoading={isLoading} marginTop={32}>
                <Paragraph3 color={Color.ContentPrimary} marginBottom={30}>
                    {tr('sign-in-page.2fa.subtitle')}
                </Paragraph3>
                <FormInput
                    label={tr('sign-in-page.email-password-form.code')}
                    value={form.values.code}
                    isError={form.errors.code}
                    onChangeText={code => form.setValues({ code })}
                    testKey='2fa-code-input'
                    name='code'
                />
                {!!authError && (
                    <Paragraph4 color={Color.ContentNegative} marginTop={8}>
                        {trAuthError(authError)}
                    </Paragraph4>
                )}
                <SubmitButton
                    label={tr('sign-in-page.email-password-form.sign-in')}
                    disableCheck
                    fullWidth
                    testKey='submit-button'
                    marginTop={24}
                />
            </Form>
        </AuthPageWrapper>
    );
};
