import { useQuery } from '@tanstack/react-query';

import { assignmentService } from '../service/assignmentService';
import { assignmentConfigurationCacheKey } from './cacheKey';

export const useAssignmentConfigurationQuery = (id: number) => {
    const { data, isLoading } = useQuery({
        queryKey: [assignmentConfigurationCacheKey, id],

        queryFn: () => assignmentService.getAssignmentConfiguration(id),
    });

    return {
        data,
        isLoading,
    };
};
