import React, { FC } from 'react';

import { useMessageQuery } from '@hofy/api-admin';

import { MessagePreviewSlideout } from '../../../components/domain/messages/MessagePreviewSlideout';

interface UserMessagePreviewSlideoutProps {
    messageId: number;
    onClose(): void;
}

export const UserMessagePreviewSlideout: FC<UserMessagePreviewSlideoutProps> = ({ messageId, onClose }) => {
    const { message, isLoading } = useMessageQuery(messageId);
    return <MessagePreviewSlideout message={message} isLoading={isLoading} onClose={onClose} />;
};
