import React, { FC, ReactNode } from 'react';

import { StockLevelCountsDto, WarehouseDetailsUnionDto } from '@hofy/api-admin';
import {
    AssignmentStatus,
    AssignmentType,
    isDeliveryPending,
    ItemLocation,
    LocationDto,
    WarehouseBinDto,
} from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon, Tooltip } from '@hofy/ui';

import { StockLevelTooltip } from '../../../../inventoryPage/stockLevels/components/StockLevelTooltip';
import { ItemLocationCell } from '../../../../itemsPage/components/ItemLocationCell';

interface ItemAvailabilityCellProps {
    status: AssignmentStatus;
    assignmentType: AssignmentType;
    stockLevelCounts: StockLevelCountsDto | null;
    sku: string | null;
    itemId: number | null;
    isRefurbished: boolean;
    assignedBin: WarehouseBinDto | null;
    warehouse: WarehouseDetailsUnionDto | null;
    itemLocation: ItemLocation | null;
}

export const ItemAvailabilityCell: FC<ItemAvailabilityCellProps> = ({
    status,
    assignmentType,
    stockLevelCounts,
    sku,
    itemId,
    isRefurbished,
    assignedBin,
    itemLocation,
    warehouse,
}) => {
    const shouldDisplayStockDetail =
        isDeliveryPending(status) &&
        [AssignmentType.Standard, AssignmentType.Replacement].includes(assignmentType) &&
        !itemId &&
        !isRefurbished &&
        stockLevelCounts &&
        sku;

    if (shouldDisplayStockDetail) {
        return (
            <ItemCellWrapper>
                <StockLevelCell sku={sku} stockLevelCounts={stockLevelCounts} />
            </ItemCellWrapper>
        );
    }

    const location = ((): LocationDto | null => {
        if (!itemLocation) {
            return null;
        }
        if (!warehouse) {
            return {
                location: itemLocation,
                warehouse: null,
                user: null,
            };
        }
        return {
            location: itemLocation,
            warehouse: {
                id: warehouse.idDeprecated,
                uuid: warehouse.id,
                publicId: warehouse.publicId,
                ownership: warehouse.ownership,
                name: warehouse.name,
                bin: assignedBin,
                country: null,
            },
            user: null,
        };
    })();

    if (location) {
        return (
            <ItemCellWrapper>
                <ItemLocationCell location={location} />
            </ItemCellWrapper>
        );
    }

    // Needed to maintain column spacing
    return <ItemCellWrapper />;
};

interface StockLevelCellProps {
    sku: string;
    stockLevelCounts: StockLevelCountsDto;
}

const StockLevelCell: FC<StockLevelCellProps> = ({ sku, stockLevelCounts }) => {
    const color =
        stockLevelCounts.available < stockLevelCounts.minQuantity
            ? stockLevelCounts.available > 0
                ? Color.NonContextualOrangeDefault
                : Color.NonContextualRedDefault
            : Color.ContentPositive;
    return (
        <Tooltip
            bodySlot={<StockLevelTooltip sku={sku} stockLevel={stockLevelCounts} />}
            maxWidth='auto'
            interactive
            asChild
        >
            <Paragraph3 color={Color.ContentTertiary}>Availability</Paragraph3>
            <Box gap={8}>
                <Box row gap={4}>
                    <Icon svg={SvgIcon.Home} color={color} />
                    <Paragraph3 color={color}>{stockLevelCounts.available}</Paragraph3>
                </Box>
                <Box row gap={4}>
                    <Icon color={Color.ContentSecondary} svg={SvgIcon.Box} />
                    <Paragraph3>{stockLevelCounts.openAssignments.total}</Paragraph3>
                </Box>
            </Box>
        </Tooltip>
    );
};

interface ItemCellWrapperProps {
    children?: ReactNode;
}

const ItemCellWrapper: FC<ItemCellWrapperProps> = ({ children }) => {
    return (
        <Box width={160} column>
            {children}
        </Box>
    );
};
