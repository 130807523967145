import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';

import { invoiceMonths } from '@hofy/api-admin';
import { formatMonth, nowDate } from '@hofy/helpers';
import {
    AsyncButton,
    LabeledSelect,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    MonthLabel,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';

interface GenerateInvoicesModalProps {
    onClose(): void;
    onSubmit(invoiceDate: DateTime): void;
    isLoading: boolean;
}

export const GenerateInvoicesModal: FC<GenerateInvoicesModalProps> = ({ onSubmit, onClose, isLoading }) => {
    const [invoiceDate, setInvoiceDate] = useState(
        nowDate()
            .startOf('month')
            .minus({
                month: 1,
            })
            .endOf('month'),
    );

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Generate invoices' />
            <ModalContent>
                <LabeledSelect
                    options={invoiceMonths}
                    label='Invoicing month'
                    toText={formatMonth}
                    toLabel={date => <MonthLabel date={date} />}
                    value={invoiceDate}
                    onChange={date => setInvoiceDate(date!)}
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Generate'
                    disableCheck
                    onClick={() => onSubmit(invoiceDate)}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
