import React, { FC } from 'react';

import { Box, Button, FeaturedIcon, Paragraph3, SectionTitle2, SvgIcon } from '@hofy/ui';

import { useAuthI18n } from '../../../../i18n/useAuthI18n';

interface EmailSentStepProps {
    goBack(): void;
    email: string;
}

export const EmailSentStep: FC<EmailSentStepProps> = ({ goBack, email }) => {
    const { tr } = useAuthI18n();

    return (
        <Box column gap={24}>
            <Box column gap={4}>
                <SectionTitle2>{tr('org-sign-up-page.email-verification.title')}</SectionTitle2>
                <Paragraph3>{email}</Paragraph3>
            </Box>
            <Box row gap={16}>
                <FeaturedIcon type='positive' shape='square' icon={SvgIcon.Mail} size={40} />
                <Paragraph3>{tr('org-sign-up-page.email-verification.message')}</Paragraph3>
            </Box>
            <Box row gap={4}>
                <Paragraph3>{tr('org-sign-up-page.email-verification.wrong-email')}</Paragraph3>
                <Button
                    type='plain'
                    onClick={goBack}
                    label={tr('org-sign-up-page.email-verification.back')}
                />
            </Box>
        </Box>
    );
};
