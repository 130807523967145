import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { allShipmentStatuses, pendingShipmentStatuses } from '@hofy/api-shared';
import { EnumRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { ShipmentTab } from '../../store/shipments/types/ShipmentTab';
import { AssignmentSlideoutRouter } from '../assignmentSlideout/AssignmentSlideoutRouter';
import { ShipmentSlideoutRouter } from '../shipmentSlideout/ShipmentSlideoutRouter';
import { ShipmentsPage } from './ShipmentsPage';

export const ShipmentsRouter: FC = () => {
    const history = useHistory();
    const handleOpenTab = (tab: ShipmentTab) => {
        history.push(`/shipments/${tab}`);
    };

    const handleOpenShipment = (tab: ShipmentTab, id: number) => {
        history.push(`${AdminNavLink.Shipments}/${tab}/shipment/${id}`);
    };

    const handleOpenShipmentTrackingHistory = (tab: ShipmentTab, id: number) => {
        history.push(`${AdminNavLink.Shipments}/${tab}/shipment/${id}/tracking`);
    };

    const handleDefaultShipmentStatuses = (tab: ShipmentTab) => {
        switch (tab) {
            case ShipmentTab.Pending:
            case ShipmentTab.ShipToday:
            case ShipmentTab.RealTimeTracking:
                return pendingShipmentStatuses;
            case ShipmentTab.All:
                return allShipmentStatuses;
            default:
                return allShipmentStatuses;
        }
    };

    return (
        <Switch>
            <ShipmentsRoute path='/shipments/:filter'>
                {({ filter }) => (
                    <>
                        <ShipmentsPage
                            defaultShipmentStatus={handleDefaultShipmentStatuses(filter)}
                            onChangeTab={handleOpenTab}
                            tab={filter}
                            onOpenShipment={id => handleOpenShipment(filter, id)}
                            onOpenShipmentTrackingHistory={id =>
                                handleOpenShipmentTrackingHistory(filter, id)
                            }
                        />
                        <ShipmentSlideoutRouter
                            base={`/shipments/${filter}/shipment`}
                            assignmentsBase={`/shipments/${filter}/assignment`}
                            onCloseBase={`/shipments/${filter}`}
                        />
                        <AssignmentSlideoutRouter
                            base={`/shipments/${filter}/assignment`}
                            shipmentsBase={`/shipments/${filter}/shipment`}
                            onCloseBase={`/shipments/${filter}`}
                        />
                    </>
                )}
            </ShipmentsRoute>
            <Redirect to='/shipments/pending' from='/shipments' />
        </Switch>
    );
};

const ShipmentsRoute = EnumRoute<ShipmentTab>('filter', ShipmentTab, Route);
