import React, { FC } from 'react';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { AuthPageWrapper } from '../components/authPageWrapper/AuthPageWrapper';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPasswordPage: FC = () => {
    const { tr } = useAuthI18n();

    return (
        <AuthPageWrapper
            pageId='forgot-password'
            title={tr('forgot-password-page.title')}
            subtitle={tr('forgot-password-page.subtitle')}
            showSubHeader
        >
            <ForgotPasswordForm />
        </AuthPageWrapper>
    );
};
