import React, { FC, useMemo, useState } from 'react';

import { emptyItemsFilters, ItemsFilters, useItems } from '@hofy/api-admin';
import { formatVariant } from '@hofy/api-shared';
import { FormDropdown, FormDropdownProps } from '@hofy/common';
import { Box, Paragraph3 } from '@hofy/ui';

import { ItemLocationCell } from '../../../views/itemsPage/components/ItemLocationCell';
import { ProductItem } from '../products/ProductItem';

interface ItemListDropdownProps extends Omit<FormDropdownProps<number | null>, 'items' | 'labelFormatter'> {
    value: number | null;
    onChange(o: number | null): void;
    filters?: Omit<ItemsFilters, 'search'>;
}

export const ItemListDropdown: FC<ItemListDropdownProps> = ({
    value,
    onChange,
    filters = emptyItemsFilters,
    ...rest
}) => {
    const [search, setSearch] = useState('');
    const { items, isLoading } = useItems({ ...filters, search: search }, 10);

    const itemIds = useMemo(() => items.map(i => i.id), [items]);
    const itemsRecord = useMemo(() => Object.fromEntries(items.map(i => [i.id, i])), [items]);

    const labelFormatter = (id: number) => `#${id}`;
    const labelListFormatter = (id: number) => {
        if (isLoading || !itemsRecord[id]) {
            return null;
        }
        const { variant, product, location } = itemsRecord[id];
        return (
            <Box row gap={10}>
                <Paragraph3>#{id}</Paragraph3>
                <Box flex={2}>
                    <ProductItem
                        image={variant.image}
                        label={product.name}
                        subLabel={formatVariant(variant)}
                    />
                </Box>
                <Box flex={1}>
                    <ItemLocationCell location={location} />
                </Box>
            </Box>
        );
    };

    return (
        <FormDropdown
            {...rest}
            items={itemIds}
            value={value}
            onChange={onChange}
            labelFormatter={labelFormatter}
            labelListFormatter={labelListFormatter}
            searchable
            searchPlaceholder='Search items by ID, SKU, MPN, Product or User'
            onSearchChange={setSearch}
            loadingSearchResults={isLoading}
            emptyContent='Select an item'
        />
    );
};
