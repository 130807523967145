import { useRepaymentPlanQuery } from '@hofy/api-admin';

export const useRepaymentPlan = (planId: number) => {
    const { data, isError, isLoading } = useRepaymentPlanQuery(planId);

    return {
        plan: data,
        isLoading,
        isError,
    };
};
