import { repaymentPlanService } from '@hofy/api-admin';

import { useSinglePlanMutation } from './useSinglePlanMutation';

export const useForceRecreateRepaymentPlan = (planId: number) => {
    return useSinglePlanMutation(
        planId,
        repaymentPlanService.forceRecreatePlan,
        'Repayment plan moved to pending',
    );
};
