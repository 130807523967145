import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { ManagementContractUnionDetailsDto } from '@hofy/api-admin';
import {
    ManagementContractStatus,
    ManagementContractType,
    timeOrderedContractStatus,
    useTrManagementContractStatus,
    useTrManagementContractType,
} from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutHeader, Timeline } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, CountryLabel, FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { CustomerAssetStorageLocationChip } from '../../../components/domain/contracts/CustomerStorageLocationChip';
import { BillingEntityLink } from '../../invoicingPage/billingEntities/BillingEntityLink';
import { ManagementContractStatusLabel } from './ManagementContractStatusLabel';

interface ManagementSectionProps {
    managementContract: ManagementContractUnionDetailsDto;
    onClose(): void;
}

export const ManagementSection: FC<ManagementSectionProps> = ({ managementContract, onClose }) => {
    const { formatPrice, formatMonthlyPrice } = usePrice();
    const trContractStatus = useTrManagementContractStatus();
    const trContractType = useTrManagementContractType();

    const { idDeprecated, billingEntity, hofySubsidiary, type, status, billingToAddress } =
        managementContract;
    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title={`Service package details #${idDeprecated}`} justify='space-between' />
            <SlideoutContent paddingBottom={30}>
                <FormSection marginTop={20} label='Invoicing details'>
                    <FormGridRow columns={2}>
                        <LabeledText label='Type' content={trContractType(type)} />
                        <LabeledText label='Status' content={trContractStatus(status)} />
                        <LabeledText label='Invoice from' content={hofySubsidiary.name} />
                        <LabeledText
                            label='Invoice to'
                            content={<BillingEntityLink billingEntity={billingEntity} />}
                        />
                    </FormGridRow>
                    <FormGridRow columns={1}>
                        <LabeledText
                            label='Customer invoice address'
                            content={<PostAddressView address={billingToAddress} />}
                        />
                    </FormGridRow>
                    {type === ManagementContractType.StoreAndReuse && (
                        <>
                            <FormGridRow columns={2}>
                                <LabeledText
                                    label='Activation fee'
                                    content={formatPrice(
                                        managementContract.storeAndReuseContractDetail.activationFee,
                                    )}
                                />
                                <LabeledText
                                    label='Storage fee'
                                    content={formatMonthlyPrice(
                                        managementContract.storeAndReuseContractDetail.storageMonthlyFee,
                                    )}
                                />
                            </FormGridRow>
                            <FormGridRow columns={2}>
                                <Labeled
                                    label='Storage country'
                                    content={
                                        <CountryLabel
                                            country={managementContract.storeAndReuseContractDetail.country}
                                        />
                                    }
                                />
                                <Labeled
                                    label='Storage location'
                                    content={
                                        (
                                            <CustomerAssetStorageLocationChip
                                                storageLocation={
                                                    managementContract.storeAndReuseContractDetail
                                                        .collectionLocation
                                                }
                                            />
                                        ) ?? '--'
                                    }
                                />
                                <ManagementContractStatusLabel managementContract={managementContract} />
                            </FormGridRow>
                        </>
                    )}
                </FormSection>
                <FormSection marginTop={20} label='Timeline'>
                    <ServicePackageTimeline contract={managementContract} />
                </FormSection>
            </SlideoutContent>
        </Slideout>
    );
};

interface ServicePackageTimelineProps {
    contract: ManagementContractUnionDetailsDto;
}

const ServicePackageTimeline: FC<ServicePackageTimelineProps> = ({ contract }) => {
    const trContractStatus = useTrManagementContractStatus();

    const extractUpdatedAt = (status: ManagementContractStatus) => {
        switch (status) {
            case ManagementContractStatus.Pending:
                return contract.pendingOn;
            case ManagementContractStatus.Cancelled:
                return contract.cancelledOn;
            case ManagementContractStatus.Active:
                return contract.activeOn;
            case ManagementContractStatus.Ended:
                return contract.endedOn;
        }
    };

    const filteredStatuses = timeOrderedContractStatus.filter(status => extractUpdatedAt(status));
    return (
        <Box row>
            <Timeline
                currentIndex={filteredStatuses.length - 1}
                items={filteredStatuses.map(status => {
                    return (
                        <Box key={status}>
                            <Paragraph3 bold>{trContractStatus(status)}:</Paragraph3>
                            <Paragraph3 color={Color.ContentTertiary}>
                                {formatDate(extractUpdatedAt(status))}
                            </Paragraph3>
                        </Box>
                    );
                })}
            />
        </Box>
    );
};
