import {
    AddressDto,
    AuditableModelDeprecatedDto,
    BillingEntityContactDto,
    BillingEntityPaymentTerms,
    BillingEntityStatus,
    NetsuiteStatusDto,
    OrganizationRefDto,
    TwoCreditDecision,
    TwoStatusDto,
} from '@hofy/api-shared';
import { Country, Currency, DateString, DateTimeString, Price, UUID } from '@hofy/global';

export interface BillingEntityDto extends AuditableModelDeprecatedDto {
    isDefault: boolean;
    organization: OrganizationRefDto | null;
    name: string;
    status: BillingEntityStatus;
    registeredCompanyNumber: string | null;
    contacts: BillingEntityContactDto[];
    vatNumber: string | null;
    currency: Currency;
    paymentTerms: BillingEntityPaymentTerms;
    billingAddress: AddressDto | null;
    countries: Country[];
    isContractSigned: boolean;
    netsuite: NetsuiteStatusDto;
    deel: DeelBillingEntitySyncDto;
    twoSpreadsheetEnabled: boolean;
    twoApiEnabled: boolean;
    twoRecourseLimit: Price | null;
    twoCredit: TwoCreditDto | null;
    isConfirmedByManager: boolean;
    twoStatuses: TwoStatusDto[];
    twoRecoursedBack: boolean;
}

export interface BillingEntityDetailsDto extends BillingEntityDto {
    hasInvoiceEntries: boolean;
}

interface DeelBillingEntitySyncDto {
    id: UUID | null;
    lastUpdatedAt: DateTimeString | null;
}

interface TwoCreditDto {
    creditDecision: TwoCreditDecision;
    creditMonths: number;
    creditCheckedAt: DateString;
    monthlyCredit: Price;
}

export const getCountryBillingEntity = (
    billingEntities: BillingEntityDto[],
    country: Country,
): BillingEntityDto | undefined => {
    const countryBillingEntity = billingEntities.find(a => a.countries.includes(country));
    if (countryBillingEntity) {
        return countryBillingEntity;
    }
    return getDefaultBillingEntity(billingEntities);
};

export const getDefaultBillingEntity = (
    billingEntities: BillingEntityDto[],
): BillingEntityDto | undefined => {
    return billingEntities.find(a => a.isDefault);
};
