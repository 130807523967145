import React from 'react';

import { ActorDto } from '@hofy/api-shared';
import { DateTimeString } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { Box, Paragraph3, Paragraph4 } from '@hofy/ui';

import { UserDetailsLink } from '../../../usersPage/UserDetailsLink';

interface ChangedByProps {
    when: DateTimeString | null;
    who: ActorDto | null;
}

export const ChangedBy = ({ when, who }: ChangedByProps) => {
    return (
        <Box column>
            <Paragraph3>{formatDateTime(when)}</Paragraph3>
            {who && (
                <Paragraph4>
                    {'By: '}
                    {who.userId ? <UserDetailsLink id={who.userId}>{who.name}</UserDetailsLink> : who.name}
                </Paragraph4>
            )}
        </Box>
    );
};
