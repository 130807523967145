import React, { FC } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, MarginBoxProps } from '../base';
import { Icon } from './Icon';

export type GlassIconAppearance = 'primary' | 'positive' | 'warning' | 'error';
export type GlassIconPart = 'border' | 'background';

interface GlassIconProps extends MarginBoxProps {
    appearance?: GlassIconAppearance;
    icon: Svg;
}

const backgroundColorsMap: Record<GlassIconPart, Record<GlassIconAppearance, Color>> = {
    border: {
        primary: Color.NonContextualBorderSubtleBrand,
        positive: Color.NonContextualBorderSubtlePositive,
        warning: Color.NonContextualBorderSubtleWarning,
        error: Color.NonContextualBorderSubtleNegative,
    },
    background: {
        primary: Color.BackgroundBrand,
        positive: Color.BackgroundPositive,
        warning: Color.BackgroundWarning,
        error: Color.BackgroundNegative,
    },
};

export const GlassIcon: FC<GlassIconProps> = ({ appearance = 'primary', icon, ...props }) => {
    const borderColor = backgroundColorsMap.border[appearance];
    const bgColor = backgroundColorsMap.background[appearance];

    return (
        <Box rect={32} relative {...props}>
            <BackBox bg={bgColor} rounded={8} />
            <FrontBox border borderColor={borderColor} centered rounded={8}>
                <Icon svg={icon} size={16} color={Color.ContentInvertedPrimary} />
            </FrontBox>
        </Box>
    );
};

const FilledBox = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const BackBox = styled(FilledBox)`
    transform: translateX(5px) translateY(-5px) rotate(20deg);
`;

const FrontBox = styled(FilledBox)`
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
`;
