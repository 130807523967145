/* eslint-disable import/no-cycle */
import { createEvent, STORAGE_LOCAL_EVENT } from '@hofy/helpers';
import { RequestInterceptorParam, restClient, RestClientImpl } from '@hofy/rest';

import { sessionService } from './sessionService';
import { AuthError, isAuthError } from './types/AuthError';
import { OrganizationSignupPayload } from './types/OrganizationSignupPayload';
import { PublicSSO } from './types/PublicSSO';
import { ResetPasswordPayload } from './types/ResetPasswordPayload';
import { SendEmailVerificationPayload } from './types/SendEmailVerificationPayload';
import { SetPasswordPayload } from './types/SetPasswordPayload';
import { SignInPayload } from './types/SignInPayload';
import { SignUpSignInPayload } from './types/SignUpPayload';
import { SwitchIdentityPayload } from './types/SwitchIdentityPayload';
import {
    isValidToken,
    isValidTokenExpires,
    TokenPair,
    TokenPairDto,
    toTokenPair,
} from './types/TokenPairDto';

export const AUTH_ERROR_PARAM = 'auth-error';

const baseUrl = `${window.location.protocol}//${window.location.host}`;

interface InitPayload {
    historyReplaceHandler(args: { pathName: string; search: string }): void;
}

interface SignInWithConnectionPayload {
    signUpToken: string | null;
    redirect: string | null;
}

const addAuthHeader = async (as: AuthService, requestInit?: RequestInit): Promise<RequestInit> => {
    let currentRequestInit = requestInit || {};
    if (!currentRequestInit.headers) {
        currentRequestInit.headers = new Headers();
    }
    const token = await as.getValidToken();
    if (token) {
        (currentRequestInit.headers as Headers).append('Authorization', `Bearer ${token}`);
    }

    return currentRequestInit;
};

const authRequestInterceptor =
    (as: AuthService) =>
    async ([url, init]: RequestInterceptorParam): Promise<RequestInterceptorParam> => {
        const newInit = await addAuthHeader(as, init);
        return [url, newInit];
    };

class AuthService {
    public constructor(restClient: RestClientImpl) {
        restClient.addRequestInterceptor(authRequestInterceptor(this));
    }

    public STORAGE_KEY = `auth:tokens`;

    private refreshPromise: Promise<string | null> | null = null;

    private getTokenFromStorage = (): TokenPair | null => {
        const item = window.localStorage.getItem(this.STORAGE_KEY);
        return item ? JSON.parse(item) : null;
    };

    private setValueInStorage = (value: TokenPair) => {
        window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(value));
        window.dispatchEvent(createEvent(STORAGE_LOCAL_EVENT));
    };

    private clearToken = () => {
        window.localStorage.removeItem(this.STORAGE_KEY);
        window.dispatchEvent(createEvent(STORAGE_LOCAL_EVENT));
    };

    public init = async ({ historyReplaceHandler }: InitPayload): Promise<AuthError | boolean> => {
        const token = await this.getInitialValidToken();

        if (!token) {
            const ssoToken = this.getSSORedirectResult({ historyReplaceHandler });
            if (isAuthError(ssoToken)) {
                return ssoToken;
            }
            if (ssoToken) {
                const newTokenPair = await sessionService.exchangeTemporary({ token: ssoToken! });
                this.consumeTokenPair(newTokenPair);
                if (isAuthError(newTokenPair)) {
                    return newTokenPair;
                } else {
                    return true;
                }
            }
            return false;
        }

        return true;
    };

    public getInitialValidToken = async (): Promise<string | null> => {
        const token = this.getTokenFromStorage();

        if (!token) {
            return null;
        }

        if (!token.refresh_token && isValidTokenExpires(token.expires_at)) {
            return token.access_token;
        }

        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        if (isValidToken(token.refresh_token)) {
            const promise = sessionService
                .refresh({ refresh_token: token.refresh_token })
                .then(this.consumeTokenPair);
            this.refreshPromise = promise;
            return promise;
        }
        this.clearToken();
        return null;
    };

    public forceRefreshToken = async (): Promise<string | null> => {
        const token = this.getTokenFromStorage();

        if (!token || !isValidToken(token.refresh_token)) {
            this.clearToken();
            return null;
        }

        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        const promise = sessionService
            .refresh({ refresh_token: token.refresh_token })
            .then(this.consumeTokenPair);
        this.refreshPromise = promise;
        return promise;
    };

    public getValidToken = async (): Promise<string | null> => {
        const token = this.getTokenFromStorage();

        if (!token) {
            return null;
        }

        if (isValidTokenExpires(token.expires_at)) {
            return token.access_token;
        }

        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        if (isValidToken(token.refresh_token)) {
            const promise = sessionService
                .refresh({ refresh_token: token.refresh_token })
                .then(this.consumeTokenPair);
            this.refreshPromise = promise;
            return promise;
        }
        this.clearToken();
        return null;
    };

    public signIn = async (payload: SignInPayload): Promise<TokenPair | AuthError> => {
        try {
            const tokens = await sessionService.signIn(payload);
            if (!isAuthError(tokens)) {
                this.setValueInStorage(tokens);
            }
            return tokens;
        } catch (e) {
            this.clearToken();
            return Promise.reject(e);
        }
    };
    public switchIdentity = async (payload: SwitchIdentityPayload): Promise<TokenPair | AuthError> => {
        const tokens = await sessionService.switchIdentity(payload);
        if (!isAuthError(tokens)) {
            this.setValueInStorage(tokens);
        }
        return tokens;
    };

    public signInWithPublicSSO = async (sso: PublicSSO, payload: SignInWithConnectionPayload) => {
        return this.signInWithConnection(sso, payload);
    };
    public signInWithConnection = async (
        connection: string,
        { signUpToken, redirect }: SignInWithConnectionPayload,
    ) => {
        if (signUpToken) {
            await sessionService.signUp({ token: signUpToken });
        }
        const params = new URLSearchParams();
        params.append('redirect', redirect || '');
        const [connectionType, uuid] = connection.split('.');
        switch (connectionType) {
            case 'saml':
                window.location.href = `${baseUrl}/api/auth/saml/${uuid}/init?${params.toString()}`;
                return;
            default:
                window.location.href = `${baseUrl}/api/auth/${connection}/init?${params.toString()}`;
                return;
        }
    };

    public signUpWithPublicSSO = async (connection: string) => {
        window.location.href = `${baseUrl}/api/auth/self-sign-up/${connection}/init`;
    };

    public signOut = () => {
        this.clearToken();
    };

    public forgotPassword = (p: ResetPasswordPayload): Promise<void> => {
        return sessionService.resetPassword(p);
    };

    public setPassword = async (p: SetPasswordPayload): Promise<TokenPair | AuthError> => {
        const newTokenPair = await sessionService.setPassword(p);
        this.consumeTokenPair(newTokenPair);
        return newTokenPair;
    };

    public signUp = async (p: SignUpSignInPayload): Promise<void> => {
        await sessionService.signUp(p);
        if (p.password) {
            const tokens = await sessionService.signIn({ email: p.email, password: p.password, code: '' });
            this.consumeTokenPair(tokens);
        }
    };

    public sendEmailVerification = async (p: SendEmailVerificationPayload): Promise<void> =>
        sessionService.verifyEmail({ email: p.email, recaptchaToken: p.recaptchaToken });

    public organizationSignUp = async (p: OrganizationSignupPayload): Promise<void> => {
        const tokens = await sessionService.organizationSignUp(p);
        this.consumeTokenPair(tokens);
    };

    public setTokens = async (token: TokenPairDto) => {
        this.setValueInStorage(toTokenPair(token));
    };

    private getSSORedirectResult = ({ historyReplaceHandler }: InitPayload) => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('temporary-token');
        const authError = params.get(AUTH_ERROR_PARAM);
        if (authError) {
            return authError as AuthError;
        }

        if (token) {
            params.delete('temporary-token');
            historyReplaceHandler({
                pathName: window.location.pathname,
                search: params.toString(),
            });
        }
        return token;
    };

    private consumeTokenPair = (newTokenPair: TokenPair | AuthError) => {
        this.refreshPromise = null;
        if (isAuthError(newTokenPair)) {
            // eslint-disable-next-line no-console
            console.error(newTokenPair);
            this.clearToken();
            return null;
        } else {
            this.setValueInStorage(newTokenPair);
            return newTokenPair.access_token;
        }
    };
}

export const authService = new AuthService(restClient);
