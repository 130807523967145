import { useQuery } from '@tanstack/react-query';

import { invoicesService } from '../service/invoiceService';
import { invoiceCacheKey } from './invoiceCacheKey';

export const useInvoiceQuery = (id: number) => {
    return useQuery({
        queryKey: [invoiceCacheKey, id],
        queryFn: () => invoicesService.getInvoice(id),
        refetchOnWindowFocus: false,
    });
};
