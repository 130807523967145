import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, shipmentService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useUpdateShipmentAddressToCollectionUserAddress = (
    shipmentId: number,
    onSuccess?: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: shipmentService.updateShipmentAddressToCollectionUserAddress,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Collection address updated successfully',
            });
            onSuccess?.();
        },
    });

    return {
        updateShipmentAddressToCollectionUserAddress: () => mutation.mutate(shipmentId),
        isLoadingMutation: mutation.isPending,
    };
};
