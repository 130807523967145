import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, Paragraph3, Pressable } from '../base';
import { Icon, SvgIcon } from '../icon';
import { ActionTileContent } from './inner/ActionTileContent';

interface ActionHeaderProps {
    icon: Svg;
    title: string;
    description?: ReactNode;
    onGoBack(): void;
}

export const ActionHeader = ({ icon, title, description, onGoBack }: ActionHeaderProps) => {
    return (
        <Box
            column
            bg={Color.NonContextualVioletSurface}
            border
            borderColor={Color.NonContextualVioletSurfaceBorder}
            rounded={8}
            overflow='hidden'
        >
            <GoBackPressable
                row
                gap={8}
                paddingVertical={16}
                paddingHorizontal={24}
                borderBottom
                borderColor={Color.NonContextualVioletSurfaceBorder}
                color={Color.InteractionDefaultNormal}
                onClick={onGoBack}
            >
                <Icon svg={SvgIcon.ChevronLeft} color='currentColor' />
                <Paragraph3 color='currentColor'>Back to all available options</Paragraph3>
            </GoBackPressable>
            <ActionTileContent
                icon={icon}
                title={title}
                description={description}
                variant='vivid'
                padding={24}
            />
        </Box>
    );
};

const GoBackPressable = styled(Pressable)`
    &:hover {
        background-color: ${Color.NonContextualVioletSurfaceBorder};
    }
`;
