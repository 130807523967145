import React, { FC } from 'react';

import { InvoiceEntryDto } from '@hofy/api-admin';

import { useRepaymentPlanInvoiceEntriesQuery } from '../../../../store/invoiceEntries/useRepaymentPlanInvoiceEntriesQuery';
import { InvoiceEntriesTable } from '../../invoiceEntries/components/InvoiceEntriesTable';

interface RepaymentInvoiceEntriesTabProps {
    planId: number;
    onRowClick?(entry: InvoiceEntryDto): void;
}

export const RepaymentInvoiceEntriesTab: FC<RepaymentInvoiceEntriesTabProps> = ({ planId, onRowClick }) => {
    const { data, isLoading } = useRepaymentPlanInvoiceEntriesQuery(planId);
    return (
        <InvoiceEntriesTable
            entries={data}
            onRowClick={onRowClick}
            infinityScroll={{
                hasMore: false,
                isLoading: isLoading,
                isLoadingMore: false,
                loadMore: () => {},
            }}
        />
    );
};
