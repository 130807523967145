import React, { FC } from 'react';

import { Box } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { PasswordPayloadValidation } from '../../store/auth/types/PasswordPayload';
import { PasswordHint } from './PasswordHint';

interface PasswordHintsProps {
    currentErrors: Partial<PasswordPayloadValidation>;
    errors: Partial<PasswordPayloadValidation>;
    keyPrefix: string;
}

export const PasswordHints: FC<PasswordHintsProps> = ({ currentErrors, errors, keyPrefix }) => {
    const { trParts } = useAuthI18n();

    return (
        <Box column gap={4}>
            <PasswordHint
                isError={errors.v8Characters}
                isOk={!currentErrors.v8Characters}
                label={trParts(keyPrefix, 'password-validation.8-characters')}
            />
            <PasswordHint
                isError={errors.vCapitalCharacters}
                isOk={!currentErrors.vCapitalCharacters}
                label={trParts(keyPrefix, 'password-validation.capital')}
            />
            <PasswordHint
                isError={errors.vSymbolCharacters}
                isOk={!currentErrors.vSymbolCharacters}
                label={trParts(keyPrefix, 'password-validation.symbol')}
            />
            <PasswordHint
                isError={errors.vNumberCharacters}
                isOk={!currentErrors.vNumberCharacters}
                label={trParts(keyPrefix, 'password-validation.number')}
            />
        </Box>
    );
};
