import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    CompleteShipmentItemPayload,
    CompleteShipmentPayload,
    ShipmentDetailsDto,
    shipmentService,
} from '@hofy/api-admin';
import { errorMap, isEmpty, nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

export const useCompleteDeliveryShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, unknown, CompleteShipmentPayload>({
        mutationFn: payload => shipmentService.completeShipment(shipment.idDeprecated, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            onSuccess?.();
        },
    });

    const form = useForm<CompleteShipmentPayload>({
        initial: initialCompleteShipmentPayload(shipment),
        onSubmit: mutation.mutate,
        validate: ({ completedOn }) => ({
            completedOn: errorMap([
                isEmpty(completedOn),
                'Completed date must be set if status is Completed',
            ]),
        }),
    });

    const setItemSerialNumber = (assignmentId: number, serialNumber: string) => {
        const newItems: CompleteShipmentItemPayload[] = form.values.items.map(item => {
            if (item.assignmentId === assignmentId) {
                return {
                    assignmentId,
                    serialNumber: serialNumber || null,
                };
            }
            return item;
        });
        form.setValues({
            items: newItems,
        });
    };

    return {
        form,
        isLoading: mutation.isPending,
        setItemSerialNumber,
    };
};

const initialCompleteShipmentPayload = (shipment: ShipmentDetailsDto): CompleteShipmentPayload => {
    return {
        completedOn: nowISODate(),
        sendNotification: true,
        deliveryNotes: shipment.deliveryNotes,
        items: shipment.assignments.map(a => ({
            serialNumber: null,
            assignmentId: a.id,
        })),
    };
};
