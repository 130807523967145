import { xor } from 'lodash';
import React, { FC } from 'react';

import { useBillingEntitiesQuery, useInvoiceEntries } from '@hofy/api-admin';
import { allInvoiceEntryTypes, allTaxStatuses, BillingEntityStatus, Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { UUID } from '@hofy/global';
import { Box, Button, PageHeader, Paragraph3, SearchInput, Switch } from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../../components/design/blockFilters/EnumBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { BillingEntityBlockFilter } from '../../../components/domain/filters/BillingEntityBlockFilter';
import { LabeledOrganizationSelect } from '../../../components/domain/organizations/LabeledOrganizationSelect';
import { useInvoiceEntriesFilters } from '../../../store/invoiceEntries/useInvoiceEntriesFilters';
import { useTrInvoiceEntryType } from '../../../store/invoiceEntries/useTrInvoiceEntryType';
import { useTrTaxStatus } from '../../../store/invoiceEntries/useTrTaxStatus';
import { InvoicingTabs } from '../InvoicingTabs';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { InvoiceEntriesTable } from './components/InvoiceEntriesTable';
import { InvoiceDateBlockFilter } from './filters/InvoiceDateBlockFilter';
import { InvoiceEntriesActiveFilterChips } from './filters/InvoiceEntriesActiveFilterChips';
import { InvoiceTimeRangeFilter } from './filters/InvoiceTimeRangeFilter';
import { IsInvoicingBlockFilter } from './filters/IsInvoicingBlockFilter';
import { InvoiceEntriesDownloadReports } from './InvoiceEntriesDownloadReports';

interface InvoiceEntriesPageProps extends InvoicingTabRouterProps {
    onAddInvoiceEntry(organizationId: UUID): void;
    onOpenInvoiceEntry(id: number): void;
}

export const InvoiceEntriesPage: FC<InvoiceEntriesPageProps> = ({
    tab,
    tabs,
    onChangeTab,
    onAddInvoiceEntry,
    onOpenInvoiceEntry,
}) => {
    const { hasPermission } = useSession();

    const {
        filters,
        filterCount,
        setOrganizations,
        setBillingEntity,
        setInvoiceDate,
        setInvoiceEntryType,
        setIsInvoiced,
        setSearch,
        setTaxStatus,
        setDateRange,
        setIncludeDeleted,
    } = useInvoiceEntriesFilters();

    const { invoiceEntries, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
        useInvoiceEntries(filters);

    const { billingEntities } = useBillingEntitiesQuery({
        status: [BillingEntityStatus.Active, BillingEntityStatus.Archived],
    });

    const trInvoiceEntryType = useTrInvoiceEntryType();
    const trTaxStatus = useTrTaxStatus();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Invoice entries'
                rightSlot={
                    <>
                        <Box gap={8} row>
                            <Switch
                                checked={filters.includeDeleted === true}
                                onChange={v => setIncludeDeleted(v)}
                            />
                            <Paragraph3>Include deleted</Paragraph3>
                        </Box>
                        <InvoiceEntriesDownloadReports filters={filters} />
                        <SearchInput
                            value={filters.search || ''}
                            onChange={setSearch}
                            placeholder='Reference…'
                        />
                        <LabeledOrganizationSelect
                            width={240}
                            label=''
                            value={filters.organization}
                            placeholder='Choose organization'
                            nullable
                            onChange={setOrganizations}
                        />
                        {hasPermission(Permission.AdminInvoicesEntriesCreate) && (
                            <Button label='Add' onClick={() => onAddInvoiceEntry(filters.organization!)} />
                        )}
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={<InvoicingTabs tabs={tabs} tab={tab} onChangeTab={onChangeTab} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <BillingEntityBlockFilter
                    selected={filters.billingEntity}
                    onChange={setBillingEntity}
                    billingEntities={billingEntities}
                />
                <EnumBlockFilter
                    title='Type'
                    selected={filters.invoiceEntryType}
                    onChange={setInvoiceEntryType}
                    items={allInvoiceEntryTypes}
                    renderItem={trInvoiceEntryType}
                />
                <EnumBlockFilter
                    title='Tax status'
                    selected={filters.taxStatus}
                    onChange={setTaxStatus}
                    items={allTaxStatuses}
                    renderItem={trTaxStatus}
                />
                <InvoiceDateBlockFilter selected={filters.invoiceDate} onChange={setInvoiceDate} />
                <IsInvoicingBlockFilter isInvoiced={filters.isInvoiced} onChange={setIsInvoiced} />
                <InvoiceTimeRangeFilter date={filters.dateRange} onChange={setDateRange} />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <InvoiceEntriesActiveFilterChips
                    filters={filters}
                    billingEntities={billingEntities}
                    onClearDateRange={() => setDateRange(null)}
                    onClearInvoiceDate={() => setInvoiceDate(null)}
                    onClearInvoiceEntryType={() => setInvoiceEntryType(null)}
                    onClearIsInvoiced={() => setIsInvoiced(null)}
                    onClearTaxStatus={() => setTaxStatus(null)}
                    onClearBillingEntity={entity => setBillingEntity(xor(filters.billingEntity, [entity]))}
                />
            </BlockFilterChipContainer>
            <InvoiceEntriesTable
                entries={invoiceEntries}
                onRowClick={entry => onOpenInvoiceEntry(entry.id)}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: isLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Box>
    );
};
