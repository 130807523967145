import { getEnumValues } from '@hofy/global';

export enum AddonItem {
    HardwareSupport = 'hardware_support',
    ManagedRepair = 'managed_repair',
    Loaner = 'loaner',
    RepairLogistics = 'repair_logistics',
    Replacement = 'replacement',
    LocalStoreAndReuse = 'local_store_and_reuse',
    HubStoreAndReuse = 'hub_store_and_reuse',
    Transfer = 'transfer',
    Clearance = 'clearance',
    DeviceConfig = 'device_config',
    GlobalWarranty = 'global_warranty',
}

export const allAddonItems = getEnumValues<AddonItem>(AddonItem);
