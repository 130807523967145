import { partition } from 'lodash';
import React, { FC } from 'react';

import { useOrderQuery } from '@hofy/api-admin';
import { GlobalLoader, Page } from '@hofy/common';
import { Box, ErrorStatePlaceholder, FormGridRow, FormSection, PageHeader, SeparatedBox } from '@hofy/ui';

import { OrderDetailsContent } from './OrderDetailsContent';
import { OrderItem } from './OrderItem';

interface RequestDetailsPageProps {
    orderId: number;
}

export const OrderDetailsPage: FC<RequestDetailsPageProps> = ({ orderId }) => {
    const { data: request, isLoading, isError } = useOrderQuery(orderId);

    if (isLoading) {
        return <GlobalLoader />;
    }

    if (isError || !request) {
        return <ErrorStatePlaceholder />;
    }

    const { id, items } = request;
    const [approvedItems, notApprovedItems] = partition(items, item => item.isApproved);

    return (
        <Page>
            <PageHeader title={`Order #${id}`} />
            <Box
                column
                flex={1}
                gap={24}
                overflow='auto'
                paddingVertical='mainMarginVertical'
                paddingHorizontal='mainMarginHorizontal'
            >
                <FormSection label='Details'>
                    <FormGridRow columns={8}>
                        <OrderDetailsContent request={request} />
                    </FormGridRow>
                </FormSection>

                <FormSection label='Approved items'>
                    <SeparatedBox column gap={24}>
                        {approvedItems.map(requestItem => (
                            <OrderItem orderItem={requestItem} key={requestItem.id} />
                        ))}
                    </SeparatedBox>
                </FormSection>
                {notApprovedItems.length > 0 && (
                    <FormSection label='Not approved items'>
                        <SeparatedBox column gap={24}>
                            {notApprovedItems.map(requestItem => (
                                <OrderItem orderItem={requestItem} key={requestItem.id} />
                            ))}
                        </SeparatedBox>
                    </FormSection>
                )}
            </Box>
        </Page>
    );
};
