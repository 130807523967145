import { useHofyWarehousesQuery } from '@hofy/api-admin';
import { queryNumberArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useWarehousesFilterDefinition = (filterName: string): FilterDefinitionMulti<number> => {
    const { data: warehouses } = useHofyWarehousesQuery();
    const warehouseIdLabel = (id: number) => {
        const warehouse = warehouses.find(warehouse => warehouse.idDeprecated === id);
        return warehouse!.name;
    };
    const warehouseIds = warehouses.map(warehouse => warehouse.idDeprecated);

    return {
        type: 'multi',
        name: filterName,
        query: queryNumberArrayParser(),
        allValues: warehouseIds,
        toLabel: warehouseIdLabel,
    };
};
