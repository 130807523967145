import React, { FC } from 'react';

import { useContractDetailsQuery } from '@hofy/api-admin';
import { ContractType } from '@hofy/api-shared';
import { Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Skeleton } from '@hofy/ui';

import { ContractTab } from '../../../store/contracts/types/ContractTab';
import { ManagementDetails } from './ManagementDetails';
import { PurchaseDetails } from './PurchaseDetails';
import { RentalDetails } from './RentalDetails';

interface ContractDetailsOverlayProps {
    tab: ContractTab;
    contractId: number;
    onChangeTab(t: ContractTab): void;
    onOpenAddSlideout(organizationId: UUID): void;
    onInvoiceEntryClick(entryId: number): void;
    onEdit(contractId: number): void;
}

export const ContractDetailsOverlay: FC<ContractDetailsOverlayProps> = ({
    tab,
    contractId,
    onChangeTab,
    onOpenAddSlideout,
    onInvoiceEntryClick,
    onEdit,
}) => {
    const { contract, isLoading } = useContractDetailsQuery(contractId);

    if (isLoading || !contract) {
        return (
            <Overlay column flex='auto' bg={Color.BackgroundDefault}>
                <Skeleton />
            </Overlay>
        );
    }

    switch (contract.type) {
        case ContractType.Purchase:
            return (
                <PurchaseDetails
                    contract={contract}
                    tab={tab}
                    onEdit={onEdit}
                    onChangeTab={onChangeTab}
                    onOpenAddInvoiceEntrySlideout={onOpenAddSlideout}
                    onInvoiceEntryClick={onInvoiceEntryClick}
                />
            );
        case ContractType.Rental:
            return (
                <RentalDetails
                    contract={contract}
                    tab={tab}
                    onEdit={onEdit}
                    onChangeTab={onChangeTab}
                    onInvoiceEntryClick={onInvoiceEntryClick}
                    onOpenAddSlideout={onOpenAddSlideout}
                />
            );
        case ContractType.Management:
            return (
                <ManagementDetails
                    contract={contract}
                    tab={tab}
                    onChangeTab={onChangeTab}
                    onInvoiceEntryClick={onInvoiceEntryClick}
                    onOpenAddInvoiceEntrySlideout={onOpenAddSlideout}
                />
            );
    }
};
