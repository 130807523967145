import React, { FC } from 'react';

import { AddressDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import {
    Button,
    FormModal,
    FormSwitch,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph4,
    SubmitButton,
} from '@hofy/ui';

import { useReplaceAssignment } from '../../../store/assignments/useReplaceAssignment';
import { SelectAddress } from './SelectAddress';

interface AddReplacementModalProps {
    assignmentId: number;
    userAddress: AddressDto;
    deliveryAddress: AddressDto;
    onClose(): void;
}

export const AddReplacementModal: FC<AddReplacementModalProps> = ({
    assignmentId,
    userAddress,
    deliveryAddress,
    onClose,
}) => {
    const { form, isLoading, isError } = useReplaceAssignment(assignmentId, deliveryAddress.id, onClose);

    return (
        <FormModal
            onSubmit={form.submit}
            isLoading={isLoading}
            isError={isError}
            onClose={onClose}
            width={600}
        >
            <ModalHeader title='Order replacement' />
            <ModalContent column gap={10}>
                <FormSwitch label='Bill on delivery' api={form.fields.isBillable} />
                {!form.values.isBillable && (
                    <Paragraph4>The organization will not be billed for this replacement.</Paragraph4>
                )}
                {form.values.isBillable && (
                    <Paragraph4 color={Color.ContentWarning}>
                        An invoice will be automatically created on delivery - unless the corresponding
                        contract addon provides replacements for free or with tokens.
                    </Paragraph4>
                )}
                <SelectAddress
                    userAddress={userAddress}
                    deliveryAddress={deliveryAddress}
                    onSelect={({ id }) => form.setValues({ deliveryAddressId: id })}
                />
            </ModalContent>
            <ModalFooter>
                <Button label='Cancel' type='ghost' onClick={onClose} />
                <SubmitButton label='Confirm' />
            </ModalFooter>
        </FormModal>
    );
};
