import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    CancelContractPayload,
    ContractDetailsDtoWithRental,
    contractsCacheKey,
    contractService,
    invoiceEntriesQueryKey,
} from '@hofy/api-admin';
import { nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

export const useCancelContract = (contract: ContractDetailsDtoWithRental, onEnd: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (payload: CancelContractPayload) =>
            contractService.cancelContract(contract.idDeprecated, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [contractsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [invoiceEntriesQueryKey, contract.idDeprecated],
            });
            onEnd();
        },
    });

    const form = useForm<CancelContractPayload>({
        initial: {
            cancelledOn: nowISODate(),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
