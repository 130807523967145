import React, { FC } from 'react';

import { Alert, Box, Form, SubmitButton } from '@hofy/ui';

import { FormOrganizationSelect } from '../../../../components/domain/organizations/FormOrganizationSelect';
import { useSeedRequests } from '../../../../store/settings/useSeedRequests';

export const SeedRandomRequests: FC = () => {
    const { form, isLoading } = useSeedRequests();
    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='Creates request for 50% of users with 1 laptop and 0-3 additional accessories approved withing a user team'
                />
                <FormOrganizationSelect
                    label='Organization'
                    api={form.fields.organizationId}
                    placeholder='Choose organization'
                />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
