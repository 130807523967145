import React, { FC } from 'react';

import { ActorDto, ActorType } from '@hofy/api-shared';
import { Box, Icon, SvgIcon } from '@hofy/ui';

import { UserDetailsLink } from '../../usersPage/UserDetailsLink';

interface ActorLabelProps {
    actor: ActorDto | null;
}

export const ActorLabel: FC<ActorLabelProps> = ({ actor }) => {
    const content = () => {
        if (!actor) {
            return '--';
        }
        switch (actor.actorType) {
            case ActorType.Api:
                return (
                    <>
                        <Icon size={12} svg={SvgIcon.Monitor} marginRight={4} />
                        {actor.name}
                    </>
                );
            case ActorType.User:
                return (
                    <UserDetailsLink id={actor.userId!}>
                        <Icon size={12} svg={SvgIcon.User} marginRight={4} />
                        {actor.name}
                    </UserDetailsLink>
                );
        }
    };

    return <Box row>{content()}</Box>;
};
