import { useQuery } from '@tanstack/react-query';

import { contractService } from '@hofy/api-admin';

const contractPredictionQueryKey = 'contract-end-prediction';
export const useGetEndContractPrediction = (contractId: number, date: string) => {
    const { isLoading, data: prediction } = useQuery({
        queryKey: [contractPredictionQueryKey, contractId, date],

        queryFn: () => contractService.getEndContractPrediction(contractId, date),
    });

    return {
        prediction,
        isLoading,
    };
};
