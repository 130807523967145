import React, { FC, useState } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Modals } from '@hofy/ui';

import { useUploadTwoPayoutsFlag } from '../../../store/payouts/useUploadTwoPayoutsFlag';
import { UploadTwoPayoutsModal } from './components/UploadTwoPayoutsModal';

export const PayoutsPageMenu: FC = () => {
    const { hasPermission } = useSession();
    const [showTwoUploadPayoutModal, setShowTwoUploadPayoutModal] = useState(false);
    const [uploadTwoPayoutsEnabled] = useUploadTwoPayoutsFlag();

    return (
        <>
            <MoreMenu
                items={[
                    {
                        action: () => setShowTwoUploadPayoutModal(true),
                        label: 'Upload Two payouts',
                        visible:
                            uploadTwoPayoutsEnabled &&
                            hasPermission(Permission.AdminAccountingTransactionalSync),
                    },
                ]}
            />
            <Modals>
                {showTwoUploadPayoutModal && (
                    <UploadTwoPayoutsModal onClose={() => setShowTwoUploadPayoutModal(false)} />
                )}
            </Modals>
        </>
    );
};
