import React, { FC } from 'react';

import { AsyncButton, FormTextarea, Modal, ModalContent, ModalFooter, ModalHeader } from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useExcludeFromDunning } from '../../../../../store/invoices/useExcludeFromDunning';

interface ExcludeFromDunningModalProps {
    invoiceId: number;
    onClose(): void;
}

export const ExcludeFromDunningModal: FC<ExcludeFromDunningModalProps> = ({ invoiceId, onClose }) => {
    const { form, isLoading, isError } = useExcludeFromDunning(invoiceId, onClose);

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Exclude from payment reminders' />
            <ModalContent>
                <FormTextarea
                    rows={4}
                    flex={1}
                    api={form.fields.reason}
                    label='Exclusion reason'
                    placeholder='Enter the reason for excluding this invoice from payment reminders.'
                    isRequired
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Exclude'
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                />
            </ModalFooter>
        </Modal>
    );
};
