import { getEnumValues } from '@hofy/global';

export enum SupplierPaymentTerms {
    TermsPrepaid = 'prepaid',
    Terms5days = '5_days',
    Terms7days = '7_days',
    Terms14days = '14_days',
    Terms21days = '21_days',
    Terms30days = '30_days',
    Terms60days = '60_days',
}

export const allSupplierPaymentTerms = getEnumValues<SupplierPaymentTerms>(SupplierPaymentTerms);
