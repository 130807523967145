import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { IntRoute } from '@hofy/router';

import { AssignmentSlideoutRouter } from '../assignmentSlideout/AssignmentSlideoutRouter';
import { ShipmentSlideoutRouter } from '../shipmentSlideout/ShipmentSlideoutRouter';

interface UserSlideoutRouterProps {
    ordersBasePath: string;
}

export const UserSlideoutRouter: FC<UserSlideoutRouterProps> = ({ ordersBasePath }) => {
    return (
        <Switch>
            <SlideoutRoute path={`${ordersBasePath}/:orderId(\\d+)/shipment`}>
                {({ orderId }) => (
                    <ShipmentSlideoutRouter
                        base={`${ordersBasePath}/${orderId}/shipment`}
                        assignmentsBase={ordersBasePath}
                        onCloseBase={ordersBasePath}
                    />
                )}
            </SlideoutRoute>
            <SlideoutRoute path={`${ordersBasePath}/:orderId(\\d+)`}>
                {({ orderId }) => (
                    <AssignmentSlideoutRouter
                        base={ordersBasePath}
                        shipmentsBase={`${ordersBasePath}/${orderId}/shipment`}
                        onCloseBase={ordersBasePath}
                    />
                )}
            </SlideoutRoute>
        </Switch>
    );
};

const SlideoutRoute = IntRoute('orderId', Route);
