import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { Permission, ShipmentStatus } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { VisualType } from '@hofy/global';
import { Alert, Button, OuterBoxProps, Paragraph3 } from '@hofy/ui';

import { getPurchaseOrderLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';
import { shipmentPurchaseOrderUnmatchedItems } from '../../../store/shipments/useValidateShipmentPurchaseOrder';

interface ShipmentPurchaseOrderPaneProps extends OuterBoxProps {
    onCreatePurchaseOrder(): void;
    onDisconnectPurchaseOrder(): void;
    shipment: ShipmentDetailsDto;
}

export const ShipmentPurchaseOrderPane: FC<ShipmentPurchaseOrderPaneProps> = ({
    shipment,
    onCreatePurchaseOrder,
    onDisconnectPurchaseOrder,
    ...boxProps
}) => {
    const { hasPermission } = useSession();
    const hasPurchaseOrderUnmatchedItems = shipmentPurchaseOrderUnmatchedItems(shipment);
    const type: VisualType =
        !shipment.purchaseOrder || hasPurchaseOrderUnmatchedItems ? 'warning' : 'positive';
    const message = hasPurchaseOrderUnmatchedItems
        ? 'Shipment contains items that are not part of purchase order'
        : '';
    const canUserCreatePurchaseOrder = hasPermission(Permission.AdminPurchaseOrdersCreate);
    const canCreatePurchaseOrder =
        !shipment.purchaseOrder &&
        (shipment.status === ShipmentStatus.Created ||
            shipment.status === ShipmentStatus.Booked ||
            shipment.status === ShipmentStatus.Backorder);
    const canDisconnectPurchaseOrder =
        shipment.purchaseOrder &&
        (shipment.status === ShipmentStatus.Created ||
            shipment.status === ShipmentStatus.Booked ||
            shipment.status === ShipmentStatus.Backorder);

    const actions = (
        <>
            {shipment.purchaseOrder && (
                <Paragraph3 bold>
                    <Link to={getPurchaseOrderLink(shipment.purchaseOrder.id)}>
                        #{shipment.purchaseOrder.purchaseOrderReference}
                    </Link>
                </Paragraph3>
            )}
            {canCreatePurchaseOrder && (
                <Button
                    type='secondary'
                    label='Create'
                    onClick={onCreatePurchaseOrder}
                    disabled={!canUserCreatePurchaseOrder}
                />
            )}
            {canDisconnectPurchaseOrder && (
                <Button type='secondary' label='Disconnect' onClick={onDisconnectPurchaseOrder} />
            )}
        </>
    );

    if (shipment.status === ShipmentStatus.Canceled) {
        return null;
    }

    if (shipment.purchaseOrder) {
        return (
            <Alert
                type={type}
                title={`#${shipment.purchaseOrder.purchaseOrderReference}`}
                description={message}
                horizontal
                {...boxProps}
            >
                {actions}
            </Alert>
        );
    }

    return (
        <Alert
            type={type}
            title='Purchase order required'
            description='Dropship shipments require purchase order to proceed'
            horizontal
            {...boxProps}
        >
            {actions}
        </Alert>
    );
};
