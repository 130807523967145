export interface UploadInvoicePayload {
    file: UploadInvoiceFilePayload | null;
}

export interface UploadInvoiceFilePayload {
    name: string;
    content: string;
}

export const emptyAdminUploadInvoicePayload: UploadInvoicePayload = {
    file: null,
};
