import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { SupplierListItemDto } from '@hofy/api-admin';
import { SupplierPaymentTerms } from '@hofy/api-shared';
import { Price, priceToNumber } from '@hofy/global';
import { useDecimal, usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, InfinityScrollTable, Paragraph3, TwoLineSmallCell } from '@hofy/ui';

import { NetsuiteStatusChip } from '../../../components/domain/netsuite/NetsuiteStatusChip';
import { SupplierStatusChip } from './components/SupplierStatusChip';

interface SuppliersTableProps {
    suppliers: SupplierListItemDto[];
    isLoading: boolean;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage(): void;
    onOpenSupplier(id: number): void;
}

export const SuppliersTable: FC<SuppliersTableProps> = ({
    suppliers,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    onOpenSupplier,
}) => {
    return (
        <InfinityScrollTable
            data={suppliers}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoadingMore: isFetchingNextPage,
                isLoading: isLoading,
                loadMore: fetchNextPage,
            }}
            toKey={supplier => supplier.id}
            isLoading={isLoading}
            flex='auto'
            onRowClick={supplier => onOpenSupplier(supplier.id)}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    flexGrow: 0,
                    width: 80,
                    renderer: supplier => `#${supplier.id}`,
                },
                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 2,
                    renderer: supplier => supplier.name,
                },
                {
                    id: 'subsidiary',
                    header: 'Primary subsidiary',
                    renderer: supplier => supplier.hofySubsidiary.name,
                },
                {
                    id: 'supplierStatus',
                    header: 'Status',
                    renderer: supplier => <SupplierStatusChip status={supplier.status} />,
                },
                {
                    id: 'creditLimit',
                    header: 'Used credit limit',
                    flexGrow: 1.5,
                    renderer: supplier => (
                        <SupplierCreditLimit
                            paymentTerm={supplier.paymentTerm}
                            unlimitedCredit={supplier.unlimitedCredit}
                            creditLimit={supplier.creditLimit}
                            usedCreditLimit={supplier.usedCreditLimit}
                        />
                    ),
                },
                {
                    id: 'netsuiteSync',
                    header: 'Netsuite',
                    renderer: supplier => <NetsuiteStatusChip status={supplier.netsuite} />,
                },
            ]}
        />
    );
};

interface SupplierCreditLimitProps {
    paymentTerm: SupplierPaymentTerms;
    unlimitedCredit: boolean;
    creditLimit?: Price;
    usedCreditLimit?: Price;
}

const SupplierCreditLimit: FC<SupplierCreditLimitProps> = ({
    paymentTerm,
    unlimitedCredit,
    creditLimit,
    usedCreditLimit,
}) => {
    const { formatPriceWithISOCode: formatPrice } = usePrice();
    const { formatDecimal } = useDecimal();

    if (paymentTerm === SupplierPaymentTerms.TermsPrepaid) {
        return <Paragraph3 color={Color.Neutral300}>Purchases are prepaid</Paragraph3>;
    }

    if (unlimitedCredit) {
        return <Paragraph3 color={Color.AccentGreenPastel}>Unlimited credit</Paragraph3>;
    }

    if (!usedCreditLimit || !creditLimit) {
        return <Paragraph3 color={Color.AccentRedPastel}>No credit data</Paragraph3>;
    }

    const width = (priceToNumber(usedCreditLimit) / priceToNumber(creditLimit)) * 100.0;
    const getColor = () => {
        if (width <= 30.0) {
            return Color.AccentGreenPastel;
        }

        if (width <= 60.0) {
            return Color.AccentYellowPastel;
        }

        return Color.AccentRedPastel;
    };

    return (
        <Box fullWidth paddingRight={50} justify='center'>
            <TwoLineSmallCell
                line1={
                    <ProgressWrapper>
                        <Box row fullWidth height={8} border rounded={4} marginTop={4}>
                            <RangeBar
                                rounded={4}
                                height={8}
                                minWidth={5}
                                width={`${width}%`}
                                bg={getColor()}
                            />
                        </Box>
                    </ProgressWrapper>
                }
                line2={`${formatDecimal(usedCreditLimit.amount)}/${formatPrice(creditLimit)}`}
            />
        </Box>
    );
};

const ProgressWrapper = styled(Box)`
    min-width: 120px;
    text-align: right;
    margin-bottom: 10px;
`;

const progressBar = (width: string) => keyframes`
    0% { width: 0; }
    100% { width: ${width}; }
`;
const RangeBar = styled(Box)<{
    width: string;
}>`
    animation: ${props => progressBar(props.width)} 1.5s ease;
`;
