export const uiI18n = {
    'ui.yes': 'Yes',
    'ui.no': 'No',

    'ui.dropdown.unselect-all': 'Unselect all',
    'ui.dropdown.search-placeholder': 'Search…',

    'ui.filters.reset-filters': 'Reset filters',

    'ui.form.search.placeholder': 'Search…',
    'ui.form.leave-confirmation.title': 'Unsaved changes',
    'ui.form.leave-confirmation.message': 'Are you sure you want to leave without saving?',
    'ui.form.leave-confirmation.confirm': 'Leave without saving',
    'ui.form.leave-confirmation.cancel': 'Cancel',

    'ui.error.title': 'Something went wrong',
    'ui.error.message': 'Please try again later',
    'ui.error.retry': 'Retry',

    'ui-domain.user-select.loading-users': 'Loading team members…',
    'ui-domain.user-select.placeholder': 'Select team member',
    'ui-domain.user-select.search-placeholder': 'Search to filter',
    'ui-domain.user-select.footer': 'Search to see others',
    'ui-domain.user-select.error': 'Something went wrong…',

    'ui-domain.permissions.no-permissions': 'You do not have permission to perform this action',
    'ui-domain.permissions.no-permissions-section':
        'You do not have the required permissions to edit this section. {role} role required',
    'ui-domain.permissions.no-permissions-page':
        'You do not have the required permissions to edit this page. Contact your Hofy administrator for the {role} role',
    'ui-domain.permissions.no-permissions-role':
        'You do not have permission to perform this action. {role} role required',
};
