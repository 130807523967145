import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { NoteDto, NoteSource, Permission } from '@hofy/api-shared';
import { Markdown } from '@hofy/editor';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { useScrollToBottom } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import {
    Badge,
    Box,
    Button,
    ErrorStatePlaceholder,
    IconButton,
    LabeledCheckbox,
    LabeledTextarea,
    Paragraph3,
    Skeleton,
    Span,
    SvgIcon,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { useCreateNote } from '../../../store/notes/useCreateNote';
import { useDeleteNote } from '../../../store/notes/useDeleteNote';
import { useNotesQuery } from '../../../store/notes/useNotesQuery';

interface NotesEditorProps {
    entityId: UUID;
    source: NoteSource;
    canSetCustomerVisibleNote: boolean;
}

export const NotesEditor: FC<NotesEditorProps> = ({ entityId, source, canSetCustomerVisibleNote }) => {
    const { data: notes, isLoading, isError } = useNotesQuery(source, entityId);

    const [text, setText] = useState('');
    const [isCustomerVisibleNote, setIsCustomerVisibleNote] = useState(false);

    const scrollRef = useScrollToBottom([notes]);

    const { createNote, isSubmitting } = useCreateNote(entityId, source);
    const handleAdd = () => {
        createNote({
            text,
            isExternal: canSetCustomerVisibleNote ? isCustomerVisibleNote : null,
        });
        setText('');
        setIsCustomerVisibleNote(false);
    };

    return (
        <Box flex='auto' column fullHeight>
            <Box ref={scrollRef} overflow='auto'>
                {isError && <ErrorStatePlaceholder />}
                {isLoading && (
                    <Box column gap={10}>
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                    </Box>
                )}
                {!isLoading && notes && (
                    <Box paddingTop={10}>
                        {notes.map(note => (
                            <NoteDetails key={note.id} note={note} context={source} />
                        ))}
                    </Box>
                )}
            </Box>
            <PermissionWrapper permission={Permission.AdminNotesManage}>
                <Box paddingTop={30}>
                    <LabeledTextarea
                        label='New note'
                        value={text}
                        rows={4}
                        onChange={t => setText(t)}
                        marginBottom={20}
                    />
                    {canSetCustomerVisibleNote && (
                        <Box flex={1}>
                            <LabeledCheckbox
                                checked={isCustomerVisibleNote}
                                onChange={setIsCustomerVisibleNote}
                                label='Customer note'
                            />
                        </Box>
                    )}
                    <Box row justify='flex-end'>
                        <Button
                            label={isCustomerVisibleNote ? 'Send to Customer' : 'Add'}
                            disabled={!text || isSubmitting}
                            onClick={handleAdd}
                        />
                    </Box>
                </Box>
            </PermissionWrapper>
        </Box>
    );
};

interface NoteDetailsProps {
    context: NoteSource;
    note: NoteDto;
}

const NoteDetails: FC<NoteDetailsProps> = ({ note, context }) => {
    const { text, createdBy, createdAt, isExternal } = note;
    const { deleteNote, isSubmitting: isDeleting } = useDeleteNote(note);

    const isFromAnotherSource = context !== note.source;

    return (
        <Box paddingBottom={20}>
            <Box row paddingBottom={20}>
                <Paragraph3 color={Color.ContentPrimary}>
                    {createdBy && (
                        <>
                            <Span bold color={Color.ContentPrimary}>
                                {createdBy.name}
                            </Span>
                            {' | '}
                        </>
                    )}
                    {formatDateTime(createdAt)}
                </Paragraph3>
                {isExternal && <Badge label='External note' color='purple' marginLeft={10} />}
                {isFromAnotherSource && <Badge label={note.source} color='cyan' marginLeft={10} />}
            </Box>
            <Wrapper padding={12} row gap={15} relative justify='space-between' externalNote={isExternal}>
                <Markdown>{text}</Markdown>
                <PermissionWrapper permission={Permission.AdminNotesManage}>
                    <IconButton
                        icon={SvgIcon.Trash}
                        size={12}
                        color={Color.ContentPrimary}
                        disabled={isDeleting}
                        onClick={deleteNote}
                    />
                </PermissionWrapper>
            </Wrapper>
        </Box>
    );
};

const Wrapper = styled(Box)<{ externalNote: boolean }>`
    background: ${props =>
        props.externalNote ? Color.NonContextualCyanMuted : Color.BackgroundSubtleNeutral};
    border-radius: 8px;

    ::before {
        content: '';
        background: ${props =>
            props.externalNote ? Color.NonContextualCyanMuted : Color.BackgroundSubtleNeutral};
        width: 16px;
        height: 16px;
        position: absolute;
        top: -8px;
        left: 8px;
        transform: scaleX(0.8) rotate(45deg);
    }
`;
