import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    collectionRequestService,
    CreateCollectionRequestPayload,
} from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

import { initialCollectionSurveyFormData, SendCollectionSurveyFormData } from './useCreateShipment';
import { shipmentCollectionRequestQueryKey } from './useShipmentCollectionRequestQuery';

export const useCreateCollectionRequestSurvey = (shipmentId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (p: CreateCollectionRequestPayload) =>
            collectionRequestService.createCollectionRequestSurvey(shipmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Survey created successfully',
            });
            onSuccess?.();
        },
    });

    const form = useForm<SendCollectionSurveyFormData>({
        initial: initialCollectionSurveyFormData,
        onSubmit: mutation.mutate,
    });

    return { form };
};
