import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, BoxProps, Paragraph3, Paragraph4 } from '../../base';
import { Link } from '../../link/Link';
import { TextCell } from './TextCell';

interface TwoLineTextCellProps extends BoxProps {
    line1: ReactNode;
    line2: ReactNode;
    link?: string;
}

export const TwoLineTextCell: FC<TwoLineTextCellProps> = ({ line1, line2, link }) => {
    if (!link) {
        return (
            <Box overflow='hidden'>
                <TextCell color={Color.ContentPrimary} data-test-key='line-1'>
                    {line1}
                </TextCell>
                <Paragraph3 marginTop={4} color={Color.ContentSecondary} data-test-key='line-2'>
                    {line2}
                </Paragraph3>
            </Box>
        );
    }
    return (
        <Box overflow='hidden'>
            <Link to={link}>
                <TextCell color={Color.ContentPrimary} data-test-key='line-1'>
                    {line1}
                </TextCell>
            </Link>
            <Paragraph3 marginTop={4} color={Color.ContentSecondary} data-test-key='line-2'>
                {line2}
            </Paragraph3>
        </Box>
    );
};

export const TwoLineSmallCell: FC<TwoLineTextCellProps> = ({ line2, line1, link }) => {
    if (!link) {
        return (
            <Box overflow='hidden'>
                <TextCell color={Color.ContentPrimary}>{line1}</TextCell>
                {line2 && (
                    <Paragraph4 marginTop={2} color={Color.ContentSecondary}>
                        {line2}
                    </Paragraph4>
                )}
            </Box>
        );
    }

    return (
        <Box overflow='hidden'>
            <Link inline to={link}>
                <TextCell color={Color.ContentPrimary}>{line1}</TextCell>
            </Link>
            {line2 && (
                <Paragraph4 marginTop={2} color={Color.ContentSecondary}>
                    {line2}
                </Paragraph4>
            )}
        </Box>
    );
};
