import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, shipmentsCacheKey, shipmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from './useShipmentCollectionRequestQuery';

export const useRemoveCollectionRequestItemFromShipment = (shipmentId: number, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (collectionRequestItemId: UUID) =>
            shipmentService.removeCollectionRequestItemFromShipment(shipmentId, collectionRequestItemId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [shipmentsCacheKey, shipmentId] });
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Assignment removed from shipment',
            });
            onSuccess?.();
        },
    });

    return {
        removeCollectionRequestItemFromShipment: (collectionRequestItemId: UUID) =>
            mutation.mutate(collectionRequestItemId),
        isLoadingMutation: mutation.isPending,
    };
};
