import { identity, isEmpty, sortBy } from 'lodash';
import React, { FC } from 'react';

import { useAdminsQuery, useHofyWarehousesQuery } from '@hofy/api-admin';
import {
    allInitialInspectionStatuses,
    getInspectionStatusColorDye,
    getInspectionStatusIndex,
    InspectionStatus,
    Role,
    useTrInspectionStatus,
} from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { getColorFromColorDye } from '@hofy/theme';
import {
    Box,
    Button,
    CheckboxGroup,
    Dot,
    ErrorStatePlaceholder,
    PageHeader,
    PageSkeleton,
    Paragraph3,
    Popover,
    PopoverBody,
    SvgIcon,
    Switch,
} from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { InspectionsTab } from '../../../store/inspections/inspectionsTab';
import { useInspectionsFilters } from '../../../store/inspections/useInspectionsFilters';
import { InspectionsList } from './InspectionsList';
import { InspectionsPageContextProvider } from './InspectionsPageContext';
import { InspectionsFilterChips } from './InspectionsPageFilterChips';
import { InspectionsPageFilters } from './InspectionsPageFilters';
import { InspectionPageTabs } from './InspectionsPageTabs';

interface InspectionsPageProps {
    tab: InspectionsTab;
    onTabChange(tab: InspectionsTab): void;
}

export const InspectionsPage: FC<InspectionsPageProps> = ({ tab, onTabChange }) => {
    const trInspectionStatus = useTrInspectionStatus();

    const {
        data: adminUsers,
        isLoading: areAdminUsersLoading,
        isError: isAdminUsersError,
    } = useAdminsQuery(Role.FulfillmentAdmin, true);
    const {
        data: hofyWarehouses,
        isLoading: areHofyWarehousesLoading,
        isError: isHofyWarehousesError,
    } = useHofyWarehousesQuery();

    const { showFilters, toggleShowFilters } = useBlockFilters();
    const filters = useInspectionsFilters(tab);

    if (areAdminUsersLoading || areHofyWarehousesLoading) {
        return <PageSkeleton />;
    }

    if (isAdminUsersError || isHofyWarehousesError) {
        return <ErrorStatePlaceholder />;
    }

    const effectiveInspectionsStatuses = isEmpty(filters.values.inspectionStatuses)
        ? allInitialInspectionStatuses
        : filters.values.inspectionStatuses;

    const effectiveDeviceCheckStatuses = isEmpty(filters.values.deviceCheckStatuses)
        ? allInitialInspectionStatuses
        : filters.values.deviceCheckStatuses;

    const effectiveStatuses =
        tab === InspectionsTab.Inspections ? effectiveInspectionsStatuses : effectiveDeviceCheckStatuses;

    return (
        <InspectionsPageContextProvider tab={tab}>
            <Page>
                <PageHeader
                    title='Inspections and device checks'
                    rightSlot={
                        <Box row gap={12}>
                            <Box row gap={8}>
                                <Paragraph3>Kanban layout</Paragraph3>
                                <Switch
                                    checked={filters.values.viewType === 'columns'}
                                    onChange={checked => {
                                        filters.controls.setViewType(checked ? 'columns' : 'rows');
                                    }}
                                />
                            </Box>
                            <BlockFilterButton
                                onClick={toggleShowFilters}
                                isOpened={showFilters}
                                count={filters.count}
                            />
                            <Popover
                                width={200}
                                trigger={
                                    <Button
                                        leftIcon={SvgIcon.LayoutGrid}
                                        type='secondary'
                                        disabled={filters.values.viewType === 'rows'}
                                    />
                                }
                                asChild
                                placement='bottom-end'
                            >
                                {() => (
                                    <PopoverBody>
                                        <CheckboxGroup
                                            options={allInitialInspectionStatuses}
                                            value={
                                                tab === InspectionsTab.Inspections
                                                    ? effectiveInspectionsStatuses
                                                    : effectiveDeviceCheckStatuses
                                            }
                                            onChange={
                                                tab === InspectionsTab.Inspections
                                                    ? filters.controls.setInspectionStatuses
                                                    : filters.controls.setDeviceCheckStatuses
                                            }
                                            toKey={identity}
                                            toLabel={trInspectionStatus}
                                        />
                                    </PopoverBody>
                                )}
                            </Popover>
                        </Box>
                    }
                    tabsSlot={<InspectionPageTabs tab={tab} onChange={onTabChange} />}
                />
                <InspectionsPageFilters
                    tab={tab}
                    showFilters={showFilters}
                    filters={filters}
                    adminUsers={adminUsers}
                    hofyWarehouses={hofyWarehouses}
                />
                <InspectionsFilterChips
                    tab={tab}
                    filters={filters}
                    adminUsers={adminUsers}
                    hofyWarehouses={hofyWarehouses}
                />
                {filters.values.viewType === 'columns' && (
                    <Box fullHeight row alignItems='stretch' gap={12} padding={12} overflow='hidden'>
                        {sortBy(effectiveStatuses, getInspectionStatusIndex).map(status => (
                            <Box key={status} column gap={12} flex='auto'>
                                <InspectionsColumnHeader status={status} />
                                <Box column flex='auto'>
                                    <InspectionsList
                                        viewType='columns'
                                        filters={{
                                            inspectionStatuses:
                                                tab === InspectionsTab.Inspections ? [status] : [],
                                            deviceCheckStatuses:
                                                tab === InspectionsTab.DeviceChecks ? [status] : [],
                                            assignedToIds: filters.values.assignedToIds,
                                            warehouseIds: filters.values.warehouseIds,
                                            withDeviceCheckOnly: tab === InspectionsTab.DeviceChecks,
                                        }}
                                    />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
                {filters.values.viewType === 'rows' && (
                    <Box
                        fullHeight
                        row
                        alignItems='stretch'
                        justify='center'
                        gap={12}
                        padding={12}
                        overflow='hidden'
                    >
                        <InspectionsList
                            viewType='rows'
                            filters={{
                                inspectionStatuses:
                                    tab === InspectionsTab.Inspections
                                        ? filters.values.inspectionStatuses
                                        : [],
                                deviceCheckStatuses:
                                    tab === InspectionsTab.DeviceChecks
                                        ? filters.values.deviceCheckStatuses
                                        : [],
                                assignedToIds: filters.values.assignedToIds,
                                warehouseIds: filters.values.warehouseIds,
                                withDeviceCheckOnly: tab === InspectionsTab.DeviceChecks,
                            }}
                        />
                    </Box>
                )}
            </Page>
        </InspectionsPageContextProvider>
    );
};

interface InspectionsColumnHeaderProps {
    status: InspectionStatus;
}

const InspectionsColumnHeader: FC<InspectionsColumnHeaderProps> = ({ status }) => {
    const trInspectionStatus = useTrInspectionStatus();
    const color = getColorFromColorDye(getInspectionStatusColorDye(status), 'main');

    return (
        <Box column gap={12}>
            <Box row gap={12}>
                <Dot color={color} marginLeft={10} />
                <Paragraph3 bold lineHeight='large'>
                    {trInspectionStatus(status)}
                </Paragraph3>
            </Box>
            <Box bg={color} height={3} rounded />
        </Box>
    );
};
