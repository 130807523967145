import React, { FC } from 'react';

import { allOrganizationSizes, useTrOrganizationSize } from '@hofy/api-shared';
import { coreCurrencies, organizationSignupSupportedCountries } from '@hofy/global';
import { useTrCountry, useTrCurrency } from '@hofy/i18n';
import {
    Form,
    FormCheckbox,
    FormInput,
    FormSelect,
    FormSelectSearch,
    Paragraph4,
    SectionTitle2,
    SubmitButton,
    UseForm,
} from '@hofy/ui';

import { AnalyticsSelfSignUpEvent, useAuthAnalyticsPageOnce } from '../../../../hooks/useAnalytics';
import { useAuthI18n } from '../../../../i18n/useAuthI18n';
import { OrganizationDetailsFormData } from '../../../../store/auth/useOrganizationSignUp';
import { TermsAndConditionsLabel } from '../../userSignup/ConnectionSignUpForm';

interface OrganizationDetailsStepProps {
    form: UseForm<OrganizationDetailsFormData>;
    isLoading: boolean;
    isError: boolean;
    onSubmit(): void;
}

export const OrganizationDetailsStep: FC<OrganizationDetailsStepProps> = ({
    form,
    isLoading,
    isError,
    onSubmit,
}) => {
    const { tr } = useAuthI18n();
    const trCurrency = useTrCurrency();
    const trOrganizationSize = useTrOrganizationSize();
    const trCountry = useTrCountry();

    useAuthAnalyticsPageOnce(AnalyticsSelfSignUpEvent.OrganizationDetailsStepViewed);

    return (
        <Form onSubmit={onSubmit} submitOnEnter column gap={24} isLoading={isLoading} isError={isError}>
            <SectionTitle2>{tr('org-sign-up-page.organization-details-form.title')}</SectionTitle2>
            <FormInput
                label={tr('org-sign-up-page.organization-details-form.company-name.label')}
                api={form.fields.organizationName}
                autoFocus
            />
            <FormSelect
                label={tr('org-sign-up-page.organization-details-form.company-size.label')}
                api={form.fields.organizationSize}
                options={allOrganizationSizes}
                toText={trOrganizationSize}
            />
            <FormSelect
                label={tr('org-sign-up-page.organization-details-form.currency.label')}
                placeholder={tr('org-sign-up-page.organization-details-form.currency.placeholder')}
                api={form.fields.currency}
                options={coreCurrencies}
                toText={trCurrency}
            />
            <FormSelectSearch
                label={tr('org-sign-up-page.organization-details-form.country.label')}
                placeholder={tr('org-sign-up-page.organization-details-form.country.placeholder')}
                api={form.fields.billingCountry}
                options={organizationSignupSupportedCountries}
                contentMaxHeight={300}
                toText={trCountry}
            />
            <FormCheckbox
                label={
                    <Paragraph4>
                        <TermsAndConditionsLabel />
                    </Paragraph4>
                }
                api={form.fields.termsAndConditionsAccepted}
            />
            <SubmitButton label={tr('org-sign-up-page.organization-details-form.create-account')} />
        </Form>
    );
};
