import { difference, sortBy } from 'lodash';
import React, { FC, useEffect } from 'react';

import {
    allSupplierPaymentTerms,
    allSupplierStatuses,
    allSupplierTypes,
    SupplierPaymentTerms,
    SupplierType,
    useTrPaymentTerms,
} from '@hofy/api-shared';
import { FormRow, SettingsSwitch } from '@hofy/common';
import {
    allCurrencies,
    coreCurrencies,
    countryCurrencies,
    Currency,
    RootRegion,
    someBelongToRegion,
} from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import {
    FormContainer,
    FormCountryRegionSelect,
    FormCountrySelect,
    FormInput,
    FormPriceInput,
    FormSection,
    FormSelect,
    FormSelectSearch,
    FormTextarea,
    Paragraph3,
    Tooltip,
    UseForm,
} from '@hofy/ui';

import { Step1FormData } from '../../../../../store/suppliers/useSupplierForm';
import { useTrSupplierStatus } from '../../../../../store/suppliers/useTrSupplierStatus';
import { useTrSupplierType } from '../../../../../store/suppliers/useTrSupplierType';
import { SupplierHofySubsidiaryDropdown } from './SupplierHofySubsidiaryDropdown';

interface SupplierBasicDataFormProps {
    form: UseForm<Step1FormData>;
    creating?: boolean;
}

export const SupplierBasicDataForm: FC<SupplierBasicDataFormProps> = ({ form, creating }) => {
    const trSupplierType = useTrSupplierType();
    const trSupplierStatus = useTrSupplierStatus();
    const trCurrency = useTrCurrency();
    const trPaymentTerms = useTrPaymentTerms<SupplierPaymentTerms>(SupplierPaymentTerms);
    useEffect(() => {
        if (!form.values.locatedIn) {
            form.setValues({
                primaryCurrency: null,
                supplierType: null,
            });
            return;
        }
        const supplierType = someBelongToRegion(RootRegion.Core, [form.values.locatedIn])
            ? SupplierType.CoreGeography
            : SupplierType.NonCoreGeography;

        if (!form.values.supplierType) {
            form.setValues({
                supplierType,
            });
        }

        if (!form.values.primaryCurrency) {
            form.setValues({
                primaryCurrency: countryCurrencies[form.values.locatedIn],
            });
        }
    }, [form.values.locatedIn]);

    useEffect(() => {
        if (!form.values.paymentTerm || form.values.paymentTerm === SupplierPaymentTerms.TermsPrepaid) {
            form.setValues({
                unlimitedCredit: false,
                creditLimit: null,
            });
        }
    }, [form.values.paymentTerm]);

    const renderSupplierStatusInput = () => {
        const dropDown = (
            <FormSelect
                label='Supplier status'
                options={allSupplierStatuses}
                api={form.fields.supplierStatus}
                placeholder='Select status'
                toText={trSupplierStatus}
                disabled={!!creating}
                isRequired
            />
        );

        if (!creating) {
            return dropDown;
        }

        return (
            <Tooltip body='Suppliers are always created with &lsquo;Active&rsquo; status.'>
                {dropDown}
            </Tooltip>
        );
    };

    return (
        <FormContainer>
            <FormSection label='Supplier' paddingTop={10}>
                <FormInput label='Name' api={form.fields.name} isRequired nullable />
                <FormInput label='Legal name' api={form.fields.legalName} isRequired nullable />
                <FormRow>
                    <FormCountrySelect
                        label='Located in'
                        api={form.fields.locatedIn}
                        placeholder='Select a country'
                        nullable
                        isRequired
                    />
                    <SupplierHofySubsidiaryDropdown
                        label='Default Hofy subsidiary'
                        value={form.values.hofySubsidiary}
                        emptyContent='Select subsidiary'
                        onChange={hofySubsidiary =>
                            form.setValues({
                                hofySubsidiary,
                            })
                        }
                        isRequired
                        isError={form.errors.hofySubsidiary}
                        country={form.values.locatedIn ?? undefined}
                        supplierType={form.values.supplierType ?? undefined}
                    />
                </FormRow>
                <FormRow>
                    <FormSelect
                        label='Supplier type'
                        options={allSupplierTypes}
                        api={form.fields.supplierType}
                        placeholder='Select type'
                        toText={trSupplierType}
                        isRequired
                        nullable
                    />
                    {renderSupplierStatusInput()}
                </FormRow>
            </FormSection>
            <FormSection label='Billing'>
                <FormRow>
                    <FormInput
                        label='Tax registration number'
                        api={form.fields.taxRegistrationNumber}
                        nullable
                    />
                    <FormInput
                        label='Company registration number'
                        api={form.fields.companyRegistrationNumber}
                        nullable
                    />
                </FormRow>
                <FormRow>
                    <FormSelect
                        label='Payment terms'
                        options={allSupplierPaymentTerms}
                        api={form.fields.paymentTerm}
                        placeholder='Select payment terms'
                        toText={trPaymentTerms}
                        isRequired
                    />
                    <FormSelectSearch
                        label='Currency'
                        options={sortBy(difference(allCurrencies, coreCurrencies), c => trCurrency(c))}
                        fixedOptions={sortBy(coreCurrencies, c => trCurrency(c))}
                        // toSearchLabel={c => [trCurrency(c).toLowerCase(), trCurrency(c).toUpperCase()]}
                        api={form.fields.primaryCurrency}
                        toText={v => v}
                        isRequired
                    />
                </FormRow>
                {form.values.paymentTerm && form.values.paymentTerm !== SupplierPaymentTerms.TermsPrepaid && (
                    <>
                        <SettingsSwitch
                            flex={1}
                            label='Unlimited credit'
                            checked={form.values.unlimitedCredit}
                            onChange={unlimitedCredit =>
                                form.setValues({
                                    unlimitedCredit,
                                })
                            }
                        />
                        <FormPriceInput
                            label='Credit limit'
                            api={form.fields.creditLimit}
                            defaultCurrency={form.values.primaryCurrency ?? Currency.USD}
                            disabled={form.values.unlimitedCredit}
                            nullable
                            clearable
                        />
                        <Paragraph3 color={Color.ContentTertiary}>
                            * Either a Credit limit or Unlimited credit must be set
                        </Paragraph3>
                    </>
                )}
            </FormSection>
            <FormSection label='Additional'>
                <FormCountryRegionSelect
                    api={form.fields.countries}
                    label='Supported dropshipping countries'
                    placeholder='No countries selected'
                />
                <FormTextarea label='Notes' api={form.fields.notes} nullable />
            </FormSection>
        </FormContainer>
    );
};
