import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { HofySubsidiaryDto } from '@hofy/api-admin';
import { CountryLabel } from '@hofy/core';
import { useTrCountry, useTrCurrency } from '@hofy/i18n';
import { BaseTable, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import { RowMenu } from './RowMenu';

interface SubsidiariesTableProps {
    subsidiaries: HofySubsidiaryDto[];
    isLoading: boolean;
}

export const SubsidiariesTable: FC<SubsidiariesTableProps> = ({ subsidiaries, isLoading }) => {
    const trCountry = useTrCountry();
    const trCurrency = useTrCurrency();
    return (
        <BaseTable
            data={subsidiaries}
            toKey={subsidiary => subsidiary.id}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No subsidiaries' />}
            isLoading={isLoading}
            flex='auto'
            columns={[
                {
                    id: 'Id',
                    header: 'Id',
                    flexGrow: 0,
                    width: 100,
                    renderer: subsidiary => <TextCell>#{subsidiary.id}</TextCell>,
                },
                {
                    id: 'alias',
                    header: 'Alias',
                    flexGrow: 0,
                    width: 150,
                    renderer: subsidiary => <TextCell>{subsidiary.alias}</TextCell>,
                },
                {
                    id: 'country',
                    header: 'Located in',
                    flexGrow: 0,
                    width: 150,
                    renderer: subsidiary => <CountryLabel country={subsidiary.locatedIn} />,
                },
                {
                    id: 'currency',
                    header: 'Currency',
                    width: 100,
                    flexGrow: 0,
                    renderer: subsidiary => <TextCell>{trCurrency(subsidiary.currency)}</TextCell>,
                },
                {
                    id: 'address',
                    header: 'Address',
                    width: 150,
                    flexGrow: 1,
                    renderer: subsidiary => (
                        <TextCell>
                            <PostAddressView address={subsidiary.address} />
                        </TextCell>
                    ),
                },
                {
                    id: 'countries',
                    header: 'Countries',
                    width: 500,
                    flexGrow: 1,
                    renderer: subsidiary => (
                        <TextCell marginRight={24} whiteSpace='normal'>
                            {subsidiary.countries.map(item => trCountry(item)).join(', ')}
                        </TextCell>
                    ),
                },
                {
                    id: 'menu',
                    flexGrow: 0,
                    width: 50,
                    renderer: subsidiary => {
                        return <RowMenu id={subsidiary.id} />;
                    },
                },
            ]}
        />
    );
};
