import { useQuery } from '@tanstack/react-query';

import { repairsService } from '../service/repairsService';
import { repairsCacheKey } from './cacheKey';

export const useRepairQuery = (id: number) => {
    const {
        data: repair,
        isLoading,
        isError,
    } = useQuery({
        queryKey: [repairsCacheKey, id],
        queryFn: () => repairsService.getRepair(id),
    });

    return {
        repair,
        isLoading,
        isError,
    };
};
