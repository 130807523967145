import React, { FC } from 'react';

import { PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { usePrice } from '@hofy/hooks';
import { Box, FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { PurchaseOrderStatusChip } from '../../../../../components/domain/purchaseOrders/PurchaseOrderStatusChip';
import { PurchaseOrderTypeChip } from '../../../../../components/domain/purchaseOrders/PurchaseOrderTypeChip';

interface PurchaseOrderDetailsProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

export const PurchaseOrderDetails: FC<PurchaseOrderDetailsProps> = ({ purchaseOrder }) => {
    const { formatPrice } = usePrice();

    return (
        <Box>
            <FormGridRow columns={4}>
                <LabeledText label='Id' content={`#${purchaseOrder.idDeprecated}`} />
                <LabeledText label='Total price' content={formatPrice(purchaseOrder.totalPrice)} />
                {purchaseOrder.currency && <LabeledText label='Currency' content={purchaseOrder.currency} />}
                <Labeled
                    label='PO status'
                    content={<PurchaseOrderStatusChip status={purchaseOrder.status} />}
                />
            </FormGridRow>

            <FormGridRow columns={4} marginTop={20}>
                {purchaseOrder.toUser && (
                    <LabeledText label='User' content={formatUserName(purchaseOrder.toUser)} />
                )}
                {purchaseOrder.toWarehouse && (
                    <LabeledText label='Warehouse' content={purchaseOrder.toWarehouse.name} />
                )}
                {purchaseOrder.toWarehouse && <LabeledText label='Notes' content={purchaseOrder.notes} />}
                <LabeledText
                    label='Supplier reference'
                    content={purchaseOrder.purchaseOrderSupplierReference}
                />
                <Labeled
                    label='PO type'
                    content={<PurchaseOrderTypeChip isDropship={purchaseOrder.isDropship} />}
                />
            </FormGridRow>
        </Box>
    );
};
