import React, { FC } from 'react';

import { ColorDye } from '@hofy/global';
import { Color, colorDyes, VisualVariant } from '@hofy/theme';

import { Box, Paragraph4 } from '../base';

interface CounterProps {
    count: number;
    color?: ColorDye;
    variant?: VisualVariant;
}

export const Counter: FC<CounterProps> = ({ count, color, variant = 'normal' }) => {
    const dye = color && colorDyes[color];

    const bg = variant === 'normal' ? dye?.muted : dye?.main;
    const textColor = variant === 'normal' ? dye?.onMuted : Color.ContentInvertedPrimary;

    return (
        <Box
            bg={bg}
            border={!bg}
            borderColor={
                variant === 'normal'
                    ? Color.NonContextualBorderSubtleNeutral
                    : Color.NonContextualBorderNeutral
            }
            rounded={16}
            inline
        >
            <Paragraph4
                color={bg ? textColor : Color.ContentPrimary}
                paddingHorizontal={8}
                lineHeight='large'
                textAlign='center'
                bold
            >
                {count}
            </Paragraph4>
        </Box>
    );
};
