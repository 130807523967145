import React, { FC } from 'react';

import { useAuditLogs } from '@hofy/api-admin';
import { Page } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Icon, Input, NumberInput, PageHeader, SvgIcon } from '@hofy/ui';

import { useAuditLogsFilters } from '../../store/auditLogs/useAuditLogsFilters';
import { AuditLogsTable } from './AuditLogsTable';
import { AuditTableSelect } from './components/AuditTableSelect';

interface AuditLogsPageProps {
    onOpenLog(auditId: number): void;
}

export const AuditLogsPage: FC<AuditLogsPageProps> = ({ onOpenLog }) => {
    const filters = useAuditLogsFilters();
    const {
        auditTableName,
        auditTableId,
        transactionId,
        setAuditTableName,
        setAuditTableId,
        setTransactionId,
    } = filters;
    const { isLoading, hasNextPage, isFetchingNextPage, fetchNextPage, items } = useAuditLogs(
        filters.filters,
    );

    return (
        <Page>
            <PageHeader
                title='System Logs'
                rightSlot={
                    <>
                        <Icon svg={SvgIcon.Filter} size={20} color={Color.ContentSecondary} />
                        <AuditTableSelect onChange={setAuditTableName} value={auditTableName} width={300} />

                        <NumberInput
                            value={auditTableId}
                            onChange={setAuditTableId}
                            placeholder='Table id'
                            width={200}
                            nullable
                        />
                        <Input
                            onChange={setTransactionId}
                            value={transactionId}
                            placeholder='Transaction'
                            leftSlot={<Icon block svg={SvgIcon.Tag} />}
                            nullable
                        />
                    </>
                }
            />
            <Box relative flex='auto' row alignItems='stretch'>
                <AuditLogsTable
                    logs={items}
                    onOpenLogs={onOpenLog}
                    infinityScroll={{
                        hasMore: hasNextPage,
                        isLoading: isLoading,
                        isLoadingMore: isFetchingNextPage,
                        loadMore: fetchNextPage,
                    }}
                />
            </Box>
        </Page>
    );
};
