import React, { Children, FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box } from '../../base';

export type ColumnTypes = '1/1' | ('3/2' | '2/3') | ('1/4' | '4/1');

interface PageColumnsLayoutProps {
    columns: ColumnTypes;
    children: ReactNode;
}

export const PageColumnsLayout: FC<PageColumnsLayoutProps> = ({ columns, children }) => {
    const [left, right, ...more] = Children.toArray(children);

    if (more.length) {
        throw new Error('[PageColumns] Only two children are allowed');
    }

    const [leftType, rightType] = columns.split('/');
    const template = `${leftType}fr ${rightType}fr`;

    return (
        <ContainerBox flex='auto' template={template}>
            {left}
            {right}
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)<{ template: string }>`
    display: grid;
    grid-template-columns: ${p => p.template};
    gap: 1px;
    // Gap together with background-color is used to create a border between columns
    background-color: ${Color.NonContextualBorderDivider};
`;
