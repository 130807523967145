import React, { FC } from 'react';

import { PublicSSO } from '@hofy/api-auth';
import { Box, Form, FormEmailInput, SubmitButton, UseForm } from '@hofy/ui';

import { AnalyticsSelfSignUpEvent, useAuthAnalyticsPageOnce } from '../../../../hooks/useAnalytics';
import { useAuthI18n } from '../../../../i18n/useAuthI18n';
import { EmailVerificationFormPayload } from '../../../../store/auth/useOrganizationSignUp';
import { Or } from '../../../components/Or';
import { PublicSSOButtons } from '../../../components/publicSSOButton/PublicSSOButtons';

interface EmailVerificationStepProps {
    form: UseForm<EmailVerificationFormPayload>;
    signUpWithPublicSSO(sso: PublicSSO): void;
    isLoading: boolean;
    isError: boolean;
}

export const EmailVerificationStep: FC<EmailVerificationStepProps> = ({
    form,
    signUpWithPublicSSO,
    isLoading,
    isError,
}) => {
    const { tr } = useAuthI18n();

    useAuthAnalyticsPageOnce(AnalyticsSelfSignUpEvent.SignUpPageViewed);

    return (
        <Box column gap={24} marginTop={24}>
            <Or text={tr('org-sign-up-page.email-password-form.divider.sso')} />
            <PublicSSOButtons onClick={signUpWithPublicSSO} mode='sign-up' />

            <Or text={tr('org-sign-up-page.email-password-form.divider.email')} />
            <Form
                onSubmit={form.submit}
                submitOnEnter
                isLoading={isLoading}
                isError={isError}
                gap={24}
                column
            >
                <FormEmailInput
                    api={form.fields.email}
                    label={tr('org-sign-up-page.email-password-form.email')}
                />
                <SubmitButton label={tr('org-sign-up-page.email-password-form.continue')} fullWidth />
            </Form>
        </Box>
    );
};
