import { restClient } from '@hofy/rest';

import { BetaFeatureDto } from './types/BetaFeatureDto';
import { BetaFeaturePayload } from './types/BetaFeaturePayload';

class BetaFeatureService {
    public getFeatures = async (): Promise<BetaFeatureDto[]> => {
        return await restClient.getJson<BetaFeatureDto[]>(`/api/admin/beta-features`);
    };

    public updateFeatures = async (id: number, payload: BetaFeaturePayload) => {
        return restClient.put(`/api/admin/beta-features/${id}`, payload);
    };
}

export const betaFeatureService = new BetaFeatureService();
