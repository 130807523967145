import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { SessionDto } from '@hofy/api-auth';
import { hasAnyPermission, isAuthLink } from '@hofy/auth';

import { adminLinkPermissions, AdminNavLink } from './AdminNavLink';

interface DefaultRouteProps {
    session?: SessionDto;
}

export const DefaultRoute: FC<DefaultRouteProps> = ({ session }) => {
    const { pathname } = useLocation();
    const isSignedInOnSignInPage = session && isAuthLink(pathname);

    if (pathname === '/' || isSignedInOnSignInPage) {
        for (const link in adminLinkPermissions) {
            const permissions = session?.permissions || [];
            if (hasAnyPermission(permissions, adminLinkPermissions[link as AdminNavLink])) {
                return <Redirect to={link} />;
            }
        }
    }
    return null;
};
