import { useHistory } from 'react-router-dom';

import { useSession } from '@hofy/auth';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import {
    allInventoryTabs,
    InventoryTab,
    InventoryTabLink,
    inventoryTabPermissions,
} from '../inventory/types/InventoryTab';

export const useAdminNavigateInventory = () => {
    const { hasPermission } = useSession();
    const history = useHistory();

    const adminInventoryTabs = allInventoryTabs.filter(tab => hasPermission(inventoryTabPermissions[tab]));

    const navigateInventory = (tab: InventoryTab) => {
        history.push(`${AdminNavLink.Inventory}/${InventoryTabLink[tab]}`);
    };

    return {
        // If there is a single tab to navigate, we return an empty array to hide the tabs
        adminInventoryTabs: adminInventoryTabs.length > 1 ? adminInventoryTabs : [],
        navigateInventory,
    };
};
