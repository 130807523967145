import React, { FC, useState } from 'react';

import { AuditableTable, getInvitedEmailType, toLoginEmailAware } from '@hofy/api-admin';
import { formatUserName, Permission, UserStatus } from '@hofy/api-shared';
import { SendInviteModal, useResetPassword, useSendInvite, useSession, useSignInAsUser } from '@hofy/auth';
import { ComponentLoader, MoreMenu, Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Box,
    Button,
    ConfirmModal,
    Modals,
    PageHeader,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    Tab,
    Tabs,
    Tooltip,
} from '@hofy/ui';

import { useAuditLogMenuOption } from '../../store/auditLogs/useAuditMenuOption';
import { allUserTabs, UserTab } from '../../store/users/types/UserTab';
import { useDeleteUserPersonalData } from '../../store/users/useDeleteUser';
import { useSyncUserToZendesk } from '../../store/users/useSyncUserToZendesk';
import { useTrUserTab } from '../../store/users/useTrUserTab';
import { useUser } from '../../store/users/useUser';
import { AssignmentsTab } from './assignmentsTab/AssignmentsTab';
import { ResetPasswordModal } from './components/ResetPasswordModal';
import { DetailsTab } from './detailsTab/DetailsTab';
import { UserEmailsTab } from './emailsTab/UserEmailsTab';
import { GenerateSignupLinkModal } from './generateSignupLinkModal/GenerateSignupLinkModal';
import { UserMessagesTab } from './messagesTab/UserMessagesTab';
import { OrdersTab } from './requestsTab/OrdersTab';

enum UserModal {
    DeletePersonalData,
    ResetPassword,
    SendInvite,
    GenerateSignUpLink,
    SyncToZendesk,
}

interface UserDetailsOverlayProps {
    userId: UUID;
    userTab: UserTab;
    onChangeTab(tab: UserTab): void;
    onOpenAssignment(id: number): void;
    onOpenOrganization(id: UUID): void;
    onOpenEmail(id: UUID): void;
    onOpenMessage(id: number): void;
    onOpenUpdateUser(id: UUID): void;
    onOpenOrder(id: number): void;
}

export const UserDetailsOverlay: FC<UserDetailsOverlayProps> = ({
    userId,
    userTab,
    onChangeTab,
    onOpenAssignment,
    onOpenEmail,
    onOpenMessage,
    onOpenOrganization,
    onOpenUpdateUser,
    onOpenOrder,
}) => {
    const trTab = useTrUserTab();
    const { user, isLoading } = useUser(userId);
    const { hasPermission, session } = useSession();

    const [modal, setModal] = useState<UserModal>();
    const handleModalClose = () => setModal(undefined);

    const { resetPassword } = useResetPassword();
    const { signInAsUser } = useSignInAsUser();
    const { sendInvite, isInviteMutationLoading } = useSendInvite(handleModalClose);
    const { deleteUserPersonalData } = useDeleteUserPersonalData(userId);
    const { syncUserToZendesk } = useSyncUserToZendesk(userId);
    const invitedEmailType = getInvitedEmailType(user);

    const [menuLink] = useAuditLogMenuOption(AuditableTable.User, undefined, user?.id);

    if (isLoading) {
        return <ComponentLoader />;
    }

    if (user === undefined) {
        return (
            <Box flex='auto' relative>
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title='No user'
                    message='No user with selected identifier'
                />
            </Box>
        );
    }

    const renderContent = () => {
        switch (userTab) {
            case UserTab.Details:
                return <DetailsTab user={user} />;
            case UserTab.Emails:
                return <UserEmailsTab user={user} onOpenEmail={onOpenEmail} />;
            case UserTab.Messages:
                return <UserMessagesTab user={user} onOpenMessage={onOpenMessage} />;
            case UserTab.Assignments:
                return <AssignmentsTab user={user} onOpenAssignment={onOpenAssignment} />;
            case UserTab.Orders:
                return <OrdersTab userId={user.id} onOpenOrder={onOpenOrder} />;
        }
    };

    const editingYourself = session.userId === userId;

    const moreMenu = (
        <MoreMenu
            items={[
                {
                    action: () => setModal(UserModal.SendInvite),
                    color: Color.NonContextualBlueDefault,
                    icon: SvgIcon.Mail,
                    label: 'Send invite',
                    testKey: 'user-send-invitation',
                    visible: user.status === 'created' && hasPermission(Permission.AdminUsersInvite),
                },
                {
                    action: () => setModal(UserModal.GenerateSignUpLink),
                    icon: SvgIcon.Link,
                    label: 'Generate signup link',
                    testKey: 'user-generate-signup',
                    visible: !!invitedEmailType && hasPermission(Permission.AdminUsersInvite),
                },
                {
                    action: () => setModal(UserModal.ResetPassword),
                    icon: SvgIcon.Replace,
                    label: 'Reset password',
                    testKey: 'user-reset-password',
                    visible: hasPermission(Permission.AdminUsersResetPassword),
                },
                {
                    action: () => setModal(UserModal.SyncToZendesk),
                    icon: SvgIcon.Refresh,
                    label: 'Sync to Zendesk',
                    testKey: 'user-sync-to-zendesk',
                    visible: hasPermission(Permission.AdminUsersSyncToZendesk),
                },
                {
                    action: () => setModal(UserModal.DeletePersonalData),
                    color: Color.ContentNegative,
                    icon: SvgIcon.CrossCircle,
                    label: 'Delete personal data',
                    testKey: 'user-delete-personal-data',
                    visible:
                        hasPermission(Permission.AdminUsersDelete) && user.status === UserStatus.Offboarded,
                },
                menuLink,
            ]}
        />
    );
    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                title={formatUserName(user)}
                rightSlot={
                    <>
                        <Button
                            type='secondary'
                            label='Organization'
                            leftIcon={SvgIcon.Building}
                            onClick={() => onOpenOrganization(user.organization.id)}
                        />
                        <Button
                            type='secondary'
                            label='Sign in as user'
                            leftIcon={SvgIcon.User}
                            disabled={!hasPermission(Permission.AdminSignInAsUser)}
                            onClick={() => signInAsUser(user.id)}
                        />
                        {userTab === UserTab.Details && (
                            <Tooltip
                                placement='bottom'
                                body={editingYourself && 'You cannot edit your own settings'}
                                enabled={editingYourself}
                            >
                                <Box>
                                    <Button
                                        label='User settings'
                                        leftIcon={SvgIcon.Settings}
                                        onClick={() => onOpenUpdateUser(user.id)}
                                        disabled={
                                            !hasPermission(Permission.AdminUsersUpdate) || editingYourself
                                        }
                                    />
                                </Box>
                            </Tooltip>
                        )}
                        {moreMenu}
                    </>
                }
                tabsSlot={
                    <Tabs active={userTab} onChange={onChangeTab}>
                        {allUserTabs.map(tab => (
                            <Tab key={tab} id={tab} label={trTab(tab)} />
                        ))}
                    </Tabs>
                }
            />

            {renderContent()}

            <Modals>
                {modal === UserModal.SendInvite && (
                    <SendInviteModal
                        onClose={handleModalClose}
                        onSubmit={emailType =>
                            sendInvite({
                                userId: user.id,
                                emailType: emailType,
                            })
                        }
                        selectedUser={toLoginEmailAware(user)}
                        isLoading={isInviteMutationLoading}
                    />
                )}
                {modal === UserModal.GenerateSignUpLink && !!invitedEmailType && (
                    <GenerateSignupLinkModal
                        invitedEmailType={invitedEmailType}
                        userId={user.id}
                        onClose={handleModalClose}
                    />
                )}
                {user.emails.length > 0 && modal === UserModal.ResetPassword && (
                    <ResetPasswordModal
                        onClose={handleModalClose}
                        onResetPassword={email =>
                            resetPassword({
                                email,
                            })
                        }
                        emails={user.emails}
                    />
                )}
                {modal === UserModal.DeletePersonalData && (
                    <ConfirmModal
                        keyPrefix='delete-user-personal-data-modal'
                        onConfirm={deleteUserPersonalData}
                        onClose={handleModalClose}
                    />
                )}
                {modal === UserModal.SyncToZendesk && (
                    <ConfirmModal
                        keyPrefix='sync-user-to-zendesk-modal'
                        onConfirm={syncUserToZendesk}
                        onClose={handleModalClose}
                    />
                )}
            </Modals>
        </Overlay>
    );
};
