import { sortBy } from 'lodash';
import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { BoxProps, FormFieldApi, FormSelectSearch } from '@hofy/ui';

import { useVariants } from '../../hooks/useVariants';

interface VariantSelectProps extends BoxProps {
    api: FormFieldApi<UUID | null>;
    disabled?: boolean;
}

export const VariantSelect: FC<VariantSelectProps> = ({ api, disabled, ...boxProps }) => {
    const { variants, variantProductMap, isLoading } = useVariants();

    return (
        <FormSelectSearch
            api={api}
            label='Variant'
            disabled={disabled || isLoading}
            options={sortBy(variants, [v => v.sku]).map(v => v.id)}
            toLabel={v => (isLoading ? 'Loading variants...' : variantProductMap[v])}
            toText={v => (isLoading ? 'Loading variants...' : variantProductMap[v])}
            nullable={false}
            placeholder='Select SKU'
            {...boxProps}
        />
    );
};
