import React, { FC } from 'react';

import { ContractDetailsDtoWithRental } from '@hofy/api-admin';
import {
    Alert,
    FormDateInput,
    FormModal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useCancelContract } from '../../../store/contracts/useCancelContract';

interface CancelRentalAgreementModalProps {
    contract: ContractDetailsDtoWithRental;
    onClose(): void;
}

export const CancelContractModal: FC<CancelRentalAgreementModalProps> = ({ contract, onClose }) => {
    const { form } = useCancelContract(contract, onClose);

    return (
        <FormModal width={450} onClose={form.discard} onSubmit={form.submit}>
            <ModalHeader title={`Cancel contract #${contract.idDeprecated}`} />
            <ModalContent>
                <Alert type='negative' description='All invoice entries will be deleted' marginBottom={16} />
                <FormDateInput label='Cancellation date' api={form.fields.cancelledOn} />
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={form.discard} />
                <SubmitButton action='destructive' label='Cancel contract' />
            </ModalFooter>
        </FormModal>
    );
};
