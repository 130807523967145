import React, { FC } from 'react';

import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader, WizardSimpleFooter } from '@hofy/common';
import { Box, useFormObjectField } from '@hofy/ui';

import { useUpdateSupplier } from '../../../../store/suppliers/useUpdateSupplier';
import { SupplierAddressForm } from './components/SupplierAddressForm';
import { SupplierBasicDataForm } from './components/SupplierBasicDataForm';

interface EditSupplierSlideoutProps {
    supplierId: number;
    onClose(): void;
}

export const UpdateSupplierSlideout: FC<EditSupplierSlideoutProps> = ({ supplierId, onClose }) => {
    const { form, isLoading } = useUpdateSupplier(supplierId, onClose);

    const onSubmit = () => {
        form.submit();
    };
    const billingAddressForm = useFormObjectField(form.forms.step2.fields.billingAddress);
    const shippingAddressForm = useFormObjectField(form.forms.step3.fields.shippingAddress);

    const content = () => {
        switch (form.step) {
            case 'step1': // Basic Supplier data
                return <SupplierBasicDataForm form={form.forms.step1} creating />;
            case 'step2':
                return (
                    <SupplierAddressForm
                        form={billingAddressForm}
                        label='Billing address'
                        locatedIn={form.forms.step1.values.locatedIn!}
                    />
                );
            case 'step3':
                return (
                    <SupplierAddressForm
                        form={shippingAddressForm}
                        label='Shipping address'
                        locatedIn={form.forms.step1.values.locatedIn!}
                    />
                );
        }
    };

    return (
        <Slideout width={800} onClose={onClose} slideoutId='supplier'>
            <SlideoutHeader title='Edit Supplier' />
            <SlideoutContent column paddingVertical={20}>
                <Box fullWidth paddingVertical={10}>
                    {content()}
                </Box>
            </SlideoutContent>
            <SlideoutFooter>
                <WizardSimpleFooter
                    step={form.step}
                    steps={['step1', 'step2', 'step3']}
                    onCancel={onClose}
                    nextLabel='Next'
                    cancelLabel='Cancel'
                    prevLabel='Previous'
                    onSubmit={onSubmit}
                    isSubmitLoading={isLoading}
                    onChangeStep={form.goToStep}
                    submitLabel='Save'
                />
            </SlideoutFooter>
        </Slideout>
    );
};
