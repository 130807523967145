import React, { FC, useState } from 'react';

import { Box, Form, FormInput, SubmitButton, Tooltip } from '@hofy/ui';

import { AnalyticsSelfSignUpEvent, useAuthAnalyticsPageOnce } from '../../../../hooks/useAnalytics';
import { useAuthI18n } from '../../../../i18n/useAuthI18n';
import { EmailPasswordForm } from '../../../../store/auth/useOrganizationSignUp';
import { PasswordHints } from '../../../components/PasswordHints';

interface EmailPasswordStepProps {
    form: EmailPasswordForm;
    onSubmit(): void;
}

export const EmailPasswordStep: FC<EmailPasswordStepProps> = ({ form, onSubmit }) => {
    const { tr } = useAuthI18n();
    const [showHints, setShowHints] = useState(false);

    useAuthAnalyticsPageOnce(AnalyticsSelfSignUpEvent.PasswordCreationStepViewed);

    return (
        <Form onSubmit={onSubmit} submitOnEnter column gap={24} marginTop={24}>
            <FormInput
                api={form.fields.email}
                disabled
                label={tr('org-sign-up-page.email-password-form.email')}
            />

            <Box column gap={12}>
                <Tooltip body={tr('password-form.password.tooltip')} maxWidth={300}>
                    <FormInput
                        type='password'
                        label={tr('password-form.password')}
                        api={form.fields.password}
                        onChange={() => setShowHints(true)}
                        autoFocus
                    />
                </Tooltip>
                {showHints && (
                    <PasswordHints
                        errors={form.errors}
                        currentErrors={form.currentErrors}
                        keyPrefix='password-form'
                    />
                )}
            </Box>

            <SubmitButton label={tr('org-sign-up-page.email-password-form.continue')} fullWidth />
        </Form>
    );
};
