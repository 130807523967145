import { useState } from 'react';

import { AssignmentWithProductDto, HofyWarehouseDetailsDto, ItemDto } from '@hofy/api-admin';
import {
    AssignmentType,
    assignmentTypeFromStatus,
    isInBadCondition,
    ItemStatus,
    OrderType,
    ProductCategory,
    useTrItemStatus,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { isEmpty } from '@hofy/helpers';
import { useStateEffect } from '@hofy/hooks';

import { useItemByItemCode } from '../items/useItemByItemCode';
import { useAssignItem } from './useAssignItem';
import { useScanAssignedItem } from './useScanAssignedItem';

/**
 * Provides the necessary state for `ItemScanSlideout` allowing either assigning an item or scanning an item based on the type of assignment
 */
export const useScanItem = (
    assignment: AssignmentWithProductDto,
    warehouse: HofyWarehouseDetailsDto,
    organizationId?: UUID,
    defaultCode: string | null = null,
    onSuccess?: () => void,
) => {
    const [itemCode, setItemCode] = useState<string | null>(defaultCode);
    const { data: item, isLoading, isFetching } = useItemByItemCode(itemCode);

    const assignItemError = useAssignItemError(item || null, assignment, warehouse, organizationId);
    const scanItemError = getScanItemError(item || null, assignment);
    const itemError = assignment.type === AssignmentType.Redistribution ? scanItemError : assignItemError;

    const [serialNumber, setSerialNumber] = useStateEffect<string | null>(item?.serialNumber ?? null, [item]);
    const serialNumberError = getSerialNumberError(serialNumber, assignment);

    const scanItem = useScanAssignedItem(assignment.id, onSuccess);
    const assignItem = useAssignItem(assignment.id, onSuccess);

    const {
        mutate,
        isPending: isSubmitting,
        isError: isSubmitError,
    } = assignment.type === AssignmentType.Redistribution ? scanItem : assignItem;

    const submit = () => {
        if (!item || itemError || isLoading || serialNumberError) {
            return;
        }

        mutate({
            itemId: item.id,
            serialNumber,
        });
    };

    return {
        itemCode,
        setItemCode,
        itemError: isLoading ? undefined : itemError,
        serialNumber,
        setSerialNumber,
        serialNumberError: isLoading ? undefined : serialNumberError,
        isFetching,
        isLoading,
        isSubmitting,
        isSubmitError,
        item,
        submit,
    };
};

const getSerialNumberError = (serialNumber: string | null, assignment: AssignmentWithProductDto) => {
    if (assignment.product.category === ProductCategory.Laptop && isEmpty(serialNumber)) {
        return 'Required for Laptops';
    }
};

const getScanItemError = (item: ItemDto | null, assignment: AssignmentWithProductDto) => {
    if (!item) {
        return 'No Item found';
    }

    if (item.id !== assignment.item?.id) {
        return 'Item does not match assigned item';
    }
};

const useAssignItemError = (
    item: ItemDto | null,
    assignment: AssignmentWithProductDto,
    warehouse: HofyWarehouseDetailsDto,
    organizationId?: UUID,
) => {
    const trItemStatus = useTrItemStatus();

    if (!item) {
        return 'No Item found';
    }

    if (
        item.variant.id !== assignment.variant.id &&
        assignment.type !== AssignmentType.Loaner &&
        !assignment.product.isRefurbished
    ) {
        return 'The item does not match the order';
    }

    if (item.product.category !== assignment.product.category && assignment.type === AssignmentType.Loaner) {
        return 'The item category does not match the order';
    }

    if (item.status !== ItemStatus.Active) {
        return item ? `Item is not usable (${trItemStatus(item.status)})` : `Item is not usable`;
    }

    if (isInBadCondition(item.grade)) {
        return `Item is in condition 'Bad'`;
    }

    const orderIsDelivery = assignmentTypeFromStatus(assignment.status) === OrderType.Delivery;
    if (
        orderIsDelivery &&
        item.location.warehouse &&
        item.location.warehouse?.id !== warehouse.idDeprecated
    ) {
        return `Item is in different warehouse: ${item?.location.warehouse.name}`;
    }

    if (orderIsDelivery && !item.location.warehouse) {
        return `Item is not in a warehouse`;
    }

    if (
        item.contractDetails?.organization &&
        item.contractDetails.organization?.id !== organizationId &&
        !(item.isLoaner && assignment.type === AssignmentType.Loaner)
    ) {
        return 'Item belongs to other Organization';
    }
};
