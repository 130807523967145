import { ItemGrade, ItemLocation, ItemPart, LocationDto } from '@hofy/api-shared';
import { Price, UUID } from '@hofy/global';

export interface UpdateItemPayload extends UpdateLocationPayload {
    grade: ItemGrade | null;
    damageNote: string | null;
    damagedParts: ItemPart[];
    damagedPartsNote: string | null;
    missingParts: ItemPart[];
    missingPartsNote: string | null;
    isMissingOriginalPackaging: boolean;
    isDeviceLocked: boolean;
    serialNumber: string | null;
    isLoaner: boolean;
    usableInStoreAndReuse: boolean;
    clearanceQuote: Price | null;
    clearanceValue: Price | null;
}

export interface UpdateLocationPayload {
    location: ItemLocation;
    warehouseId: number | null;
    warehouseBinIdentifier: string | null;
    userId: UUID | null;
}

export const toLocationPayload = (location: LocationDto) => {
    return {
        location: location.location,
        userId: location.user?.id || null,
        warehouseId: location.warehouse?.id || null,
        warehouseBinIdentifier: location.warehouse?.bin?.identifier || null,
    };
};
