import { Currency, getEnumValues, Price } from '@hofy/global';

export enum PlatformTier {
    UserBasedLite = 'user_based_lite',
    UserBasedPro = 'user_based_pro',
    UserBasedEnterprise = 'user_based_enterprise',
    OrgBasedFree = 'org_based_free',
    OrgBasedEntry = 'org_based_entry',
    OrgBasedLite = 'org_based_lite',
    OrgBasedPro = 'org_based_pro',
    OrgBasedEnterprise = 'org_based_enterprise',
}

export const userBasedPlatformTiers: PlatformTier[] = [
    PlatformTier.UserBasedLite,
    PlatformTier.UserBasedPro,
    PlatformTier.UserBasedEnterprise,
];

export const orgBasedPlatformTiers: PlatformTier[] = [
    PlatformTier.OrgBasedFree,
    PlatformTier.OrgBasedEntry,
    PlatformTier.OrgBasedLite,
    PlatformTier.OrgBasedPro,
    PlatformTier.OrgBasedEnterprise,
];

// It looks like userBasedPlatformTiers and bundledPlatformTiers are the same, but it's not true
// below arrays responsible for bundling/unbundling organization model
// but above arrays responsible for features and limitations
export const unbundledPlatformTiers: PlatformTier[] = [
    PlatformTier.OrgBasedFree,
    PlatformTier.OrgBasedEntry,
    PlatformTier.OrgBasedLite,
    PlatformTier.OrgBasedPro,
    PlatformTier.OrgBasedEnterprise,
];

export const bundledPlatformTiers: PlatformTier[] = [
    PlatformTier.UserBasedLite,
    PlatformTier.UserBasedPro,
    PlatformTier.UserBasedEnterprise,
];

export type PlatformTierPricesMap = Record<PlatformTier, Record<Currency, Price>>;
export const allPlatformTiers = getEnumValues<PlatformTier>(PlatformTier);
