import React, { FC, useEffect, useState } from 'react';

import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader, WizardFooter } from '@hofy/common';

import { useUpdatePurchaseOrder } from '../../../../store/purchaseOrders/useUpdatePurchaseOrder';
import { CreateUpdatePurchaseOrderForm } from './CreateUpdatePurchaseOrderForm';
import { NavigationStep, useAllowedEditStep } from './hooks/useAllowedEditStep';
import { useNavigationSteps } from './hooks/useNavigationSteps';

interface UpdatePurchaseOrderSlideoutProps {
    purchaseOrderId: number;
    onClose(): void;
}

export const UpdatePurchaseOrderSlideout: FC<UpdatePurchaseOrderSlideoutProps> = ({
    purchaseOrderId,
    onClose,
}) => {
    const form = useUpdatePurchaseOrder(purchaseOrderId, onClose);

    const [step, setStep] = useState(NavigationStep.Items);
    const navigateToStep = useNavigationSteps(form.form, setStep);

    useEffect(() => {
        if (step === NavigationStep.Items) {
            form.setValidateItems(true);
        } else {
            form.setValidateItems(false);
        }
    }, [step]);

    const titleType = form.form.values.isDropship ? 'dropship' : 'warehouse';
    const editAllowedForSteps = useAllowedEditStep(form.purchaseOrder);

    return (
        <Slideout width={1300} onClose={onClose} slideoutId='purchase-order'>
            <SlideoutHeader title={`Edit ${titleType} purchase order #${purchaseOrderId}`} />
            <SlideoutContent>
                <CreateUpdatePurchaseOrderForm
                    isEdit
                    form={form}
                    step={step}
                    isEditAllowedOnSteps={editAllowedForSteps}
                />
            </SlideoutContent>
            <SlideoutFooter>
                <WizardFooter
                    step={step}
                    finalStep={3}
                    onCancel={onClose}
                    nextLabel='Next'
                    cancelLabel='Cancel'
                    prevLabel='Previous'
                    onSubmit={form.form.submit}
                    isSubmitLoading={form.isLoadingMutation}
                    onChangeStep={navigateToStep}
                    submitLabel='Save'
                />
            </SlideoutFooter>
        </Slideout>
    );
};
