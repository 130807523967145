import React, { FC } from 'react';
import { useDebounce } from 'react-use';

import {
    AssignmentWithProductDto,
    HofyWarehouseDetailsDto,
    ItemDto,
    useRedistributableItems,
} from '@hofy/api-admin';
import { AnyProductCategory, AssignmentType } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Country, UUID } from '@hofy/global';
import { Color, NumberValues } from '@hofy/theme';
import {
    AsyncButton,
    BaseTable,
    Box,
    FormSection,
    Heading3,
    LabeledText,
    Paragraph3,
    Paragraph4,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ItemGradeChip } from '../../../components/domain/items/ItemGradeChip';
import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { useScanItem } from '../../../store/assignments/useScanItem';
import { useLoanerItems } from '../../../store/items/useLoanerItems';
import { LabeledItemCodes } from '../../itemsPage/LabeledItemCodes';
import { ProductVariantDetails } from '../components/ProductVariantDetails';
import { ShipmentOrderItemAssignForm } from '../shipmentSlideoutTabs/shipmentSlideoutOrders/ShipmentOrderItemAssignForm';
import { ShipmentOrderItemRevertScan } from '../shipmentSlideoutTabs/shipmentSlideoutOrders/ShipmentOrderItemRevertScan';
import { ShipmentOrderItemUnAssign } from '../shipmentSlideoutTabs/shipmentSlideoutOrders/ShipmentOrderItemUnAssign';

interface ShipmentOrderItemAssignProps {
    assignment: AssignmentWithProductDto;
    warehouse: HofyWarehouseDetailsDto;
    country?: Country;
    organizationId?: UUID;
    scannedCode?: string;
    autoClose?: boolean;
    onClose(): void;
}

export const ScanItemSlideout: FC<ShipmentOrderItemAssignProps> = ({
    onClose,
    country,
    assignment,
    warehouse,
    organizationId,
    scannedCode,
    autoClose = false,
}) => {
    const {
        itemCode,
        setItemCode,
        itemError,
        serialNumber,
        setSerialNumber,
        serialNumberError,
        isFetching,
        isLoading,
        item,
        submit,
        isSubmitting,
        isSubmitError,
    } = useScanItem(assignment, warehouse, organizationId, scannedCode, onClose);

    const isDisabled = !!(
        isLoading ||
        itemError ||
        serialNumberError ||
        (assignment.item && !assignment.assignedItemNeedsScanning) ||
        !item
    );

    const autoCloseEnabled = autoClose && item?.serialNumber && !isDisabled;
    useDebounce(
        () => {
            if (autoCloseEnabled) {
                submit();
            }
        },
        2000,
        [isDisabled, autoClose, item],
    );

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader
                title={
                    <Box row fullWidth>
                        <Heading3 color={Color.ContentPrimary}>Scan Item</Heading3>
                    </Box>
                }
            />
            <SlideoutContent>
                <Box paddingVertical={40} gap={30} column>
                    <ProductVariantDetails product={assignment.product} variant={assignment.variant} />
                    {assignment.item && assignment.assignedItemNeedsScanning && (
                        <Box row>
                            <LabeledItemCodes
                                flex={1}
                                label='Item codes'
                                itemCodes={assignment.item.itemCodes}
                            />
                            <LabeledText
                                flex={1}
                                label='Serial number'
                                content={assignment.item.serialNumber}
                            />
                        </Box>
                    )}
                </Box>
                <Box paddingBottom={40} gap={4} column>
                    <ScanItemSlideoutContent
                        assignment={assignment}
                        warehouse={warehouse}
                        country={country}
                        organizationId={organizationId}
                        item={item || null}
                        serialNumber={serialNumber}
                        onSerialNumberChange={setSerialNumber}
                        serialNumberError={serialNumberError}
                        itemCode={itemCode}
                        onItemCodeChange={setItemCode}
                        itemError={itemError}
                        shouldUseRefurbishedStock={
                            assignment.type === AssignmentType.Loaner || assignment.product.isRefurbished
                        }
                        isFetchingItem={isFetching}
                        onSuccess={() => !isDisabled && submit()}
                        onUnassign={onClose}
                    />
                    {autoCloseEnabled && (
                        <Paragraph4 italic>Scanned item will be submitted automatically</Paragraph4>
                    )}
                </Box>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label={assignment.type === AssignmentType.Redistribution ? 'Confirm scan' : 'Assign item'}
                    onClick={submit}
                    disabled={isDisabled}
                    isLoading={isSubmitting}
                    isError={isSubmitError}
                />
            </SlideoutFooter>
        </Slideout>
    );
};

interface ScanItemSlideoutContentProps {
    assignment: AssignmentWithProductDto;
    warehouse: HofyWarehouseDetailsDto;
    country?: Country;
    organizationId?: UUID;
    item: ItemDto | null;
    serialNumber: string | null;
    onSerialNumberChange(serialNumber: string | null): void;
    serialNumberError: string | undefined;
    itemCode: string | null;
    onItemCodeChange(itemCode: string | null): void;
    itemError: string | undefined;
    shouldUseRefurbishedStock: boolean;
    isFetchingItem: boolean;
    onSuccess(): void;
    onUnassign(): void;
}

const ScanItemSlideoutContent: FC<ScanItemSlideoutContentProps> = ({
    assignment,
    warehouse,
    country,
    organizationId,
    item,
    serialNumber,
    onSerialNumberChange,
    serialNumberError,
    itemCode,
    onItemCodeChange,
    itemError,
    shouldUseRefurbishedStock,
    isFetchingItem,
    onSuccess,
    onUnassign,
}) => {
    if (assignment.item && !assignment.assignedItemNeedsScanning) {
        if (assignment.isUnconvertedRedistribution) {
            return (
                <ShipmentOrderItemRevertScan
                    assignmentId={assignment.id}
                    onRevertScan={onUnassign}
                    itemCodes={assignment.item.itemCodes}
                />
            );
        } else {
            return (
                <ShipmentOrderItemUnAssign
                    assignmentId={assignment.id}
                    onUnassign={onUnassign}
                    itemCodes={assignment.item.itemCodes}
                />
            );
        }
    }

    return (
        <>
            <FormSection label='Item'>
                <ShipmentOrderItemAssignForm
                    item={item}
                    serialNumber={serialNumber}
                    onSerialNumberChange={onSerialNumberChange}
                    serialNumberError={serialNumberError}
                    itemCode={itemCode}
                    onItemCodeChange={onItemCodeChange}
                    itemError={itemError}
                    shouldUseRefurbishedStock={shouldUseRefurbishedStock}
                    isFetchingItem={isFetchingItem}
                    onSuccess={onSuccess}
                />
            </FormSection>
            {country && assignment.type === AssignmentType.Loaner && (
                <PossibleLoaners
                    warehouseId={warehouse.idDeprecated}
                    category={assignment.product.category}
                    onSelectItem={itemCode => onItemCodeChange(`#${itemCode}`)}
                />
            )}
            {country && organizationId && (
                <PossibleRedistributions
                    warehouse={warehouse}
                    variantId={assignment.variant.id}
                    country={country}
                    organizationId={organizationId}
                    onSelectItem={itemCode => onItemCodeChange(`#${itemCode}`)}
                />
            )}
        </>
    );
};

interface PossibleRedistributionsProps {
    warehouse: HofyWarehouseDetailsDto;
    country: Country;
    organizationId: UUID;
    variantId: UUID;
    onSelectItem(id: number): void;
}

const PossibleRedistributions: FC<PossibleRedistributionsProps> = ({
    warehouse,
    organizationId,
    variantId,
    country,
    onSelectItem,
}) => {
    const { items } = useRedistributableItems(warehouse.idDeprecated, organizationId, variantId, country);
    return <ItemsTable label='Possible redistributions' items={items} onSelectItem={onSelectItem} />;
};

interface PossibleLoanersProps {
    warehouseId: number;
    category: AnyProductCategory;
    onSelectItem(id: number): void;
}

const PossibleLoaners: FC<PossibleLoanersProps> = ({ warehouseId, category, onSelectItem }) => {
    const { items } = useLoanerItems(warehouseId, category);

    return <ItemsTable label='Suggested Loaners' items={items} onSelectItem={onSelectItem} />;
};

interface ItemStableProps {
    label: string;
    items: ItemDto[];
    onSelectItem(id: number): void;
}

const ItemsTable: FC<ItemStableProps> = ({ label, items, onSelectItem }) => {
    if (!items.length) {
        return null;
    }

    return (
        <Box>
            <FormSection marginTop={30} label={label}>
                <Box marginHorizontal={-40 as NumberValues} paddingTop={10}>
                    <BaseTable
                        data={items}
                        toKey={item => item.id}
                        onRowClick={item => onSelectItem(item.id)}
                        inlineHeader
                        height='auto'
                        columns={[
                            {
                                id: 'Id',
                                header: 'Id',
                                flexGrow: 0,
                                width: 60,
                                renderer: item => `#${item.id}`,
                            },
                            {
                                id: 'grade',
                                header: 'Grade',
                                flexGrow: 0,
                                width: 120,
                                renderer: item => (
                                    <Box>
                                        <ItemStatusChip status={item.status} marginBottom={8} />
                                        <ItemGradeChip grade={item.grade} />
                                    </Box>
                                ),
                            },
                            {
                                id: 'codes',
                                header: 'Codes',
                                flexGrow: 1,
                                renderer: entry =>
                                    entry.itemCodes.length ? (
                                        <Paragraph3>
                                            {entry.itemCodes.map(c => (
                                                <Box key={c.code}>{c.code}</Box>
                                            ))}
                                        </Paragraph3>
                                    ) : (
                                        '--'
                                    ),
                            },
                            {
                                id: 'serial',
                                header: 'Serial',
                                flexGrow: 1,
                                renderer: item => item.serialNumber || '--',
                            },
                        ]}
                    />
                </Box>
            </FormSection>
        </Box>
    );
};
