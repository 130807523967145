import {
    allSubscriptionBillingFrequencies,
    allSubscriptionTerms,
    SubscriptionDto,
    SubscriptionPayload,
} from '@hofy/api-admin';
import { isOneOf, isPricePositive, isRequired, useForm, validator } from '@hofy/ui';

export const useSubscriptionForm = (
    onSubmit: (payload: SubscriptionPayload) => void,
    initial: SubscriptionDto,
) => {
    return useForm<SubscriptionPayload>({
        initial: {
            price: initial.price,
            billingFrequency: initial.billingFrequency,
            term: initial.term,
            startOn: initial.startOn,
        },
        onSubmit: onSubmit,
        validate: validator<SubscriptionPayload>({
            price: [isRequired('Price is required'), isPricePositive('Price must be positive')],
            billingFrequency: [
                isRequired('Please select a billing frequency'),
                isOneOf(allSubscriptionBillingFrequencies, 'Invalid billing frequency'),
            ],
            term: [
                isRequired('Please select a subscription term'),
                isOneOf(allSubscriptionTerms, 'Invalid subscription term'),
            ],
        }),
    });
};
