import React, { FC, useState } from 'react';

import { AssignmentDetailsDto, DataErasureDetailsDto } from '@hofy/api-admin';
import { DataErasureStatus } from '@hofy/api-shared';
import { MoreMenu, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, Box, ConfirmModal, Heading3, Modals, SvgIcon } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { DataErasureStatusBadge } from '../../../components/domain/assignments/./badge/DataErasureStatusBadge';
import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { useResetDataErasure } from '../../../store/dataErasures/useResetDataErasure';
import { useUpdateDataErasure } from '../../../store/dataErasures/useUpdateDataErasure';
import { DataErasureSlideoutCancellation } from './DataErasureSlideoutCancellation';
import { DataErasureSlideoutCertificate } from './DataErasureSlideoutCertificate';
import { DataErasureSlideoutDetails } from './DataErasureSlideoutDetails';
import { DataErasureSlideoutForm } from './DataErasureSlideoutForm';

interface DataErasureSlideoutContentProps {
    assignment: AssignmentDetailsDto;
    dataErasure: DataErasureDetailsDto;
    onClose(): void;
}

export const DataErasureSlideoutContent: FC<DataErasureSlideoutContentProps> = ({
    assignment,
    dataErasure,
    onClose,
}) => {
    const { form, isLoadingMutation } = useUpdateDataErasure(assignment.idDeprecated, onClose);

    const formSubmittable = form.values.certificateFile !== null || form.values.cannotComplete;

    const [showDeleteModalOpen, setShowDeleteModalOpen] = useState(false);
    const { mutation } = useResetDataErasure(assignment.idDeprecated, onClose);

    const isPending = dataErasure.status === DataErasureStatus.Pending;
    const isCancelled = dataErasure.status === DataErasureStatus.Cancelled;

    const menuItems = [
        {
            icon: SvgIcon.Laptop,
            action: () => setShowDeleteModalOpen(true),
            label: 'Reset data erasure',
            visible: !isPending,
        },
    ];

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader
                title={
                    <>
                        <Heading3 marginRight={16}>Data erasure</Heading3>
                        {dataErasure.status && <DataErasureStatusBadge status={dataErasure.status} />}
                        <MoreMenu items={menuItems} marginLeft={12} />
                    </>
                }
            />
            <SlideoutContent>
                <Box flex={1} overflow='auto' fullHeight row alignItems='flex-start' paddingTop={40}>
                    <Box flex={1}>
                        <DataErasureSlideoutDetails assignment={assignment} />
                        <DataErasureSlideoutCertificate
                            assignment={assignment}
                            form={form}
                            isLoadingMutation={isLoadingMutation}
                        />
                        <DataErasureSlideoutForm dataErasure={dataErasure} form={form} />
                        {isCancelled && <DataErasureSlideoutCancellation dataErasure={dataErasure} />}
                        {!isPending && <AuditSection model={dataErasure} />}
                    </Box>
                </Box>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Cancel' onClick={onClose} />
                {isPending && (
                    <AsyncButton
                        label='Submit'
                        onClick={form.submit}
                        disabled={!formSubmittable}
                        isLoading={isLoadingMutation}
                        disableCheck
                    />
                )}
            </SlideoutFooter>

            <Modals>
                {showDeleteModalOpen && (
                    <ConfirmModal
                        keyPrefix='data-erasure-reset-modal'
                        action='destructive'
                        onConfirm={mutation.mutate}
                        onClose={() => setShowDeleteModalOpen(false)}
                        isLoading={mutation.isPending}
                    />
                )}
            </Modals>
        </Slideout>
    );
};
