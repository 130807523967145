import { useQuery } from '@tanstack/react-query';

import { orderService } from '../service/orderService';
import { ordersQueryKey } from './requestsQueryKey';

export const useOrderQuery = (id: number) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [ordersQueryKey, id],

        queryFn: () => orderService.getOrderDetails(id),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
