import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    SupplierPayload,
    suppliersCacheKey,
    suppliersService,
    useSupplierDetailsQuery,
} from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { useSupplierForm } from './useSupplierForm';

export const useUpdateSupplier = (supplierId: number, onSuccess: () => void) => {
    const { data: supplier } = useSupplierDetailsQuery(supplierId);
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: SupplierPayload) => suppliersService.updateSupplier(supplierId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [suppliersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Supplier updated',
            });
            onSuccess();
        },
    });

    const form = useSupplierForm(mutation.mutate, supplier);
    return { form, isLoading: mutation.isPending };
};
