import { InvoiceEntryType, TaxStatus } from '@hofy/api-shared';
import { DateRangeStrings, DateString, UUID } from '@hofy/global';

export interface AdminInvoiceEntriesFilters {
    organization: UUID | null;
    billingEntity: number[];
    invoiceDate: DateString | null;
    invoiceEntryType: InvoiceEntryType | null;
    isInvoiced: boolean | null;
    includeDeleted: boolean | null;
    search: string;
    repaymentPlan: number | null;
    invoice: number | null;
    taxStatus: TaxStatus | null;
    dateRange: DateRangeStrings | null;
    salesOrder: number | null;
}

export const emptyInvoiceEntriesFilters: AdminInvoiceEntriesFilters = {
    organization: null,
    billingEntity: [],
    invoiceDate: null,
    invoiceEntryType: null,
    isInvoiced: null,
    includeDeleted: null,
    search: '',
    repaymentPlan: null,
    invoice: null,
    taxStatus: null,
    dateRange: null,
    salesOrder: null,
};
