import React, { FC } from 'react';

import { FormFieldApi, Optional, TestKeyAware } from '@hofy/ui';

import { LabeledBillingEntityProps, LabeledBillingEntitySelect } from './LabeledBillingEntitySelect';

type FormBillingEntitySelectOptionalProps = Optional<LabeledBillingEntityProps, 'onChange'>;
type FormBillingEntitySelectOmittedProps = Omit<
    FormBillingEntitySelectOptionalProps,
    'errorMessage' | 'value'
>;

interface FormNormalBillingEntitySelectProps extends FormBillingEntitySelectOmittedProps, TestKeyAware {
    api: FormFieldApi<number>;
    nullable?: false;
}

interface FormNullableBillingEntitySelectProps extends FormBillingEntitySelectOmittedProps, TestKeyAware {
    api: FormFieldApi<number | null>;
    nullable: true;
}

type FormBillingEntitySelectProps = FormNormalBillingEntitySelectProps | FormNullableBillingEntitySelectProps;

export const FormBillingEntitySelect: FC<FormBillingEntitySelectProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledBillingEntitySelect
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={(value: number | null) => {
                api.setValue(value!);
                onChange?.(value!);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
