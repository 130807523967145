import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { subscriptionService } from '../service/subscriptionService';
import { subscriptionCacheKey } from './cacheKey';

export const useSubscription = (organizationId: UUID, subscriptionId: number) => {
    const { data, isLoading } = useQuery({
        queryKey: [subscriptionCacheKey, organizationId, subscriptionId],
        queryFn: () => subscriptionService.getSubscription(organizationId, subscriptionId),
    });

    return { data, isLoading };
};
