import React, { FC } from 'react';

import { UserAssignmentDto } from '@hofy/api-admin';
import {
    AddressDto,
    ShipmentStatus,
    shipmentStatusNotificationType,
    ShipmentType,
    shipmentTypeAssignmentStatus,
} from '@hofy/api-shared';
import { SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Alert, AsyncButton, Button, FormContainer, FormSection, SvgIcon } from '@hofy/ui';

import { AddressDetails } from '../../../components/domain/address/AddressDetails';
import { AdminListDropdown } from '../../../components/domain/user/AdminListDropdown';
import { useCreateDropshippingShipment } from '../../../store/shipments/useCreateShipment';
import { AssignmentSelection } from './AssignmentSelection';

interface CreateDropshippingShipmentFormProps {
    userId: UUID;
    selected: UserAssignmentDto[];
    address: AddressDto;
    onSuccess(id: number): void;
    onClose(): void;
}

export const CreateDropshippingShipmentForm: FC<CreateDropshippingShipmentFormProps> = ({
    onSuccess,
    onClose,
    selected,
    userId,
    address,
}) => {
    const { selection, form, isLoadingMutation } = useCreateDropshippingShipment(
        selected,
        address.id,
        onSuccess,
    );
    return (
        <>
            <SlideoutHeader title='Create shipment' />
            <SlideoutContent>
                <Alert
                    description='Creating shipment'
                    type={shipmentStatusNotificationType[ShipmentStatus.Created]}
                    marginVertical={30}
                />
                <FormSection label='Delivery info'>
                    <AddressDetails address={address} />
                </FormSection>
                <FormContainer>
                    <FormSection label='Create dropshipping shipment'>
                        <AdminListDropdown
                            label='Assigned user'
                            emptyContent='Pick user'
                            value={form.values.assignedUserId}
                            onChange={assignedUserId =>
                                form.setValues({
                                    assignedUserId,
                                })
                            }
                        />
                    </FormSection>
                    <AssignmentSelection
                        status={shipmentTypeAssignmentStatus[ShipmentType.Dropshipping]}
                        selection={selection}
                        userId={userId}
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    isLoading={isLoadingMutation}
                    label='Create'
                    onClick={form.submit}
                    disableCheck
                />
            </SlideoutFooter>
        </>
    );
};
