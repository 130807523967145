import { useQuery } from '@tanstack/react-query';

import { emptyInvoiceEntriesFilters, invoiceEntriesQueryKey, invoiceEntryService } from '@hofy/api-admin';

export const useRepaymentPlanInvoiceEntriesQuery = (repaymentId: number) => {
    const { isLoading, data } = useQuery({
        queryKey: [invoiceEntriesQueryKey, 'repayment', repaymentId],

        queryFn: () =>
            invoiceEntryService.listInvoiceEntries(
                { ...emptyInvoiceEntriesFilters, repaymentPlan: repaymentId },
                { page: 0, pageSize: 100 },
            ),
    });
    return {
        data: data?.content || [],
        isLoading,
    };
};
