import { useMutation, useQueryClient } from '@tanstack/react-query';

import { collectionRequestService, ResolveCollectionRequestLabelsAndPackagingPayload } from '@hofy/api-admin';
import { is, useForm, useToast, validator } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from '../shipments/useShipmentCollectionRequestQuery';

interface ResolveCollectionRequestLabelsAndPackagingFormData {
    isPackagingReceivedByUser: boolean | null;
}

export const useResolveCollectionRequestLabelsAndPackaging = (
    shipmentId: number,
    collectionRequestId: number,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: ResolveCollectionRequestLabelsAndPackagingPayload) =>
            collectionRequestService.resolveCollectionRequestLabelsAndPackaging(
                shipmentId,
                collectionRequestId,
                payload,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Labels and packaging resolved successfully',
            });
            onSuccess();
        },
    });

    const form = useForm<
        ResolveCollectionRequestLabelsAndPackagingFormData,
        ResolveCollectionRequestLabelsAndPackagingPayload
    >({
        initial: {
            isPackagingReceivedByUser: null,
        },
        validate: validator<ResolveCollectionRequestLabelsAndPackagingFormData>({
            isPackagingReceivedByUser: is(
                isPackagingReceivedByUser => isPackagingReceivedByUser !== null,
                'Please select an option',
            ),
        }),
        onSubmit: ({ isPackagingReceivedByUser }) => {
            mutation.mutate({ isPackagingReceivedByUser });
        },
    });

    return {
        form,
        isPending: mutation.isPending,
        isError: mutation.isError,
    };
};
