import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, UpdateVariantPayload } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useCancelCustomProduct = (id: number) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: UpdateVariantPayload) => assignmentService.updateVariant(id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Custom product removed',
            });
        },
    });

    const cancelCustomProduct = () => {
        mutation.mutate({ customVariantId: null });
    };

    return {
        isLoadingMutation: mutation.isPending,
        cancelCustomProduct: cancelCustomProduct,
    };
};
