import React, { FC } from 'react';

import { NoteSource } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { NotesEditor } from './NotesEditor';

interface NotesTabProps {
    source: NoteSource;
    entityId: UUID;
    canSetCustomerVisibleNote?: boolean;
}

export const NotesTab: FC<NotesTabProps> = ({ source, entityId, canSetCustomerVisibleNote = false }) => {
    return (
        <NotesEditor
            entityId={entityId}
            source={source}
            canSetCustomerVisibleNote={canSetCustomerVisibleNote}
        />
    );
};
