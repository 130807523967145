import React, { FC } from 'react';

import { ConfirmModal, Paragraph3 } from '@hofy/ui';

import { usePurchaseAssignment } from '../../../store/assignments/usePurchaseAssignment';

interface AssignmentPurchaseModalProps {
    assignmentId: number;
    onSuccess(): void;
}

export const AssignmentPurchaseModal: FC<AssignmentPurchaseModalProps> = ({ assignmentId, onSuccess }) => {
    const { purchase } = usePurchaseAssignment(onSuccess);
    return (
        <ConfirmModal
            keyPrefix='item-purchase-modal'
            onClose={onSuccess}
            onConfirm={() => purchase(assignmentId)}
        >
            <Paragraph3 marginTop={10} marginBottom={10}>
                Item ownership will be changed to organization
            </Paragraph3>
        </ConfirmModal>
    );
};
