import { xor } from 'lodash';
import React, { FC } from 'react';

import { AdminItemFilter, HofyWarehouseDetailsDto, ItemsFilters } from '@hofy/api-admin';
import {
    ItemGrade,
    itemGradeColor,
    ItemLocation,
    ItemStatus,
    itemStatusColor,
    OrganizationRefDto,
    Ownership,
    useTrItemLocation,
    useTrItemStatus,
    useTrOwnership,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { FilterChip } from '@hofy/ui';

import { OrganizationFilterChip } from '../../../components/domain/filters/OrganizationFilterChip';
import { useTrItemGrade } from '../../../store/items/useTrItemGrade';
import { useTrItemFilterType } from '../../../store/products/useTrItemFilterType';

interface ItemsActiveFilterChipsProps {
    filters: ItemsFilters;
    warehouses: HofyWarehouseDetailsDto[];
    organizations: OrganizationRefDto[];
    onGradeChange(grades: ItemGrade[]): void;
    onWarehouseIdChange(id: number | null): void;
    onOrganizationChange(ids: UUID[]): void;
    onFilterTypeChange(type: AdminItemFilter | null): void;
    onItemStatusChange(status: ItemStatus[]): void;
    onItemLocationChange(location: ItemLocation[]): void;
    onOwnershipChange(ownership: Ownership | null): void;
}

export const ItemsActiveFilterChips: FC<ItemsActiveFilterChipsProps> = ({
    filters,
    warehouses,
    organizations,
    onGradeChange,
    onWarehouseIdChange,
    onOrganizationChange,
    onFilterTypeChange,
    onItemStatusChange,
    onItemLocationChange,
    onOwnershipChange,
}) => {
    const trItemGrade = useTrItemGrade();
    const trFilterType = useTrItemFilterType();
    const trItemStatus = useTrItemStatus();
    const trItemLocation = useTrItemLocation();
    const trOwnership = useTrOwnership();
    const warehouse = warehouses.find(item => item.idDeprecated === filters.warehouseId);

    return (
        <>
            <OrganizationFilterChip
                value={filters.organization || []}
                organizations={organizations}
                onChange={onOrganizationChange}
            />
            {warehouse && (
                <FilterChip label={warehouse.name} onClear={() => onWarehouseIdChange(null)} color='purple' />
            )}
            {filters.grade?.map(grade => (
                <FilterChip
                    key={grade}
                    label={trItemGrade(grade)}
                    onClear={() => onGradeChange(xor(filters.grade, [grade]))}
                    color={itemGradeColor[grade]}
                />
            ))}
            {filters.status?.map(itemStatus => (
                <FilterChip
                    key={itemStatus}
                    label={trItemStatus(itemStatus)}
                    onClear={() => onItemStatusChange(xor(filters.status, [itemStatus]))}
                    color={itemStatusColor[itemStatus]}
                />
            ))}
            {filters.filter && (
                <FilterChip
                    label={trFilterType(filters.filter)}
                    onClear={() => onFilterTypeChange(null)}
                    color='teal'
                />
            )}
            {filters.location?.map(location => (
                <FilterChip
                    key={location}
                    label={trItemLocation(location)}
                    onClear={() => onItemLocationChange(xor(filters.location, [location]))}
                    color='purple'
                />
            ))}
            {filters.ownership && (
                <FilterChip
                    label={trOwnership(filters.ownership)}
                    onClear={() => onOwnershipChange(null)}
                    color='purple'
                />
            )}
        </>
    );
};
