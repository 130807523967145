import { useQuery } from '@tanstack/react-query';

import { suppliersService } from '../service/suppliersService';
import { suppliersCacheKey } from './cacheKey';

export const useSupplierDetailsQuery = (supplierId: number | null | undefined) => {
    const { data, isLoading } = useQuery({
        queryKey: [suppliersCacheKey, supplierId],
        queryFn: () => suppliersService.getSupplier(supplierId!),
        enabled: !!supplierId,
    });

    return { isLoading, data };
};
