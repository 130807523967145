import { ColorDye, getEnumValues } from '@hofy/global';

export enum BYODOrderStatus {
    Cancelled = 'cancelled',
    WithCourier = 'with_courier',
    PartiallyReceived = 'partially_received',
    Completed = 'completed',
}

export const allBYODOrderStatuses = getEnumValues<BYODOrderStatus>(BYODOrderStatus);

export const byodOrderStatusColors: Record<BYODOrderStatus, ColorDye> = {
    [BYODOrderStatus.Cancelled]: 'red',
    [BYODOrderStatus.WithCourier]: 'teal',
    [BYODOrderStatus.PartiallyReceived]: 'purple',
    [BYODOrderStatus.Completed]: 'purple',
};
