import React, { FC } from 'react';

import { MessageDetailsDto } from '@hofy/api-admin';
import { BlockLoader, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { formatDateTime } from '@hofy/helpers';
import { FormContainer, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { MessageStatusChip } from '../../../views/usersPage/messagesTab/MessageStatusChip';
import { CancelButton } from '../../design/button/CancelButton';

interface MessagePreviewSlideoutProps {
    message: MessageDetailsDto | undefined;
    isLoading: boolean;
    onClose(): void;
}

export const MessagePreviewSlideout: FC<MessagePreviewSlideoutProps> = ({ message, isLoading, onClose }) => {
    if (isLoading || !message) {
        return <BlockLoader />;
    }
    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Message preview' />
            <SlideoutContent column gap={24}>
                <FormContainer paddingVertical={10}>
                    <FormSection label='Details'>
                        <FormGridRow columns={2}>
                            <LabeledText label='Id' content={message.id} />
                            <LabeledText label='Twilio id' content={message.externalID} />
                            <LabeledText label='From' content={message.messageFrom} />
                            <LabeledText label='To' content={message.messageTo} />
                        </FormGridRow>
                        <LabeledText label='Message' content={message.text} />
                    </FormSection>
                    <FormSection label='Current status'>
                        <LabeledText label='Status' content={<MessageStatusChip status={message.status} />} />
                        <FormGridRow columns={2}>
                            <LabeledText label='Created at' content={formatDateTime(message.createdAt)} />
                            <LabeledText label='Updated at' content={formatDateTime(message.updatedAt)} />
                        </FormGridRow>
                    </FormSection>

                    <FormSection label='Detailed logs'>
                        {message.logs.map((log, index) => (
                            <FormGridRow key={index} columns={2}>
                                <LabeledText
                                    label='Status'
                                    content={<MessageStatusChip status={log.status} />}
                                />
                                <LabeledText label='Updated at' content={formatDateTime(log.updatedAt)} />
                            </FormGridRow>
                        ))}
                    </FormSection>
                </FormContainer>
            </SlideoutContent>

            <SlideoutFooter>
                <CancelButton onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};
