import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../contexts';
import { renderTextNode } from '../../helpers/React';
import { ExpandHeight } from '../animations';
import { Box, Paragraph2, Paragraph3 } from '../base';
import { Radio } from '../form';
import { Chevron } from '../shared';

interface AccordionProps {
    open: boolean;
    title: ReactNode;
    description?: ReactNode;
    disabled?: boolean;
    radio?: boolean;
    border?: boolean;
    titleSlot?: ReactNode;
    rightSlot?: ReactNode;
    onClick?(): void;
    children: ReactNode;
}

export const Accordion: FC<AccordionProps> = ({
    open = false,
    title,
    description,
    disabled: accordionDisabled,
    radio = false,
    border = false,
    titleSlot,
    rightSlot,
    onClick,
    children,
}) => {
    const disabled = useIsDisabled(accordionDisabled);
    return (
        <Box
            column
            rounded={border && 8}
            border={border}
            bg={border ? Color.BackgroundDefault : undefined}
            padding={border ? 24 : 0}
            data-test-key='accordion'
        >
            <Box
                column
                pointer={!disabled}
                inactive={disabled}
                gap={8}
                onClick={() => {
                    if (!disabled) {
                        onClick?.();
                    }
                }}
            >
                <Box row gap={8} justify='space-between'>
                    <Box row gap={8}>
                        {radio && <Radio checked={open} disabled={disabled} />}
                        {renderTextNode(title, title => (
                            <Paragraph2 bold color={Color.ContentPrimary} data-test-key='name'>
                                {title}
                            </Paragraph2>
                        ))}
                        {titleSlot}
                    </Box>
                    {(rightSlot || children) && (
                        <Box row gap={8}>
                            {rightSlot}
                            {children && <Chevron color={Color.ContentSecondary} isOpen={open} />}
                        </Box>
                    )}
                </Box>
                {renderTextNode(description, description => (
                    <Paragraph3>{description}</Paragraph3>
                ))}
            </Box>
            <ExpandHeight>{open && children && <Box paddingTop={24}>{children}</Box>}</ExpandHeight>
        </Box>
    );
};
