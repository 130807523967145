import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SupplierContactPayload, suppliersCacheKey, suppliersService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useAddSupplierContact = (supplierId: number) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (p: SupplierContactPayload) => suppliersService.addContact(supplierId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [suppliersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Supplier contact created',
            });
        },
    });

    return {
        addContact: mutation.mutate,
    };
};
