import React, { FC, useState } from 'react';

import { ProductDto, VariantDetailsDto } from '@hofy/api-shared';
import { Box, Button, FormFieldApi, Modals, Paragraph3 } from '@hofy/ui';

import { ItemCodeInput } from './ItemCodeInput';
import { UpdateItemCodeModal } from './UpdateItemCodeModal';

interface ItemCodeSelectorProps {
    api: FormFieldApi<string | null>;
    itemId: number;
    product: ProductDto;
    variant: VariantDetailsDto;
    isRequired?: boolean;
    isAddItemCodePromptEnabled?: boolean;
    width?: number;
}

export const ItemCodeSelector: FC<ItemCodeSelectorProps> = ({
    api,
    itemId,
    product,
    variant,
    isRequired = false,
    isAddItemCodePromptEnabled = false,
    width,
}) => {
    const [modal, setModal] = useState(false);

    return (
        <Box column gap={4}>
            <ItemCodeInput api={api} itemId={itemId} isRequired={isRequired} width={width} />
            {isAddItemCodePromptEnabled && (
                <>
                    <Box row gap={4}>
                        <Paragraph3>Missing item code?</Paragraph3>
                        <Button type='plain' label='Add a new code' onClick={() => setModal(true)} />
                    </Box>
                    <Modals>
                        {modal && (
                            <UpdateItemCodeModal
                                itemId={itemId}
                                product={product}
                                variant={variant}
                                onCancel={() => setModal(false)}
                                onSuccess={code => {
                                    api.setValue(code);
                                    setModal(false);
                                }}
                            />
                        )}
                    </Modals>
                </>
            )}
        </Box>
    );
};
