import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, dataErasureService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

interface ResetDataErasureState {
    mutation: UseMutationResult<void, unknown, void>;
    assignmentId: number;
    isLoadingMutation: boolean;
    isError: boolean;
}

export const useResetDataErasure: (assignmentId: number, onSuccess: () => void) => ResetDataErasureState = (
    assignmentId: number,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => dataErasureService.resetAssignmentDataErasure(assignmentId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess();
            showToast({
                type: 'positive',
                message: 'Data erasure updated',
            });
        },
    });

    return {
        mutation: mutation,
        assignmentId: assignmentId,
        isLoadingMutation: mutation.isPending,
        isError: mutation.isError,
    };
};
