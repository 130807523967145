import { useMutation, useQueryClient } from '@tanstack/react-query';

import { billingEntitiesCacheKey, billingEntitiesService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useAdminAccountingSyncBillingEntity = (billingEntityId: number) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const accountSyncMutation = useMutation({
        mutationFn: (billingEntityId: number) => billingEntitiesService.accountingSync(billingEntityId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [billingEntitiesCacheKey, billingEntityId] });
            showToast({
                type: 'positive',
                message: 'Billing entity synced',
            });
        },
    });

    const accountingSyncBillingEntity = () => {
        accountSyncMutation.mutate(billingEntityId);
    };

    return {
        accountingSyncBillingEntity,
    };
};
