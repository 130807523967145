import { forcedNetsuiteSyncQuery } from '@hofy/api-shared';
import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { AdminPurchaseOrdersFilter } from '../store/AdminPurchaseOrdersFilter';
import { PurchaseOrderDetailsDto } from './types/PurchaseOrderDetailsDto';
import { GeneratedPurchaseOrderReference, PurchaseOrderDto } from './types/PurchaseOrderDto';
import { PurchaseOrderItemDeliveryPayload } from './types/PurchaseOrderItemDeliveryPayload';
import { PurchaseOrderItemQuantityUpdatePayload } from './types/PurchaseOrderItemQuantityUpdatePayload';
import { PurchaseOrderPayload } from './types/PurchaseOrderPayload';
import { PurchaseOrderRefDto } from './types/PurchaseOrderRefDto';
import { ReceivePurchaseOrderItemPayload } from './types/ReceivePurchaseOrderItemPayload';

class PurchaseOrderService {
    public syncPurchaseOrdersToNetsuite = async (): Promise<void> => {
        return restClient
            .post(
                stringifyUrl({
                    url: '/api/admin/purchase-orders/sync-to-netsuite',
                    query: {
                        ...forcedNetsuiteSyncQuery,
                    },
                }),
            )
            .then();
    };

    public createPurchaseOrder = (payload: PurchaseOrderPayload): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>('/api/admin/purchase-orders', payload)
            .then(r => r.id);
    };

    public updatePurchaseOrder = (payload: PurchaseOrderPayload, id: number): Promise<void> => {
        return restClient.put(`/api/admin/purchase-orders/${id}`, payload);
    };

    public getPurchaseOrder = (id: number): Promise<PurchaseOrderDetailsDto> => {
        return restClient.getJson<PurchaseOrderDetailsDto>(`/api/admin/purchase-orders/${id}`);
    };

    public downloadPurchaseOrderFile = async (fileId: UUID): Promise<void> => {
        await restClient
            .downloadFile(`/api/admin/purchase-orders/files/${fileId}/download`)
            .then(downloadFileFromResponse);
    };

    public listPurchaseOrders = (
        filters: AdminPurchaseOrdersFilter,
        page: PageRequest,
    ): Promise<PageResponse<PurchaseOrderDto>> => {
        return restClient.getJson<PageResponse<PurchaseOrderDto>>(
            stringifyUrl({
                url: `/api/admin/purchase-orders`,
                query: {
                    search: filters.search,
                    status: filters.purchaseOrderStatuses,
                    createdByUsers: filters.createdByUsers,
                    supplier: filters.suppliers,
                    warehouses: filters.warehouses,
                    isDropship: filters.isDropship,
                    variants: filters.variants,
                    netsuiteStatus: filters.netsuiteStatuses,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public listPurchaseOrdersForReceipt = (): Promise<PurchaseOrderRefDto[]> => {
        return restClient.getJson<PurchaseOrderRefDto[]>(`/api/admin/purchase-orders/receipt`);
    };

    public approvePurchaseOrder = (id: number) => {
        return restClient.post(`/api/admin/purchase-orders/${id}/approve`, undefined);
    };

    public declinePurchaseOrder = (id: number) => {
        return restClient.post(`/api/admin/purchase-orders/${id}/decline`, undefined);
    };

    public cancelPurchaseOrder = async (id: number): Promise<void> => {
        await restClient.post(`/api/admin/purchase-orders/${id}/cancel`, undefined);
    };

    public closePurchaseOrder = async (id: number): Promise<void> => {
        await restClient.post(`/api/admin/purchase-orders/${id}/close`, undefined);
    };

    public generatePurchaseOrderReference = async (): Promise<string> => {
        return restClient
            .postJson<GeneratedPurchaseOrderReference>(`/api/admin/purchase-orders/reference`)
            .then(r => r.reference);
    };

    public exportPurchaseOrders = async (filters: AdminPurchaseOrdersFilter): Promise<void> => {
        await restClient
            .downloadFile(
                stringifyUrl({
                    url: `/api/admin/purchase-orders/report`,
                    query: {
                        search: filters.search,
                        status: filters.purchaseOrderStatuses,
                        createdByUsers: filters.createdByUsers,
                        supplier: filters.suppliers,
                        warehouses: filters.warehouses,
                        isDropship: filters.isDropship,
                        variants: filters.variants,
                        netsuiteStatus: filters.netsuiteStatuses,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public updatePurchaseOrderItemDelivery = async (
        id: number,
        payload: PurchaseOrderItemDeliveryPayload,
    ): Promise<void> => {
        await restClient.patch(`/api/admin/purchase-orders/items/${id}/delivery`, payload);
    };

    public receivePurchaseOrderItem = async (
        purchaseOrderItemId: number,
        payload: ReceivePurchaseOrderItemPayload,
    ): Promise<void> => {
        await restClient.post(`/api/admin/purchase-orders/items/${purchaseOrderItemId}/receipt`, payload);
    };

    public updatePurchaseOrderItemQuantity = async (
        purchaseOrderItemId: number,
        payload: PurchaseOrderItemQuantityUpdatePayload,
    ): Promise<void> => {
        await restClient.patch(`/api/admin/purchase-orders/items/${purchaseOrderItemId}/quantity`, payload);
    };
}

export const purchaseOrderService = new PurchaseOrderService();
