import { useAdminApiI18n } from '../../useAdminApiI18n';
import { SubscriptionBillingFrequency } from '../service/types/SubscriptionBillingFrequency';

export const useTrSubscriptionBillingFrequency = () => {
    const { trEnum } = useAdminApiI18n();
    return trEnum<SubscriptionBillingFrequency>(
        SubscriptionBillingFrequency,
        'subscription-billing-frequency',
    );
};
