import { ColorDye, getEnumValues } from '@hofy/global';

export enum UserStatus {
    Created = 'created',
    Active = 'active',
    Offboarded = 'offboarded',
}

export const allUserStatuses = getEnumValues<UserStatus>(UserStatus);
export const onboardedUserStatuses = [UserStatus.Created, UserStatus.Active];

export const userStatusColor: Record<UserStatus, ColorDye> = {
    [UserStatus.Created]: 'purple',
    [UserStatus.Active]: 'green',
    [UserStatus.Offboarded]: 'grey',
};
