import { useQuery } from '@tanstack/react-query';

import { invoiceEntryService } from '../service/invoiceEntryService';
import { invoiceEntriesQueryKey } from './invoiceEntriesQueryKey';

export const useInvoiceEntry = (invoiceEntryId: number, enabled: boolean = true) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [invoiceEntriesQueryKey, invoiceEntryId],
        queryFn: () => invoiceEntryService.getInvoiceEntry(invoiceEntryId),
        enabled: enabled,
    });

    return {
        invoiceEntry: data,
        isLoading,
        isError,
    };
};
