import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, Icon, MarginBoxProps, SectionTitle2, TestKeyAware } from '@hofy/ui';

interface PurchaseOrderNewItemButtonProps extends MarginBoxProps, TestKeyAware {
    label?: ReactNode;
    icon: Svg;
    bg?: Color;
    color?: Color;
    width?: number | string;
    onClick?(): void;
    testKey?: string;
    disabled?: boolean;
}

export const PurchaseOrderNewItemButton: FC<PurchaseOrderNewItemButtonProps> = ({
    label,
    width = 'auto',
    icon,
    color = Color.Neutral500,
    testKey,
    disabled,
    ...margins
}) => {
    return (
        <TileButtonWrapper
            as='button'
            paddingVertical={10}
            paddingHorizontal={10}
            {...margins}
            width={width}
            data-test-key={testKey}
            disabled={disabled}
            flex='auto'
            direction='row'
            border
            rounded={4}
        >
            <Icon size={20} svg={icon} color={color} />
            {label && (
                <SectionTitle2 paddingHorizontal={15} color={color}>
                    {label}
                </SectionTitle2>
            )}
        </TileButtonWrapper>
    );
};

const TileButtonWrapper = styled(Box)<{ disabled?: boolean }>`
    :hover {
        background-color: ${p => (p.disabled ? p.color : Color.Highlight)};
    }
`;
