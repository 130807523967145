import React, { FC, useState } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Modals, Spinner } from '@hofy/ui';

import { useSyncAllToNetsuite } from '../../../../store/invoices/useSyncAllToNetsuite';
import { useSyncFromNetsuite } from '../../../../store/invoices/useSyncFromNetsuite';
import { SendInvoicesModal } from './SendInvoicesModal';
import { SyncInvoiceFilesFromTwoModal } from './SyncFromTwoModal';

export const InvoicesPageMenu: FC = () => {
    const { hasPermission } = useSession();

    const [syncFilesFromTwoModal, setSyncFilesFromTwoModal] = useState(false);
    const [sendInvoicesModal, setSendInvoicesModal] = useState(false);
    const { syncAllToNetsuite, isSyncAllToNetsuiteLoading } = useSyncAllToNetsuite();
    const { syncFromNetsuite, isSyncFromNetsuiteLoading } = useSyncFromNetsuite();

    if (isSyncAllToNetsuiteLoading || isSyncFromNetsuiteLoading) {
        return <Spinner size={16} />;
    }

    return (
        <>
            <MoreMenu
                items={[
                    {
                        action: syncAllToNetsuite,
                        label: 'Sync to Netsuite',
                        visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                    },
                    {
                        action: syncFromNetsuite,
                        label: 'Sync from Netsuite',
                        visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                    },
                    {
                        action: () => setSyncFilesFromTwoModal(true),
                        label: 'Sync files from Two',
                        visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                    },
                    {
                        action: () => setSendInvoicesModal(true),
                        label: 'Send invoices',
                        visible: hasPermission(Permission.AdminInvoicesSend),
                    },
                ]}
            />

            <Modals>
                {syncFilesFromTwoModal && (
                    <SyncInvoiceFilesFromTwoModal onClose={() => setSyncFilesFromTwoModal(false)} />
                )}
                {sendInvoicesModal && <SendInvoicesModal onClose={() => setSendInvoicesModal(false)} />}
            </Modals>
        </>
    );
};
