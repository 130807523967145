import React, { FC } from 'react';

import { isDeliveryPending } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, SvgIcon } from '@hofy/ui';

import { ItemStockDetailData, ItemStockDetails } from './ItemStockDetails';

interface ItemDetailsProps extends BoxProps {
    itemId?: number;
    iconSize?: number;
    iconColor: Color;
    stockDetailsData?: ItemStockDetailData;
}

export const ItemDetails: FC<ItemDetailsProps> = ({
    itemId,
    iconSize = 12,
    iconColor,
    stockDetailsData,
    ...boxProps
}) => {
    const shouldDisplayStockDetail =
        itemId === undefined &&
        stockDetailsData?.assignmentStatus &&
        isDeliveryPending(stockDetailsData.assignmentStatus) &&
        !stockDetailsData.isLoaner! &&
        !stockDetailsData.isRedistribution! &&
        stockDetailsData.stockLevelCounts &&
        stockDetailsData.sku;

    return (
        <Box flex={0} pointer={!!itemId} {...boxProps}>
            <Box>
                {shouldDisplayStockDetail ? (
                    <ItemStockDetails iconSize={iconSize} stockDetailsData={stockDetailsData} />
                ) : (
                    <Icon size={iconSize} svg={SvgIcon.Chair} color={iconColor} />
                )}
            </Box>
        </Box>
    );
};
