import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { Spinner } from '@hofy/ui';

import { useSyncCurrenciesToNetsuite } from '../../../store/accounting/useSyncCurrenciesToNetsuite';
import { useSyncNetsuiteConfig } from '../../../store/accounting/useSyncNetsuiteConfig';

export const NetsuitePageMenu: FC = () => {
    const { hasPermission } = useSession();

    const { syncConfig, isLoading: isLoadingConfigSync } = useSyncNetsuiteConfig();
    const { syncCurrencies, isLoading: isLoadingCurrencySync } = useSyncCurrenciesToNetsuite();

    if (isLoadingConfigSync || isLoadingCurrencySync) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: syncConfig,
                    label: 'Sync Netsuite config',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
                {
                    action: syncCurrencies,
                    label: 'Sync currencies to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
            ]}
        />
    );
};
