import { WarehouseBinDto } from '@hofy/api-shared';
import { stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { CreateWarehouseBinPayload } from './types/CreateWarehouseBinPayload';
import { HofyWarehousePayload } from './types/HofyWarehousePayload';
import { ListHofyWarehousesFilter } from './types/ListHofyWarehousesFilter';
import { HofyWarehouseDetailsDto } from './types/WarehouseDetailsDto';

class WarehouseService {
    public listHofyWarehouses = async (
        filter?: ListHofyWarehousesFilter,
    ): Promise<HofyWarehouseDetailsDto[]> => {
        return await restClient.getJson<HofyWarehouseDetailsDto[]>(
            stringifyUrl({
                url: '/api/admin/warehouses',
                query: { ...filter },
            }),
        );
    };

    public getHofyWarehouseById = async (id: number): Promise<HofyWarehouseDetailsDto> => {
        return await restClient.getJson<HofyWarehouseDetailsDto>(`/api/admin/warehouses/${id}`);
    };

    public createUpdateHofyWarehouse = (
        payload: HofyWarehousePayload,
        warehouseId?: number,
    ): Promise<{ id: number }> => {
        if (warehouseId) {
            return restClient.putJson(`/api/admin/warehouses/${warehouseId}`, payload);
        }
        return restClient.postJson('/api/admin/warehouses', payload);
    };

    public createWarehouseBin = async (
        warehouseId: number,
        payload: CreateWarehouseBinPayload,
    ): Promise<{ id: number }> => {
        return await restClient.postJson(`/api/admin/warehouses/${warehouseId}/bins`, payload);
    };

    public getWarehouseBinByIdentifier = (
        warehouseId: number,
        identifier: string,
    ): Promise<WarehouseBinDto> => {
        return restClient.getJson(
            `/api/admin/warehouses/${warehouseId}/bins/${encodeURIComponent(identifier)}`,
        );
    };
}

export const warehouseService = new WarehouseService();
