import React, { FC } from 'react';

import {
    ShipmentCollectionRequestDto,
    ShipmentCollectionRequestStatusUpdateDto,
    ShipmentDetailsDto,
} from '@hofy/api-admin';
import { isWithCourierOrCompletedOrCanceled } from '@hofy/api-shared';
import { Timeline } from '@hofy/common';
import { formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { ActionDropdown, ActionDropdownItem, Box, Paragraph3, SvgIcon } from '@hofy/ui';

import { CollectionSurveyStatusButtons } from '../../../../../components/domain/shipments/CollectionSurveyStatusButtons';
import { useSendCollectionRequestSurvey } from '../../../../../store/collectionRequests/useSendCollectionRequestSurvey';
import { useTrCollectionRequestDisplayStatus } from '../../../../../store/shipments/useTrCollectionRequestDisplayStatus';

interface ActivityLogSectionProps {
    shipment: ShipmentDetailsDto;
    collectionRequest: ShipmentCollectionRequestDto;
}

export const ActivityLogSection: FC<ActivityLogSectionProps> = ({ shipment, collectionRequest }) => {
    const { sendCollectionRequestSurvey, sendingInProgress } = useSendCollectionRequestSurvey(
        shipment.idDeprecated,
    );

    return (
        <Box column gap={24} alignItems='flex-start'>
            {!isWithCourierOrCompletedOrCanceled(shipment.status) && (
                <Box row gap={8}>
                    {!collectionRequest.formSubmittedByUser && (
                        <ActionDropdown label='Send survey' disabled={sendingInProgress}>
                            <ActionDropdownItem
                                icon={SvgIcon.Send}
                                title='Send email'
                                description='Send survey'
                                onClick={() => sendCollectionRequestSurvey(false)}
                            />
                            <ActionDropdownItem
                                icon={SvgIcon.Send}
                                title='Send survey with text message'
                                description='Send survey via email + text message'
                                onClick={() => sendCollectionRequestSurvey(true)}
                            />
                        </ActionDropdown>
                    )}
                    <CollectionSurveyStatusButtons
                        collectionRequest={collectionRequest}
                        shipmentId={shipment.idDeprecated}
                    />
                </Box>
            )}
            <Box marginBottom={24}>
                <ActivityLogTimeline updates={collectionRequest.statusUpdates} />
            </Box>
        </Box>
    );
};

interface ActivityLogTimelineProps {
    updates: ShipmentCollectionRequestStatusUpdateDto[];
}

const ActivityLogTimeline: FC<ActivityLogTimelineProps> = ({ updates }) => {
    const trCollectionRequestDisplayStatus = useTrCollectionRequestDisplayStatus();
    return (
        <Timeline
            items={updates.map(({ status, statusUpdatedAt }) => (
                <Box key={status + statusUpdatedAt}>
                    <Paragraph3 bold>{trCollectionRequestDisplayStatus(status)}</Paragraph3>
                    <Paragraph3 color={Color.ContentTertiary}>{formatDateTime(statusUpdatedAt)}</Paragraph3>
                </Box>
            ))}
            currentIndex={updates.length - 1}
        />
    );
};
