import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthNavLink, useSession } from '@hofy/auth';
import { ErrorBoundary } from '@hofy/common';

export const AdminRoute = <T extends RouteProps = RouteProps>(props: T) => {
    const { isInitialized, session } = useSession();

    if (!isInitialized) {
        return null;
    }

    if (!session) {
        return <Redirect to={AuthNavLink.SignIn} />;
    }

    return (
        <ErrorBoundary>
            <Route {...props} />
        </ErrorBoundary>
    );
};
