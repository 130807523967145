import React, { FC, memo } from 'react';

import { PaymentDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    InfiniteScrollConfig,
    InfinityScrollTable,
    MultiCell,
    Paragraph3,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

import { NetsuiteStatusChip } from '../../../components/domain/netsuite/NetsuiteStatusChip';
import { useTrPaymentProvider } from '../../../store/payments/useTrPaymentProvider';

interface PaymentsTableProps {
    payments: PaymentDto[];
    infinityScroll: InfiniteScrollConfig;
}

const PaymentsTableComponent: FC<PaymentsTableProps> = ({ payments, infinityScroll }) => {
    const { formatPrice } = usePrice();
    const trPaymentProvider = useTrPaymentProvider();
    return (
        <InfinityScrollTable
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No payments'
                    message='No payments for selected criteria'
                />
            }
            flex='auto'
            data={payments}
            toKey={payment => payment.id}
            infinityScroll={infinityScroll}
            columns={[
                {
                    id: 'id',
                    header: '#Id',
                    flexGrow: 0,
                    width: 80,
                    renderer: payment => <Paragraph3 alignContent='baseline'>#{payment.id}</Paragraph3>,
                },
                {
                    id: 'transactionOn',
                    header: 'Date',
                    flexGrow: 1,
                    renderer: payment => formatDate(payment.transactionOn),
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 1,
                    renderer: payment => payment.hofySubsidiary.name,
                },
                {
                    id: 'billingEntity',
                    header: 'Billing entity',
                    flexGrow: 1,
                    renderer: payment => payment.billingEntity.name,
                },

                {
                    id: 'description',
                    header: 'Description',
                    flexGrow: 2,
                    renderer: payment => <Paragraph3>{payment.description}</Paragraph3>,
                },
                {
                    id: 'totalSettled',
                    header: 'Total amount',
                    flexGrow: 1,
                    renderer: payment => <Paragraph3>{formatPrice(payment.totalSettled)}</Paragraph3>,
                },
                {
                    id: 'itemInvoiceReferences',
                    header: 'Item invoice',
                    flexGrow: 1,
                    CellRenderer: MultiCell,
                    renderer: payment => (
                        <>
                            {payment.items.map(item => {
                                return <Paragraph3 key={item.id}>{item.invoice.reference}</Paragraph3>;
                            })}
                        </>
                    ),
                },
                {
                    id: 'itemAmounts',
                    header: 'Item amount',
                    CellRenderer: MultiCell,
                    renderer: payment => (
                        <>
                            {payment.items.map(item => {
                                return <Paragraph3 key={item.id}>{formatPrice(item.settled)}</Paragraph3>;
                            })}
                        </>
                    ),
                },
                {
                    id: 'provider',
                    header: 'Provider',
                    renderer: payment => trPaymentProvider(payment.provider),
                },
                {
                    id: 'netsuite',
                    header: 'Netsuite',
                    renderer: payment => <NetsuiteStatusChip status={payment.netsuite} />,
                },
            ]}
        />
    );
};

export const PaymentsTable = memo(PaymentsTableComponent);
