import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    assignmentsCacheKey,
    PurchaseOrderPayload,
    purchaseOrdersCacheKey,
    purchaseOrderService,
    stockLevelCacheKey,
    usePurchaseOrderQuery,
} from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { createUpdatePurchaseOrderDetailsDtoToFormData } from './types/PurchaseOrderFormData';
import { usePurchaseOrderForm } from './usePurchaseOrderForm';

export const useUpdatePurchaseOrder = (purchaseOrderId: number, onSuccess: () => void) => {
    const { data: purchaseOrder, isLoading: purchaseOrderIsLoading } = usePurchaseOrderQuery(purchaseOrderId);
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    useEffect(() => {
        if (!purchaseOrderIsLoading && !!purchaseOrder) {
            form.setValues(createUpdatePurchaseOrderDetailsDtoToFormData(purchaseOrder));
        }
    }, [purchaseOrder, purchaseOrderIsLoading]);

    const mutation = useMutation({
        mutationFn: (payload: PurchaseOrderPayload) =>
            purchaseOrderService.updatePurchaseOrder(payload, purchaseOrderId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [purchaseOrdersCacheKey] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [stockLevelCacheKey] });
            showToast({
                type: 'positive',
                message: 'Purchase order edited',
            });
            onSuccess();
        },
    });

    const {
        form,
        updateItemQuantity,
        updateItemWarranty,
        updateItemUnitPrice,
        updateItemTaxRate,
        updateItemSupplierCode,
        updateItemVariant,
        updateItemEstimatedReceiveOn,
        updateItemNotes,
        deleteItem,
        addItem,
        setValidateItems,
        warehouse,
        deliveryAddress,
        supplier,
    } = usePurchaseOrderForm(mutation.mutate, false, purchaseOrder);

    return {
        form,
        isLoading: purchaseOrderIsLoading,
        updateItemQuantity,
        updateItemWarranty,
        updateItemUnitPrice,
        updateItemTaxRate,
        updateItemSupplierCode,
        updateItemVariant,
        updateItemEstimatedReceiveOn,
        updateItemNotes,
        deleteItem,
        addItem,
        setValidateItems,
        isLoadingMutation: mutation.isPending,
        purchaseOrder,
        deliveryAddress,
        warehouse,
        supplier,
    };
};
