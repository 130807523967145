import React, { FC, useEffect, useMemo, useState } from 'react';

import {
    discountsToMap,
    OrganizationDetailsDto,
    OrganizationPaymentDiscountDto,
    PaymentDiscounts,
} from '@hofy/api-admin';
import {
    allPaymentSchemasWithoutDeposit,
    allRentalTerms,
    PaymentSchema,
    Permission,
    RentalTerm,
    useTrPaymentSchema,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { FormDropdown, FormFractionInput, FormNumberInput } from '@hofy/common';
import { Percent } from '@hofy/global';
import { now } from '@hofy/helpers';
import { useDecimal } from '@hofy/hooks';
import {
    BaseTable,
    Box,
    Button,
    DateInput,
    IconButton,
    Placeholder,
    SortFilterDataTable,
    SvgIcon,
    SvgIllustration,
    TextCell,
    TooltipIcon,
} from '@hofy/ui';

import { Footer } from '../../../components/design/layout/Footer';
import { useUpdateOrganizationPaymentDiscounts } from '../../../store/organizations/useUpdateOrganizationPaymentDiscount';

interface PaymentDiscountsTabProps {
    organization: OrganizationDetailsDto;
}

export const PaymentDiscountsTab: FC<PaymentDiscountsTabProps> = ({ organization }) => {
    const [editMode, setEditMode] = useState<boolean>(false);

    if (editMode) {
        return <PaymentDiscountsEditTable onChangeEditMode={setEditMode} organization={organization} />;
    }

    return (
        <PaymentDiscountsTable
            onChangeEditMode={setEditMode}
            paymentDiscounts={organization.paymentDiscounts}
            defaultPaymentDiscounts={organization.defaultPaymentDiscounts}
        />
    );
};

interface DiscountCellProps {
    value: Percent | null;
    defaultValue: Percent;
}

const DiscountCell: FC<DiscountCellProps> = ({ value, defaultValue }) => {
    const { formatPercent } = useDecimal();
    const isNotDefault = value !== null && value !== defaultValue;
    const defaultDiscount = formatPercent(defaultValue);
    return (
        <Box row>
            {formatPercent(value ?? defaultValue)}
            {isNotDefault && (
                <TooltipIcon
                    placement='top-start'
                    icon={SvgIcon.Asterisk}
                    iconSize={12}
                    body={`This is a custom discount for organization. Default is ${defaultDiscount}`}
                    marginBottom={10}
                />
            )}
        </Box>
    );
};

interface PaymentDiscountsEditTableProps {
    organization: OrganizationDetailsDto;
    onChangeEditMode(v: boolean): void;
}

const PaymentDiscountsEditTable: FC<PaymentDiscountsEditTableProps> = ({
    onChangeEditMode,
    organization,
}) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trRentalTerm = useTrRentalTerm();
    const {
        form,
        resetForm,
        isLoadingMutation,
        addEntry,
        deleteAtIndex,
        setStateAtIndex,
        isMutationSuccess,
    } = useUpdateOrganizationPaymentDiscounts(organization.id, organization.paymentDiscounts);
    useEffect(() => {
        if (isMutationSuccess) {
            onChangeEditMode(false);
        }
    }, [isMutationSuccess]);

    return (
        <Box column fullHeight>
            <SortFilterDataTable
                data={form.values.paymentDiscounts}
                toKey={discount => discount.id}
                flex={1}
                emptyContent={
                    <Placeholder
                        illustration={SvgIllustration.FinanceSearch}
                        title='No payment discounts'
                        message='No payment discounts for this organization'
                    />
                }
                columns={[
                    {
                        id: 'precedence',
                        header: 'Precedence',
                        flexGrow: 1,
                        renderer: (entry, index) => {
                            return (
                                <FormNumberInput
                                    value={entry.precedence}
                                    onChange={precedence =>
                                        setStateAtIndex(index, {
                                            precedence,
                                        })
                                    }
                                    isError={!!form.currentErrors.paymentDiscounts[index].precedence}
                                    errorMessage={form.currentErrors.paymentDiscounts[index].precedence}
                                />
                            );
                        },
                    },
                    {
                        id: 'discount_percent',
                        header: 'Discount Percent',
                        flexGrow: 1,
                        renderer: (entry, index) => {
                            return (
                                <FormFractionInput
                                    unit='%'
                                    flex={1}
                                    value={entry.discountPercent}
                                    onChange={discountPercent =>
                                        setStateAtIndex(index, {
                                            discountPercent,
                                        })
                                    }
                                    isError={!!form.currentErrors.paymentDiscounts[index].discountPercent}
                                    errorMessage={form.currentErrors.paymentDiscounts[index].discountPercent}
                                />
                            );
                        },
                    },
                    {
                        id: 'payment_schema',
                        header: 'Payment Schema',
                        flexGrow: 1,
                        renderer: (entry, index) => {
                            return (
                                <FormDropdown
                                    emptyContent='Payment schema'
                                    items={allPaymentSchemasWithoutDeposit}
                                    labelFormatter={trPaymentSchema}
                                    onChange={paymentSchema =>
                                        setStateAtIndex(index, {
                                            paymentSchema,
                                        })
                                    }
                                    value={entry.paymentSchema}
                                    clearable={false}
                                    isError={
                                        form.currentErrors.paymentDiscounts[index].paymentSchema ||
                                        form.currentErrors.paymentDiscounts[index].termSchemaInvalid
                                    }
                                />
                            );
                        },
                    },
                    {
                        id: 'rental_term',
                        header: 'Rental term',
                        flexGrow: 1,
                        renderer: (entry, index) => {
                            return (
                                <FormDropdown
                                    emptyContent='Rental term'
                                    items={allRentalTerms}
                                    labelFormatter={trRentalTerm}
                                    onChange={rentalTerm =>
                                        setStateAtIndex(index, {
                                            rentalTerm,
                                        })
                                    }
                                    value={entry.rentalTerm}
                                    clearable={false}
                                    isError={
                                        form.currentErrors.paymentDiscounts[index].rentalTerm ||
                                        form.currentErrors.paymentDiscounts[index].termSchemaInvalid
                                    }
                                />
                            );
                        },
                    },
                    {
                        id: 'valid_starts_on',
                        header: 'Starts On',
                        flexGrow: 1,
                        renderer: (entry, index) => {
                            return (
                                <DateInput
                                    value={entry.validStartOn}
                                    onChange={validStartOn =>
                                        setStateAtIndex(index, {
                                            validStartOn,
                                        })
                                    }
                                    nullable
                                />
                            );
                        },
                    },
                    {
                        id: 'valid_end_on',
                        header: 'Ends On',
                        flexGrow: 1,
                        renderer: (entry, index) => (
                            <DateInput
                                value={entry.validEndOn}
                                onChange={validEndOn =>
                                    setStateAtIndex(index, {
                                        validEndOn,
                                    })
                                }
                                nullable
                                flex={1}
                            />
                        ),
                    },
                    {
                        id: 'delete',
                        header: '',
                        flexShrink: 0,
                        width: 30,
                        flexGrow: 0,
                        renderer: (_, index) => {
                            return (
                                <Box flex={1} row justify='flex-end'>
                                    <IconButton icon={SvgIcon.Trash} onClick={e => deleteAtIndex(e, index)} />
                                </Box>
                            );
                        },
                    },
                ]}
            />
            <Footer justify='flex-end'>
                <Button
                    type='secondary'
                    width={100}
                    marginRight={20}
                    disabled={isLoadingMutation}
                    label='Cancel'
                    onClick={() => {
                        resetForm();
                        onChangeEditMode(false);
                    }}
                />
                <Button
                    type='secondary'
                    width={100}
                    marginRight={20}
                    disabled={isLoadingMutation}
                    leftIcon={SvgIcon.Add}
                    label='Add'
                    onClick={addEntry}
                />
                <Button
                    width={100}
                    label='Save'
                    disabled={isLoadingMutation}
                    onClick={() => {
                        form.submit();
                    }}
                />
            </Footer>
        </Box>
    );
};

interface PaymentDiscountsTableProps {
    paymentDiscounts: OrganizationPaymentDiscountDto[];
    defaultPaymentDiscounts: PaymentDiscounts;
    onChangeEditMode(v: boolean): void;
}

const PaymentDiscountsTable: FC<PaymentDiscountsTableProps> = ({
    paymentDiscounts,
    defaultPaymentDiscounts,
    onChangeEditMode,
}) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trRentalTerm = useTrRentalTerm();
    const { hasPermission } = useSession();
    const discountsMap = useMemo(() => discountsToMap(paymentDiscounts, now()), [paymentDiscounts]);

    const renderDiscountCell = (term: RentalTerm, schema: PaymentSchema) => {
        if (
            schema === PaymentSchema.Annual &&
            (term === RentalTerm.Rental6Months || term === RentalTerm.Rental12Months)
        ) {
            return <>Same as Upfront</>;
        }
        const discountSchema = discountsMap[schema];
        const defaultDiscountSchema = defaultPaymentDiscounts[schema];
        return <DiscountCell value={discountSchema[term]} defaultValue={defaultDiscountSchema[term]!} />;
    };

    return (
        <Box column fullHeight>
            <BaseTable
                data={[
                    RentalTerm.Rental6Months,
                    RentalTerm.Rental12Months,
                    RentalTerm.Rental24Months,
                    RentalTerm.Rental36Months,
                ]}
                toKey={term => term}
                width='100%'
                flex='auto'
                columns={[
                    {
                        id: 'discounts',
                        header: 'Discounts',
                        renderer: term => <TextCell>{trRentalTerm(term)}</TextCell>,
                    },
                    ...allPaymentSchemasWithoutDeposit.map(schema => ({
                        id: schema,
                        header: trPaymentSchema(schema),
                        renderer: (term: RentalTerm) => (
                            <TextCell>{renderDiscountCell(term, schema)}</TextCell>
                        ),
                    })),
                ]}
            />
            {hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) && (
                <Footer justify='flex-end'>
                    <Button label='Edit' width={100} onClick={() => onChangeEditMode(true)} />
                </Footer>
            )}
        </Box>
    );
};
