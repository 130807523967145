import styled, { CSSObject } from 'styled-components';

import { Color, ColorKeywords } from '@hofy/theme';

import { MarginBoxProps, marginStyleGenerator } from '../base';
import { SvgImage } from './SvgImage';

interface RawIconProps extends MarginBoxProps {
    svg: Svg;
    size?: number;
    block?: boolean;
}

export const RawIcon = styled(SvgImage)<RawIconProps>(({ theme, size, block, ...props }) => {
    const styles: CSSObject = {
        display: block ? 'block' : 'inline-block',
        fill: 'currentColor',
    };

    const margins = marginStyleGenerator(props, theme);

    if (size) {
        styles.width = `${size}px`;
        styles.height = `${size}px`;
        styles.flexShrink = 0;
    }

    return { ...styles, ...margins };
});

export interface IconProps extends RawIconProps {
    color?: Color | ColorKeywords;
}

export const Icon = styled(RawIcon)<IconProps>(({ color = Color.ContentPrimary }) => ({ color }));
