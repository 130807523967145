import React, { FC } from 'react';

import {
    allSubscriptionBillingFrequencies,
    allSubscriptionTerms,
    SubscriptionBillingFrequency,
    SubscriptionPayload,
    useTrSubscriptionBillingFrequency,
    useTrSubscriptionTerm,
} from '@hofy/api-admin';
import { FormContainer, FormDateInput, FormPriceInput, FormSelect, UseForm } from '@hofy/ui';

interface SubscriptionFormProps {
    form: UseForm<SubscriptionPayload>;
    organizationBased: boolean;
}

export const SubscriptionForm: FC<SubscriptionFormProps> = ({ form, organizationBased }) => {
    const trBillingFrequency = useTrSubscriptionBillingFrequency();
    const trTerm = useTrSubscriptionTerm();
    const allowedFrequencies = organizationBased
        ? allSubscriptionBillingFrequencies
        : [SubscriptionBillingFrequency.Monthly];

    return (
        <FormContainer marginTop={40} marginBottom={40}>
            <FormDateInput label='Starts on' api={form.fields.startOn} />
            <FormSelect
                label='Subscription term'
                api={form.fields.term}
                options={allSubscriptionTerms}
                toText={trTerm}
                isRequired
            />
            <FormSelect
                label='Billing frequency'
                api={form.fields.billingFrequency}
                options={allowedFrequencies}
                toText={trBillingFrequency}
                isRequired
            />
            <FormPriceInput label='Price' api={form.fields.price} isRequired />
        </FormContainer>
    );
};
