import React, { FC } from 'react';

import { Box, Paragraph3, Paragraph4 } from '@hofy/ui';

interface IdentifierCellProps {
    id: number;
    publicId: string;
}

export const IdentifierCell: FC<IdentifierCellProps> = ({ id, publicId }) => (
    <Box column overflow='hidden' marginRight={20}>
        <Paragraph3 bold>#{id}</Paragraph3>
        <Paragraph4 textNoWrap ellipsis overflow='hidden'>
            {publicId}
        </Paragraph4>
    </Box>
);
