import React, { FC } from 'react';

import { HofyWarehouseDetailsDto, StockLevelsDto } from '@hofy/api-admin';
import { formatVariant } from '@hofy/api-shared';
import {
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';
import { StockLevelCell } from './components/StockLevelCell';

interface StockLevelsTableProps {
    warehouses: HofyWarehouseDetailsDto[];
    stockLevels: StockLevelsDto[];
    infinityScroll: InfiniteScrollConfig;
}

export const StockLevelsTable: FC<StockLevelsTableProps> = ({ stockLevels, warehouses, infinityScroll }) => {
    return (
        <InfinityScrollTable
            data={stockLevels}
            toKey={stockLevel => stockLevel.variant.id}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Parcel}
                    title='No products'
                    message='No products for selected criteria'
                />
            }
            minWidth={1300}
            columns={[
                {
                    id: 'sku',
                    header: 'SKU/MPN',
                    width: 200,
                    flexGrow: 0,
                    renderer: stockLevel => (
                        <Box>
                            <Paragraph3>{stockLevel.variant.sku || '--'}</Paragraph3>
                            <Paragraph3>{stockLevel.variant.manufacturerPartCode || '--'}</Paragraph3>
                        </Box>
                    ),
                },
                {
                    id: 'product',
                    header: 'Product',
                    flexGrow: 1,
                    renderer: stockLevel => (
                        <ProductItem
                            image={stockLevel.variant.image}
                            label={stockLevel.product.name}
                            subLabel={formatVariant(stockLevel.variant)}
                            category={stockLevel.product.category}
                        />
                    ),
                },
                ...warehouses.map(w => ({
                    id: w.idDeprecated.toString(),
                    header: w.name,
                    width: 160,
                    flexGrow: 0,
                    renderer: (stockLevel: StockLevelsDto) => (
                        <StockLevelCell
                            stockLevel={stockLevel.stockLevel[w.idDeprecated]}
                            sku={stockLevel.variant.sku!}
                        />
                    ),
                })),
            ]}
        />
    );
};
