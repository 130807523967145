import React, { FC, useState } from 'react';

import { Button, ConfirmModal, Modals, SvgIcon } from '@hofy/ui';

import { useDeferCollectionRequest } from '../../../store/collectionRequests/useDeferCollectionRequest';
import { useUndoDeferCollectionRequest } from '../../../store/collectionRequests/useUndoDeferCollectionRequest';

enum CollectionRequestModals {
    DeferModal = 'defer_modal',
    UndoDeferModal = 'undo_defer_modal',
}

interface DeferCollectionSurveyButtonProps {
    collectionRequestId: number;
    deferred: boolean;
    shipmentId: number;
}

export const DeferCollectionSurveyButton: FC<DeferCollectionSurveyButtonProps> = ({
    collectionRequestId,
    deferred,
    shipmentId,
}) => {
    const [showModal, setShowModal] = useState<CollectionRequestModals | null>(null);
    const { mutate: defer, isPending: isDeferring } = useDeferCollectionRequest(
        shipmentId,
        collectionRequestId,
    );
    const { mutate: undoDefer, isPending: isUndoing } = useUndoDeferCollectionRequest(
        shipmentId,
        collectionRequestId,
    );
    return (
        <>
            {deferred ? (
                <Button
                    type='secondary'
                    leftIcon={SvgIcon.Undo}
                    label='Undo defer'
                    onClick={() => setShowModal(CollectionRequestModals.UndoDeferModal)}
                />
            ) : (
                <Button
                    type='secondary'
                    leftIcon={SvgIcon.Archive}
                    label='Defer'
                    onClick={() => setShowModal(CollectionRequestModals.DeferModal)}
                />
            )}
            <Modals>
                {showModal === CollectionRequestModals.DeferModal && (
                    <ConfirmModal
                        keyPrefix='defer-collection-survey-modal'
                        onClose={() => {
                            setShowModal(null);
                        }}
                        isLoading={isDeferring}
                        onConfirm={defer}
                    />
                )}
                {showModal === CollectionRequestModals.UndoDeferModal && (
                    <ConfirmModal
                        keyPrefix='undo-defer-collection-survey-modal'
                        onClose={() => {
                            setShowModal(null);
                        }}
                        isLoading={isUndoing}
                        onConfirm={undoDefer}
                    />
                )}
            </Modals>
        </>
    );
};
