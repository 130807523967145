import React, { FC, ReactNode } from 'react';

import { renderTextNode } from '../../../helpers';
import { Box, Heading3 } from '../../base';
import { FeaturedIcon } from '../../icon';

interface PageHeaderProps {
    icon?: Svg;
    title?: ReactNode;
    titleSlot?: ReactNode;
    rightSlot?: ReactNode;
    tabsSlot?: ReactNode;
    bottomSlot?: ReactNode;
}

export const PageHeader: FC<PageHeaderProps> = ({
    icon,
    title,
    titleSlot,
    rightSlot,
    tabsSlot,
    bottomSlot,
}) => {
    return (
        <Box
            paddingTop={16}
            paddingBottom={tabsSlot || bottomSlot ? 0 : 16}
            paddingHorizontal='mainMarginHorizontal'
            borderBottom
            data-test-key='header'
        >
            <Box paddingVertical={16} row gap={16}>
                {icon && <FeaturedIcon shape='square' size={40} icon={icon} />}
                {renderTextNode(title, text => (
                    <Heading3 data-test-key='title' lineHeight='large' textClomp={1}>
                        {text}
                    </Heading3>
                ))}
                {titleSlot}
                {rightSlot && (
                    <Box row gap={16} marginLeft='auto'>
                        {rightSlot}
                    </Box>
                )}
            </Box>
            {tabsSlot}
            {bottomSlot && <Box marginBottom={24}>{bottomSlot}</Box>}
        </Box>
    );
};
