import { useMutation, useQueryClient } from '@tanstack/react-query';

import { billingEntitiesCacheKey, billingEntitiesService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useUpdateCredit = (billingEntityId: number) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const accountSyncMutation = useMutation({
        mutationFn: (billingEntityId: number) => billingEntitiesService.updateCredit(billingEntityId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [billingEntitiesCacheKey, billingEntityId] });
            showToast({
                type: 'positive',
                message: 'Credit updated',
            });
        },
    });

    const updateCredit = () => {
        accountSyncMutation.mutate(billingEntityId);
    };

    return {
        updateCredit,
    };
};
