import { useQuery } from '@tanstack/react-query';

import { sessionService } from '@hofy/api-auth';

export const sessionCacheKey = 'auth/session';

export const useSessionQuery = () => {
    const { data, isLoading } = useQuery({
        queryKey: [sessionCacheKey],
        queryFn: sessionService.getSession,
        refetchOnWindowFocus: false,
        retry: false,
    });

    return {
        session: data,
        isInitialized: !isLoading,
    };
};
