import React, { FC } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { AddItemSlideout } from '../inventoryPage/stockLevels/addItem/AddItemSlideout';
import { ItemSlideoutRouter } from './ItemSlideoutRouter';
import { ItemsPage } from './ItemsPage';

export const ItemsRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const handleOpenItemDetails = (id: number) =>
        history.push(`${path}/item/${id}${history.location.search}`);
    const handleOpenItemNewSlideout = () => history.push(`${path}/item/new`);
    const handleSlideoutClose = () => history.push(`${path}${history.location.search}`);

    return (
        <>
            <ItemsPage onOpenItemDetails={handleOpenItemDetails} onOpenNewItem={handleOpenItemNewSlideout} />
            <Switch>
                <Route path={`${path}/item/new`} exact>
                    <AddItemSlideout onClose={handleSlideoutClose} />
                </Route>
                <ItemSlideoutRouter base={`${path}/item`} />
            </Switch>
        </>
    );
};
