import React, { FC } from 'react';

import { SupplierDetailDto } from '@hofy/api-admin';
import { SupplierPaymentTerms, useTrPaymentTerms } from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import { CountryLabel } from '@hofy/core';
import { usePrice } from '@hofy/hooks';
import { useTrCurrency } from '@hofy/i18n';
import { Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../../../components/domain/auditingInline/AuditSection';
import { NetsuiteStatusChip } from '../../../../../components/domain/netsuite/NetsuiteStatusChip';
import { useTrSupplierType } from '../../../../../store/suppliers/useTrSupplierType';
import { SupplierStatusChip } from '../../components/SupplierStatusChip';
import { DropShipAvailableList } from './DropShipAvailableList';

interface SupplierDetailsTabProps {
    supplier: SupplierDetailDto;
}

export const SupplierDetailsTab: FC<SupplierDetailsTabProps> = ({ supplier }) => {
    const trSupplierType = useTrSupplierType();
    const trPaymentTerms = useTrPaymentTerms<SupplierPaymentTerms>(SupplierPaymentTerms);
    const trCurrency = useTrCurrency();
    const { formatPrice } = usePrice();
    const billingAddress = [
        supplier.billingAddress.line1,
        supplier.billingAddress.line2,
        supplier.billingAddress.city,
        supplier.billingAddress.state,
        supplier.billingAddress.postCode,
    ].filter(item => !!item);
    const shippingAddress = [
        supplier.shippingAddress.line1,
        supplier.shippingAddress.line2,
        supplier.shippingAddress.city,
        supplier.shippingAddress.state,
        supplier.shippingAddress.postCode,
    ].filter(item => !!item);

    return (
        <FormGridRow columns={2} marginRight={20} marginVertical={30}>
            <Box flex={1} paddingHorizontal={40} column gap={30}>
                <FormSection label='Supplier'>
                    <FormRow>
                        <LabeledText flex={1} label='Name' content={supplier.legalName} />
                        <LabeledText flex={1} label='Id' content={supplier.idDeprecated} />
                    </FormRow>
                    <FormRow>
                        <LabeledText flex={1} label='Type' content={trSupplierType(supplier.supplierType)} />
                        <Labeled
                            flex={1}
                            label='Status'
                            content={<SupplierStatusChip status={supplier.supplierStatus} />}
                        />
                    </FormRow>
                    <FormRow>
                        <LabeledText label='Notes' content={supplier.notes ?? '--'} />
                    </FormRow>
                </FormSection>
                <FormSection label='Billing information'>
                    <FormRow>
                        <LabeledText
                            flex={1}
                            label='Tax registration number'
                            content={supplier.taxRegistrationNumber}
                        />
                        <LabeledText
                            flex={1}
                            label='Company registration number'
                            content={supplier.companyRegistrationNumber}
                        />
                    </FormRow>
                    <FormRow>
                        <LabeledText
                            flex={1}
                            label='Payment terms'
                            content={trPaymentTerms(supplier.paymentTerm)}
                        />
                        <LabeledText flex={1} label='Currency' content={trCurrency(supplier.currency)} />
                        {supplier.paymentTerm !== SupplierPaymentTerms.TermsPrepaid && (
                            <LabeledText
                                flex={1}
                                label='Credit limit'
                                content={
                                    supplier.unlimitedCredit
                                        ? 'Unlimited'
                                        : formatPrice(supplier.creditLimit, 2)
                                }
                            />
                        )}
                    </FormRow>
                </FormSection>
                <FormSection label='Localization'>
                    <FormRow>
                        <Labeled
                            flex={1}
                            label='Billing country'
                            content={<CountryLabel country={supplier.billingAddress.country} />}
                        />
                        <Labeled
                            flex={1}
                            label='Shipping country'
                            content={<CountryLabel country={supplier.shippingAddress.country} />}
                        />
                    </FormRow>
                    <FormRow>
                        <LabeledText
                            flex={1}
                            label='Billing address'
                            content={
                                billingAddress.length > 0
                                    ? billingAddress.map((item, index) => <Box key={index}>{item}</Box>)
                                    : null
                            }
                        />
                        <LabeledText
                            flex={1}
                            label='Shipping address'
                            content={
                                shippingAddress.length > 0
                                    ? shippingAddress.map((item, index) => <Box key={index}>{item}</Box>)
                                    : null
                            }
                        />
                    </FormRow>
                </FormSection>
                <FormSection label='Accounting'>
                    <Labeled label='Netsuite' content={<NetsuiteStatusChip status={supplier.netsuite} />} />
                </FormSection>
                <AuditSection model={supplier} />
            </Box>
            <Box flex={1}>
                <FormSection label='Dropship supported countries'>
                    <DropShipAvailableList countries={supplier.countries} />
                </FormSection>
            </Box>
        </FormGridRow>
    );
};
