import { useQuery } from '@tanstack/react-query';

import { contractService } from '@hofy/api-admin';

const storeAndReuseFeeQueryKey = 'contract-store-and-reuse-fee';

export const useGetRentalStoreAndReuseFee = (contractId: number | null) => {
    const { isLoading, data } = useQuery({
        queryKey: [storeAndReuseFeeQueryKey, contractId],
        queryFn: () => contractService.getRentalStoreAndReuseFee(contractId!),
        enabled: !!contractId,
    });

    return {
        data,
        isLoading,
    };
};
