import { PasswordPayload, PasswordPayloadValidation } from './PasswordPayload';

export interface SignUpFormPayload extends PasswordPayload {
    termsAndConditions: boolean;
}

export const emptySignUpFormPayload: SignUpFormPayload = {
    password: '',
    confirmedPassword: '',
    termsAndConditions: false,
};

export interface SignUpFormPayloadValidation extends PasswordPayloadValidation {
    termsAndConditions?: string;
}

export interface SignUpConnectionFormPayloadValidation {
    termsAndConditions?: string;
}

export const emptySignUpConnectionFormPayload: SignUpConnectionFormPayload = {
    termsAndConditions: false,
};

export interface SignUpConnectionFormPayload {
    termsAndConditions: boolean;
}
