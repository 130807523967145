import { keyBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import { useHofyWarehousesQuery } from '@hofy/api-admin';
import { useI18nGeneric } from '@hofy/i18n';
import { Box, CountryFlag, LabeledSelectSearch, Paragraph3 } from '@hofy/ui';

export interface LabeledHofyWarehouseSelectProps {
    label: string;
    value: number | null;
    onChange(v: number): void;
    isRequired?: boolean;
    errorMessage?: string;
    onBlur?(): void;
    nullable?: boolean;
}

export const HofyWarehouseSelect: FC<LabeledHofyWarehouseSelectProps> = ({
    label,
    value,
    onChange,
    isRequired,
    errorMessage,
    onBlur,
    nullable,
}) => {
    const { tr } = useI18nGeneric();

    const { data: warehouses, isLoading, isError } = useHofyWarehousesQuery();

    const placeholder = (() => {
        if (isLoading) {
            return tr('general.placeholder-loading-search-results');
        }

        if (isError) {
            return tr('ui.error.title');
        }

        return tr('ui.dropdown.search-placeholder');
    })();

    const nameMap = useMemo(() => keyBy(warehouses, v => v.idDeprecated), [warehouses]);

    return (
        <LabeledSelectSearch
            label={label}
            onBlur={onBlur}
            placeholder={placeholder}
            toText={warehouse => nameMap[warehouse]?.name}
            toLabel={warehouse => (
                <Box row gap={8}>
                    <CountryFlag country={nameMap[warehouse]?.address?.country} />
                    <Paragraph3 size={13}>{nameMap[warehouse]?.name}</Paragraph3>
                </Box>
            )}
            value={value}
            onChange={onChange}
            options={warehouses.map(v => v.idDeprecated)}
            isRequired={isRequired}
            errorMessage={errorMessage}
            nullable={nullable}
        />
    );
};
