import React, { FC } from 'react';

import { ColorDye } from '@hofy/global';
import { useBaseI18n } from '@hofy/i18n';

import { Badge } from './Badge';

interface BooleanBadgeProps {
    value: boolean;
    offColor?: ColorDye;
    onColor?: ColorDye;
}

export const BooleanBadge: FC<BooleanBadgeProps> = ({ value, offColor = 'red', onColor = 'green' }) => {
    const { tr } = useBaseI18n();

    const color: ColorDye = value ? onColor : offColor;
    const title = tr(value ? 'ui.yes' : 'ui.no');

    return <Badge color={color} label={title} />;
};
