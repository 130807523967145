import React, { FC } from 'react';

import { ContractDetailsDtoWithRental } from '@hofy/api-admin';
import {
    BackButton,
    ComponentLoader,
    RadioGroup,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { Color } from '@hofy/theme';
import { Alert, AsyncButton, FormSection, Paragraph3, SvgIcon } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import {
    allContractUpdateModes,
    ContractUpdateMode,
} from '../../../store/contracts/types/ContractUpdateMode';
import { useUpdateRentalContract } from '../../../store/contracts/useUpdateRentalContract';
import { AutoRentalContractForm } from './AutoRentalContractForm';
import { ManualRentalContractForm } from './ManualRentalContractForm';

interface UpdateRentalContractSlideoutProps {
    contract: ContractDetailsDtoWithRental;
    onClose(): void;
}

export const UpdateRentalContractSlideout: FC<UpdateRentalContractSlideoutProps> = ({
    onClose,
    contract,
}) => {
    const {
        manualFormState,
        autoFormState,
        updateMode,
        setUpdateMode,
        isLoading,
        organizationId,
        submit,
        mainSubContract,
    } = useUpdateRentalContract(contract, onClose);

    const content = () => {
        if (!organizationId) {
            return <ComponentLoader />;
        }

        switch (updateMode) {
            case ContractUpdateMode.Manual:
            case ContractUpdateMode.WithCredit:
                return (
                    <ManualRentalContractForm organizationId={organizationId} formState={manualFormState} />
                );
            case ContractUpdateMode.Auto:
                return <AutoRentalContractForm form={autoFormState} />;
        }
    };
    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title='Update contract' />
            <SlideoutContent borderBottom paddingBottom={30}>
                {mainSubContract?.hasReadonlyEntries ? (
                    <Alert
                        marginVertical={16}
                        type='warning'
                        title='This contract has been invoiced'
                        description='Any update will fork the existing contract and create
                            a new subcontract with new settings'
                    />
                ) : (
                    <Alert
                        marginVertical={16}
                        type='informative'
                        title='This contract has not yet been invoiced'
                        description='Invoice lines will be replaced'
                    />
                )}
                <FormSection label='Update mode' marginBottom={20}>
                    <RadioGroup
                        items={allContractUpdateModes}
                        onChange={setUpdateMode}
                        value={updateMode}
                        orientation='horizontal'
                        spacing={8}
                        row
                        labelFormatter={(p: ContractUpdateMode) => (
                            <Paragraph3 marginRight={8} color={Color.Neutral700}>
                                {p}
                            </Paragraph3>
                        )}
                    />
                </FormSection>
                {content()}
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton
                    defaultNavigation={`${AdminNavLink.Contracts}/${contract.idDeprecated}/details`}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton isLoading={isLoading} label='Save' onClick={submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
