import { useMutation, useQueryClient } from '@tanstack/react-query';

import { billingEntitiesCacheKey, billingEntitiesService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { useBillingEntityForm } from './useBillingEntityForm';

export const useCreateUpdateBillingEntity = (
    onSuccess: () => void,
    organizationId: UUID | null,
    billingEntityId?: number,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: billingEntitiesService.createUpdateBillingEntity,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [billingEntitiesCacheKey] });
            showToast({
                type: 'positive',
                message: 'Billing entity saved successfully',
            });
            onSuccess();
        },
    });

    return useBillingEntityForm(mutation, organizationId, billingEntityId);
};
