import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { HofyWarehouseDetailsDto, useHofyWarehousesQuery } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { useBaseI18n, useTrCountry } from '@hofy/i18n';
import { BaseTable, Box, TextCell } from '@hofy/ui';

import { useTrHofyWarehouseType } from '../../../store/warehouses/useTrHofyWarehouseType';

interface WarehousesTableProps {
    onUpdateClick(id: number): void;
}

export const WarehouseTable: FC<WarehousesTableProps> = ({ onUpdateClick }) => {
    const { data: warehouses, isLoading } = useHofyWarehousesQuery();
    const { tr } = useBaseI18n();
    const trCountry = useTrCountry();
    const trWarehouseType = useTrHofyWarehouseType();
    const { hasPermission } = useSession();
    const editable = hasPermission(Permission.AdminWarehouseManage);

    const handleRowClick = (warehouse: HofyWarehouseDetailsDto): void =>
        onUpdateClick(warehouse.idDeprecated);

    return (
        <Box column flex='auto'>
            <BaseTable
                data={warehouses}
                toKey={warehouse => warehouse.id}
                onRowClick={editable ? handleRowClick : undefined}
                isLoading={isLoading}
                flex='auto'
                columns={[
                    {
                        id: 'uuid',
                        header: 'UUID',
                        width: 200,
                        flexGrow: 1,
                        renderer: warehouse => warehouse.id,
                    },
                    {
                        id: 'warehouseName',
                        header: 'Warehouse name',
                        width: 200,
                        flexGrow: 1,
                        renderer: warehouse => warehouse.name,
                    },
                    {
                        id: 'warehouseAddress',
                        header: 'Warehouse address',
                        width: 300,
                        flexGrow: 1,
                        renderer: warehouse => (
                            <TextCell>
                                <PostAddressView address={warehouse.address} />
                            </TextCell>
                        ),
                    },
                    {
                        id: 'countries',
                        header: 'Countries',
                        width: 400,
                        flexGrow: 1,
                        renderer: warehouse => (
                            <TextCell whiteSpace='normal'>
                                {warehouse.hofyWarehouse.countries.map(item => trCountry(item)).join(', ')}
                            </TextCell>
                        ),
                    },
                    {
                        id: 'type',
                        header: 'Type',
                        flexGrow: 1,
                        renderer: warehouse => (
                            <TextCell>{trWarehouseType(warehouse.hofyWarehouse.type)}</TextCell>
                        ),
                    },
                    {
                        id: 'dataErasure',
                        header: 'Data erasure',
                        flexGrow: 1,
                        renderer: warehouse => (
                            <TextCell>
                                {tr(
                                    warehouse.hofyWarehouse.dataErasureSupported
                                        ? 'general.yes'
                                        : 'general.no',
                                )}
                            </TextCell>
                        ),
                    },
                ]}
            />
        </Box>
    );
};
