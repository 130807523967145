import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, ScanAssignedItemPayload } from '@hofy/api-admin';

export const useScanAssignedItem = (assignmentId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (p: ScanAssignedItemPayload) => assignmentService.scanAssignedItem(assignmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });
};
