import React, { FC, ReactNode } from 'react';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';

import { getEnumParam, getIntParam } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { RepaymentPlanDetailsTab } from '../../../store/repaymentPlan/types/RepaymentPlanDetailsTab';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { RepaymentPlanDetailsOverlay } from './repaymentPlanDetailsOverlay/RepaymentPlanDetailsOverlay';
import { RepaymentPlansPage } from './RepaymentPlansPage';

export const RepaymentPlansRouter: FC<InvoicingTabRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();

    const repaymentPlansNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.RepaymentPlans}`;
    const handleOpenPlan = (id: number, tab: RepaymentPlanDetailsTab, method: 'push' | 'replace') => {
        history[method](`${repaymentPlansNavLink}/${id}/${tab}`);
    };

    return (
        <Route path={repaymentPlansNavLink}>
            <RepaymentPlansPage
                tabs={tabs}
                tab={tab}
                onChangeTab={onChangeTab}
                onOpenPlan={id => handleOpenPlan(id, RepaymentPlanDetailsTab.Details, 'push')}
            />
            <RepaymentPlanOverlayRoute path={`${repaymentPlansNavLink}/:planId(\\d+)/:planTab`}>
                {({ planTab, planId }) => (
                    <RepaymentPlanDetailsOverlay
                        planId={planId}
                        planTab={planTab}
                        onChangeTab={tab => handleOpenPlan(planId, tab, 'replace')}
                    />
                )}
            </RepaymentPlanOverlayRoute>
        </Route>
    );
};

interface RepaymentPlanOverlayRouteProps {
    path: string;

    children(v: { planTab: RepaymentPlanDetailsTab; planId: number }): ReactNode;
}

const RepaymentPlanOverlayRoute: FC<RepaymentPlanOverlayRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const planId = getIntParam(p.match?.params || {}, 'planId');
        const planTab = getEnumParam<RepaymentPlanDetailsTab>(
            p.match?.params || {},
            'planTab',
            RepaymentPlanDetailsTab,
        );

        if (planId && planTab) {
            return children({
                planTab,
                planId,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
