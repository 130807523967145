import React, { FC } from 'react';

import {
    AssignmentsPageTab,
    useAdminsQuery,
    useHofyWarehousesQuery,
    UserAssignmentDto,
} from '@hofy/api-admin';
import {
    allOwnerships,
    allShipmentTypes,
    Ownership,
    Role,
    ShipmentType,
    useTrOwnership,
    useTrShipmentType,
} from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { Country, UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, DropdownList, LabeledSwitch, PageHeader, Paragraph3, SearchInput, SvgIcon } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../components/design/blockFilters/EnumBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { UsersBlockFilter } from '../../components/domain/user/UsersBlockFilter';
import { WarehouseBlockFilter } from '../../components/domain/warehouses/WarehouseBlockFilter';
import { useAdminI18n } from '../../i18n/useAdminI18n';
import { useAdminAssignmentsFilters } from '../../store/assignments/useAdminAssignmentsFilters';
import { SortOptions, useAdminAssignmentsSorting } from '../../store/assignments/useAdminAssignmentsSortings';
import { UserAssignmentSelectionRecord } from '../../store/assignments/useAssignmentsSelection';
import { AssignmentsPageTabs } from './AssignmentsPageTabs';
import { AssignmentsDateFilter } from './components/filters/blocks/AssignmentsDateFilter';
import { CollectionSurveyStatusBlockFilter } from './components/filters/blocks/CollectionSurveyStatusBlockFilter';
import { CountryBlockFilter } from './components/filters/blocks/CountryBlockFilter';
import { FilterBlockFilter } from './components/filters/blocks/FilterBlockFilter';
import { RegionBlockFilter } from './components/filters/blocks/RegionBlockFilter';
import { ShipmentStatusBlockFilter } from './components/filters/blocks/ShipmentStatusBlockFilter';
import { OrdersPageActiveFilterChips } from './components/filters/OrdersPageActiveFilterChips';
import { UsersShipments } from './components/users/UsersShipments';

interface AssignmentsPageProps {
    activeTab: AssignmentsPageTab;
    onTabChanged(tab: AssignmentsPageTab): void;
    selectedAssignments: Record<UUID, UserAssignmentSelectionRecord>;
    onToggleSelectedAssignments(a: UserAssignmentDto[]): void;
    onCreateShipment(userId: UUID, organizationId: UUID, country: Country, shipmentType: ShipmentType): void;
    onInPersonTransfer(userId: UUID, organizationId: UUID, country: Country): void;
}

export const AssignmentsPage: FC<AssignmentsPageProps> = ({
    activeTab,
    onTabChanged,
    selectedAssignments,
    onToggleSelectedAssignments,
    onCreateShipment,
    onInPersonTransfer,
}) => {
    const { trEnum } = useAdminI18n();
    const trShipmentType = useTrShipmentType();
    const trOwnership = useTrOwnership();
    const trSortOption = trEnum<SortOptions>(SortOptions, 'admin-order-sort-option');

    const {
        filters,
        filterCount,
        setShipmentStatus,
        setSearch,
        setAllShipments,
        setContainingLaptopOnly,
        setConfigRequired,
        setIsPending,
        setAssignedUsers,
        setUnassigned,
        setNewJoiners,
        setShipmentType,
        setFutureScheduled,
        setScheduledOnly,
        setRegion,
        setCountries,
        setWarehouses,
        setIsLoaner,
        setIsStoreAndReuse,
        setIsDisposal,
        setOrderType,
        setPartner,
        setScanned,
        setUnscanned,
        setCollectionSurveyStatus,
        setOtdDate,
        setShipDateRange,
        setDeliveryDate,
        setOwnership,
        setIsOnHold,
    } = useAdminAssignmentsFilters();

    const { sort, sortingOptions, handleSort } = useAdminAssignmentsSorting();

    const { data: admins } = useAdminsQuery(Role.FulfillmentAdmin, true);
    const { data: warehouses } = useHofyWarehousesQuery();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    const { futureScheduled, isPending, isOnHold, ...rest } = filters;
    const filterWithDefaults = {
        futureScheduled: activeTab === AssignmentsPageTab.All ? true : futureScheduled,
        isPending: showPendingOnly(activeTab, isPending),
        isOnHold: activeTab === AssignmentsPageTab.All ? isOnHold : isOnHold ?? false,
        ...rest,
    };

    return (
        <Page pageId='admin-assignments'>
            <PageHeader
                title='Assignments'
                rightSlot={
                    <Box gap={20} flex={1} justify='flex-end' row>
                        <Box row>
                            <Paragraph3 bold marginRight={4} color={Color.ContentPrimary}>
                                Sort:
                            </Paragraph3>
                            <DropdownList
                                options={sortingOptions}
                                value={sort}
                                onChange={handleSort}
                                toText={sort => trSortOption(sort.sortBy)}
                            />
                        </Box>
                        <LabeledSwitch
                            label='Completed'
                            checked={filterWithDefaults.isPending === false}
                            disabled={!isCompletedSwitchEnabled(activeTab)}
                            onChange={value => setIsPending(!value)}
                        />
                        <LabeledSwitch
                            label='Scheduled'
                            checked={!!filterWithDefaults.futureScheduled}
                            disabled={activeTab === AssignmentsPageTab.All}
                            onChange={setFutureScheduled}
                        />
                        <SearchInput
                            value={filters.search}
                            onChange={setSearch}
                            placeholder='Search customer, assignment, ...'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </Box>
                }
                tabsSlot={<AssignmentsPageTabs tab={activeTab} onChange={onTabChanged} />}
            />
            <BlockFilterContainer show={showFilters} ref={filterElRef}>
                <UsersBlockFilter
                    selected={filters.assignedUsers}
                    onChange={setAssignedUsers}
                    users={admins}
                />
                <EnumBlockFilter<ShipmentType>
                    title='Shipment type'
                    icon={SvgIcon.Box}
                    selected={filters.shipmentType}
                    onChange={setShipmentType}
                    items={allShipmentTypes}
                    renderItem={type => trShipmentType(type)}
                />
                <ShipmentStatusBlockFilter selected={filters.shipmentStatus} onChange={setShipmentStatus} />
                <CollectionSurveyStatusBlockFilter
                    selected={filters.collectionSurveyStatus}
                    onChange={setCollectionSurveyStatus}
                />
                <RegionBlockFilter selected={filters.region} onChange={setRegion} />
                <CountryBlockFilter selected={filters.countries} onChange={setCountries} />
                <WarehouseBlockFilter
                    warehouses={warehouses}
                    selected={filters.warehouses}
                    onChange={setWarehouses}
                />
                <EnumBlockFilter<Ownership>
                    title='Ownership'
                    icon={SvgIcon.Box}
                    selected={filters.ownership}
                    onChange={setOwnership}
                    items={allOwnerships}
                    renderItem={ownership => trOwnership(ownership)}
                />
                <FilterBlockFilter
                    filter={filters}
                    onAllShipmentsChange={setAllShipments}
                    onContainingLaptopOnlyChange={setContainingLaptopOnly}
                    onConfigRequiredChange={setConfigRequired}
                    onUnassignedChange={setUnassigned}
                    onNewJoinersChange={setNewJoiners}
                    onIsLoanerChange={setIsLoaner}
                    onIsStoreAndReuse={setIsStoreAndReuse}
                    onIsDisposalChange={setIsDisposal}
                    onScheduledOnlyChange={setScheduledOnly}
                    onPartnerChange={setPartner}
                    onScannedChange={setScanned}
                    onUnscannedChange={setUnscanned}
                    onIsOnHoldChange={setIsOnHold}
                />
                <AssignmentsDateFilter
                    otdDate={filters.otdDate}
                    onChangeOtd={setOtdDate}
                    shipDateRange={filters.shipDateRange}
                    onChangeShipRange={setShipDateRange}
                    deliverDate={filters.deliveryDate}
                    onChangeDeliver={setDeliveryDate}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <OrdersPageActiveFilterChips
                    filters={filters}
                    allUsers={admins}
                    onAllShipmentsChange={setAllShipments}
                    onCountriesChange={setCountries}
                    onAssignedUserChange={setAssignedUsers}
                    onContainingLaptopOnlyChange={setContainingLaptopOnly}
                    onConfigRequiredChange={setConfigRequired}
                    onShipmentStatusChange={setShipmentStatus}
                    onNewJoinersChange={setNewJoiners}
                    onShipmentTypeChange={setShipmentType}
                    onRegionChange={setRegion}
                    onIsLoanerChange={setIsLoaner}
                    onIsStoreAndReuseChange={setIsStoreAndReuse}
                    onIsDisposalChange={setIsDisposal}
                    onScheduledOnlyChange={setScheduledOnly}
                    onOrderTypeChange={setOrderType}
                    onPartnerChange={setPartner}
                    onScannedChange={setScanned}
                    onUnscannedChange={setUnscanned}
                    onCollectionSurveyStatusChange={setCollectionSurveyStatus}
                    onOtdChange={setOtdDate}
                    onShipRangeChange={setShipDateRange}
                    onDeliveryChange={setDeliveryDate}
                    onOwnershipChange={setOwnership}
                />
            </BlockFilterChipContainer>
            <UsersShipments
                filters={filterWithDefaults}
                tab={activeTab}
                sortOptions={sort}
                selectedAssignments={selectedAssignments}
                onToggleSelectedAssignments={onToggleSelectedAssignments}
                onCreateShipment={onCreateShipment}
                onInPersonTransfer={onInPersonTransfer}
            />
        </Page>
    );
};

// Completed switch

const completedSwitchAvailableTabs = [AssignmentsPageTab.Collection, AssignmentsPageTab.Delivery];

const showPendingOnly = (tab: AssignmentsPageTab, showIsPending: boolean | null): boolean | null => {
    if (completedSwitchAvailableTabs.includes(tab)) {
        return showIsPending;
    }

    return tab !== AssignmentsPageTab.All;
};

const isCompletedSwitchEnabled = (tab: AssignmentsPageTab): boolean => {
    return completedSwitchAvailableTabs.includes(tab);
};
