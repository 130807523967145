import { keyBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import { AdminInvoiceEntriesFilters, BillingEntityDto } from '@hofy/api-admin';
import { invoiceEntryTypeColor, taxStatusColors } from '@hofy/api-shared';
import { formatDateRange, parseDateTime } from '@hofy/helpers';
import { FilterChip } from '@hofy/ui';

import { useTrInvoiceEntryType } from '../../../../store/invoiceEntries/useTrInvoiceEntryType';
import { useTrTaxStatus } from '../../../../store/invoiceEntries/useTrTaxStatus';

interface InvoiceEntriesActiveFilterChipsProps {
    filters: AdminInvoiceEntriesFilters;
    billingEntities: BillingEntityDto[];
    onClearInvoiceDate(): void;
    onClearInvoiceEntryType(): void;
    onClearIsInvoiced(): void;
    onClearTaxStatus(): void;
    onClearDateRange(): void;
    onClearBillingEntity(id: number): void;
}

export const InvoiceEntriesActiveFilterChips: FC<InvoiceEntriesActiveFilterChipsProps> = ({
    filters,
    onClearInvoiceDate,
    onClearInvoiceEntryType,
    onClearIsInvoiced,
    onClearTaxStatus,
    onClearDateRange,
    onClearBillingEntity,
    billingEntities,
}) => {
    const trInvoiceEntryType = useTrInvoiceEntryType();
    const trTaxStatus = useTrTaxStatus();
    const billingEntityIdMap = useMemo(() => keyBy(billingEntities, v => v.idDeprecated), [billingEntities]);
    return (
        <>
            {filters.invoiceEntryType && (
                <FilterChip
                    label={trInvoiceEntryType(filters.invoiceEntryType)}
                    onClear={() => onClearInvoiceEntryType()}
                    color={invoiceEntryTypeColor[filters.invoiceEntryType]}
                />
            )}
            {filters.invoiceDate && (
                <FilterChip
                    label={parseDateTime(filters.invoiceDate!).toFormat('LLLL yyyy')}
                    onClear={() => onClearInvoiceDate()}
                    color='aquamarine'
                />
            )}
            {filters.isInvoiced !== null && (
                <FilterChip
                    label={filters.isInvoiced ? 'Invoiced' : 'Not invoiced'}
                    onClear={() => onClearIsInvoiced()}
                    color={filters.isInvoiced ? 'green' : 'red'}
                />
            )}
            {filters.taxStatus && (
                <FilterChip
                    label={trTaxStatus(filters.taxStatus)}
                    onClear={() => onClearTaxStatus()}
                    color={taxStatusColors[filters.taxStatus]}
                />
            )}
            {!!filters.billingEntity &&
                filters.billingEntity.map(b =>
                    billingEntityIdMap[b] ? (
                        <FilterChip
                            key={b}
                            label={billingEntityIdMap[b].name}
                            onClear={() => onClearBillingEntity(b)}
                            color='green'
                        />
                    ) : null,
                )}
            {filters.dateRange && (
                <FilterChip
                    label={formatDateRange(filters.dateRange)}
                    onClear={() => onClearDateRange()}
                    color='aquamarine'
                />
            )}
        </>
    );
};
