import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';

import {
    assignmentsCacheKey,
    CancelRepairPayload,
    itemCacheKey,
    repairsCacheKey,
    repairsService,
} from '@hofy/api-admin';
import { ItemStatus } from '@hofy/api-shared';
import { isRequired, RequiredKeys, useForm, useToast, validator } from '@hofy/ui';

interface CancelRepairFormData {
    itemStatus: ItemStatus | null;
}

export const useCancelRepair = (repairId: number, onSuccess = noop) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: CancelRepairPayload) => repairsService.cancelRepair(repairId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repairsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            showToast({
                type: 'positive',
                message: 'Repair cancelled successfully',
            });
            onSuccess();
        },
    });

    const form = useForm<CancelRepairFormData, RequiredKeys<CancelRepairFormData>>({
        initial: {
            itemStatus: null,
        },
        onSubmit: ({ itemStatus }) => {
            mutation.mutate({
                itemStatus,
            });
        },
        validate: validator<CancelRepairFormData>({
            itemStatus: isRequired('Item status is required when cancelling a repair'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
