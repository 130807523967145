import { restClient } from '@hofy/rest';

import { TermAndConditionDto } from './types/TermAndConditionDto';
import { TermAndConditionPayload } from './types/TermAndConditionPayload';

interface ListTermsAndConditionsResponse {
    termsAndConditions: TermAndConditionDto[];
}

class TermAndConditionService {
    public getTermsAndConditions = async (): Promise<TermAndConditionDto[]> => {
        const response = await restClient.getJson<ListTermsAndConditionsResponse>(
            '/api/admin/terms-and-conditions',
        );

        return response.termsAndConditions;
    };

    public updateTermAndCondition = async (id: number, payload: TermAndConditionPayload) => {
        return restClient.put(`/api/admin/term-and-condition/${id}`, payload);
    };
}

export const termAndConditionService = new TermAndConditionService();
