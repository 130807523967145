import React, { FC, ReactNode } from 'react';

import { Link } from '../../components/routing/Link';

interface ContractLinkProps {
    id: number;
    children?: ReactNode;
}

export const ContractLink: FC<ContractLinkProps> = ({ id, children }) => {
    return <Link to={`/contracts/${id}/details`}>{children}</Link>;
};

export const ContractLinkLabel: FC<{ id: number | null }> = ({ id }) => {
    return id ? <ContractLink id={id}>{`#${id}`}</ContractLink> : <>--</>;
};
