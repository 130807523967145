export const inspectionErrorI18n = {
    'api-error.invalid-inspection-status.title': 'Invalid inspection status',
    'api-error.invalid-inspection-status.message': 'Cannot perform this operation',
    'api-error.device-check-not-required.title': 'Device check not required',
    'api-error.device-check-not-required.message': 'Cannot perform this operation',
    'api-error.invalid-assigned-admin.title': 'Invalid assigned admin',
    'api-error.invalid-assigned-admin.message': 'Cannot perform this operation',
    'api-error.invalid-assigned-bin.title': 'Invalid assigned bin',
    'api-error.invalid-assigned-bin.message': 'Cannot perform this operation',
    'api-error.no-longer-assignable-inspection.title': 'No longer assignable inspection',
    'api-error.no-longer-assignable-inspection.message': 'Cannot perform this operation',
    'api-error.activation-lock-removal-not-required.title': 'Activation lock removal not required',
    'api-error.activation-lock-removal-not-required.message': 'Cannot perform this operation',
    'api-error.org-mdm-removal-not-required.title': 'Org mdm removal not required',
    'api-error.org-mdm-removal-not-required.message': 'Cannot perform this operation',
    'api-error.device-check-pending-inspection-completion.title':
        'Device check pending inspection completion',
    'api-error.device-check-pending-inspection-completion.message': 'Cannot perform this operation',
    'api-error.missing-grade.title': 'Missing grade',
    'api-error.missing-grade.message': 'Cannot perform this operation',
    'api-error.missing-damaged-part-note.title': 'Missing damaged part note',
    'api-error.missing-damaged-part-note.message': 'Cannot perform this operation',
    'api-error.missing-missing-part-note.title': 'Missing missing part note',
    'api-error.missing-missing-part-note.message': 'Cannot perform this operation',
    'api-error.missing-cleaning.title': 'Missing cleaning',
    'api-error.missing-cleaning.message': 'Cannot perform this operation',
    'api-error.missing-accessory-reset.title': 'Missing accessory reset',
    'api-error.missing-accessory-reset.message': 'Cannot perform this operation',
    'api-error.invalid-device-check-status.title': 'Invalid device check status',
    'api-error.invalid-device-check-status.message': 'Cannot perform this operation',
};
