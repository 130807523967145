import { useQuery } from '@tanstack/react-query';

import { BillingEntitiesFilter, billingEntitiesService } from '../service/billingEntityService';
import { billingEntitiesCacheKey } from './cacheKey';

export const useBillingEntitiesQuery = (filters?: BillingEntitiesFilter) => {
    const { isLoading, data } = useQuery({
        queryKey: [billingEntitiesCacheKey, filters],

        queryFn: () => billingEntitiesService.getBillingEntities(filters),
    });

    return {
        isLoading,
        billingEntities: data || [],
    };
};

export const useBillingEntityQuery = (billingEntityId?: number) => {
    const { isLoading, data } = useQuery({
        queryKey: [billingEntitiesCacheKey, billingEntityId],
        queryFn: () => billingEntitiesService.getBillingEntity(billingEntityId!),
        enabled: !!billingEntityId,
    });
    return {
        isLoading,
        billingEntity: data,
    };
};
